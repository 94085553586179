import {
	Box,
	Button,
	FormControl,
	FormHelperText,
	IconButton,
	TextField,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { useFormik } from "formik";
import EditDialog from "src/components/EditDialog";
import { useLocale } from "src/provider/LocaleProvider";
import formikProps from "src/utils/formikProps";

import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import useApi from "src/api/api";
import ClassSelector from "src/components/ClassSelector";
import { SubjectSelector } from "src/components/SubjectSelector";
import Icons from "src/icons";
import { useSnack } from "src/provider/SnackbarProvider";
const yup = require("yup");

export const ResourceCreateDialog = ({
	setDialogMode,
	isEdit = false,
	isOpen = false,
	selectedRow = {},
}) => {
	const { t } = useLocale();
	const api = useApi();
	const snack = useSnack();
	const queryClient = useQueryClient();
	const [isUploading, setIsLoading] = useState(false);

	const [filters, setFilters] = useState([]);
	const [subjects, setSubjects] = useState([]);
	const [filename, setFilename] = useState("");
	const [clickDelete, setClickDelete] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);

	const schema = yup.object().shape({
		name_en: yup.string().required(t("yup_required")),
		name_zh: yup.string().required(t("yup_required")),
		filters: yup.array().of(yup.string()),
		subjects: yup.array().of(yup.string()),
		file: isEdit
			? yup.boolean().nullable()
			: yup.mixed().maxFilesize(1 * 1024 * 1024 * 1024).required(t("yup_required")),
	});

	useEffect(() => {
		if (isEdit) {
			const subjects = selectedRow?.subjects?.map((s) => s._id) ?? [];
			const filters = selectedRow?.filters?.map((f) => f._id) ?? [];
			setSubjects(subjects);
			setFilters(filters);
		}
	}, [selectedRow, isEdit]);

	const formik = useFormik({
		initialValues: {
			name_en: selectedRow?.name?.en ?? "",
			name_zh: selectedRow?.name?.zh ?? "",
			filters: selectedRow?.filters?.map((f) => f._id) ?? [],
			subjects: selectedRow?.subjects?.map((s) => s._id) ?? [],
			file: null,
		},
		validationSchema: schema,
		onSubmit: async (values) => {
			setIsLoading(true);
			try {
				if (isEdit) {
					await api.putResources(values, selectedRow?._id);
				} else {
					await api.createResources(values);
				}

				queryClient.invalidateQueries("learning-resource");
				snack.open(t("upload_success"));
			} catch (e) {
				console.error(e?.response?.data?.message);

				switch (e?.response?.data?.message) {
					case "File too large":
						snack.error(t("learning_resource_msg_file_too_large"));
						break;
					case "Resource with same name already exists":
						snack.error(t("learning_resource_msg_same_name_exists"));
						break;
					case "Storage is full":
						snack.error(t("learning_resource_msg_storage_limit_exceeded"));
						break;
					default:
						snack.error(t("failed"));
						break;
				}
			} finally {
				setIsLoading(false);
				setDialogMode(null);
			}
		},
	});

	const handleDeleteResource = async (id) => {
		setIsDeleting(true);
		try {
			await api.deleteResource(id);
			queryClient.invalidateQueries("learning-resource");
			snack.open(t("delete_success"));
		} catch (e) {
			snack.error(t("failed"));
		} finally {
			setIsDeleting(false);
			setDialogMode(null);
		}
	};

	const handleFileUpload = (e) => {
		if (!e.target.files) {
			return;
		}
		const file = e.target.files[0];
		const { name } = file;
		setFilename(name);
		formik.setFieldValue("file", file);
	};

	//* Handle filters and subjects
	useEffect(() => {
		formik.setFieldValue(
			"filters",
			filters.filter((v) => v),
		);
	}, [filters, formik.setFieldValue]);

	useEffect(() => {
		formik.setFieldValue("subjects", subjects);
	}, [subjects, formik.setFieldValue]);
	//*

	return (
		<>
			{clickDelete && (
				<EditDialog
					title={t("delete")}
					open={clickDelete}
					handleClose={() => setClickDelete(false)}
					handleSave={() => handleDeleteResource(selectedRow._id)}
					confirmText={t("delete")}
					confirmDisabled={isDeleting}
				>
					{t("delete_confirm_msg").replace("{a}", t("learning_resource"))}
				</EditDialog>
			)}
			<EditDialog
				title={isEdit ? t("edit") : t("add_learning_resource")}
				handleSave={formik.handleSubmit}
				open={isOpen}
				handleClose={() => setDialogMode(null)}
				confirmDisabled={Object.keys(formik.errors).length > 0 || isUploading}
				TitleComponent={() => (
					<>
						{isEdit && (
							<Tooltip title={t("delete")}>
								<IconButton onClick={() => setClickDelete(true)}>
									<Icons.DeleteForeverIcon />
								</IconButton>
							</Tooltip>
						)}
					</>
				)}
			>
				{!isEdit && (
					<>
						<FormControl >
							<Box display={"flex"} sx={{}}>
								<Button variant="contained" component="label" onClick={() => formik.setTouched({ file: true })}>
									{t("choose_file")}
									<input
										type="file"
										accept="image/*,video/*,audio/*,.pdf"
										hidden
										onChange={handleFileUpload}
										name="file"
										onBlur={formik.handleBlur}
									/>
								</Button>
								<Typography sx={{ ml: 2, my: "auto" }}>{filename}</Typography>
							</Box>
						</FormControl >
						<FormHelperText component="div" error={formik.errors.file && formik.touched.file} sx={{ mb: 2 }}>
							<p>{t("learning_resource_msg_type_size")}</p>
						</FormHelperText>
					</>
				)}
				<TextField
					fullWidth
					label={t("name_en")}
					{...formikProps(formik, "name_en")}
					sx={{ mb: 2 }}
				/>
				<TextField
					fullWidth
					label={t("name_zh")}
					{...formikProps(formik, "name_zh")}
					sx={{ mb: 2 }}
				/>
				<ClassSelector
					selected={filters}
					multiple
					setSelected={setFilters}
					sx={{ mb: 2 }}
				/>
				<SubjectSelector
					selected={subjects}
					setSelected={setSubjects}
					multiple
					sx={{ mb: 2 }}
				/>
			</EditDialog>
		</>
	);
};
