import { Grid, Paper } from "@material-ui/core"
import { useLocation } from "react-router"
import { t } from "react-switch-lang"
import { useLocale } from "src/provider/LocaleProvider"

export default function NoRecordItem({ grid }) {

    const { t } = useLocale()

    if (grid) {
        return <Grid item xs>
            <Paper sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex', height: '200px', p: 2 }}>
                {t('no_record')}
            </Paper>
        </Grid>
    }

    return <>
        <Paper sx={{ justifyContent: 'center', width: '100%', alignItems: 'center', display: 'flex', height: '200px', p: 2 }}>
            {t('no_record')}
        </Paper>
    </>

};
