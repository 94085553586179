import { Box, Button, Container, Dialog, DialogActions, DialogTitle, Divider, Grid, IconButton, Switch, TextField, Tooltip } from '@material-ui/core';
import React, { Fragment, useMemo, useCallback, useEffect, useState, useRef, useLayoutEffect } from 'react'
import useApi from 'src/api/api';
import { Helmet } from 'react-helmet';
import DataTable from 'src/components/DataTable/DataTable';
import { useLocale } from 'src/provider/LocaleProvider';
import { useAuthData } from 'src/provider/AuthDataProvider';
import Icons from 'src/icons';
import EditDialog from 'src/components/EditDialog';
import { Formik, useFormik } from 'formik';
import formikProps from 'src/utils/formikProps';
import utils from 'src/utils/utils';
import PeriodSelector from 'src/components/PeriodSelector';
import ClassSelector from 'src/components/ClassSelector';
import SearchInput from 'src/components/SearchInput';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSnack } from 'src/provider/SnackbarProvider';
import LayoutContainer from 'src/layout/LayoutContainer';
import { AddBoxTwoTone } from '@material-ui/icons';
import LoadingView from 'src/components/LoadingView';
const moment = require('moment-timezone')
const _ = require('lodash')
const yup = require('yup')


const StudentList = ({ backDrop }) => {
    const api = useApi()
    const { t, getLanguage, translate } = useLocale()
    const rowsRef = useRef([])
    const { currentPeriod, selectedPeriod } = useAuthData()

    const [studentList, setStudentList] = useState([]);
    const [dialogMode, setDialogMode] = useState(null);
    const [selectedStudent, setSelectedStudent] = useState();
    const [filter, setFilter] = useState();

    const [search, setSearch] = useState('');
    const snack = useSnack()
    const nav = useNavigate()
    const [isLoading, setIsLoading] = useState(true)

    const schema = yup.object().shape({
        name: yup.object({
            en: yup.string().nullable(),
            zh: yup.string().nullable(),
        }),
        u_id: yup.string().required().nullable().strict().when('new', {
            is: v => dialogMode == 'add',
            then: yup.string().notOneOf(_.map(studentList, s => s.u_id), t('u_id_exists')) //.matches(/^[\d\w]*$/g),
        }),
        class: yup.string().required().nullable().trim().strict(),
        class_no: yup.number().required().nullable().min(1).max(999),
        date_of_birth: yup.string().isValidDate()
    })

    useEffect(() => {
        setIsLoading(true);
        api.getStudentList().then(res => {
            setStudentList(res.data)
            setIsLoading(false);
        }).catch(e => console.log(e))
    }, [selectedPeriod]);

    const formik = useFormik({
        initialValues: selectedStudent || {
            name: {
                en: '',
                zh: ''
            },
            u_id: '',
            class: '',
            class_no: null,
            password: ''
        },
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: (values, helper) => {
            console.log("file: StudentList.js ~ line 73 ~ StudentList ~ values", values)
            api.batchCreateUser([values], selectedPeriod).then(res => {
                api.getStudentList().then(res => {
                    snack.open()
                    setStudentList(res.data)
                    setDialogMode(null)
                })
            }).catch(e => {
                snack.error()
            }).finally(() => {
                helper.setSubmitting(false)
            })
        }
    })

    useEffect(() => {
        if (dialogMode) formik.resetForm()
    }, [dialogMode]);

    const handleExport = () => {
        utils.exportCsv(rowsRef.current, [
            { label: t('u_id'), value: row => row.u_id },
            { label: t('class'), value: row => row.class },
            { label: t('class_no'), value: row => row.class_no },
            { label: t('name_zh'), value: row => row.name?.zh },
            { label: t('name_en'), value: row => row.name?.en },
            { label: t('cumulative_point'), value: row => row.cumulative_point },
            { label: t('point_balance'), value: row => row.point },
            { label: t('username'), value: row => row.username },
            { label: t('date_of_birth'), value: row => row.date_of_birth },
            { label: t('setup_password'), value: row => row.setup_password },
        ], 'student_list')
    }

    const handleCreate = () => {
        setSelectedStudent(null)
        setDialogMode('add')
    }


    const handleEdit = (row) => {
        setSelectedStudent(row)
        setDialogMode('edit')
    }

    const handleConfirmReset = () => {
        api.resetUserPassword(selectedStudent?._id).then(res => {
            api.getStudentList().then(res => {
                setStudentList(res.data)
                setDialogMode(null)
            })
        }).catch(e => {
            snack.error()
        })
    }


    const handleConfirmDelete = () => {
        api.deleteUser(selectedStudent?._id).then(res => {
            api.getStudentList().then(res => {
                setStudentList(res.data)
                setDialogMode(null)
            })
        })
    }

    const filteredList = useCallback(
        utils.searchFilter(studentList, [search, filter?.value], [['u_id', 'class', 'class_no', 'name.en', 'name.zh', 'username'], ['class']])
        , [studentList, search, filter])

    if (isLoading) {
        return <LoadingView></LoadingView>;
    }

    return (
        <LayoutContainer title={t('users_title')}>
            <Box alignItems='center' mb={2}>
                <Grid container spacing={1} className='flex-sb ac'>
                    <Grid item>
                        <ClassSelector selected={filter} setSelected={setFilter} sx={{ mr: { sm: 1 } }} />
                    </Grid>
                    <Grid item>
                        <Box display={'flex'}>
                            <Button sx={{ ml: 0 }} onClick={handleExport}>
                                {t('export')}
                            </Button>
                            <NavLink to='/admin/student_list/upload'>
                                <Button sx={{ mx: 1 }}>
                                    {t('import')}
                                </Button>
                            </NavLink>
                            <Button sx={{ ml: 0 }} onClick={handleCreate}>
                                {t('add_student')}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <SearchInput fullWidth setSearch={setSearch} sx={{ maxWidth: '400px', mt: 1 }} />
            </Box>
            <DataTable rowsRef={rowsRef}
                rows={filteredList}
                heads={[
                    { label: t('u_id'), value: 'u_id', sort: true },
                    { label: t('class'), value: 'class', sort: true },
                    { label: t('class_no'), value: 'class_no', sort: true },
                    { label: t('name_zh'), value: 'name.zh', sort: true },
                    { label: t('name_en'), value: 'name.en', sort: true },
                    { label: t('date_of_birth'), value: 'date_of_birth', sort: true },
                    { label: t('cumulative_point'), value: 'cumulative_point', sort: true },
                    { label: t('point_balance'), value: 'point', sort: true },
                    { label: t('username'), value: 'username', sort: true },
                    { label: t('setup_password'), value: 'setup_password' },
                    {
                        id: 'action', label: t('actions'), content: (row) => {
                            return <Box sx={{ mx: -1 }}>
                                <Tooltip title={t('moreInfo_title')}>
                                    <IconButton onClick={() => nav(`/admin/student_list/${row.profile}`)} sx={{ p: .5 }}> <Icons.VisibilityIcon /> </IconButton>
                                </Tooltip>
                                <Tooltip title={t('edit')}>
                                    <IconButton onClick={() => handleEdit(row)} sx={{ p: .5 }}> <Icons.EditIcon /> </IconButton>
                                </Tooltip>
                            </Box>
                        }
                    },
                ]} _orderBy='class' _order='asc' />
            {/* Dialogs */}

            <EditDialog
                title={dialogMode == 'edit' ? t('edit_student') : t('add_student')}
                handleSave={formik.handleSubmit}
                open={dialogMode == 'add' || dialogMode == 'edit'}
                handleClose={() => setDialogMode(null)}
                TitleComponent={() => (
                    <>
                        {selectedStudent && <Tooltip title={t('reset_password_title')}>
                            <IconButton onClick={() => setDialogMode('resetpw')}  >
                                <Icons.VpnKeyIcon />
                            </IconButton>
                        </Tooltip>}
                        {selectedStudent && <Tooltip title={t('delete_student')}>
                            <IconButton onClick={() => setDialogMode('delete')}  >
                                <Icons.DeleteForeverIcon />
                            </IconButton>
                        </Tooltip>}
                    </>
                )}
            >
                <TextField
                    disabled={dialogMode == 'edit'}
                    fullWidth
                    label={t('u_id')}
                    {...formikProps(formik, 'u_id')}
                    sx={{ mb: 2 }}
                />
                <TextField
                    fullWidth
                    label={t('class')}
                    {...formikProps(formik, 'class')}
                    sx={{ mb: 2 }}
                />
                <TextField
                    fullWidth
                    label={t('class_no')}
                    type='number'
                    {...formikProps(formik, 'class_no')}
                    sx={{ mb: 2 }}
                />
                <TextField
                    fullWidth
                    label={t('name_en')}
                    {...formikProps(formik, 'name.en')}
                    sx={{ mb: 2 }}
                />
                <TextField
                    fullWidth
                    label={t('name_zh')}
                    {...formikProps(formik, 'name.zh')}
                    sx={{ mb: 2 }}
                />
                <TextField
                    fullWidth
                    label={t('date_of_birth')}
                    placeholder='YYYY-MM-DD'
                    {...formikProps(formik, 'date_of_birth')}
                    sx={{ mb: 2 }}
                />
            </EditDialog>
            <EditDialog
                maxWidth='xs'
                title={t('reset_password_title')}
                open={dialogMode == 'resetpw'}
                handleClose={() => setDialogMode(null)}
                confirmText={t("confirm")}
                handleSave={handleConfirmReset}
            >
                <span >{t('confirm_resetpw')}</span>
                <span className='bold' style={{ marginLeft: '4px' }}>{`${selectedStudent?.class} ${selectedStudent?.class_no}  ${_.get(selectedStudent, `name.${getLanguage()}`)} `}</span>
            </EditDialog>
            <EditDialog
                title={t('delete_student')}
                handleSave={handleConfirmDelete}
                open={dialogMode == 'delete'}
                handleClose={() => setDialogMode(null)}
                confirmText={t('confirm')}
            >
                <p>
                    {t('delete_student_confirm_msg', { a: `${selectedStudent?.class} ${selectedStudent?.class_no} ${translate(selectedStudent?.name)}` })}
                </p>
            </EditDialog>
        </LayoutContainer >
    );
}

export default StudentList;