import { Box, Button, Card, Container, IconButton, MenuItem, Switch, TextField, Typography } from '@material-ui/core';
import { FieldArray, Formik, FormikProvider, useFormik } from 'formik';
import _ from 'lodash';
import React, { Fragment, useMemo, useCallback, useEffect, useState, useRef, useLayoutEffect } from 'react'
import { useNavigate, useParams } from 'react-router';
import useApi from 'src/api/api';
import ClassSelector from 'src/components/ClassSelector';
import EditDialog from 'src/components/EditDialog';
import Icons from 'src/icons';
import LayoutContainer from 'src/layout/LayoutContainer';
import { useAuthData } from 'src/provider/AuthDataProvider';
import { useLocale } from 'src/provider/LocaleProvider';
import { useSnack } from 'src/provider/SnackbarProvider';
import formikProps from 'src/utils/formikProps';
const prettyBytes = require('pretty-bytes');
const moment = require('moment-timezone')

const yup = require('yup')
const NoticeEdit = ({ isEdit = true }) => {
    const { t } = useLocale()
    const api = useApi()
    const [notice, setNotice] = useState();
    const { notice_id } = useParams()
    const snack = useSnack()
    const nav = useNavigate()
    const auth = useAuthData()
    const [dialogMode, setDialogMode] = useState(null);
    const handleDeleteNotice = () => {
        api.batchDeleteNotice([notice_id]).then(() =>
            nav('/admin/notice', { replace: true })
        ).catch(e => console.log(e))
    }

    useEffect(() => {
        if (notice_id) {
            api.getNoticeById(notice_id)
                .then(res => {

                    setNotice(res.data)
                }).catch(e => {

                })
        }
    }, []);


    const formik = useFormik({
        initialValues: {
            notice: { ...notice, filters: _.map(notice?.filters, i => i._id) },
            attachs: []
        },
        validationSchema: yup.object({
            notice: yup.object({
                title: yup.string().required().nullable(),
                message: yup.string().required().nullable(),
            })
        }),
        enableReinitialize: true,
        onSubmit: (value, helper) => {

            if (notice_id) {
                api.updateNotice(notice_id, value.notice).then(res => {
                    snack.open('saved')
                    nav("/admin/notice")
                }).catch(e => {
                    snack.error('failed')
                    helper.setSubmitting(false)
                })
            }
            else {
                api.addNotice({ ...value.notice, period: auth.currentPeriod }).then(res => {
                    snack.open('created')
                    nav("/admin/notice")
                }).catch(e => {
                    snack.error('failed')
                    helper.setSubmitting(false)
                })
            }
        }
    })
    const handleFileChange = event => {
        var file = _.get(event, 'target.files[0]')
        if (file) {
            var attachments = _.toArray(_.get(formik.values, 'notice.attachments'))
            attachments.push(file)
            formik.setFieldValue('notice.attachments', attachments)
        }
        event.target.value = null
    }

    const removeAttach = (index) => {
        var attachments = _.toArray(_.get(formik.values, 'notice.attachments'))
        attachments.splice(index, 1)
        formik.setFieldValue('notice.attachments', attachments)
    }


    // if (!notice && notice_id !== undefined) return null
    return (
        <LayoutContainer title={t('edit_notice')} backSrc={'/admin/notice'}
            ActionComponent={() => isEdit &&
                (
                    <Button onClick={() => { setDialogMode('delete') }} >
                        {t('delete')}
                    </Button>
                )}
        >


            <form onSubmit={formik.handleSubmit}>

                <TextField label={t('title')} name='title' {...formikProps(formik, 'notice.title')} fullWidth sx={{ mb: 2 }} />
                <TextField label={t('message')} name='message' multiline {...formikProps(formik, 'notice.message')} fullWidth sx={{ mb: 2 }} inputProps={{
                    style: { minHeight: '100px' }
                }} />
                {/* <OrganizationFilterSelector value={_.get(formik.values, 'notice.filters')} setValue={(v) => formik.setFieldValue('notice.filters', v)} style={{ marginBottom: '16px' }} /> */}
                <ClassSelector multiple selected={_.get(formik.values, 'notice.filters')} setSelected={(v) => formik.setFieldValue('notice.filters', v)} sx={{ mb: 2 }} />
                <TextField
                    select
                    // disabled={formik.values.role == 'organization_admin'}
                    // fullWidth
                    sx={{ width: '50%', mb: 2 }}
                    label={t('status')}
                    {...formikProps(formik, 'notice.is_public')}
                >
                    <MenuItem key={'active'} value={true}> {t('is_public')} </MenuItem>
                    <MenuItem key={'inactive'} value={false}> {t('not_public')} </MenuItem>
                </TextField>

                {formik.values.notice.attachments?.length > 0 && <Box sx={{ mb: 2 }}>
                    <Typography variant='h4' paragraph>
                        {t('attachments')}
                    </Typography>
                    {
                        _.get(formik.values, 'notice.attachments', []).map((a, index) => {
                            return <Box mb={1} py={1} pr={1} pl={.5} display='flex' alignItems='center' style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}>
                                <div style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}>
                                    <Icons.AttachFileIcon sx={{ float: 'left' }} />
                                    {a && a.name}
                                </div>
                                <div style={{ flexGrow: 1 }} />
                                <div style={{
                                    whiteSpace: 'nowrap',
                                }}>
                                    {a.size && prettyBytes(a.size)}
                                </div>
                                {a.url && <IconButton href={a.url} target='_blank' download={a.name} style={{ padding: 0, marginLeft: '8px' }} >
                                    <Icons.GetAppIcon />
                                </IconButton>
                                }
                                <IconButton style={{ padding: 0, marginLeft: '8px' }} onClick={() => removeAttach(index)}>
                                    <Icons.CloseIcon />
                                </IconButton>
                            </Box>
                        })
                    }
                </Box>}
                <Box display='flex' mb={2}>
                    <Box flexGrow={1} />
                    <Box sx={{ mr: 1 }}>
                        <input id='attachment' type="file" onChange={handleFileChange} style={{ display: 'none' }} />
                        <label htmlFor='attachment'>
                            <Button color='primary' component="span" variant='contained' >
                                {t('attachment')}
                            </Button>
                        </label>
                    </Box>
                    <Button color='primary' disabled={formik.isSubmitting} variant='contained' onClick={formik.handleSubmit}>
                        {notice_id ? t('save') : t('publish')}
                    </Button>
                </Box>
            </form>
            <EditDialog
                title={t('delete_notice')}
                handleSave={handleDeleteNotice}
                open={dialogMode == 'delete'}
                handleClose={() => setDialogMode(null)}
                confirmText={t('confirm')}
            >
                <p>
                    {t('delete_notice_confirm_msg', { a: notice?.title })}
                </p>
            </EditDialog>
        </LayoutContainer >
    );
}

export default NoticeEdit;