import {
  NavLink,
  matchPath,
  useLocation
} from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button, ListItem } from '@material-ui/core'
import { useEffect } from 'react'

const NavCategory = ({
  href,
  icon: Icon,
  title,
  isActive,
  ...rest
}) => {
  const location = useLocation()

  const active = href ? !!matchPath({
    path: href,
    end: true
  }, location.pathname) : false

  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        py: 0
      }}
      {...rest}
    >
      <Button
        variant='text'
        component={NavLink}
        end={true}
        sx={{
          color: 'text.primary',
          fontWeight: 'medium',
          justifyContent: 'flex-start',
          // fontSize: '1rem',
          letterSpacing: 0,
          py: 1.25,
          textTransform: 'none',
          width: '100%',
          ...(active && {
            color: 'primary.main'
          }),
          '& svg': {
            mr: 1
          }
        }}
        to={href}
      >
        {Icon && (
          <Icon size="20" />
        )}
        <span>
          {title}
        </span>
      </Button>
    </ListItem>
  )
}

NavCategory.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string
}

export default NavCategory
