import React, { Fragment, useMemo, useCallback, useEffect, useState, useRef, useLayoutEffect } from 'react'
import { Box, Button, Card, CardActions, CardContent, CardHeader, Container, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, TextField, Typography } from '@material-ui/core'
import { useLocale } from 'src/provider/LocaleProvider'
import useApi from 'src/api/api';
import { Helmet } from 'react-helmet';
import { useFormik } from 'formik';
import formikProps from 'src/utils/formikProps';
import { useAuthData } from 'src/provider/AuthDataProvider';
import { makeStyles } from '@material-ui/styles';
import utils from 'src/utils/utils';
import LayoutContainer from 'src/layout/LayoutContainer';
const yup = require('yup')
const _ = require('lodash')

const MB = 1048576

const BrithdaySetting = ({ snack }) => {
    // const classes = useStyles()
    const api = useApi()
    const { t } = useLocale()
    const [info, setinfo] = useState({})
    const auth = useAuthData()
    const [rules, setRules] = useState([]);

    useEffect(() => {
        api.getOrganizationInfo(setinfo)
        api.getPointRules({ type: 'reward', isActive: true }).then(res => {
            setRules(res.data)
            //console.log({ info })
        })
    }, [])


    const formik = useFormik({
        initialValues: info || {
            logo: null,
            banner: null,
        },
        enableReinitialize: true,
        validationSchema: yup.object().shape({
            name: yup.object().shape({
                en: yup.string().required(),
                zh: yup.string().required()
            }),
            day_before_birthday: yup.number()
                .min( 0, t("brithday_validation_error_msg"))
                .max( 30, t("brithday_validation_error_msg")),
            day_after_birthday: yup.number()
                .min( 0, t("brithday_validation_error_msg"))
                .max( 30, t("brithday_validation_error_msg")),
        }),
        onSubmit: async (values, helper) => {
            try {
                //console.log(values);
                //await api.batchUploadBlobs(values, ['logo', 'banner'])
                await api.updateOrganizationInfo(values)
                auth.refresh()
                snack.open()
                formik.setSubmitting(false)
            }
            catch (e) {
                snack.error()
                formik.setSubmitting(false)
            }
        }
    })

    const { values, errors } = formik

    return (

        <LayoutContainer title={t('birthday')}>
            <Card>
                <form onSubmit={formik.handleSubmit}>
                    <CardContent>

                        <Typography variant='h3' paragraph>{t('birthday_msg')}</Typography>
                        <TextField label={t('birthday_message_en')} fullWidth {...formikProps(formik, 'birthday_message.en')} sx={{ mb: 2 }} />
                        <TextField label={t('birthday_message_zh')} fullWidth {...formikProps(formik, 'birthday_message.zh')} sx={{ mb: 2 }} />

                        <Typography variant='h3' paragraph>{t('message_display_time')}</Typography>
                        <TextField label={t('day_before_birthday')} type='number' fullWidth {...formikProps(formik, 'day_before_birthday')} sx={{ mb: 2 }} />
                        <TextField label={t('day_after_birthday')} type='number' fullWidth {...formikProps(formik, 'day_after_birthday')} sx={{ mb: 2 }} />
                        
                        <Typography variant='h3' paragraph>{t('add_point')}</Typography>
                        <TextField
                            select
                            fullWidth
                            {...formikProps(formik, 'birthday_point_rule')}
                            label={t('birthday_point_rule')}
                            sx={{ mb: 2 }}
                        >
                            <MenuItem value={null}> {'-------'} </MenuItem>
                            {_.map(rules, c => {
                                return <MenuItem key={c._id} value={c._id}> {c?.name} </MenuItem>
                            })}
                        </TextField>
                        <Typography variant='h6' paragraph>{t('note')}</Typography>

                    </CardContent>
                    <CardActions>
                        <Button type='reset' onClick={() => {
                            formik.resetForm()
                        }} variant='outlined' > {t('reset')}</Button>
                        <Button type='submit' > {t('save')}</Button>
                    </CardActions>
                </form>
            </Card>
        </LayoutContainer>

    )
}

export default BrithdaySetting