import Axios from "axios";
import { useNavigate } from "react-router";
import vars from "src/config/vars";
import { useAuthData } from "src/provider/AuthDataProvider";
import { useLocale } from "src/provider/LocaleProvider";
import { useSnack } from "src/provider/SnackbarProvider";

const _ = require("lodash");
const axios = Axios.create({
	baseURL: vars.server.url + "/api",
	headers: { "Content-Type": "application/json" },
});

axios.interceptors.request.use(
	(config) => {
		var token = localStorage.getItem("token");
		config.params = {
			token,
			...config.params,
		};
		return config;
	},
	(error) => {
		return error;
	},
);

axios.interceptors.response.use(
	(response) => {
		const result = response.data;
		if (result && result.result !== "fail") {
			return response;
		}
		throw result;
	},
	(error) => {
		return Promise.reject(error);
	},
);

const useApi = () => {
	const authToken = localStorage.getItem("token");
	const organization_id = localStorage.getItem("organization_id");
	const current_period = localStorage.getItem("current_period");

	const { selectedPeriod } = useAuthData();

	const nav = useNavigate();
	const { t } = useLocale();
	const snack = useSnack();

	function logout() {
		localStorage.removeItem("token");
		localStorage.removeItem("current_period");
		localStorage.removeItem("selected_period");
		localStorage.removeItem("organization_id");
		nav("/sign-in");
	}

	const API = {
		logout: logout,
		login: ({ username, email, password, type }) => {
			var data;
			if (type === "admin") {
				data = {
					type: "admin",
					email: email,
					password: password,
				};
			} else if (type === "parent") {
				data = {
					type: "parent",
					username: username,
					parent_password: password,
				};
			} else if (type === "user") {
				data = {
					type: "user",
					username: username,
					password: password,
				};
			}
			return axios
				.post(`/v2/cms/auth/login`, data)
				.then((res) => {
					var result = res.data;
					localStorage.setItem("token", result.token);
					return res;
				})
				.catch((error) => {
					if (!error.response?.status) throw "timeout";
					if (error.code === "ECONNABORTED") throw "timeout";
					var result = error.response?.data;
					if (result.error == "organization_expired") throw "invalid_user";
					if (result.error == "Inactive user") throw "invalid_user";
					if (result.error == "User not found") throw "invalid_user";
					if (result.error == "Invaild password") throw "invalid_password";
					throw "timeout";
				});
		},

		/**  ------------- @dashboard -------------------  */
		getTotalReward: async function (period_id) {
			return axios.get(`/rank/actions/reward`, {
				params: {
					token: authToken,
					groupBy: "organization",
					period: period_id,
				},
			});
		},
		getTotalRedeem: async (period_id) => {
			return axios.get(`/rank/actions/redeem`, {
				params: {
					token: authToken,
					groupBy: "organization",
					period: period_id,
				},
			});
		},
		getCurrentPeriod: (period_id) => {
			return axios
				.get(`/periods?groupBy=organization`)
				.then((res) => {
					const result = res.data;
					for (let i = 0; i < result.length; i++) {
						if (result[i]._id === period_id) {
							return {
								name: result[i].name,
								start: new Date(result[i].start_at).toDateString(),
								end: new Date(result[i].end_at).toDateString(),
							};
						}
					}
				})
				.catch((error) => { });
		},
		/**  ------------- @profile -------------------  */
		getUserRanking: (period_id) => {
			return axios.get(`/profiles/rank`, {
				params: {
					token: authToken,
					groupBy: "user",
					period: period_id,
				},
			});
		},
		getClassRanking: (period_id) => {
			return axios.get(`/profiles/rank`, {
				params: {
					token: authToken,
					groupBy: "class",
					period: period_id,
				},
			});
		},
		parentReward: function ({ pointRule_id, period_id }) {
			return axios.post(`/v2/cms/profiles/parent`, { pointRule_id, period_id });
		},
		getOrganizationInfo: function (setOrganiationInfo) {
			return axios.get(`/organizations/user`).then(({ data }) => {
				if (setOrganiationInfo) setOrganiationInfo(data);
				return Promise.resolve(data);
			});
		},
		updateOrganizationInfo: function (updates, setOrganiationInfo) {
			return axios
				.put(`/v2/organization/${organization_id}`, updates)
				.then(({ data }) => {
					snack.open(t("saved"));
					if (setOrganiationInfo) setOrganiationInfo(data);
					return Promise.resolve(data);
				})
				.catch(() => snack.error(t("failed")));
		},

		/**  ------------- @periods -------------------  */
		activatePeriod: function (id) {
			return axios.post(`/periods/current/${id}`);
		},
		getPeriodList: function (setPeriodList = new Function()) {
			return axios.get(`/periods`).then((res) => {
				const result = res.data;
				setPeriodList(result);
				return res;
			});
		},
		updatePeriod: async (id, data) => {
			return axios.put(`/periods/${id}`, data);
		},
		addPeriod: async (data) => {
			return axios.post(`/periods/`, data);
		},

		/**  ------------- @devices -------------------  */
		getDevices: () => {
			return axios.get(`/devices`);
		},
		addDevice: async () => {
			return null;
		},
		updateDevice: (
			id,
			{
				name,
				state,
				attendanceRule,
				pointRule,
				description,
				isEnableDeviceFunction,
			},
		) => {
			return axios.put(`/devices/${id}`, {
				name,
				state,
				organization_id: organization_id,
				attendanceRule,
				pointRule,
				description,
				isEnableDeviceFunction,
			});
		},

		/**  ------------- @users -------------------  */
		uploadCsv: function (formData) {
			return axios.post(`/users/upload`, formData);
		},
		batchCreateUser: (newUsers = [], period_id) => {
			if (!period_id) throw "missing period_id";
			return axios
				.post(`/v2/cms/users/batch`, { users: newUsers, period: period_id })
				.then((res) => {
					return res;
				})
				.catch((e) => {
					snack.error(t("upload_failed"));
					throw e;
				});
		},
		updateUser: function (id, { name, isActive, role, privilege }) {
			return axios.put(`/users/${id}`, { name, isActive, role, privilege });
		},
		deleteUser: function (id) {
			return axios.delete(`/users/${id}`);
		},
		// addUser: () => {
		//     return axios.post(`/users`, { users: newUsers, period: period_id }).then(res => {
		//         snack.open(t('upload_success'))
		//         nav('/users')
		//     })
		// },
		getStudentList: async function (period_id) {
			var pid = period_id || selectedPeriod || current_period;
			if (!pid) return { data: [] };
			return axios.get(`/users?period=${pid}`);
		},
		getStudentById: async function (period_id, id) {
			return axios.get(`/users/${id}?period=${period_id}`);
		},
		resetUserPassword: (user_iD) => {
			return axios.post(`/users/${user_iD}/resetpw`);
		},
		getStudentProfiles: (profileId, { pointActions, attendanceActions }) => {
			return axios.get(`/profiles/${profileId}`, {
				params: {
					pointActions,
					attendanceActions,
				},
			});
		},
		/**  ------------- @admin -------------------  */
		getAdminList: function () {
			return axios.get(`/admin/users`);
		},
		addAdmin: function ({ email, name, role }) {
			return axios.post(`/admin/users`, { name, email, role });
		},
		batchCreateAdmin: (newAdmins = []) => {
			return axios.post(`/admin/batch`, { newAdmins: newAdmins });
		},
		/**  ------------- @auth -------------------  */
		getUserInfo: async function () {
			if (authToken === null) {
				logout();
				return null;
			}
			return axios
				.get(`/auth/user`)
				.then((res) => {
					if (!res.data.isActive) {
						throw "inactive account";
					}
					return res;
				})
				.catch((error) => {
					logout();
					return Promise.reject(error);
				});
		},
		setPassword: function ({ password, new_password }) {
			return axios
				.post(`/auth/setpw`, {
					password: password,
					new_password: new_password,
				})
				.then((res) => {
					this.getUserInfo();
					return res;
				});
		},
		setParentPassword: function ({ password, new_password }) {
			return axios
				.post(`/v2/cms/auth/setParentPw`, {
					parent_password: password,
					new_password: new_password,
				})
				.then((res) => {
					this.getUserInfo();
					return res;
				});
		},
		/**  ------------- @ruleCategories -------------------  */
		getPointRuleCategories: function ({ setCategories, noCache, type } = {}) {
			return axios
				.get(`/ruleCategories`, {
					params: {
						token: authToken,
						cache: noCache ? false : undefined,
						type: type,
					},
				})
				.then((res) => {
					const data = res.data;
					if (setCategories) setCategories(data);
					return res;
				});
		},

		addRuleCategory: function (newCategory) {
			return axios.post(`/ruleCategories`, newCategory);
		},
		updateRuleCategory: function (newCategory) {
			return axios.put(`/ruleCategories/${newCategory._id}`, {
				name: newCategory.name,
			});
		},

		/**  ------------- @pointRules -------------------  */
		getPointRules: function ({ isReward, isActive, type } = {}) {
			return axios.get(`/pointRules`, {
				params: {
					isReward,
					isActive,
					type,
					token: authToken,
				},
			});
		},
		addPointRule: function (newRule) {
			return axios.post(`/pointRules`, newRule);
		},

		updatePointRule: function (id, update) {
			return axios.put(`/pointRules/${id}`, update);
		},
		deletePointRule: function (id) {
			return axios.delete(`/pointRules/${id}`);
		},

		/**  ------------- @organizationFilters -------------------  */
		getOrganizationFilters: function (setFilterList) {
			return axios.get(`/organizationFilters`).then((res) => {
				if (setFilterList) setFilterList(_.toArray(res.data));
				return res;
			});
		},
		updateOrganizationFilters: (id, { name, value, attribute }) => {
			return axios.put(`/organizationFilters/${id}`, {
				name,
				value,
				attribute,
			});
		},
		addOrganizationFilters: ({ name, value, attribute }) => {
			return axios.post(`/organizationFilters`, { name, value, attribute });
		},
		/**  ------------- @actions -------------------  */
		deletePointHistory: (history_id) => {
			return axios.delete(`/actions/${history_id}`);
		},
		studentReward: ({ pointRule_id, profiles, counts }) => {
			return axios.post(`v2/actions/profiles`, {
				pointRule_id,
				profiles,
				counts,
			});
		},
		studentRedeemByProfile: ({ pointRule_id, profiles, counts }) => {
			return axios.post(`v2/actions/profiles_redeem`, {
				pointRule_id,
				profiles,
				counts,
			});
		},
		studentRedeem: ({ pointRules, profile }) => {
			return axios.post(`/actions/rules`, { pointRules, profile });
		},
		studentCheckin: ({ device, profiles, attendanceRule }) => {
			return axios
				.post(`/actions/attendance/batch/checkin`, {
					device,
					profiles,
					attendanceRule,
				})
				.then(() => snack.open(t("checkin_success")))
				.catch(() => snack.error(t("checkin_fail")));
		},
		studentCheckout: ({ device, profiles, attendanceRule }) => {
			return axios
				.post(`/actions/attendance/batch/checkout`, {
					device,
					profiles,
					attendanceRule,
				})
				.then(() => snack.open(t("checkout_success")))
				.catch(() => snack.error(t("checkout_fail")));
		},
		getPointHistories: ({ period_id, u_id, dateRange = "30" }) => {
			return axios
				.get(`/actions`, {
					params: {
						token: authToken,
						pointRule: true,
						period: period_id,
						user: u_id,
						dateRange: dateRange,
					},
				})
				.then((res) => {
					return res;
				});
		},
		getPointHistoriesV2: ({
			dateRange,
			search,
			attendanceRule = false,
			type = "reward redeem parent_reward",
			...rest
		}) => {
			return axios
				.get(`v2/actions`, {
					params: {
						...rest,
						token: authToken,
						search: JSON.stringify(search),
						type,
						period: selectedPeriod,
						dateRange,
						attendanceRule
					},
				})
				.then((res) => {
					return res;
				});
		},
		getAllPointHistoriesStream: ({
			dateRange,
			search,
			attendanceRule = false,
			type = "reward redeem parent_reward",
			...rest
		}) => {
			return axios({
				url: `v2/actions/export_stream`,
				method: "GET",
				params: {
					...rest,
					token: authToken,
					search: JSON.stringify(search),
					type,
					period: selectedPeriod,
					dateRange,
					attendanceRule
				},
			})
				.then((res) => res.data)
		},
		getProfileRankingByPointCategory: ({
			period_id,
			profile_id,
			ruleCategory_id,
		}) => {
			return axios.get(`/actions/pointRuleCategory`, {
				params: {
					token: authToken,
					period: period_id,
					profile: profile_id,
					ruleCategory: ruleCategory_id,
				},
			});
		},
		getAttendanceList: async ({ user, period, ...query } = {}) => {
			if (!period) return { data: [] };
			return axios
				.get(`/actions`, {
					params: {
						user,
						period,
						...query,
						token: authToken,
						attendanceRule: true,
					},
				})
				.then((res) => {
					return res;
				});
		},
		/**  ------------- @attendanceRules -------------------  */
		getAttendanceRules: (query) => {
			return axios.get(`/attendanceRules`, {
				params: {
					token: authToken,
					...query,
				},
			});
		},
		addAttendanceRules: ({ name, description, isActive }) => {
			return axios.post(`/attendanceRules`, {
				name,
				description,
				isActive,
				organization_id: organization_id,
			});
		},
		updateAttendanceRules: (rule_id, { name, description, isActive }) => {
			return axios.put(`/attendanceRules/${rule_id}`, {
				name,
				description,
				isActive,
			});
		},
		getUserPointHistories: (period_id, user) => {
			return axios.get(`/auth/actions`, {
				params: {
					token: authToken,
					pointRule: true,
					period: period_id,
					user: user,
				},
			});
		},
		getUserAttendanceList: (period_id, user) => {
			return axios.get(`/auth/actions`, {
				params: {
					token: authToken,
					attendanceRule: true,
					period: period_id,
					user,
				},
			});
		},
		getUserProfile: () => {
			return axios.get(`/auth/profiles`, {
				params: {
					token: authToken,
				},
			});
		},
		getUserPeriod: (p_id) => {
			return axios.get(`/auth/actions`, {
				params: {
					token: authToken,
				},
			});
		},
		/**  ------------- @game -------------------  */
		getGameList: function () {
			return axios.get(`/games`);
		},
		getGameData: function (gameId, { questions, sessions, records } = {}) {
			return axios.get(`/games/${gameId}`, {
				params: {
					questions: questions,
					sessions: sessions,
					records: records,
				},
			});
		},
		getGameRecordDetails: function (gameId) {
			return axios.get(`/gameSessions/`, {
				params: {
					game: gameId,
				},
			});
		},
		createGame: async (newGame) => {
			await API.batchUploadBlobs(newGame, ["logo"]);
			return axios.post(`/games/create`, newGame);
		},
		updateGame: async (gameId, data) => {
			await API.batchUploadBlobs(data, ["logo"]);
			return axios.put(`/games/${gameId}`, data);
		},
		addQuestion: (gameId, { options, text, image, gameType }) => {
			return axios.post(`/gameQuestions`, {
				options,
				text,
				image,
				game: gameId,
				gameType,
			});
		},
		updateQuestion: (
			gameId,
			{ _id, options, text, image, gameType, isActive },
		) => {
			return axios.put(`/gameQuestions`, {
				_id,
				options,
				text,
				image,
				game: gameId,
				gameType,
				isActive,
			});
		},
		/**  ------------- @blobs -------------------  */
		//media
		batchUploadBlobs: async (form, fields) => {
			const formData = new FormData();
			for (const field of fields) {
				var value = _.get(form, field);
				formData.append(field, value);
			}
			return axios.post("/blobs/batch", formData).then(({ data }) => {
				for (const file of data.files) {
					_.set(form, file.fieldname, file.path);
				}
				return form;
			});
		},
		batchUploadBlobsWithNameSize: async (form, fields) => {
			const formData = new FormData();
			for (const field of fields) {
				var value = _.get(form, field);
				formData.append(field, value);
				_.set(form, field, {
					name: value.name,
					size: value.size,
				});
			}
			return axios.post("/blobs/batch", formData).then(({ data }) => {
				for (const file of data.files) {
					_.set(form, file.fieldname + ".url", file.path);
				}
				return form;
			});
		},
		/**  ------------- @notice -------------------  */
		getNotices: (p_id) => {
			return axios.get(`/notices`, {
				params: {
					period: p_id,
					token: authToken,
				},
			});
		},
		getNoticeById: (id) => {
			return axios.get(`/notices/` + id, {
				params: {
					token: authToken,
				},
			});
		},
		addNotice: async (notice) => {
			if (notice.attachments) {
				var fields = _.map(notice.attachments, (a, i) => {
					if (a instanceof Blob) {
						return "attachments." + i;
					}
					return null;
				}).filter((f) => !_.isNil(f));
				await API.batchUploadBlobsWithNameSize(notice, fields);
			}
			return axios.post(`/notices`, notice, {
				params: {
					token: authToken,
				},
			});
		},
		updateNotice: async (id, notice) => {
			var fields = _.map(notice.attachments, (a, i) => {
				if (a instanceof Blob) {
					return "attachments." + i;
				}
				return null;
			}).filter((f) => !_.isNil(f));
			await API.batchUploadBlobsWithNameSize(notice, fields);
			return axios.put(`/notices/` + id, notice, {
				params: {
					token: authToken,
				},
			});
		},
		updateNoticeInfo: async (id, body) => {
			return axios.post(`/notices/${id}/info`, body, {
				params: {
					token: authToken,
				},
			});
		},
		batchDeleteNotice: async (notices) => {
			return axios.post(
				`/notices/batchDelete`,
				{ notices },
				{
					params: {
						token: authToken,
					},
				},
			);
		},
		/**  ------------- @memo -------------------  */
		getMemo: () => {
			return axios.get(`/memos`, {
				params: {
					token: authToken,
				},
			});
		},
		getMemoById: (id) => {
			return axios.get(`/memos/` + id, {
				params: {
					token: authToken,
				},
			});
		},
		addMemo: (memo) => {
			return axios.post(`/memos`, memo, {
				params: {
					token: authToken,
				},
			});
		},
		updateMemoInfo: async (id, body) => {
			return axios.post(`/memos/${id}/info`, body, {
				params: {
					token: authToken,
				},
			});
		},
		batchDeleteMemo: async (memos) => {
			return axios.post(
				`/memos/batchDelete`,
				{ memos },
				{
					params: {
						token: authToken,
					},
				},
			);
		},
		/**  ------------- @badgeRule -------------------  */
		addBadgeRule: async (data) => {
			await API.batchUploadBlobs(data, ["image_url"]);
			return axios.post("/v2/cms/badgeRules", data);
		},
		getBadgeRuleList: async () => {
			return axios.get("/v2/cms/badgeRules");
		},
		updateBadgeRule: async (rule_id, data) => {
			await API.batchUploadBlobs(data, ["image_url"]);
			return axios.put(`/v2/cms/badgeRules/${rule_id}`, data);
		},
		deleteBadgeRule: async (rule_id) => {
			return axios.delete(`/v2/cms/badgeRules/${rule_id}`);
		},
		/**  ------------- @profileBadge -------------------  */
		getBadge: async (params) => {
			return axios.get("/v2/cms/profileBadges", {
				params: params,
			});
		},
		getBadgeGroupByRule: async (params) => {
			return axios.get("/v2/cms/profileBadges/groupByRule", {
				params: params,
			});
		},
		/**  ------------- @boardGame -------------------  */
		getBoardGameList: async (params) => {
			return axios.get("/v2/boardGame/game", {
				params: params,
			});
		},
		getBoardGameStats: async (gameId, params) => {
			return axios.get(`/v2/boardGame/game/${gameId}/stats`, {
				params: params,
			});
		},
		/**  ------------- @boardGameSession -------------------  */
		getBoardGameDashboardData: async (params) => {
			return axios.get("/v2/boardGame/session/dashboard", {
				params: {
					token: authToken,
					...params,
				},
			});
		},
		getBoardGameSession: async (params) => {
			return axios.get("/v2/boardGame/session", {
				params: {
					token: authToken,
					...params,
				},
			});
		},
		getBoardGameProfileStats: async (profileId, params) => {
			return axios.get(`/v2/boardGame/session/profileStats/${profileId}`, {
				params: {
					token: authToken,
					...params,
				},
			});
		},
		getBoardGameStats: async (gameId, params) => {
			return axios.get(`/v2/boardGame/session/gameStats/${gameId}`, {
				params: {
					token: authToken,
					...params,
				},
			});
		},
		getBoardGameSessionStats: async (sessionId, params) => {
			return axios.get(`/v2/boardGame/session/sessionStats/${sessionId}`, {
				params: {
					token: authToken,
					...params,
				},
			});
		},
		getOrganization: async (params) => {
			return axios.get(`/organizations`, {
				params: {
					token: authToken,
					...params,
				},
			});
		},

		/**  ------------- @HomeworkSession -------------------  */

		putHomeworkSubmitRecord: ({ profiles, date, period_id }) => {
			return axios.post(`v2/homework/homeworkSubmitRecord`, {
				profiles,
				date,
				period: period_id,
			});
		},
		putUnfinishedHomeworkRecord: ({ profiles, date, homeworkType_id }) => {
			return axios.post(`v2/homework/unfinishedHomework`, {
				profiles,
				date,
				homeworkType_id,
			});
		},
		putHomeworkType: ({
			name,
			subject,
			homeworkType_id = "",
			isActive = true,
		}) => {
			return axios.post(`v2/homework/type`, {
				name,
				subject,
				homeworkType_id,
				isActive,
			});
		},
		putHomeworkDateRange: ({
			submitHomeworkRange,
			unfinishHomeworkRange,
			isActive = false,
		}) => {
			return axios.post(`v2/homework/setting`, {
				submitHomeworkRange,
				unfinishHomeworkRange,
				isActive,
			});
		},
		getHomeworkType: async (params) => {
			return axios.get(`/v2/homework/type`, {
				params: {
					token: authToken,
					...params,
				},
			});
		},
		getStudentSubmittedHomeworkRecord: (profileId) => {
			return axios.get(`v2/homework/homeworkSubmitRecord/${profileId}`, {
				params: {
					period_id: selectedPeriod,
				},
			});
		},
		getStudentUnfinishedHomeworkRecord: (profileId) => {
			return axios.get(`v2/homework/unfinishedHomework/${profileId}`, {
				params: {
					period_id: selectedPeriod,
				},
			});
		},
		getSubmittedHomeworkRecord: ({
			dateRange = "30",
			search,
			period_id,
			...rest
		}) => {
			return axios
				.get(`v2/homework/homeworkSubmitRecord`, {
					params: {
						...rest,
						search,
						token: authToken,
						dateRange: dateRange,
						period_id,
					},
				})
				.then((res) => {
					return res;
				});
		},
		getClassSubmittedRecord: async ({ date }) => {
			return axios
				.get(`/v2/homework/homeworkSubmitRecord/classSubmittedRecord`, {
					params: {
						token: authToken,
						date: date,
						period_id: selectedPeriod,
					},
				})
				.then((res) => {
					return res;
				});
		},
		getUnfinishedHomework: ({
			dateRange = "30",
			search,
			period_id,
			...rest
		}) => {
			return axios
				.get(`v2/homework/unfinishedHomework`, {
					params: {
						...rest,
						search,
						token: authToken,
						dateRange: dateRange,
						period_id,
					},
				})
				.then((res) => {
					return res;
				});
		},
		getUnfinishedHomeworkStat: async (startDate = null, endDate = null) => {
			return axios.get(`/v2/homework/unfinishedHomework/statistics`, {
				params: {
					token: authToken,
					startDate,
					endDate,
					period_id: selectedPeriod,
					// ...params
				},
			});
		},
		getSubmittedHomeworkStat: async (startDate = null, endDate = null) => {
			return axios.get(`/v2/homework/homeworkSubmitRecord/statistics`, {
				params: {
					startDate,
					endDate,
					token: authToken,
					period_id: selectedPeriod,
					// ...params
				},
			});
		},
		deleteSubmittedHomeworkRecordByID: (history_id) => {
			return axios.delete(`v2/homework/homeworkSubmitRecord/${history_id}`);
		},
		deleteUnfinishedHomeworkRecordByID: (history_id) => {
			return axios.delete(`v2/homework/unfinishedHomework/${history_id}`);
		},
		deleteHomeworkTypeByID: (history_id) => {
			return axios.delete(`v2/homework/type/${history_id}`);
		},

		/**  ------------- @SubjectSession -------------------  */
		getSubjects: () => {
			return axios.get("v2/subjects");
		},
		getSubjectById: (subjectId) => {
			return axios.get(`v2/subjects/${subjectId}`);
		},
		createSubject: (values) => {
			return axios.post("v2/subjects", {
				...values,
			});
		},
		updateSubjectById: (subjectId, values) => {
			return axios.put(`v2/subjects/${subjectId}`, {
				...values,
			});
		},
		inactiveSubjectById: (subjectId) => {
			return axios.put(`v2/subjects/inactivate/${subjectId}`);
		},
		updateSubjectsOrder: (subjects) => {
			return axios.put(`v2/subjects`, {
				subjects,
			});
		},

		createResources: (values) => {
			const bodyFormData = new FormData();
			bodyFormData.append("blob", values.file);
			bodyFormData.append("name_zh", values.name_zh);
			bodyFormData.append("name_en", values.name_en);

			for (let i = 0; i < values.filters.length; i++) {
				bodyFormData.append("filters[]", values.filters[i]);
			}
			for (let i = 0; i < values.subjects.length; i++) {
				bodyFormData.append("subjects[]", values.subjects[i]);
			}

			return axios.post("v2/learningResources", bodyFormData);
		},

		putResources: (values, id) => {
			return axios.put(`v2/learningResources/${id}`, values);
		},

		getResources: ({ page, limit, subjects = [], filters = [], name = "" }) => {
			return axios.post("v2/learningResources/byFilters", {
				page,
				limit,
				subjects,
				filters,
				name,
			});
		},
		deleteResource: (id) => {
			return axios.delete(`v2/learningResources/${id}`);
		},
	};

	return API;
};

export default useApi;
