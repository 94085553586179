import React, { Fragment, useMemo, useCallback, useEffect, useState, useRef, useLayoutEffect } from 'react'
import useApi from 'src/api/api';
import LayoutContainer from 'src/layout/LayoutContainer';
import { useLocale } from 'src/provider/LocaleProvider';
import { Backdrop, Box, Container, Grid, Card, CardContent, Typography, Tab, Tabs, tab, CardHeader, Button, Divider, IconButton, List, ListItem, ListItemText, TextField, MenuItem, Select, FormControl, InputLabel } from '@material-ui/core';
import TransferTable from 'src/components/TransferTable';
import SearchInput from 'src/components/SearchInput';
import utils from 'src/utils/utils';
import ClassSelector from 'src/components/ClassSelector';
import { makeStyles } from '@material-ui/styles';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { useSnack } from 'src/provider/SnackbarProvider';
import formikProps from 'src/utils/formikProps';
import { useAuthData } from 'src/provider/AuthDataProvider';
import LoadingView from 'src/components/LoadingView';
const _ = require('lodash')
const yup = require('yup')
const useStyles = makeStyles({
    checked: {
        backgroundColor: 'rgba(50, 255, 50,0.3)',
        '&:hover': {
            backgroundColor: 'rgba(50, 255, 50,0.4) !important'
        }
    }
})

const PointReward = () => {
    const classes = useStyles()
    const api = useApi()
    const { t, getLanguage, translate } = useLocale()

    const [studentList, setStudentList] = useState([]);
    const { selectedPeriod } = useAuthData()
    const [selectedRule, setSelectedRule] = useState();
    const [selectedStudent, setSelectedStudent] = useState([]);
    const [search, setSearch] = useState('');
    const [searchStudent, setSearchStudent] = useState('');
    const [filter, setFilter] = useState();
    const [rules, setRules] = useState([]);
    const [parentRules, setParentRules] = useState([]);

    const [tab, setTab] = useState('teacher');
    
    const snack = useSnack()

    const [ruleCategories, setRuleCategories] = useState([]);
    const [parentRuleCategories, setParentRuleCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        api.getPointRuleCategories({ type: 'reward' }).then(res => {
            setRuleCategories(res.data)
        })
        api.getPointRules({ type: 'reward', isActive: true }).then(res => {
            setRules(res.data)
        
        })
        api.getPointRuleCategories({ type: 'parent_reward' }).then(res => {
            setParentRuleCategories(res.data)
        })
        api.getPointRules({ type: 'parent_reward', isActive: true }).then(res => {
            setParentRules(res.data)
            setIsLoading(false);
        })
    }, []);


    const formik = useFormik({
        initialValues: {
            selectedCategory: '',
            selectedParentCategory: '',
            pointRule_id: '',
            counts: 1,
            profiles: []
        },
        validationSchema: yup.object().shape({
            pointRule_id: yup.string().required(),
            profiles: yup.array().min(1).of(yup.string().required()).required(),
            counts: yup.number().min(1)
        }),
        onSubmit: (values, helper) => {
            api.studentReward(values).then(res => {
                snack.open(t('success_reward'))
                api.getStudentList().then(res => {
                    setStudentList(res.data)
                    setSelectedRule(null)
                    setSelectedStudent([])
                    helper.resetForm()
                })
            }).catch(e => {
                if (e.response.data.errorCode === 0) {
                    console.log(e.response)
                    snack.error(_.replace(t('fail_reward_current_period'), "{current period name}", e.response.data.period))
                }
                else
                    snack.error(t('fail_reward'))
            }).finally(() => {
                helper.setSubmitting(false)
            })
        }
    })

    useEffect(() => {
        api.getStudentList().then(res => {
            setStudentList(res.data)
            formik.resetForm()
            setSelectedRule(null)
            setSelectedStudent([])
        })
    }, [selectedPeriod]);

    useEffect(() => {
        formik.setFieldValue('profiles', _.map(selectedStudent, s => s.profile))
    }, [selectedStudent]);

    useEffect(() => {
        if (selectedRule) formik.setFieldValue('pointRule_id', selectedRule?._id)
    }, [selectedRule]);



    var classFiltered = useMemo(() => utils.searchFilter(studentList, filter?.value, ['class']), [studentList, filter?.value])
    var ruleFiltered  = utils.searchFilter(rules, [formik.values.selectedCategory, search], [['ruleCategory._id'], ['name']])
    var parentRuleFiltered  = utils.searchFilter(parentRules, [formik.values.selectedParentCategory, search], [['ruleCategory._id'], ['name']])

    if(isLoading){
        return  <LoadingView></LoadingView>
    }
    return (
        <LayoutContainer title={t('reward')}>
            <Card sx={{ mb: 2, borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tab} onChange={(e, v) => {setTab(v);}} >
                    <Tab label={t('reward_by_teacher')} value={'teacher'} />
                    <Tab label={t('reward_by_parent')} value={'parent'} />
                </Tabs>
            </Card>
            {tab == 'teacher' &&
                <form onSubmit={formik.handleSubmit}>
                    <Typography variant='h3' paragraph >{t('choose_rule')}</Typography>
                    <Grid container spacing={1} sx={{ mb: 2 }}>
                        <Grid item>
                            <FormControl>
                                <InputLabel shrink>{t('rule_category')}</InputLabel>
                                <Select
                                    className="mySelect"
                                    variant='outlined'
                                    size='small'
                                    displayEmpty
                                    label={t('rule_category')}
                                    {...formikProps(formik, 'selectedCategory')}
                                    sx={{ minWidth: '200px', width: 'min(200px, 100%)', mr: 1 }}
                                >
                                    {/* <option disabled selected value={""} > -- {t('select_a_period')} -- </option> */}
                                    <MenuItem value=""> {t('all_rule_category')} </MenuItem>
                                    {_.map(ruleCategories, n => <MenuItem key={n} value={n._id}> {_.get(n, `name.${getLanguage()}`, '-')} </MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sx={{ flex: 'auto' }}>
                            <SearchInput setSearch={setSearch} />
                        </Grid>
                    </Grid>
                    <Card elevation={2} sx={{ mb: 3, maxWidth: '800px' }}>
                        <List className={classes.list} component="div" role="list" sx={{ pr: 2 }}>
                            <ListItem style={{ height: '40px' }}>
                                <ListItemText primary={t('rule_name')} style={{ flexBasis: '70%' }} />
                                <ListItemText primary={t('point')} style={{ textAlign: 'right', flexBasis: '30%' }} />
                            </ListItem>
                        </List>
                        <Divider />
                        <List className={classes.list} component="div" role="list" sx={{ overflowY: 'scroll', maxHeight: '400px' }}>
                            {_.map(ruleFiltered, (rule, index) => {
                                var className = classNames({ [classes.checked]: rule._id == formik.values.pointRule_id })
                                return (
                                    <ListItem className={className} key={rule._id} button onClick={() => setSelectedRule(rule)} >
                                        <ListItemText primary={rule.name} style={{ flexBasis: '70%' }} />
                                        <ListItemText primary={rule.point} style={{ textAlign: 'right', flexBasis: '30%' }} />
                                    </ListItem>
                                )
                            })}
                            {_.size(ruleFiltered) == 0 &&
                                <ListItem>
                                    <ListItemText primary={t('no_record')} style={{ textAlign: 'center', flexBasis: '30%' }} />
                                </ListItem>
                            }
                            <ListItem />
                        </List>
                    </Card>
                    {
                        selectedRule?.name &&
                        <>
                            <Typography variant='h3' paragraph >{t('choose_student')}</Typography>
                            <Grid container spacing={1} sx={{ mb: 2 }}>
                                <Grid item>
                                    <ClassSelector selected={filter} setSelected={setFilter} all />
                                </Grid>
                                <Grid item sx={{ flex: 'auto' }}>
                                    <SearchInput setSearch={setSearchStudent} />
                                </Grid>
                            </Grid>
                            <TransferTable list={utils.searchFilter(classFiltered, searchStudent, ['class', 'class_no', 'name.en', 'name.zh'])} selected={selectedStudent} setSelected={setSelectedStudent}
                                leftHeads={[
                                    { id: 'class', label: t('class'), value: 'class' },
                                    { id: 'class_no', label: t('class_no'), value: 'class_no' },
                                    { id: 'name', label: t('name'), value: `name.${getLanguage()}` },
                                    { id: 'point', label: t('point'), value: `point`, placeHolder: '0' }
                                ]}
                                rightHeads={[
                                    { id: 'class', label: t('class'), value: 'class' },
                                    { id: 'class_no', label: t('class_no'), value: 'class_no' },
                                    { id: 'name', label: t('name'), value: `name.${getLanguage()}` },
                                    { id: 'point', label: t('point'), value: `point`, placeHolder: '0' }
                                ]}
                            />
                        </>
                    }
                    <Box className='flex ac' justifyContent='flex-end' mt={2}>
                        {selectedRule?.name &&
                            <>
                                <Typography variant='h5' sx={{ mr: 1 }} >
                                    {t('reward_confirm_message', { a: _.size(selectedStudent), b: selectedRule?.name, c: formik.values.counts })}
                                </Typography>
                                <TextField
                                    select
                                    size='small'
                                    label={t('count')}
                                    {...formikProps(formik, 'counts')}
                                    sx={{ width: '100px', mr: 1 }}
                                >
                                    {_.times(5, n => <MenuItem key={n} value={n + 1}> {n + 1} </MenuItem>)}
                                </TextField>
                            </>
                        }
                        <Button type='submit' disabled={_.size(formik.errors) > 0 || !selectedRule || formik.isSubmitting}>{t('confirm')}</Button>
                    </Box>
                </form >}

            {tab == 'parent' &&
                 <form onSubmit={formik.handleSubmit}>
                 <Typography variant='h3' paragraph >{t('choose_rule')}</Typography>
                 <Grid container spacing={1} sx={{ mb: 2 }}>
                     <Grid item>
                         <FormControl>
                             <InputLabel shrink>{t('rule_category')}</InputLabel>
                             <Select
                                className="mySelect"
                                 variant='outlined'
                                 size='small'
                                 displayEmpty
                                 label={t('rule_category')}
                                 {...formikProps(formik, 'selectedParentCategory')}
                                 sx={{ minWidth: '200px', width: 'min(200px, 100%)', mr: 1 }}
                             >
                                 {/* <option disabled selected value={""} > -- {t('select_a_period')} -- </option> */}
                                 <MenuItem value=""> {t('all_rule_category')} </MenuItem>
                                 {_.map(parentRuleCategories, n => <MenuItem key={n} value={n._id}> {_.get(n, `name.${getLanguage()}`, '-')} </MenuItem>)}
                             </Select>
                         </FormControl>
                     </Grid>
                     <Grid item sx={{ flex: 'auto' }}>
                         <SearchInput setSearch={setSearch} />
                     </Grid>
                 </Grid>
                 <Card elevation={2} sx={{ mb: 3, maxWidth: '800px' }}>
                     <List className={classes.list} component="div" role="list" sx={{ pr: 2 }}>
                         <ListItem style={{ height: '40px' }}>
                             <ListItemText primary={t('rule_name')} style={{ flexBasis: '70%' }} />
                             <ListItemText primary={t('point')} style={{ textAlign: 'right', flexBasis: '30%' }} />
                         </ListItem>
                     </List>
                     <Divider />
                     <List className={classes.list} component="div" role="list" sx={{ overflowY: 'scroll', maxHeight: '400px' }}>
                         {_.map(parentRuleFiltered, (rule, index) => {
                             var className = classNames({ [classes.checked]: rule._id == formik.values.pointRule_id })
                             return (
                                 <ListItem className={className} key={rule._id} button onClick={() => setSelectedRule(rule)} >
                                     <ListItemText primary={rule.name} style={{ flexBasis: '70%' }} />
                                     <ListItemText primary={rule.point} style={{ textAlign: 'right', flexBasis: '30%' }} />
                                 </ListItem>
                             )
                         })}
                         {_.size(parentRuleFiltered) == 0 &&
                             <ListItem>
                                 <ListItemText primary={t('no_record')} style={{ textAlign: 'center', flexBasis: '30%' }} />
                             </ListItem>
                         }
                         <ListItem />
                     </List>
                 </Card>
                 {
                     selectedRule?.name &&
                     <>
                         <Typography variant='h3' paragraph >{t('choose_student')}</Typography>
                         <Grid container spacing={1} sx={{ mb: 2 }}>
                             <Grid item>
                                 <ClassSelector selected={filter} setSelected={setFilter} all />
                             </Grid>
                             <Grid item sx={{ flex: 'auto' }}>
                                 <SearchInput setSearch={setSearchStudent} />
                             </Grid>
                         </Grid>
                         <TransferTable list={utils.searchFilter(classFiltered, searchStudent, ['class', 'class_no', 'name.en', 'name.zh'])} selected={selectedStudent} setSelected={setSelectedStudent}
                             leftHeads={[
                                 { id: 'class', label: t('class'), value: 'class' },
                                 { id: 'class_no', label: t('class_no'), value: 'class_no' },
                                 { id: 'name', label: t('name'), value: `name.${getLanguage()}` },
                                 { id: 'point', label: t('point'), value: `point`, placeHolder: '0' }
                             ]}
                             rightHeads={[
                                 { id: 'class', label: t('class'), value: 'class' },
                                 { id: 'class_no', label: t('class_no'), value: 'class_no' },
                                 { id: 'name', label: t('name'), value: `name.${getLanguage()}` },
                                 { id: 'point', label: t('point'), value: `point`, placeHolder: '0' }
                             ]}
                         />
                     </>
                 }
                 <Box className='flex ac' justifyContent='flex-end' mt={2}>
                     {selectedRule?.name &&
                         <>
                             <Typography variant='h5' sx={{ mr: 1 }} >
                                 {t('reward_confirm_message', { a: _.size(selectedStudent), b: selectedRule?.name, c: formik.values.counts })}
                             </Typography>
                             <TextField
                                 select
                                 size='small'
                                 label={t('count')}
                                 {...formikProps(formik, 'counts')}
                                 sx={{ width: '100px', mr: 1 }}
                             >
                                 {_.times(5, n => <MenuItem key={n} value={n + 1}> {n + 1} </MenuItem>)}
                             </TextField>
                         </>
                     }
                     <Button type='submit' disabled={_.size(formik.errors) > 0 || !selectedRule || formik.isSubmitting}>{t('confirm')}</Button>
                 </Box>
             </form >
            }

        </LayoutContainer >
    );
}

export default PointReward;