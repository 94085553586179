import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import PeriodSelector from 'src/components/PeriodSelector';
import { useAuthData } from 'src/provider/AuthDataProvider';

export const ALLOWED_PATHS = [
  '/admin/dashboard',
  '/admin/student_list',
  '/admin/student_list/upload',
  '/admin/reward',
  '/admin/redeem',
  '/admin/history',
  '/admin/checkin',
  '/admin/checkout',
  '/admin/attendance_history',
  '/admin/notice',
  '/admin/homeworkstatistics',
  '/user/badge',
  '/user/notice',
  '/user/studentHomeworkStatistics'
];
export const READONLY_PATHS = ['/admin/reward', '/user/badge', '/user/notice'];

const PeriodSelectorV2 = () => {
  const location = useLocation();
  const authData = useAuthData();
  const visible = useMemo(() => {
    return ALLOWED_PATHS.includes(location.pathname);
  }, [location.pathname]);
  const readOnly = useMemo(() => {
    return READONLY_PATHS.includes(location.pathname);
  }, [location.pathname]);
  if (!visible) return null;
  return (
    <PeriodSelector
      disabled={!visible || readOnly}
      fullWidth
      selected={authData.selectedPeriod}
      setSelected={authData.setSelectedPeriod}
      sx={{ mt: 0, width: 'unset' }}
      readOnly={readOnly}
    />
  );
};

export default PeriodSelectorV2;
