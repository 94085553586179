import { IconButton, TextField, Tooltip } from "@material-ui/core";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useQuery } from "react-query";
import useApi from "src/api/api";
import EditDialog from "src/components/EditDialog";
import LoadingView from "src/components/LoadingView";
import Icons from "src/icons";
import { useLocale } from "src/provider/LocaleProvider";
import { useSnack } from "src/provider/SnackbarProvider";
import formikProps from "src/utils/formikProps";

const yup = require("yup");

const schema = yup.object().shape({
	name: yup.object({
		zh: yup.string().min(0).max(255),
		en: yup.string().min(0).max(255),
	}),
	type: yup.string().min(0).max(255).required(),
});

export function SubjectDialog({
	isOpen,
	subjectId,
	mode,
	setDialogProps,
	setSubjects,
}) {
	const { t } = useLocale();
	const api = useApi();
	const snack = useSnack();
	const { isLoading, data: subject } = useQuery(
		["subject-by-id", subjectId],
		async () => {
			if (!subjectId) return;

			const { data } = await api.getSubjectById(subjectId);
			return data.subject;
		},
	);

	const handleClose = () => setDialogProps({ isOpen: false, subjectId: "" });

	const handleDelete = () => {
		api
			.inactiveSubjectById(subjectId)
			.then(() => {
				setSubjects((subjects) => subjects.filter((s) => s._id !== subjectId));
				handleClose();
				snack.open(t("saved"));
			})
			.catch(() => snack.error(t("failed")));
	};

	const handleCreate = (values) => {
		api
			.createSubject(values)
			.then(() => {
				setSubjects((subjects) => [values, ...subjects]);
				handleClose();
				snack.open(t("saved"));
			})
			.catch(() => snack.error(t("failed")));
	};

	const handleEdit = (values) => {
		api
			.updateSubjectById(subjectId, values)
			.then(() => {
				setSubjects((subjects) =>
					subjects.map((subject) =>
						subject._id === subjectId ? { ...subject, ...values } : subject,
					),
				);
				handleClose();
				snack.open(t("saved"));
			})
			.catch(() => snack.error(t("failed")));
	};

	const handleConfirm = (values) => {
		return mode === "create" ? handleCreate(values) : handleEdit(values);
	};

	const formik = useFormik({
		initialValues: {
			name: {
				zh: "",
				en: "",
			},
			type: "",
		},
		validationSchema: schema,
		onSubmit: (values) => {
			handleConfirm(values);
		},
	});

	useEffect(() => {
		formik.setValues({ ...subject });
		return () => formik.resetForm();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [subject, formik.resetForm, formik.setValues]);

	return (
		<EditDialog
			handleClose={handleClose}
			title={mode === "create" ? t("add_subject") : t("edit")}
			handleSave={formik.handleSubmit}
			open={isOpen}
			TitleComponent={() => (
				<>
					{mode === "edit" && (
						<Tooltip title={t("add_subject")}>
							<IconButton onClick={handleDelete}>
								<Icons.DeleteForeverIcon />
							</IconButton>
						</Tooltip>
					)}
				</>
			)}
		>
			{isLoading ? (
				<LoadingView />
			) : (
				<>
					<TextField
						fullWidth
						label={t("name_en")}
						{...formikProps(formik, "name.en")}
						sx={{ mb: 2 }}
					/>
					<TextField
						fullWidth
						label={t("name_zh")}
						{...formikProps(formik, "name.zh")}
						sx={{ mb: 2 }}
					/>
					<TextField
						fullWidth
						label={t("type")}
						disabled={mode === "edit"}
						{...formikProps(formik, "type")}
						sx={{ mb: 2 }}
					/>
				</>
			)}
		</EditDialog>
	);
}
