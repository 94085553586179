import { Button, Card, Container, Grid, IconButton, InputAdornment, MenuItem, Tab, Tabs, TextField, Tooltip } from '@material-ui/core'
import React, { useState, useEffect, useMemo } from 'react'
import useApi from 'src/api/api'
import Icons from 'src/icons'
import DataTable from 'src/components/DataTable/DataTable'
import { useLocale } from 'src/provider/LocaleProvider'
import SearchInput from 'src/components/SearchInput'
import { Helmet } from 'react-helmet'
import LayoutContainer from 'src/layout/LayoutContainer'
import { useFormik } from 'formik'
import utils from 'src/utils/utils'
import formikProps from 'src/utils/formikProps'
import EditDialog from 'src/components/EditDialog'
import LoadingView from 'src/components/LoadingView';
const _ = require('lodash')
const yup = require('yup')
const PointRuleCategories = ({ snack }) => {

    const [addDialog, setAddDialog] = useState(false)
    const [selected, setSelected] = useState(null)
    const [search, setSearch] = useState('')
    const api = useApi()
    const [tab, setTab] = useState('teacher');
    const { t } = useLocale()
    const [dialogMode, setDialogMode] = useState();
    const [categories, setCategories] = useState()
    const [parentCategories, setParentCategories] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getData()
    }, [])

    function getData() {
        setIsLoading(true)
        api.getPointRuleCategories({ noCache: false }).then(res => {
            setCategories(_.filter(res.data, row => !_.includes(row.type, 'parent')))
            setParentCategories(_.filter(res.data, row => _.includes(row.type, 'parent')))
            setIsLoading(false)
        })
    }

    const schema = yup.object().shape({
        name: yup.object({
            en: yup.string().nullable(),
            zh: yup.string().nullable(),
        }),
        type: yup.mixed().oneOf(['reward', 'parent_reward'])
    })
    const formik = useFormik({
        initialValues: selected || {
            name: {
                en: '',
                zh: ''
            },
            type: tab == 'parent' ? 'parent_reward' : 'reward',
        },
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: (values, helper) => {
            if (values._id) {
                api.updateRuleCategory(values).then(() => {
                    getData()
                    setDialogMode(null)
                }).catch(e => {
                    snack.error()
                }).finally(() => {
                    helper.setSubmitting(false)
                })
            }
            else {
                api.addRuleCategory(values).then(() => {
                    getData()
                    setDialogMode(null)
                }).catch(e => {
                    snack.error()
                }).finally(() => {
                    helper.setSubmitting(false)
                })
            }
        },
    })
    const handleCreate = () => {
        setSelected(null)
        setDialogMode('add')
        formik.validateForm()
    }

    const handleEdit = (row) => {
        setSelected(row)
        formik.validateForm()
        setDialogMode('edit')
    }

    function handleSearch(e) {
        setSearch(e.target.value)
    }

    const headCells = [
        { id: 'name_zh', label: t('name_zh'), value: 'name.zh', width: '40%', align: 'left' },
        { id: 'name_en', label: t('name_en'), value: 'name.en', width: '40%', align: 'left' },
        {
            id: 'actions', label: t('actions'),
            width: '20%',
            content: row => (
                <Tooltip title={t('edit')}>
                    <IconButton onClick={() => handleEdit(row)} sx={{ p: .5 }}> <Icons.EditIcon /> </IconButton>
                </Tooltip>
            ),
        },
    ]

    function rowfilter(row) {
        if (!search || search === '') return true
        if (row.name) {
            for (var key in row.name) {
                var value = row.name[key]
                if (value.includes(search.trim())) {
                    return true
                }
            }
        }
        return false
    }
    const rows = useMemo(() => {
        if (!categories) return categories
        return categories.filter(rowfilter)
    }, [search, categories])

    var filtered = useMemo(() => utils.searchFilter(tab == 'parent' ? parentCategories : categories, search, ['name.en', `name.zh`]), [categories, parentCategories, search, tab])

    if (isLoading) {
        return <LoadingView></LoadingView>;
      }
    return (
        <LayoutContainer title={t('rule_category')}>
            <Card sx={{ mb: 2, borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tab} onChange={(e, v) => setTab(v)} >
                    <Tab label={t('reward_by_teacher')} value={'teacher'} />
                    <Tab label={t('reward_by_parent')} value={'parent'} />
                </Tabs>
            </Card>
            <Grid container spacing={1} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={6} >
                    <SearchInput setSearch={setSearch} />
                </Grid>
                <Grid item xs={12} sm={6} className='flex ac'  >
                    <div className="spacer"></div>
                    <Button variant='contained' color='primary' onClick={handleCreate}>
                        {t('add_rule_categories')}
                    </Button>
                </Grid>
            </Grid>
            {tab == 'teacher' &&
                <DataTable row_id='_id' rows={filtered} heads={headCells} _rowsPerPage={10} />
            }
            {tab == 'parent' &&
                <DataTable row_id='_id' rows={filtered} heads={headCells} _rowsPerPage={10} />
            }
            {/* Dialogs */}
            <EditDialog title={dialogMode == 'edit' ? t('edit_rule_categories') : t('add_rule_categories')} handleSave={formik.handleSubmit} open={dialogMode == 'add' || dialogMode == 'edit'} handleClose={() => setDialogMode(null)}>
                <TextField
                    select
                    fullWidth
                    {...formikProps(formik, 'type')}
                    label={t('type')}
                    disabled
                    sx={{ mb: 2 }}
                >
                    <MenuItem key={'reward'} value={'reward'}> {t('reward')} </MenuItem>
                    <MenuItem key={'parent_reward'} value={'parent_reward'}> {t('parent_reward')} </MenuItem>
                </TextField>
                <TextField
                    fullWidth
                    label={t('name_zh')}
                    {...formikProps(formik, 'name.zh')}
                    sx={{ mb: 2 }}
                />
                <TextField
                    fullWidth
                    label={t('name_en')}
                    {...formikProps(formik, 'name.en')}
                    sx={{ mb: 2 }}
                />
               
            </EditDialog>
        </LayoutContainer>
    )

}


export default PointRuleCategories