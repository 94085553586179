import {
  Box,
  Button,
  IconButton,
  MenuItem,
  TextField,
  Tooltip
} from '@material-ui/core';
import classNames from 'classnames';
import { useFormik } from 'formik';
import _ from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import useApi from 'src/api/api';
import DataTable from 'src/components/DataTable/DataTable';
import EditDialog from 'src/components/EditDialog';
import LoadingView from 'src/components/LoadingView';
import SearchInput from 'src/components/SearchInput';
import Icons from 'src/icons';
import LayoutContainer from 'src/layout/LayoutContainer';
import { useLocale } from 'src/provider/LocaleProvider';
import { useSnack } from 'src/provider/SnackbarProvider';
import formikProps from 'src/utils/formikProps';
import utils from 'src/utils/utils';
const moment = require('moment-timezone');

const yup = require('yup');

const PointRedeemRules = () => {
  const api = useApi();
  const { t, getLanguage } = useLocale();
  const rowsRef = useRef([]);
  const snack = useSnack();

  const [dialogMode, setDialogMode] = useState(null);
  const [selectedRow, setSelectedRow] = useState();
  const [search, setSearch] = useState('');

  const [rules, setRules] = useState([]);
  const [parentRules, setParentRules] = useState([]);
  const [categories, setCategories] = useState();

  const [isLoading, setIsLoading] = useState(true);
  const [tab, setTab] = useState('teacher');

  useEffect(() => {
    getRules();
  }, []);

  function getRules() {
    setIsLoading(true);
    api.getPointRules({ isReward: false }).then((res) => {
      setRules(res.data);
      setIsLoading(false);
    });
  }

  const schema = yup.object().shape({
    name: yup.string().required(),
    ruleCategory: yup.string(),
    type: yup.mixed().oneOf(['redeem', 'virtual_redeem']).required(),
    point: yup.number().min(0).required(),
    isActive: yup.bool()
  });

  const formik = useFormik({
    initialValues: selectedRow || {
      name: '',
      type: 'redeem',
      isActive: true,
      point: null
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values, helper) => {
      if (values._id) {
        api
          .updatePointRule(values._id, values)
          .then((res) => {
            setDialogMode(null);
            getRules();
            snack.open();
          })
          .catch((e) => {
            snack.error();
          })
          .finally(() => {
            helper.setSubmitting(false);
          });
      } else {
        api
          .addPointRule(values)
          .then((res) => {
            setDialogMode(null);
            getRules();
            snack.open();
          })
          .catch((e) => {
            snack.error();
          })
          .finally(() => {
            helper.setSubmitting(false);
          });
      }
    }
  });

  useEffect(() => {
    if (dialogMode) formik.resetForm();
  }, [dialogMode]);

  const handleCreate = () => {
    setSelectedRow(null);
    setDialogMode('add');
    formik.validateForm();
  };

  const handleEdit = (row) => {
    setSelectedRow({
      ...row,
      ruleCategory: row.ruleCategory?._id,
      point: Math.abs(_.toNumber(row.point))
    });
    formik.validateForm();
    setDialogMode('edit');
  };

  var filtered = useMemo(
    () =>
      utils.searchFilter(tab == 'parent' ? parentRules : rules, search, [
        'name',
        `ruleCategory.name.${getLanguage()}`,
        'point'
      ]),
    [parentRules, rules, search, tab]
  );

  const handleDelete = (row) => {
    setSelectedRow({
      ...row,
      ruleCategory: row.ruleCategory?._id,
      point: Math.abs(_.toNumber(row.point))
    });
    formik.validateForm();
    setDialogMode('delete');
  };

  const handleExport = () => {
    utils.exportCsv(
      rowsRef.current,
      [
        { label: t('prize_name'), value: (row) => row.name },
        { label: t('point'), value: (row) => row.point },
        { label: t('status'), value: (row) => row.isActive },
        // { label: t('rule_category'), value: row => _.get(row, `ruleCategory.name.${getLanguage()}`) },
        { label: t('description'), value: (row) => row.description }
      ],
      `attendance_history`
    );
  };

  const handleConfirmDelete = async () => {
    await api.deletePointRule(selectedRow._id);
    getRules();
    setDialogMode(null);
  };

  if (isLoading) {
    return <LoadingView></LoadingView>;
  }

  return (
    <LayoutContainer title={t('prize_rules')}>
      <Box className="flex ac" mb={2}>
        <SearchInput setSearch={setSearch} />
        <div className="spacer" />
        <Button sx={{ ml: 1 }} onClick={handleExport}>
          {t('export_all')}
        </Button>
        <Button sx={{ ml: 1 }} onClick={handleCreate}>
          {t('add_prize_rule')}
        </Button>
      </Box>
      <DataTable
        rowsRef={rowsRef}
        rowClassName={(row) =>
          classNames({ 'text-green': row.type == 'reward' })
        }
        rows={filtered}
        heads={[
          { label: t('prize_name'), value: 'name', sort: true },
          { label: t('point'), value: 'point', sort: true },
          {
            label: t('status'),
            value: `isActive`,
            content: (r) => (r.isActive ? t('active') : t('inactive')),
            className: (row) => (row.isActive ? 'text-green' : 'text-red'),
            sort: true
          },
          // { label: t('rule_category'), value: `ruleCategory.name.${getLanguage()}`, sort: true },
          { label: t('description'), value: `description`, sort: true },
          {
            id: 'action',
            label: t('actions'),
            content: (row) => {
              return (
                <Box sx={{ mx: -1 }}>
                  <Tooltip title={t('edit')}>
                    <IconButton onClick={() => handleEdit(row)} sx={{ p: 0.5 }}>
                      {' '}
                      <Icons.EditIcon />{' '}
                    </IconButton>
                  </Tooltip>
                </Box>
              );
            }
          }
        ]}
        _orderBy={'name'}
        _order="asc"
      />
      {/* Dialogs */}
      <EditDialog
        title={
          dialogMode == 'edit' ? t('edit_prize_rule') : t('add_prize_rule')
        }
        handleSave={formik.handleSubmit}
        open={dialogMode == 'add' || dialogMode == 'edit'}
        handleClose={() => setDialogMode(null)}
        TitleComponent={() => (
          <>
            {selectedRow && (
              <Tooltip title={t('delete')}>
                <IconButton onClick={() => setDialogMode('delete')}>
                  <Icons.DeleteForeverIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
      >
        <TextField
          // select
          fullWidth
          {...formikProps(formik, 'type')}
          label={t('type')}
          sx={{ mb: 2 }}
          InputProps={{
            readOnly: true
          }}
        >
          <MenuItem key={'redeem'} value={'redeem'}>
            {' '}
            {t('redeem')}{' '}
          </MenuItem>
          <MenuItem key={'virtual_redeem'} value={'virtual_redeem'}>
            {' '}
            {t('virtual_redeem')}{' '}
          </MenuItem>
          {/* <MenuItem key={'parent_reward'} value={'parent_reward'}> {t('parent_reward')} </MenuItem> */}
        </TextField>
        {/* {formik.values.type != 'redeem' && (
          <TextField
            select
            fullWidth
            {...formikProps(formik, 'ruleCategory')}
            label={t('type')}
            sx={{ mb: 2 }}
          >
            {_.map(categories, (c) => {
              return (
                <MenuItem key={c._id} value={c._id}>
                  {' '}
                  {_.get(c, 'name.' + getLanguage())}{' '}
                </MenuItem>
              );
            })}
          </TextField>
        )} */}
        <TextField
          fullWidth
          label={t('name')}
          {...formikProps(formik, 'name')}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label={t('description')}
          {...formikProps(formik, 'description')}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          type="number"
          label={t('point')}
          {...formikProps(formik, 'point')}
          sx={{ mb: 2 }}
        />
        <TextField
          select
          fullWidth
          label={t('status')}
          {...formikProps(formik, 'isActive')}
        >
          <MenuItem key={'active'} value={true}>
            {' '}
            {t('active')}{' '}
          </MenuItem>
          <MenuItem key={'inactive'} value={false}>
            {' '}
            {t('inactive')}{' '}
          </MenuItem>
        </TextField>
      </EditDialog>
      <EditDialog
        title={t('delete')}
        handleSave={handleConfirmDelete}
        open={dialogMode == 'delete'}
        handleClose={() => setDialogMode(null)}
        confirmText={t('confirm')}
      >
        <p>{t('delete_confirm_msg', { a: `${selectedRow?.name}` })}</p>
      </EditDialog>
    </LayoutContainer>
  );
};

export default PointRedeemRules;
