import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import useApi from 'src/api/api';
import LayoutContainer from 'src/layout/LayoutContainer';
import { useAuthData } from 'src/provider/AuthDataProvider';
import { useLocale } from 'src/provider/LocaleProvider';
import { Card, Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import SubmittedHomeworkChart from './SubmittedHomeworkChart';
import UnfinishedHomeworkChart from './UnfinishedHomeworkChart';
const moment = require('moment-timezone');
const _ = require('lodash');
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF80FF'];

const useStyles = makeStyles((theme) => ({
  row: {
    // height: '10px',
    // paddingBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(1)
    // marginTop: theme.spacing(1)
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },

  cardTitle: {
    fontWeight: 700
  },
  tableCell: {
    '& .MuiTableCell-root': {
      textAlign: 'center',
      borderLeft: 'none !important'
    },
    '& .MuiTableRow-root': {
      height: '60px'
    }
  }
}));
export default function HomeworkStatistics() {
  const { t, getLanguage } = useLocale();
  const [tab, setTab] = useState('submitted_homework');

  return (
    <LayoutContainer title={t('homework_statistics')}>
      <Card sx={{ mb: 2, borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tab}
          onChange={(e, v) => {
            setTab(v);
          }}
        >
          <Tab label={t('submitted_homework')} value={'submitted_homework'} />
          <Tab label={t('unfinished_homework')} value={'unfinished_homework'} />
        </Tabs>
      </Card>
      {tab === 'submitted_homework' ? (
        <SubmittedHomeworkChart />
      ) : (
        <UnfinishedHomeworkChart />
      )}
    </LayoutContainer>
  );
}
