import React, { Fragment, useMemo, useCallback, useEffect, useState, useRef, useLayoutEffect } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Box, Divider, Typography } from '@material-ui/core'
import { useLocale } from 'src/provider/LocaleProvider'
import PerfectScrollbar from 'react-perfect-scrollbar'
const useStyles = makeStyles((theme) => ({
    dialog: {
        overflow: 'hidden'
    },
    action: {
        width: '80px'
    },
}))
const _ = require('lodash')
const EditDialog = ({ open, handleClose, title, children, handleSave, confirmText, TitleComponent, cancelText, confirmDisabled = false, maxWidth, ...rest }) => {
    const { t } = useLocale()
    const classes = useStyles()

    const [dialogTitle, setDialogTitle] = useState('');

    useEffect(() => {
        if (open) setDialogTitle(title)
    }, [open]);

    return (
        <Dialog
            maxWidth={maxWidth}
            open={open}
        >
            <form onSubmit={e => { e.preventDefault(); handleSave(e) }} autoComplete='off' >
                <DialogTitle sx={{ pt: 3, fontSize: '24px' }} color='textPrimary' className='bold flex ac'>
                    {_.toString(dialogTitle)}
                    <Box flexGrow={1} />
                    {TitleComponent && <TitleComponent />}
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ overflowY: 'auto', maxHeight: "calc(100vh - 240px)" }}>
                    {/* <PerfectScrollbar> */}
                    {children}
                    {/* </PerfectScrollbar> */}
                </DialogContent>
                <Divider />
                <DialogActions>
                    {!handleSave ?
                        <>
                            <Button variant='text' className={classes.action} onClick={handleClose}>
                                {confirmText || t('confirm')}
                            </Button>
                        </>
                        :
                        <>
                            <Button variant='text' className={classes.action} onClick={handleClose}>
                                {cancelText || t('cancel')}
                            </Button>
                            <Button variant='text' type='submit' disabled={confirmDisabled} className={classes.action} variant='contained' color="primary" autoFocus>
                                {confirmText || t('save')}
                            </Button>
                        </>
                    }
                </DialogActions>
            </form>
        </Dialog>
    )
}

EditDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string,
    children: PropTypes.any.isRequired,
    handleClose: PropTypes.func,
    handleSave: PropTypes.func,
}


export default EditDialog