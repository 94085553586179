
import { Stack, TextField } from '@material-ui/core';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'moment/locale/zh-hk';
import { useLocale } from 'src/provider/LocaleProvider';

export default function CustomDatePicker({
  label = '',
  selectedDate,
  setSelectedDate, //= new Function(),
  sx,
  minDate = null,
  maxDate = null,
  readOnly = false,
  openTo = 'day',
  inputFormat = "YYYY/MM/DD",
  views = ['year', 'month', 'day'],
  ...rest
}) {
  const handleChange = (newDate) => {
    if (newDate) setSelectedDate(newDate.toDate());
    else setSelectedDate(null);
  };

  const { getLanguage } = useLocale();

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} size="small" adapterLocale={getLanguage()} {...rest}>
      <Stack spacing={3}>
        <DesktopDatePicker
          label={label}
          inputFormat={inputFormat}
          value={selectedDate}
          openTo={openTo}
          onChange={(event) => handleChange(event)}
          minDate={minDate}
          maxDate={maxDate}
          views={views}
          renderInput={(params) => (
            <TextField
              size="small"
              sx={{ width: '100%', maxWidth: '500px', ...sx }}
              {...params}
              inputProps={{
                ...params.inputProps,
                readOnly: readOnly
              }}
              {...rest}
            />
          )}
        />
      </Stack>
    </LocalizationProvider>
  );
}
