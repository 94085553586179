import { MenuItem, TextField } from '@material-ui/core';
import { useEffect, useMemo, useState } from 'react';
import useApi from 'src/api/api';
import { useAuthData } from 'src/provider/AuthDataProvider';
import { useLocale } from 'src/provider/LocaleProvider';

const _ = require('lodash');

export default function PeriodSelector({
  default_id,
  selected = {},
  setSelected = () => {},
  global,
  periods,
  ...rest
}) {
  const { t } = useLocale();
  const [periodList, setPeriodList] = useState([]);
  const { currentPeriod, selectedPeriod } = useAuthData();
  const api = useApi();

  useEffect(() => {
    if (periods) {
      setPeriodList(periods);
    } else {
      api.getPeriodList().then((res) => {
        setPeriodList(res.data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periods]);

  useEffect(() => {
    let temp = default_id || selectedPeriod || currentPeriod;
    if (!selected) setSelected(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPeriod, currentPeriod, periodList]);

  const reversePeriodList = useMemo(() => {
    return _.reverse(periodList);
  }, [periodList]);

  return (
    <TextField
      select
      size="small"
      label={t('period')}
      name="period"
      type="text"
      value={selected || {}}
      onChange={(e) => setSelected(e.target.value)}
      style={{ minWidth: '200px' }}
      {...rest}
    >
      {_.map(reversePeriodList, (period) => {
        return (
          <MenuItem key={period._id} value={period._id}>
            {`${period.name}${currentPeriod === period._id ? '*' : ''}`}
          </MenuItem>
        );
      })}
    </TextField>
  );
}
