import { InputAdornment, TextField } from '@material-ui/core';
import Icons from 'src/icons';
import { useLocale } from 'src/provider/LocaleProvider';

const SearchInput = ({ setSearch = new Function(), sx, ...rest }) => {
    const { t } = useLocale()
    return (
        <TextField {...rest} size='small'
            variant='outlined'
            onChange={e => setSearch(e.target.value)}
            placeholder={t('searchBar')}
            InputProps={{
                startAdornment: <InputAdornment position="start"><Icons.SearchIcon /></InputAdornment>
            }}
            sx={{ width: '100%', maxWidth: '500px', ...sx }}
            {...rest}
        />
    );
}

export default SearchInput;