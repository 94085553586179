import { Autocomplete, Button, Chip, FormControl, InputLabel, MenuItem, Select, TextField, Toolbar, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import useApi from 'src/api/api'
import { useAuthData } from 'src/provider/AuthDataProvider'
import { useLocale } from 'src/provider/LocaleProvider'

const _ = require('lodash')


const RuleSelector = ({
    default_id,
    selected,
    setSelected = new Function(),
    filters,
    autocomplete = true,
    all = true,
    multiple = false,
    ...rest
}) => {

    const { t, getLanguage } = useLocale()
    const [filterList, setFilterList] = useState([])
    const api = useApi()
    const allOption = {
        name: {
            en: t('all_rule_category'),
            zh: t('all_rule_category'),
        },
        // attribute: null,
        _id: null
    }

    useEffect(() => {
        if (filters) {
            setFilterList(filters)
        }
        else {

            api.getPointRuleCategories().then(res => {
                setFilterList(res.data)
            })
        }
        if ((!selected || selected == {}) && all) setSelected(allOption)
    }, [])



    let selectedValue = _.filter(filterList, item => selected && _.includes(selected, item._id));

    return (
        multiple ?
            <Autocomplete
                multiple
                size='medium'
                openOnFocus
                autoHighlight
                disableClearable
                value={selectedValue.length > 0 ? selectedValue : [allOption]}
                options={all ? _.concat([allOption], filterList) : filterList}
                getOptionLabel={op => _.get(op, `name.${getLanguage()}`, '')}
                onChange={(e, v, c, d) => {
                    
                    if (d.option.value === null) {
                        setSelected([])
                    }
                    else setSelected(_.map(v, i => i._id))
                }}
                renderTags={(tagValue, getTagProps) => {
                    return tagValue.map((option, index) => {
                        return (
                            <Chip
                                // size='small'
                                variant='outlined'
                                label={_.get(option, `name.${getLanguage()}`, '')}
                                {...getTagProps({ index })}
                            // disabled={filterList.indexOf(option) === -1}
                            />)
                    })
                }}
                renderInput={(params) => <TextField {...params} label={t('rule_category')} variant="outlined" InputLabelProps={{
                    shrink: true
                }} />}
                style={{ minWidth: '150px' }}
                {...rest}
            />
            :
            <Autocomplete
                size='small'
                openOnFocus
                autoHighlight
                disableClearable
                value={selected || allOption}
                options={all ? _.concat([allOption], filterList) : filterList}
                getOptionLabel={op => _.get(op, `name.${getLanguage()}`, '')}
                onChange={(e, v) => setSelected(v)}
                renderInput={(params) => <TextField {...params} label={t('rule_category')} variant="outlined" InputLabelProps={{
                    shrink: true
                }} />}
                style={{ minWidth: '150px' }}
                {...rest}
            />
    )
}
export default RuleSelector  