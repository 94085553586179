import React, { useState, useEffect, useContext } from 'react'
import { Alert, Grow, makeStyles, Snackbar, useTheme } from '@material-ui/core'
import _ from 'lodash'
import { useLocale } from './LocaleProvider'


const { createContext } = require('react')

const SnackbarContext = createContext()

export function useSnack() {
    var context = useContext(SnackbarContext) || {}
    return ({
        open: context.open,
        error: context.error,
        info: context.info,
    })
}

const ResultSnackbar = props => {
    const defaultAnchorOrigin = {
        vertical: 'bottom',
        horizontal: 'center',
    }

    const { snack, setSnack, timeout, ...rest } = props

    const open = snack.open
    const level = snack.level
    const message = snack.message

    const handleClose = () => {
        setSnack({
            ...snack,
            open: false
        })
    }

    return (
        <Snackbar
            open={open}
            onClose={handleClose}
            anchorOrigin={defaultAnchorOrigin}
            TransitionComponent={Grow}
            autoHideDuration={2000}
        >
            <Alert variant='filled' severity={level} onClose={handleClose}>
                <strong>{message}</strong>
            </Alert>
        </Snackbar>
    )
}


const SnackbarProvider = (props) => {

    const { children } = props
    const { t } = useLocale()

    const [snack, setSnack] = useState({
        open: false,
        level: 'error',
        message: ''
    })

    const handleError = msg => {
        handleClose()
        setTimeout(() => setSnack({
            open: true,
            level: 'error',
            message: _.toString(msg) || t('failed')
        }), 100)
    }

    const handleSuccess = msg => {
        handleClose()
        setTimeout(() => setSnack({
            open: true,
            level: 'success',
            message: _.toString(msg) || t('saved')
        }), 100)
    }


    const handleWarn = msg => {
        handleClose()
        setTimeout(() => setSnack({
            open: true,
            level: 'waring',
            message: _.toString(msg) || 'Warning'
        }), 100)
    }

    const handleInfo = msg => {
        handleClose()
        setTimeout(() => setSnack({
            open: true,
            level: 'info',
            message: _.toString(msg) || 'Info'
        }), 100)
    }

    const handleClose = () => {
        setSnack({
            ...snack,
            open: false
        })
    }

    const state = {
        open: handleSuccess,
        error: handleError,
        warn: handleWarn,
        info: handleInfo,
        close: handleClose
    }

    return (
        <SnackbarContext.Provider value={state} >
            <ResultSnackbar snack={snack} setSnack={setSnack} />
            {children}
        </SnackbarContext.Provider>
    )
}

export default SnackbarProvider