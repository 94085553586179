import { Box, Button, Card, Container, IconButton, InputAdornment, MenuItem, Select, Switch, Tab, Tabs, TextField, Tooltip } from '@material-ui/core';
import React, { Fragment, useMemo, useCallback, useEffect, useState, useRef, useLayoutEffect } from 'react'
import useApi from 'src/api/api';
import { Helmet } from 'react-helmet';
import DataTable from 'src/components/DataTable/DataTable';
import { useLocale } from 'src/provider/LocaleProvider';
import { useAuthData } from 'src/provider/AuthDataProvider';
import Icons from 'src/icons';
import EditDialog from 'src/components/EditDialog';
import { Formik, useFormik } from 'formik';
import formikProps from 'src/utils/formikProps';
import utils from 'src/utils/utils';
import SearchInput from 'src/components/SearchInput';
import _ from 'lodash';
import { UsbTwoTone } from '@material-ui/icons';
import { useSnack } from 'src/provider/SnackbarProvider';
import LayoutContainer from 'src/layout/LayoutContainer';
import classNames from 'classnames';
import { getLanguage, translate } from 'react-switch-lang';
import axios from 'axios';
import LoadingView from 'src/components/LoadingView';
const moment = require('moment-timezone')

const yup = require('yup')





const PointRules = () => {
    const api = useApi()
    const { t, getLanguage, translate } = useLocale()
    const rowsRef = useRef([])
    const snack = useSnack()

    const [dialogMode, setDialogMode] = useState(null);
    const [selectedRow, setSelectedRow] = useState();
    const [search, setSearch] = useState('');

    const [rules, setRules] = useState([]);
    const [parentRules, setParentRules] = useState([]);
    const [categories, setCategories] = useState()

    const [tab, setTab] = useState('teacher');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true)
        api.getPointRuleCategories({ setCategories })
        getRules()
    }, []);


    function getRules() {
        api.getPointRules({ isReward: true }).then(res => {
            // setRules(res.data)
            setRules(_.filter(res.data, row => !_.includes(row.type, 'parent')))
            setParentRules(_.filter(res.data, row => _.includes(row.type, 'parent')))
            setIsLoading(false);
        })
    }

    const schema = yup.object().shape({
        name: yup.string().required(),
        ruleCategory: yup.string().required().nullable(),
        type: yup.mixed().oneOf(['reward', 'parent_reward']).required(),
        point: yup.number().min(0).required(),
        isActive: yup.bool(),
        quota_type: yup.mixed().when('type', {
            is: v => v == 'parent_reward',
            then: yup.mixed().oneOf(['daily', 'weekly']).required()
        }),
        quota_count: yup.number().when('type', {
            is: v => v == 'parent_reward',
            then: yup.number().min(1).required()
        }),
    })

    const formik = useFormik({
        initialValues: selectedRow || {
            name: '',
            type: tab == 'parent' ? 'parent_reward' : 'reward',
            isActive: true,
            point: null,
            ruleCategory: null,
            quota_type: 'daily',
            quota_count: 1,
        },
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: (values, helper) => {
            if (values._id) {
                api.updatePointRule(values._id, values).then(res => {
                    setDialogMode(null)
                    getRules()
                    snack.open()
                }).catch(e => {
                    snack.error()
                }).finally(() => {
                    helper.setSubmitting(false)
                })
            }
            else {
                api.addPointRule(values).then(res => {
                    setDialogMode(null)
                    getRules()
                    snack.open()
                }).catch(e => {
                    snack.error()
                }).finally(() => {
                    helper.setSubmitting(false)
                })
            }
        },
    })
    useEffect(() => {
        if (dialogMode) formik.resetForm()
    }, [dialogMode]);

    const handleCreate = () => {
        setSelectedRow(null)
        setDialogMode('add')
        formik.validateForm()
    }

    const handleEdit = (row) => {
        setSelectedRow({ ...row, ruleCategory: row.ruleCategory?._id, point: Math.abs(_.toNumber(row.point)) })
        formik.validateForm()
        setDialogMode('edit')
    }

    var filtered = useMemo(() => utils.searchFilter(tab == 'parent' ? parentRules : rules, search, ['name', `ruleCategory.name.${getLanguage()}`, 'point']), [parentRules, rules, search, tab])

    const handleExport = () => {
        utils.exportCsv(rowsRef.current, [
            { label: t('pointrule_name'), value: row => row.name },
            { label: t('point'), value: row => row.point, sort: true },
            { label: t('rule_category'), value: row => _.get(row, `ruleCategory.name.${getLanguage()}`) },
            { label: t('status'), value: r => r.isActive ? t('active') : t('inactive') },
            { label: t('description'), value: row => row.description },
        ], `attendance_history`)
    }


    const handleConfirmDelete = async () => {
        await api.deletePointRule(selectedRow._id)
        getRules()
        setDialogMode(null)
    }

    if (isLoading) {
        return <LoadingView></LoadingView>;
      }

    return (
        <LayoutContainer title={t('pointRules_title')}>
            <Card sx={{ mb: 2, borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tab} onChange={(e, v) => setTab(v)} >
                    <Tab label={t('reward_by_teacher')} value={'teacher'} />
                    <Tab label={t('reward_by_parent')} value={'parent'} />
                </Tabs>
            </Card>
            <Box className='flex ac' mb={2}>
                <SearchInput setSearch={setSearch} />
                <div className="spacer" />
                <Button sx={{ ml: 1 }} onClick={handleExport}>
                    {t('export_all')}
                </Button>
                <Button sx={{ ml: 1 }} onClick={handleCreate}>
                    {t('add_pointrule')}
                </Button>
            </Box>
            {tab == 'teacher' &&
                <DataTable
                    rowsRef={rowsRef}
                    // rowClassName={row => classNames({ 'text-green': row.type == 'reward' })}
                    rows={filtered}
                    heads={[
                        { label: t('pointrule_name'), value: 'name', sort: true },
                        { label: t('point'), value: 'point', sort: true },
                        { label: t('rule_category'), value: `ruleCategory.name.${getLanguage()}`, sort: true },
                        { label: t('status'), value: `isActive`, content: r => r.isActive ? t('active') : t('inactive'), className: (row) => row.isActive ? 'text-green' : 'text-red', sort: true },
                        { label: t('description'), value: `description`, sort: true },
                        {
                            id: 'action', label: t('actions'), content: (row) => {
                                return <Box sx={{ mx: -1 }}>
                                    <Tooltip title={t('edit')}>
                                        <IconButton onClick={() => handleEdit(row)} sx={{ p: 0.5 }}> <Icons.EditIcon /> </IconButton>
                                    </Tooltip>
                                </Box>
                            }
                        },
                    ]} _orderBy={'name'} _order='asc'
                />
            }
            {tab == 'parent' &&
                <DataTable
                    rowsRef={rowsRef}
                    // rowClassName={row => classNames({ 'text-green': row.type == 'parent_reward' })}
                    rows={filtered}
                    heads={[
                        { label: t('pointrule_name'), value: 'name', sort: true },
                        { label: t('point'), value: 'point', sort: true },
                        { label: t('rule_category'), value: `ruleCategory.name.${getLanguage()}`, sort: true },
                        { label: t('status'), value: `isActive`, content: r => r.isActive ? t('active') : t('inactive'), className: (row) => row.isActive ? 'text-green' : 'text-red', sort: true },
                        { label: t('description'), value: `description`, sort: true },
                        {
                            id: 'action', label: t('actions'), content: (row) => {
                                return <Box sx={{ mx: -1 }}>
                                    <Tooltip title={t('edit')}>
                                        <IconButton onClick={() => handleEdit(row)} sx={{ p: 0.5 }}> <Icons.EditIcon /> </IconButton>
                                    </Tooltip>
                                </Box>
                            }
                        },
                    ]} _orderBy={'name'} _order='asc'
                />
            }
            {/* Dialogs */}
            <EditDialog title={dialogMode == 'edit' ? t('edit_pointRules_title') : t('add_pointrule')} handleSave={formik.handleSubmit} open={dialogMode == 'add' || dialogMode == 'edit'} handleClose={() => setDialogMode(null)}
                TitleComponent={() => (
                    <>
                        {selectedRow && <Tooltip title={t('delete')}>
                            <IconButton onClick={() => setDialogMode('delete')}  >
                                <Icons.DeleteForeverIcon />
                            </IconButton>
                        </Tooltip>}
                    </>
                )}

            >
                <TextField
                    select
                    fullWidth
                    {...formikProps(formik, 'type')}
                    label={t('type')}
                    disabled
                    sx={{ mb: 2 }}
                >
                    <MenuItem key={'reward'} value={'reward'}> {t('reward')} </MenuItem>
                    <MenuItem key={'parent_reward'} value={'parent_reward'}> {t('parent_reward')} </MenuItem>
                </TextField>
                {formik.values.type != 'redeem' &&
                    <TextField
                        select
                        fullWidth
                        {...formikProps(formik, 'ruleCategory')}
                        label={t('rule_category')}
                        sx={{ mb: 2 }}
                    >
                        {_.map(_.filter(categories, c => tab == 'parent' ? c.type == 'parent_reward' : c.type == 'reward'), c => {
                            return <MenuItem key={c._id} value={c._id}> {translate(c?.name)} </MenuItem>
                        })}
                    </TextField>
                }
                <TextField
                    fullWidth
                    label={t('name')}
                    {...formikProps(formik, 'name')}
                    sx={{ mb: 2 }}
                />
                <TextField
                    fullWidth
                    label={t('description')}
                    {...formikProps(formik, 'description')}
                    sx={{ mb: 2 }}
                />
                <TextField
                    fullWidth
                    type='number'
                    label={t('point')}
                    {...formikProps(formik, 'point')}
                    sx={{ mb: 2 }}
                />
                {formik.values.type == 'parent_reward' && <>
                    <TextField
                        select
                        fullWidth
                        label={t('quota_type')}
                        {...formikProps(formik, 'quota_type')}
                        sx={{ mb: 2, width: { xs: '100%', sm: '50%' }, pr: { xs: 0, sm: 2 } }}
                    // sx={{ mb: 2, width: { xs: '100%', sm: '50%' } }}
                    >
                        <MenuItem key={'daily'} value={'daily'}> {t('quota_daily')} </MenuItem>
                        <MenuItem key={'weekly'} value={'weekly'}> {t('quota_weekly')} </MenuItem>
                    </TextField>
                    <TextField
                        fullWidth
                        type='number'
                        label={t('quota_count')}
                        {...formikProps(formik, 'quota_count')}
                        sx={{ mb: 2, width: { xs: '100%', sm: '50%' } }}
                    />
                </>
                }
                <TextField
                    select
                    fullWidth
                    label={t('status')}
                    {...formikProps(formik, 'isActive')}
                >
                    <MenuItem key={'active'} value={true}> {t('active')} </MenuItem>
                    <MenuItem key={'inactive'} value={false}> {t('inactive')} </MenuItem>
                </TextField>
            </EditDialog>
            <EditDialog
                title={t('delete')}
                handleSave={handleConfirmDelete}
                open={dialogMode == 'delete'}
                handleClose={() => setDialogMode(null)}
                confirmText={t('confirm')}
            >
                <p>
                    {t('delete_confirm_msg', { a: `${selectedRow?.name}` })}
                </p>
            </EditDialog>
            {/* <EditDialog
                title={t('delete_student')}
                handleSave={formik.handleSubmit}
                open={dialogMode == 'delete'}
                handleClose={() => setDialogMode(null)}
            >
                {() => {
                }}
                <p>
                    {'Are you sure to delete'}
                </p>
                <p>{selectedStudent?.username}</p>
                <p>{selectedStudent?.class}</p>
                <p>{selectedStudent?.class_no}</p>
                <p>{selectedStudent?.name?.en}</p>
            </EditDialog> */}
        </LayoutContainer>
    );
}

export default PointRules;