import DashboardIcon from '@material-ui/icons/Dashboard';

import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Icons from 'src/icons';

import { FaAward } from 'react-icons/fa';
const _ = require('lodash');

export default (t, modules = {}) => [
  {
    name: t('overview_title'),
    icon: () => <DashboardIcon />,
    href: '/user/overview'
  },
  {
    name: t('profile_title'),
    icon: () => <PeopleIcon />,
    href: '/user/profile'
  },
  {
    show: modules.badge,
    name: t('badge'),
    icon: () => <Icons.FaAward style={{ width: '24px', height: '22px' }} />,
    href: '/user/badge'
  },
  {
    name: t('notice'),
    show: modules.notice,
    icon: () => <Icons.NoticeIcon />,
    href: '/user/notice'
  },

  {
    name: t('memo'),
    show: modules.memo,
    icon: () => <Icons.EmailIcon />,
    href: '/user/memo'
  },
  {
    name: t('games'),
    show: modules.game,
    icon: () => <Icons.SportsEsportsIcon />,
    href: '/user/games'
  },
  {
    name: t('homework_statistics'),
    icon: () => <AssignmentIcon />,
    href: '/user/studentHomeworkStatistics'
  },
  {
    name: t('settings_title'),
    icon: () => <SettingsIcon />,
    href: '/user/settings'
  }
];
