import { Box, Button, Chip, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, List, ListItem, Tooltip, Typography } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import useApi from 'src/api/api';
import DataTable from 'src/components/DataTable/DataTable';
import EditDialog from 'src/components/EditDialog';
import Icons from 'src/icons';
import LayoutContainer from 'src/layout/LayoutContainer';
import { useAuthData } from 'src/provider/AuthDataProvider';
import { useLocale } from 'src/provider/LocaleProvider';
import LoadingView from 'src/components/LoadingView';
const prettyBytes = require('pretty-bytes');



export default function Notice() {
    const nav = useNavigate()
    const { t, getLanguage } = useLocale()
    const [notices, setNotices] = useState([]);
    const { selectedPeriod } = useAuthData()
    const api = useApi()
    const [selectedRow, setSelectedRow] = useState();
    const [selected, setSelected] = useState([])
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [dialogMode, setDialogMode] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true)
        api.getNotices(selectedPeriod).then(res => {
            setNotices(res.data)
            setIsLoading(false)

        }).catch(e => {

        })
    }, [selectedPeriod]);

    const handleDeleteNotice = () => {
        api.batchDeleteNotice(_.map(selected, k => k._id)).then(() =>
            api.getNotices(selectedPeriod).then(res => {
                setNotices(res.data)
                setSelected([])
                setDialogMode(null)
            })).catch(e => console.log(e))
    }
    if (isLoading) {
        return <LoadingView></LoadingView>;
    }
    return (
        <LayoutContainer title={t('notice')}>

            <Box display='flex' mb={2}>
                <Box flexGrow={1} />
                <Button disabled={_.size(selected) <= 0} onClick={() => setDialogMode('delete')} sx={{ mr: 1 }}>
                    {t('delete')}
                </Button>
                <Button onClick={() => nav('/admin/notice/publish')}>
                    {t('publish_notice')}
                </Button>
                <Dialog
                    // maxWidth={maxWidth}
                    open={isDialogOpen}
                >
                    <DialogTitle sx={{ pt: 3, pb: 0, fontSize: '24px' }} color='textPrimary' className='bold' >
                        {selectedRow?.title}
                    </DialogTitle>
                    <List sx={{ px: 2 }}>
                        {_.size(selectedRow?.filters) > 0 ? _.map(selectedRow?.filters, (k, i) => {
                            return (
                                <ListItem sx={{ display: 'inline', p: 0 }}>

                                    <Chip sx={{ minWidth: '50px', mr: '10px' }} label={k.name[getLanguage()]}></Chip>
                                </ListItem>
                            )
                        }) : <ListItem sx={{ display: 'inline', p: 0 }}>

                            <Chip label={t('all_grade_and_class')}></Chip>
                        </ListItem>}
                    </List>

                    < Divider />
                    <DialogContent>
                        {/* <Typography paragraph>
                            {_.map(selected?.filters, f => t(f.name)).sort().join(', ')}
                        </Typography> */}
                        <Typography paragraph style={{ whiteSpace: 'pre-wrap' }}>
                            {selectedRow?.message}
                        </Typography>
                        <Box sx={{ mb: 2 }}>
                            {
                                selectedRow?.attachments.map((a, index) => {
                                    return <Box mb={1} py={1} pr={1} pl={.5} display='flex' alignItems='center' style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}>
                                        <div style={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',


                                        }}>
                                            <Icons.AttachFileIcon sx={{ float: 'left' }} />

                                            {a && a.name}
                                        </div>
                                        <div style={{ flexGrow: 1 }} />
                                        <div style={{
                                            whiteSpace: 'nowrap',
                                        }}>
                                            {a.size && prettyBytes(a.size)}
                                        </div>
                                        {a.url && <IconButton href={a.url} target='_blank' download={a.name} style={{ padding: 0, marginLeft: '8px' }} >
                                            <Icons.GetAppIcon />
                                        </IconButton>
                                        }

                                    </Box>
                                })
                            }
                        </Box>
                    </DialogContent>
                    <Divider />

                    <DialogActions>

                        <Button onClick={() => setIsDialogOpen(false)}>
                            {t('close')}
                        </Button>

                    </DialogActions>

                </Dialog>

            </Box>
            <DataTable
                rows={notices}
                loading={false}
                heads={[
                    {
                        type: 'checkbox',

                    },
                    {
                        label: t('title'), value: 'title',

                    },
                    { label: t('created_at'), value: 'created_at', width: '25%', content: (row) => moment(row.created_at).format('YYYY/MM/DD, HH:mm:ss'), sort: true },
                    // {id: 'is_public', label: t('is_public'), value: 'is_public', content: (row) => row.is_public ? t('yes') : t('no'), sort: true },
                    {
                        label: t('status'), value: 'is_public',

                        content: (row, index) => row.is_public ? t('is_public') : t('not_public')
                    },
                    {
                        label: t('actions'), content: (row) =>
                            <>
                                <Tooltip title={t('preview')}>
                                    <IconButton sx={{ mr: .5, p: .5 }} onClick={() => {
                                        setSelectedRow(row)
                                        setIsDialogOpen(true)
                                    }}>
                                        <Icons.VisibilityIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={t('edit')}>
                                    <IconButton sx={{ p: 0.5 }} onClick={(e) => {
                                        nav(`/admin/notice/edit/${row._id}`)
                                    }}>
                                        <Icons.EditIcon />
                                    </IconButton>
                                </Tooltip>
                            </>
                    }
                ]}
                checked={selected}
                setChecked={setSelected}
            />
            <EditDialog
                title={t('delete_notice')}
                handleSave={handleDeleteNotice}
                open={dialogMode == 'delete'}
                handleClose={() => setDialogMode(null)}
                confirmText={t('confirm')}
            >
                <p>
                    {t('delete_notice_confirm_msg')}
                </p>
            </EditDialog>
        </LayoutContainer >)
}