import { Card, Tab, Tabs } from '@material-ui/core';
import { useState } from 'react';
import LayoutContainer from 'src/layout/LayoutContainer';
import { useLocale } from 'src/provider/LocaleProvider';
import ClassSubmittedRecord from './ClassSubmittedRecord';
import SubmittedHomeworkHistory from './SubmittedHomeworkHistory';
import UnfinishedHomeworkHistory from './UnfinishedHomeworkHistory';

const HomeworkHistory = () => {
  const [tab, setTab] = useState('submitted_homework');
  const { t } = useLocale();
  return (
    <LayoutContainer title={t('homework_record_history')}>
      <Card sx={{ mb: 2, borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tab}
          onChange={(e, v) => {
            setTab(v);
          }}
        >
          <Tab label={t('submitted_homework')} value={'submitted_homework'} />
          <Tab label={t('unfinished_homework')} value={'unfinished_homework'} />
        </Tabs>
      </Card>

      {tab === 'submitted_homework' && (
        <Card>
          <ClassSubmittedRecord />
          <SubmittedHomeworkHistory />
        </Card>
      )}
      {tab === 'unfinished_homework' && (
        <Card>
          <UnfinishedHomeworkHistory />
        </Card>
      )}
    </LayoutContainer>
  );
};

export default HomeworkHistory;
