import { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
    Avatar,
    Box,
    Card,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useLocale } from 'src/provider/LocaleProvider'
import classNames from 'classnames'
import utils from 'src/utils/utils'
const _ = require('lodash')

const useStyles = makeStyles({
    table: {
        "& .MuiTableRow-root": {
            height: '50px'
        },
        "& .MuiTableCell-root": {
            whiteSpace: 'nowrap',
            textAlign: 'center'
            // borderLeft: "1px solid rgba(224, 224, 224, 1)",
        },
        "& .MuiTableSortLabel-icon": {
            opacity: 0.5,
        },
        "& .Mui-active .MuiTableSortLabel-icon": {
            opacity: 1,
            color: 'limegreen',
        },
        "& .MuiCheckbox-root": {
            padding: '4px'
        }
    },
})


export const usePagination = ({
    _page = 0,
    _orderBy = 'created_at',
    _order = 'desc',
    _limit = 10,
    _limitOptions = [5, 10, 25]
} = {}) => {
    const [page, setPage] = useState(_page)
    const [limit, setLimit] = useState(_limit)
    const [limitOptions, setLimitOptions] = useState(_limitOptions)
    const [order, setOrder] = useState(_order)
    const [orderBy, setOrderBy] = useState(_orderBy)

    return ({
        page, setPage,
        limit, setLimit,
        order, setOrder,
        orderBy, setOrderBy,
        limitOptions,
    })
}


export default ({
    rows = [], heads = [], total, className,
    placeHolder = '-',
    limitOptions = [],
    page, setPage,
    limit, setLimit,
    order, setOrder,
    orderBy, setOrderBy,
    rowClassName = new Function(),
    ...rest
}) => {
    const classes = useStyles()

    const emptyRows = limit - Math.min(limit, _.size(rows))
    // const [checked, setChecked] = useState([])
    const handleLimitChange = (event) => {
        setLimit(event.target.value)
    }

    const handlePageChange = (event, newPage) => {
        setPage(newPage)
    }

    const handleSort = (cell) => {
        if (orderBy == cell.value) {
            setOrder(order === 'asc' ? 'desc' : 'asc')
        }
        else {
            setOrderBy(cell.value)
        }
    }
    const { t } = useLocale()

    return (
        <Card {...rest}>
            <PerfectScrollbar>
                <Box >
                    {/* {'https://discord.gg/SCGddHEM'} */}
                    <Table size='small' className={classNames(classes.table, className)}>
                        <TableHead>
                            <TableRow >
                                {
                                    _.map(heads, (cell, index) => {
                                        return (
                                            <TableCell key={cell.label + index} sx={cell.sx}>
                                                {cell.sort === true
                                                    ? <TableSortLabel
                                                        active={orderBy === cell.value}
                                                        direction={orderBy === cell.value ? order : 'asc'}
                                                        onClick={(event) => handleSort(cell)}
                                                        hideSortIcon={false}
                                                    >
                                                        {cell.label}
                                                    </TableSortLabel>
                                                    : _.get(cell, 'label')
                                                }
                                            </TableCell>)
                                    })
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_.map(rows, (bodyCell, index) => (
                                <TableRow
                                    hover
                                    key={index}
                                    className={rowClassName(bodyCell, index)}
                                // onClick={() => {
                                //     rowOnClick(bodyCell)
                                // }}
                                >
                                    {_.map(heads, cell => {
                                        var render = ''
                                        if (cell.content) {
                                            render = cell.content(bodyCell, index)
                                        }
                                        else {
                                            render = _.get(bodyCell, cell.value)
                                        }
                                        if (cell.translate) {
                                            render = t(_.toString(render))
                                        }
                                        if (typeof render != 'number' && (_.isNil(render) || render == '')) {
                                            render = cell.placeHolder || placeHolder
                                        }
                                        return (
                                            <TableCell className={cell.className && cell.className(bodyCell, index)} key={cell.label + index} width={cell.width} sx={cell.sx}>
                                                {render}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: emptyRows * 50 }}>
                                    <TableCell colSpan={99}>
                                        {total === 0 && rows && <Typography variant='h5'>{t('no_records')}</Typography>}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Box>
            </PerfectScrollbar>
            <TablePagination
                component="div"
                count={total}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={limitOptions}
                labelRowsPerPage={t('rows_per_page')}
            />
        </Card >
    )
}

