import { Box, Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  Bar,
  BarChart,
  Brush,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import useApi from 'src/api/api';
import CustomDatePicker from 'src/components/DatePicker';
import { useAuthData } from 'src/provider/AuthDataProvider';
import { useLocale } from 'src/provider/LocaleProvider';
const moment = require('moment-timezone');
const _ = require('lodash');
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF80FF'];

export default function SubmittedHomeworkChart() {
  const { t } = useLocale();
  const { selectedPeriod } = useAuthData();
  const api = useApi();
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(moment().toDate());
  const timer = useRef();

  useEffect(() => {
    if (selectedPeriod) {
      api.getSubmittedHomeworkStat(startDate, endDate).then((res) => {
        const { data } = res;
        const { dateRange } = data;
        setData(data);
        setStartDate(dateRange.fromDate);
        setEndDate(dateRange.toDate);
      });
    }
  }, [selectedPeriod]);

  const getData = async () => {
    return api.getSubmittedHomeworkStat(startDate, endDate).then((res) => {
      setData(res.data);
    });
  };
  useEffect(() => {
    if (data) {
      setData({});
      clearTimeout(timer.current);
      timer.current = setTimeout(getData, 500);
    }
  }, [startDate, endDate]);
  return (
    <>
      <Box mb={2} sx={{ display: 'flex', justifyContent: 'space-around' }}>
        <CustomDatePicker
          label={t('from')}
          selectedDate={startDate}
          setSelectedDate={setStartDate}
        />
        <CustomDatePicker
          label={t('to')}
          selectedDate={endDate}
          setSelectedDate={setEndDate}
        />
      </Box>
      <Card sx={{ p: 2, mb: 2 }}>
        <Box mb={2} textAlign="center">
          <Typography variant="h3">
            {t('percentage_of_completion_by_grade')}
          </Typography>
        </Box>

        <Box sx={{ height: '300px' }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={data.gradeData}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Bar
                dataKey="submitted"
                label={t('submitted')}
                name={t('submitted')}
                stackId="a"
                fill={COLORS[0]}
              />
              <Bar
                dataKey="unsubmitted"
                name={t('unsubmitted')}
                stackId="a"
                fill={COLORS[1]}
              />
              <Bar
                dataKey="absent"
                name={t('absent')}
                stackId="a"
                fill={COLORS[2]}
              />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Card>
      <Card sx={{ p: 2, mb: 2 }}>
        <Box mb={2} textAlign="center">
          <Typography variant="h3">
            {t('percentage_of_completion_by_class')}
          </Typography>
        </Box>

        <Box sx={{ height: '300px' }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={data.classData}
              stackOffset="expand"
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Bar
                dataKey="submitted"
                label={t('submitted')}
                name={t('submitted')}
                stackId="a"
                fill={COLORS[0]}
              />
              <Bar
                dataKey="unsubmitted"
                name={t('unsubmitted')}
                stackId="a"
                fill={COLORS[1]}
              />
              <Bar
                dataKey="absent"
                name={t('absent')}
                stackId="a"
                fill={COLORS[2]}
              />
              <Brush />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Card>
    </>
  );
}
const CustomTooltip = ({ active, payload }) => {
  function Percentage(count, total) {
    if (total === 0) return 0;
    return _.round((count / total) * 100, 1);
  }

  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        {_.map(payload, (payloads) => (
          <Typography key={payloads?.dataKey} sx={{ color: payloads?.color }}>
            {payloads?.name} : {payloads?.payload[payloads.dataKey]} (
            {Percentage(
              payloads?.payload[payloads.dataKey],
              payloads?.payload['total']
            )}
            %)
          </Typography>
        ))}
      </div>
    );
  } else return <></>;
};
