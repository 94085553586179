import React, { useState, useEffect, useContext, createContext, useMemo, useCallback, } from 'react'
import { useLocation, useNavigate } from 'react-router'
import useApi from 'src/api/api'
import useSWR from 'swr'

const AuthDataContext = createContext()

const CURRENT_PERIOD_POLLING_INTERVAL = 5000

export function useAuthData() {
    const context = useContext(AuthDataContext) || {}
    return {
        userInfo: context.userInfo,
        organization: context.organization,
        currentPeriod: context.currentPeriod,
        setCurrentPeriod: context.setCurrentPeriod,
        refresh: context.refresh,
        selectedPeriod: context.selectedPeriod,
        setSelectedPeriod: context.setSelectedPeriod
    }
}
const AuthDataProvider = (props) => {

    const api = useApi()
    const nav = useNavigate()
    const { children } = props

    const [currentPeriod, setCurrentPeriod] = useState();
    const [selectedPeriodByPathObject, setSelectedPeriodByPathObject] = useState();

    const authUserInfo = useSWR('/auth/user', api.getUserInfo)
    const orgUserInfo = useSWR('/organizations/user', () => api.getOrganizationInfo(), { refreshInterval: CURRENT_PERIOD_POLLING_INTERVAL })

    const location = useLocation()
    const path = location.pathname


    const selectedPeriod = useMemo(() => {
        if (selectedPeriodByPathObject?.[path]) {
            return selectedPeriodByPathObject[path]
        }
        return currentPeriod || null
    }, [selectedPeriodByPathObject, path, currentPeriod])

    const setSelectedPeriod = useCallback((period) => {
        if (!selectedPeriodByPathObject) {
            setSelectedPeriodByPathObject({ [path]: period })
        } else {
            if (!selectedPeriodByPathObject[path] || selectedPeriodByPathObject[path] !== period) {
                setSelectedPeriodByPathObject(s => ({ ...s, [path]: period }))
            }
        }
    }, [selectedPeriodByPathObject, path])

    useEffect(() => {
        if (!selectedPeriod && currentPeriod) {
            setSelectedPeriod(currentPeriod)
        }
    }, [selectedPeriod, currentPeriod, setSelectedPeriod])

    const userInfo = useMemo(() => {
        if (authUserInfo.data && authUserInfo.data.data) {
            const { organization, __v, ...rest } = authUserInfo.data.data
            return rest
        }
        return null
    }, [authUserInfo.data])
    const organization = useMemo(() => {
        if (orgUserInfo.data && orgUserInfo.data) {

            const { current_period, ...rest } = orgUserInfo.data
            return rest
        }
        return null
    }, [orgUserInfo.data])
    useEffect(() => {
        if (orgUserInfo?.data) {
            const { current_period, _id } = orgUserInfo.data
            if (current_period && current_period !== currentPeriod) {
                setCurrentPeriod(current_period)
                localStorage.setItem('current_period', current_period)
                localStorage.setItem('organization_id', _id)
            }
        }
    }, [currentPeriod, orgUserInfo.data])
    useEffect(() => {
        if (orgUserInfo?.data) {
            const { current_period, role } = orgUserInfo.data
            if (!current_period) {
                setCurrentPeriod(null)
                if (role === "organization_admin") nav('/admin/period')
                else api.logout()
            }
        }
    }, [api, nav, orgUserInfo.data])

    const refresh = useCallback(() => {
        authUserInfo.mutate()
        orgUserInfo.mutate()
    }, [authUserInfo, orgUserInfo])


    const state = {
        userInfo,
        organization,
        currentPeriod, setCurrentPeriod,
        selectedPeriod, setSelectedPeriod,
        refresh
    }

    var redirectWhenNoPeriod = !currentPeriod && location.pathname !== '/admin/period'
    if (!organization || !userInfo || redirectWhenNoPeriod) return null
    return (
        <AuthDataContext.Provider value={state}>
            {children}
        </AuthDataContext.Provider>
    )
}

export default AuthDataProvider