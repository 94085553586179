import { Helmet } from 'react-helmet'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import { useLocale } from 'src/provider/LocaleProvider';
import { useState } from 'react';
import { useFormik } from 'formik';
import useApi from 'src/api/api';
import formikProps from 'src/utils/formikProps';
import { useSnack } from 'src/provider/SnackbarProvider';
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import FacebookIcon from '@material-ui/icons/Facebook'
import LayoutContainer from 'src/layout/LayoutContainer';
import { useAuthData } from 'src/provider/AuthDataProvider';
// import { Languages, Password, ReportContact } from './components';

const useStyles = makeStyles(theme => ({

}));

const _ = require('lodash')

const yup = require('yup')

const Languages = () => {

  const { t, getLanguage, getLanguageLabel, getLanguages, setLanguage } = useLocale()
  const [lang, setLang] = useState(getLanguage());

  function handleChangeLang() {

    setLanguage(lang)
  }

  return (
    <Card className='w100'>
      <CardHeader title={t('language_settings')} />
      <CardContent className='flex ac'>
        {t('language_text')}
        <div className="spacer" />
        <FormControl sx={{ minWidth: '100px', ml: 1 }}>
          <Select
            size='small'
            name="language"
            type="text"
            fullWidth
            value={lang}
            onChange={(e) => setLang(e.target.value)}
          >
            {_.map(getLanguages(), op => {
              return (
                <MenuItem key={op} value={op}>
                  {getLanguageLabel(op)}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </CardContent>
      <CardActions>
        <div className="spacer" />
        <Button disabled={getLanguage() == lang} onClick={handleChangeLang}>
          {t('save')}
        </Button>
      </CardActions>
    </Card>
  )
}


const Password = () => {
  const api = useApi()
  const { t } = useLocale()
  const snack = useSnack()
  const formik = useFormik({
    initialValues: {
      password: '',
      new_password: '',
      confirm_password: ''
    },
    validationSchema: yup.object().shape({
      password: yup.string().required().nullable(),
      new_password: yup.string().min(6).required().nullable().test('equal', t('yup_new_password'), function (value) {
        return this.parent.password !== value
      }).label('new password'),
      confirm_password: yup.string().required().nullable().test('equal', t('yup_confirm_password'), function (value) {
        return this.parent.new_password === value
      }).label('confirm new password')
    }),
    onSubmit: (values, helper) => {
      api.setPassword({ password: values.password, new_password: values.new_password })
        .then(res => {
          snack.open(t('change_pw_success'))
        })
        .catch(e => {
          snack.error(t('change_pw_fail'))

        }).finally(() => {
          helper.setSubmitting(false)
          helper.resetForm()
        })

    }
  })
  return (
    <form onSubmit={formik.handleSubmit} autoComplete='off'>
      <Card className='w100'>
        <CardHeader title={t('reset_password_title')} />
        <CardContent >
          <TextField
            fullWidth
            label={t('current_password')}
            type='password'
            {...formikProps(formik, 'password')}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label={t('new_password')}
            type='password'
            {...formikProps(formik, 'new_password')}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label={t('confirm_password')}
            type='password'
            {...formikProps(formik, 'confirm_password')}
            sx={{ mb: 2 }}
          />
        </CardContent>
        <CardActions>
          <div className="spacer" />
          <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <Button type='submit' disabled={formik.isSubmitting || _.size(formik.errors) > 0} variant='contained' color="primary" >
              {'Submit'}
            </Button>
          </Box>
        </CardActions>
      </Card>
    </form>
  )
}



const Settings = () => {
  const classes = useStyles();
  const auth = useAuthData()
  const { t } = useLocale()
  return (
    <LayoutContainer title={t('settings_title')}>
      <Grid container spacing={4} sx={{ pb: 4 }} >
        <Grid item xs={12} md={8}>
          <Languages />
        </Grid>

        <Grid item xs={12} md={4}>
          <Card>
            <CardHeader title={t('report_problem')} />
            <CardContent>
              <div className={'flex ac'}>
                <MailOutlineIcon />
                <Typography variant="subtitle2" sx={{ pl: 1 }}>
                  <a style={{ wordBreak: 'break-all' }} href={"mailto:" + "contact@animaetech.com"}>contact@animaetech.com</a>
                </Typography>
              </div>
              <div className={'flex ac'}>
                <FacebookIcon />
                <Typography variant="subtitle2" sx={{ pl: 1 }} >
                  <a href="https://www.facebook.com/animaetech" target="_blank" style={{ color: '#0000EE' }}>Facebook</a>
                </Typography>
              </div>
            </CardContent>
          </Card>
        </Grid>
        {!auth.userInfo.isParent &&
          <Grid item xs={12} md={8} >
            <Password />
          </Grid>
        }
      </Grid>
    </LayoutContainer>
  );
};

export default Settings;
