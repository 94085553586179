import { Box, Button, Card, ListItem, ListItemText } from "@material-ui/core";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import { useState } from "react";
import { useQuery } from "react-query";
import { ReactSortable } from "react-sortablejs";
import useApi from "src/api/api.js";
import LoadingView from "src/components/LoadingView";
import LayoutContainer from "src/layout/LayoutContainer";
import { useLocale } from "src/provider/LocaleProvider";
import { useSnack } from "src/provider/SnackbarProvider";
import { SubjectDialog } from "./SubjectDialog";

export function SubjectSetting() {
	const { t, getLanguage } = useLocale();
	const api = useApi();
	const snack = useSnack();

	const [dialogProps, setDialogProps] = useState({
		mode: "create",
		isOpen: false,
		subjectId: "",
	});

	const { isLoading } = useQuery("subjects", () =>
		api.getSubjects().then(({ data }) => {
			setSubjects(data?.subjects);
			return data?.subjects;
		}),
	);

	const [subjects, setSubjects] = useState([]);

	const handelEdit = (id) => {
		setDialogProps({ isOpen: true, subjectId: id, mode: "edit" });
	};

	const handleCreate = () => {
		setDialogProps({ isOpen: true, subjectId: "", mode: "create" });
	};

	const handleSave = () => {
		api
			.updateSubjectsOrder(subjects)
			.then(() => {
				snack.open(t("saved"));
			})
			.catch(() => snack.error(t("failed")));
	};

	if (isLoading) return <LoadingView />;

	return (
		<LayoutContainer title={t("subject")}>
			<Card>
				<Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }} m={3}>
					<Button variant="contained" onClick={handleSave}>
						{t("save")}
					</Button>
					<Button variant="contained" onClick={handleCreate}>
						{t("add_subject")}
					</Button>
				</Box>

				<Box sx={{ width: "100%" }} mb={6}>
					<ReactSortable
						list={subjects}
						setList={setSubjects}
						style={{ margin: "auto" }}
					>
						{subjects?.map((item) => (
							<Box key={item._id}>
								<ListItem
									button
									disableRipple
									height={64}
									sx={{
										// border: 3,
										// borderColor: 'coral',
										backgroundColor: "#f0f0f0",
										mb: 0.5,
										mx: "auto",
										width: "50%",
									}}
									key={item._id}
									onClick={() => handelEdit(item._id)}
								>
									<ListItemText primary={item.name[getLanguage()]} />

									<DragHandleIcon />
								</ListItem>
								{/* <Divider></Divider> */}
							</Box>
						))}
					</ReactSortable>
				</Box>
			</Card>

			{dialogProps.isOpen && (
				<SubjectDialog
					title="Edit"
					setDialogProps={setDialogProps}
					subjects={subjects}
					setSubjects={setSubjects}
					{...dialogProps}
				/>
			)}
		</LayoutContainer>
	);
}
