import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import useApi from 'src/api/api';
import LayoutContainer from 'src/layout/LayoutContainer';
import { useAuthData } from 'src/provider/AuthDataProvider';
import { useLocale } from 'src/provider/LocaleProvider';
import {
  Backdrop,
  Box,
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  CardHeader,
  Button,
  Divider,
  IconButton,
  TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import DataTableNoLimit from 'src/components/DataTable/DataTableNoLimit';
import Icons from 'src/icons';
import DataTable from 'src/components/DataTable/DataTable';
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  AreaChart,
  Brush
} from 'recharts';
const moment = require('moment-timezone');
const _ = require('lodash');
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF80FF'];

const useStyles = makeStyles((theme) => ({
  row: {
    // height: '10px',
    // paddingBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(1)
    // marginTop: theme.spacing(1)
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },

  cardTitle: {
    fontWeight: 700
  },
  tableCell: {
    '& .MuiTableCell-root': {
      textAlign: 'center',
      borderLeft: 'none !important'
    },
    '& .MuiTableRow-root': {
      height: '60px'
    }
  }
}));
export default function StudentRecord() {
  const { t, getLanguage } = useLocale();
  const classes = useStyles();
  const nav = useNavigate();
  const { userId } = useParams();
  const { selectedPeriod } = useAuthData();
  const api = useApi();
  const [profile, setProfile] = useState();
  const [submittedHomeworkRecord, setSubmittedHomeworkRecord] = useState([]);
  const [unFinishedHomeworkRecord, setUnfinishedHomeworkRecord] = useState([]);

  function toPecentage(molecular, total) {
    if (!molecular) return null;
    return `${_.round((molecular / total) * 100, 1)}\%`;
  }

  useEffect(() => {
    if (selectedPeriod) {
      api
        .getStudentProfiles(userId, {
          pointActions: 1,
          attendanceActions: 1
        })
        .then(({ data }) => {
          var ptArr = _.add(data.points);

          setProfile(data);
        })
        .catch((e) => console.log(e));

      api
        .getStudentSubmittedHomeworkRecord(userId)
        .then((res) => {
          setSubmittedHomeworkRecord(res.data);
        })
        .catch((e) => console.log(e));

      api
        .getStudentUnfinishedHomeworkRecord(userId)
        .then((res) => {
          setUnfinishedHomeworkRecord(res.data);
        })
        .catch((e) => console.log(e));
    }
  }, [selectedPeriod]);

  return (
    <LayoutContainer
      title={t('student_record')}
      backSrc={'/admin/student_list'}
    >
      {profile && (
        <Box sx={{ mb: 2 }}>
          <Card className={'mb8'}>
            <CardHeader
              className={classes.actions}
              title={t('student_information')}
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    // disabled={dialogMode == 'edit'}
                    InputProps={{
                      readOnly: true
                    }}
                    fullWidth
                    label={t('u_id')}
                    defaultValue={profile?.u_id || t('no_record')}
                    // {...formikProps(formik, 'u_id')}
                    // sx={{ mb: 2 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    InputProps={{
                      readOnly: true
                    }}
                    fullWidth
                    label={t('class')}
                    defaultValue={profile?.class || t('no_record')}
                    // {...formikProps(formik, 'class')}
                    // sx={{ mb: 2 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    InputProps={{
                      readOnly: true
                    }}
                    fullWidth
                    label={t('class_no')}
                    type="number"
                    defaultValue={profile?.class_no || t('no_record')}
                    // {...formikProps(formik, 'class_no')}
                    // sx={{ mb: 2 }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    InputProps={{
                      readOnly: true
                    }}
                    fullWidth
                    label={t('name_zh')}
                    defaultValue={profile?.user?.name?.zh || t('no_record')}
                    // {...formikProps(formik, 'name.zh')}
                    // sx={{ mb: 2 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    InputProps={{
                      readOnly: true
                    }}
                    fullWidth
                    label={t('name_en')}
                    defaultValue={profile?.user?.name?.en || t('no_record')}
                    // {...formikProps(formik, 'name.en')}
                    // sx={{ mb: 2 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    InputProps={{
                      readOnly: true
                    }}
                    fullWidth
                    label={t('total_point')}
                    defaultValue={profile?.cumulative_point || t('no_record')}
                    // {...formikProps(formik, 'name.en')}
                    // sx={{ mb: 2 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    InputProps={{
                      readOnly: true
                    }}
                    fullWidth
                    label={t('point_balance')}
                    defaultValue={profile?.point || t('no_record')}
                    // {...formikProps(formik, 'name.en')}
                    // sx={{ mb: 2 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    InputProps={{
                      readOnly: true
                    }}
                    fullWidth
                    label={t('total_days_uncompleted_homework')}
                    defaultValue={
                      submittedHomeworkRecord?.amount?.unsubmitted ||
                      t('no_record')
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    InputProps={{
                      readOnly: true
                    }}
                    fullWidth
                    label={t('total_uncompleted_homework')}
                    defaultValue={
                      unFinishedHomeworkRecord?.total || t('no_record')
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    InputProps={{
                      readOnly: true
                    }}
                    fullWidth
                    label={t('total_days_completed_homework')}
                    defaultValue={
                      submittedHomeworkRecord?.amount?.submitted ||
                      t('no_record')
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    InputProps={{
                      readOnly: true
                    }}
                    fullWidth
                    label={t('percentage_of_total_days_completed_homework')}
                    defaultValue={
                      toPecentage(
                        submittedHomeworkRecord?.amount?.submitted,
                        submittedHomeworkRecord?.total
                      ) || t('no_record')
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}
      <Card sx={{ p: 2, mb: 2 }}>
        <Box mb={2} textAlign="center">
          <Typography variant="h3">{t('student_point_ratio')}</Typography>
        </Box>
        <Box sx={{ height: '300px' }}>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Legend />
              <Pie
                data={_.entries(profile?.points)}
                outerRadius="70%"
                dataKey="1"
                nameKey={(obj) => t(obj[0] + '_point')}
                cx="50%"
                cy="50%"
                fill="#8884d8"
                isAnimationActive={false}
                labelLine={false}
                local
                label={(e) => {
                  if (e.percent > 0) {
                    return `${e.name}: ${e.value} (${_.round(
                      e.percent * 100,
                      1
                    )}%)`;
                  }
                  return ' ';
                }}
              >
                {_.map(_.entries(profile?.points), (entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </Box>
      </Card>

      <Box sx={{ mb: 2 }}>
        <Card className="mb8">
          <CardHeader
            className={classes.actions}
            action={
              <Button
                color="primary"
                size="medium"
                variant="text"
                onClick={() =>
                  nav(`/admin/student_list/${userId}/point_record`)
                }
              >
                {t('see_all')} <Icons.ArrowRightIcon />
              </Button>
            }
            title={t('point_history')}
          />
          <Divider />
          <CardContent>
            <DataTableNoLimit
              className={classes.tableCell}
              rows={_.slice(profile?.pointActions, 0, 3)}
              heads={[
                {
                  label: t('date'),
                  value: 'created_at',
                  content: (row) =>
                    moment(row.created_at).format('YYYY-MM-DD HH:MM'),
                  width: '33.3%'
                },
                {
                  label: t('pointrule_name'),
                  value: 'pointRule.name',
                  width: '33.3%'
                },
                { label: t('point'), value: 'point', width: '33.3%' }
              ]}
            />
          </CardContent>
        </Card>
        <div align="right" className={'font12'}>
          Last Updated at {moment().format('YYYY-MM-DD HH:mm:ss')}
        </div>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Card className="mb8">
          <CardHeader
            className={classes.actions}
            action={
              <Button
                color="primary"
                size="medium"
                variant="text"
                onClick={() =>
                  nav(`/admin/student_list/${userId}/attendance_record`)
                }
              >
                {t('see_all')} <Icons.ArrowRightIcon />
              </Button>
            }
            title={t('attendance_history')}
          />
          <Divider />
          <CardContent>
            <DataTableNoLimit
              className={classes.tableCell}
              rows={_.slice(profile?.attendanceActions, 0, 3)}
              heads={[
                {
                  label: t('date'),
                  value: 'created_at',
                  content: (row) =>
                    moment(row.created_at).format('YYYY-MM-DD HH:MM'),
                  width: '33.3%'
                },
                {
                  label: t('attendancerule_name'),
                  value: 'attendanceRule.name',
                  width: '33.3%'
                },
                {
                  label: t('type'),
                  value: 'type',
                  translate: 1,
                  width: '33.3%'
                }
              ]}
            />
          </CardContent>
        </Card>
        <div align="right" className={'font12'}>
          Last Updated at {moment().format('YYYY-MM-DD HH:mm:ss')}
        </div>
      </Box>
      {/* ////////////////////!!  new*/}
      <Card sx={{ mb: 2 }}>
        <CardHeader
          className={classes.actions}
          title={t('submitted_homework')}
        />
        <CardContent>
          {submittedHomeworkRecord.total ? (
            <Card sx={{ p: 2, mb: 2 }}>
              <Box mb={2} textAlign="center">
                <Typography variant="h3">
                  {t('percentage_of_completion')}
                </Typography>
              </Box>
              <Box sx={{ height: '300px' }}>
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Legend />
                    <Pie
                      data={_.entries(submittedHomeworkRecord?.amount)}
                      outerRadius="70%"
                      dataKey="1"
                      nameKey={(obj) => t(obj[0])}
                      cx="50%"
                      cy="50%"
                      fill="#8884d8"
                      isAnimationActive={false}
                      labelLine={false}
                      local
                      label={(e) => {
                        if (e.percent > 0) {
                          return `${e.name}: ${e.value} (${_.round(
                            e.percent * 100,
                            1
                          )}%)`;
                        }
                        return ' ';
                      }}
                    >
                      {_.map(
                        _.entries(submittedHomeworkRecord?.amount),
                        (entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        )
                      )}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </Box>
            </Card>
          ) : (
            <></>
          )}

          <Card className="mb8">
            <CardHeader
              className={classes.actions}
              action={
                <Button
                  color="primary"
                  size="medium"
                  variant="text"
                  onClick={() =>
                    nav(
                      `/admin/student_list/${userId}/submitted_homework_record`
                    )
                  }
                >
                  {t('see_all')} <Icons.ArrowRightIcon />
                </Button>
              }
              title={t('submitted_homework_record')}
            />
            <Divider />
            <CardContent>
              <DataTableNoLimit
                className={classes.tableCell}
                rows={_.slice(submittedHomeworkRecord.records, 0, 3)}
                heads={[
                  {
                    label: t('date'),
                    value: 'date',
                    content: (row) => moment(row.date).format('YYYY-MM-DD'),
                    width: '33.3%'
                  },
                  {
                    label: t('status'),
                    value: 'status',
                    translate: 1,
                    width: '33.3%'
                  },
                  {
                    label: t('action_created_by'),
                    content: (row) =>
                      _.get(row, `created_by.name.${getLanguage()}`),
                    width: '33.3%'
                  }
                ]}
              />
            </CardContent>
          </Card>
          <div align="right" className={'font12'}>
            Last Updated at {moment().format('YYYY-MM-DD HH:mm:ss')}
          </div>
        </CardContent>
      </Card>
      <Card sx={{ mb: 2 }}>
        <CardHeader
          className={classes.actions}
          title={t('unfinished_homework')}
        />
        <CardContent>
          {unFinishedHomeworkRecord.total ? (
            <Card sx={{ p: 2, mb: 2 }}>
              <Box mb={2} textAlign="center">
                <Typography variant="h3">
                  {t('monthly_number_of_uncompleted_homework')}
                </Typography>
              </Box>
              <Box sx={{ height: '300px' }}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width={500}
                    height={300}
                    data={unFinishedHomeworkRecord?.dataInMonth}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar
                      dataKey="count"
                      stackId="a"
                      name={t('count')}
                      fill={COLORS[2]}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </Card>
          ) : (
            <></>
          )}
          {unFinishedHomeworkRecord.total ? (
            <Card sx={{ p: 2, mb: 2 }}>
              <Box mb={2} textAlign="center">
                <Typography variant="h3">
                  {t('daily_number_of_uncompleted_homework')}
                </Typography>
              </Box>
              <Box sx={{ height: '300px' }}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width={500}
                    height={300}
                    data={unFinishedHomeworkRecord?.dataInDay}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="day" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar
                      dataKey="count"
                      stackId="a"
                      name={t('count')}
                      fill={COLORS[2]}
                    />
                    <Brush />
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </Card>
          ) : (
            <></>
          )}
          <Card className="mb8">
            <CardHeader
              className={classes.actions}
              action={
                <Button
                  color="primary"
                  size="medium"
                  variant="text"
                  onClick={() =>
                    nav(
                      `/admin/student_list/${userId}/unfinished_homework_record`
                    )
                  }
                >
                  {t('see_all')} <Icons.ArrowRightIcon />
                </Button>
              }
              title={t('unfinished_homework_record')}
            />
            <Divider />
            <CardContent>
              <DataTableNoLimit
                className={classes.tableCell}
                rows={_.slice(unFinishedHomeworkRecord?.records, 0, 3)}
                heads={[
                  {
                    label: t('date'),
                    content: (row) => moment(row.date).format('YYYY-MM-DD'),
                    width: '20%',
                    sort: true
                  },
                  {
                    label: t('subject'),
                    translate: 1,
                    content: (row) =>
                      _.get(row, `homework_type.subject.name.${getLanguage()}`),
                    width: '20%'
                  },
                  {
                    label: t('code'),
                    translate: 1,
                    content: (row) => _.get(row, `homework_type.name`),
                    width: '20%'
                  },
                  {
                    label: t('remark'),
                    translate: 1,
                    content: (row) => _.get(row, `remarks`),
                    width: '20%'
                  },
                  {
                    label: t('action_created_by'),
                    content: (row) =>
                      _.get(row, `created_by.name.${getLanguage()}`),
                    width: '20%'
                  }
                ]}
              />
            </CardContent>
          </Card>
          <div align="right" className={'font12'}>
            Last Updated at {moment().format('YYYY-MM-DD HH:mm:ss')}
          </div>
        </CardContent>
      </Card>
      {/* ////////////////////!! */}
      <Box>
        <Card className="mb8">
          <CardHeader
            className={classes.actions}
            // action={
            //     <Button
            //         color="primary"
            //         size="medium"
            //         variant="text"
            //         onClick={() => nav(`/admin/student_list/${userId}/badges`)}
            //     >
            //         {t('see_all')} <Icons.ArrowRightIcon />
            //     </Button>
            // }
            title={t('badges')}
          />
          <Divider />
          <CardContent>
            <DataTable
              className={classes.tableCell}
              rows={profile?.badges}
              heads={[
                {
                  id: 'created_at',
                  label: t('created_at'),
                  value: 'created_at',
                  content: (row) => new Date(row.created_at).toLocaleString(),
                  sort: true
                },
                {
                  id: 'badge_rule_name',
                  label: t('badge_rule_name'),
                  value: 'badge_rule.name',
                  sort: true
                }
              ]}
            />
          </CardContent>
        </Card>
        <div align="right" className={'font12'}>
          Last Updated at {moment().format('YYYY-MM-DD HH:mm:ss')}
        </div>
      </Box>
    </LayoutContainer>
  );
}
