import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import useApi from 'src/api/api';
import DataTable from 'src/components/DataTable/DataTable';
import SearchInput from 'src/components/SearchInput';
import Icons from 'src/icons';
import LayoutContainer from 'src/layout/LayoutContainer';
import { useLocale } from 'src/provider/LocaleProvider';
import utils from 'src/utils/utils';
import AddHomeworkTypeDialog from './AddHomeworkTypeDialog';
import EditHomeworkTypeDialog from './EditHomeworkTypeDialog';
const _ = require('lodash');

const HomeworkType = (_props) => {
  const [addDialog, setAddDialog] = useState(false);
  const [selected, setSelected] = useState(null);
  const [search, setSearch] = useState('');
  const api = useApi();
  const { t, getLanguage } = useLocale();
  const [homeworkType, setHomeworkType] = useState([]);
  const [allSubject, setAllSubject] = useState([]);
  const [homeworkCategory, setHomeworkCategory] = useState('');

  useEffect(() => {
    api.getHomeworkType().then((res) => {
      setAllSubject(res.data?.allSubject);
      setHomeworkType(res.data?.homeworkType);
    });
  }, []);
  function refreshPage() {
    api.getHomeworkType().then((res) => {
      setAllSubject(res.data?.allSubject);
      setHomeworkType(res.data?.homeworkType);
    });
  }

  function handleEdit(row) {
    setSelected(row);
  }

  const headCells = [
    {
      id: 'name',
      label: t('name'),
      value: 'name',
      width: '30%',
      align: 'left'
    },
    {
      id: 'subject',
      label: t('subject'),
      // value: 'subject.name.zh',
      content: (s) => `${_.get(s, `subject.name.${getLanguage()}`)}`,
      width: '30%',
      align: 'left'
    },
    {
      id: 'isActive',
      label: t('status'),
      content: (r) => (r.isActive ? t('active') : t('inactive')),
      className: (row) => (row.isActive ? 'text-green' : 'text-red'),
      // sort: true,
      width: '30%',
      align: 'left'
    },
    {
      id: 'actions',
      label: t('actions'),
      width: '10%',
      content: (row) => (
        <Tooltip title={t('edit')}>
          <IconButton onClick={() => handleEdit(row)} sx={{ p: 0.5 }}>
            <Icons.EditIcon />
          </IconButton>
        </Tooltip>
      )
    }
  ];

  function handleAdd() {
    setAddDialog(true);
  }

  var homeworkTypeFiltered = _.flow(
    () => {
      if (!homeworkCategory) return homeworkType;
      return _.filter(
        homeworkType,
        (type) => type?.subject?._id === homeworkCategory
      );
    },
    (result) => {
      return utils.searchFilter(result, [search], [['name']]);
    }
  );
  return (
    <LayoutContainer title={t('homework_code')}>
      <AddHomeworkTypeDialog
        open={addDialog}
        setOpen={setAddDialog}
        refresh={refreshPage}
        allSubject={allSubject}
      />
      <EditHomeworkTypeDialog
        category={selected}
        open={selected !== null}
        setOpen={(flag) => !flag && setSelected(null)}
        refresh={refreshPage}
        allSubject={allSubject}
      />
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} sm={12}>
          <FormControl>
            <InputLabel shrink>{t('subject')}</InputLabel>
            <Select
              className="mySelect"
              variant="outlined"
              size="small"
              displayEmpty
              label={t('subject')}
              onChange={(event) => setHomeworkCategory(event.target.value)}
              value={homeworkCategory?._id}
              defaultValue={''}
              sx={{ minWidth: '200px', width: 'min(200px, 100%)', mr: 1 }}
            >
              {/* <option disabled selected value={""} > -- {t('select_a_period')} -- </option> */}
              <MenuItem value=""> {t('see_all')} </MenuItem>
              {_.map(allSubject, (n) => (
                <MenuItem key={n?._id} value={n?._id}>
                  {`${_.get(n, `name.${getLanguage()}`)}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={8} sm={6}>
          <SearchInput setSearch={setSearch} />
        </Grid>
        <Grid item xs={4} sm={6} className="flex ac">
          <div className="spacer"></div>
          <Button variant="contained" color="primary" onClick={handleAdd}>
            {t('homework_add')}
          </Button>
        </Grid>
      </Grid>

      <DataTable
        row_id="_id"
        rows={homeworkTypeFiltered()}
        heads={headCells}
        rowsPerPage={10}
      />
    </LayoutContainer>
  );
};

export default HomeworkType;
