import React, { Fragment, useMemo, useCallback, useEffect, useState, useRef, useLayoutEffect } from 'react'
import { Box, Button, Card, CardActions, CardContent, CardHeader, Container, FormControl, FormHelperText, IconButton, InputLabel, TextField } from '@material-ui/core'
import { useLocale } from 'src/provider/LocaleProvider'
import useApi from 'src/api/api';
import { Helmet } from 'react-helmet';
import { useFormik } from 'formik';
import formikProps from 'src/utils/formikProps';
import { useAuthData } from 'src/provider/AuthDataProvider';
import { makeStyles } from '@material-ui/styles';
import utils from 'src/utils/utils';
import LayoutContainer from 'src/layout/LayoutContainer';
import Icons from 'src/icons';
const yup = require('yup')
const _ = require('lodash')

const useStyles = makeStyles((theme) => ({
    imageuploader: {
        position: 'relative',
        '& img': {
            objectFit: 'contain'
        },
        '& .removeIcon': {
            position: 'absolute', right: 0, top: 0, zIndex: 999
        },
        '& .uploadIcon': {
            // backgroundColor: 'rgba(255,255,255,0.5)',
            // borderRadius: '4px',
            position: 'absolute', right: 10, bottom: 10, zIndex: 999
        },
        '& .MuiFormControl-root': {
            height: '300px',
            border: '1px solid',
            borderRadius: '4px',
            borderColor: 'rgba(0, 0, 0, 0.23)',
            padding: '8px',
            '&:hover': {
                borderColor: 'black',
            },
            '& .MuiInputLabel-root': {
                padding: '0 4px',
                backgroundColor: 'white'
            },

        },
        '& .error': {
            borderColor: 'red !important',
            '&:hover': {
                borderColor: 'red !important',
            },
        }
    }

}))
const ImageUploader = ({ formik, name, label = 'label', helperText = '', ...rest }) => {
    const classes = useStyles()
    const { t } = useLocale()
    const value = _.get(formik.values, name, '')
    const error = _.get(formik.errors, name)
    const isError = Boolean(error)
    return useMemo(() =>
        <Box className={classes.imageuploader} {...rest} >
            <FormControl variant="outlined" fullWidth error={isError} className={isError && 'error'} >
                <InputLabel shrink >{label}</InputLabel>
                {value && <img className='h100 w100' src={value?.size ? URL.createObjectURL(value) : value} />}
                <div className='removeIcon'>
                    {value &&
                        <IconButton onClick={() => { formik.setFieldValue(name, null) }}>
                            <Icons.DeleteIcon />
                        </IconButton>
                    }
                </div>
                <div className='uploadIcon'>
                    <Button variant='text' onClick={() => utils.readFile(f => formik.setFieldValue(name, f))}>
                        <Icons.PublishIcon />
                        <span>  {t('choose_file')}</span>
                    </Button>
                </div>
            </FormControl >
            <FormHelperText error={isError}>
                {error}
                <p>{helperText}</p>
                {/* <p>{`< 1MB | PNG ${t('or')} JPEG | 1:1 ${t('aspect_ratio')}`}</p> */}
            </FormHelperText>
        </Box>
        , [value, error])
}

export default ImageUploader;