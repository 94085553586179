import {
  Box,
  Button,
  Card,
  Chip,
  Divider,
  Grid,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@material-ui/core';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import SubjectIcon from '@material-ui/icons/Subject';
import { useFormik } from 'formik';
import Fuse from 'fuse.js'
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import useApi from 'src/api/api';
import ClassSelector from 'src/components/ClassSelector';
import EditDialog from 'src/components/EditDialog';
import NoRecordItem from 'src/components/NoRecordItem';
import SearchInput from "src/components/SearchInput";
import { SubjectSelector } from 'src/components/SubjectSelector';
import vars from 'src/config/vars';
import LayoutContainer from 'src/layout/LayoutContainer';
import { useLocale } from 'src/provider/LocaleProvider';
import formikProps from 'src/utils/formikProps';

// const prettyBytes = require('pretty-bytes');
const yup = require('yup');

export default function GameList({ snack }) {
  const nav = useNavigate();
  const { t, translate } = useLocale();
  const api = useApi();
  const [dialogMode, setDialogMode] = useState();
  const [tab, setTab] = useState('single');
  const [selectedSubject, setSelectedSubject] = useState('ALL');
  const [selectedClass, setSelectedClass] = useState();

  const [gameList, setGameList] = useState([]);
  const [gameListBySubject, setGameListBySubject] = useState([]);
  const [search, setSearh] = useState('');

  const deboundedSearch = _.debounce((value) => {
    setSearh(value);
  }, 500);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    api.getGameList().then((res) => {
      setGameList(res.data);
    });
  }, []);

  const fuse = useMemo(() => {
    return new Fuse(gameList, {
      isCaseSensitive: false,
      useExtendedSearch: true,
      keys: ['subjects._id', 'filters._id', "name", "description"],
    })
  }, [gameList])

  useEffect(() => {
    const fuesParams = [];

    // Apply name filter using Fuse.js
    (selectedClass?._id) &&
      fuesParams.push({ "filters._id": `'${selectedClass._id}` });

    (search) &&
      fuesParams.push({ $or: [{ name: search }, { description: search }] });

    (selectedSubject !== "ALL" && selectedSubject !== "NONE") &&
      fuesParams.push({ "subjects._id": `'${selectedSubject}` });

    const fuseResult = fuesParams.length ?
      fuse.search({ $and: fuesParams }).map((item) => item.item) :
      gameList;

    (selectedSubject === "NONE") ?
      setGameListBySubject(fuseResult.filter((g) => g.subjects.length === 0)) :
      setGameListBySubject(fuseResult)

  }, [selectedSubject, gameList, selectedClass, search, fuse]);


  useEffect(() => {
    if (dialogMode) formik.resetForm();
  }, [dialogMode]);

  const formik = useFormik({
    initialValues: {
      name: '',
      gameType: 'mc',
      pointCorrect: null,
      pointIncorrect: null,
      pointExchangeRate: null,
      isActive: true,
      filters: [],
      subjects: []
    },
    enableReinitialize: true,
    validationSchema: yup
      .object({
        name: yup.string().required(),
        gameType: yup
          .string()
          .oneOf(['mc', 'interactive'])
          .required()
          .nullable(),
        isActive: yup.bool().required(),
        filters: yup.array()
      })
      .when('this', {
        is: (v) => formik.values.gameType !== 'interactive',
        // biome-ignore lint/suspicious/noThenProperty: <explanation>
        then: yup.object().shape({
          pointCorrect: yup.number().min(0).required().nullable(),
          pointIncorrect: yup.number().min(0).required().nullable(),
          pointExchangeRate: yup.number().min(0).required().nullable()
        })
      }),
    onSubmit: (values, helper) => {
      api
        .createGame(values)
        .then(async (res) => {
          nav(`/admin/games/${res.data._id}`);
          // await api.getGameList().then(res => {
          //     setGameList(res.data);
          //     setDialogMode(null);
          // });
        })
        .catch((e) => {
          snack.error();
        })
        .finally(() => {
          helper.setSubmitting(false);
        });
    }
  });
  const hasPointConfig = formik.values.gameType === 'mc';

  const filteredList = useMemo(() => {
    if (tab === 'multi') {
      return _.filter(gameListBySubject, (g) => g.gameType === 'interactive');
    } if (tab === 'single') {
      return _.filter(gameListBySubject, (g) => g.gameType === 'mc');
    }
  }, [tab, gameListBySubject]);

  return (
    <LayoutContainer title={t('game.game')}>
      <Box display="flex" justifyContent={"space-between"} mb={2}>
        <Box display={"flex"}>
          <SearchInput setSearch={deboundedSearch} />
          <ClassSelector
            selected={selectedClass}
            setSelected={setSelectedClass}
            sx={{ mx: 1 }}
          />
          <SubjectSelector
            selected={selectedSubject}
            setSelected={setSelectedSubject}
            sx={{ mb: 2 }} />
        </Box>

        <Button onClick={() => setDialogMode('add')}>{t('add_game')}</Button>
      </Box>
      <Card sx={{ mb: 2, borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={(_e, v) => setTab(v)}>
          <Tab label={t('single_player_mode')} value={'single'} />
          <Tab label={t('multi_player_mode')} value={'multi'} />
        </Tabs>
      </Card>
      <Grid container spacing={4}>
        {_.size(filteredList) > 0 ? (
          _.map(filteredList, (game) => {
            return (
              <Grid
                key={game._id}
                item
                xs={12}
                sm={6}
                sx={{
                  '& .MuiGrid-item': {
                    height: '120px'
                  },
                  '& img': {
                    height: '100%',
                    width: '100%'
                  },
                  '& .MuiTypography-root': {
                    overflow: 'hidden',
                    lineHeight: '25px',
                    maxHeight: '50px'
                  }
                }}
              >
                <NavLink to={`/admin/games/${game._id}`}>
                  <Card>
                    <Grid container>
                      <Grid item sx={{ p: 2, height: '120px', width: '120px' }}>
                        <img src={game.logo || '/images/game.png'} alt="Game" />
                      </Grid>
                      <Grid item xs lg sx={{ p: 2 }}>
                        <Typography variant="h4">{game.name}</Typography>
                        <Typography color="textSecondary" variant="h5">
                          {game.description}
                        </Typography>
                      </Grid>
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ display: { xs: 'none', lg: 'block' } }}
                      />
                      <Divider
                        flexItem
                        sx={{
                          display: { xs: 'block', lg: 'none' },
                          width: '100%'
                        }}
                      />
                      <Grid
                        item
                        xs={12}
                        lg={4}
                        sx={{ p: 1, display: 'flex', flexDirection: 'column' }}
                      >
                        <Box display="flex">
                          <DescriptionOutlinedIcon />
                          <Box pr={1} />
                          <Typography noWrap color="textSecondary" variant="h5">
                            {t(`game_${game.gameType}`)}
                          </Typography>
                        </Box>
                        <Box display="flex">
                          <PeopleAltOutlinedIcon />
                          <Box pr={1} />
                          <Typography color="textSecondary" variant="h5">
                            {_.size(game.filters) > 0
                              ? _.map(game.filters, (f) => translate(f.name))
                                .sort()
                                .join(', ')
                              : t('all_grade_and_class')}
                          </Typography>
                        </Box>
                        <Box display="flex">
                          <SubjectIcon />
                          <Box pr={1} />
                          <Typography noWrap color="textSecondary" variant="h5">
                            {_.size(game?.subjects) > 0
                              ? _.map(game?.subjects, (f) => translate(f.name))
                                .sort()
                                .join(', ')
                              : t('all_subject')}
                          </Typography>
                        </Box>
                        {!game.isActive && (
                          <Box display="flex">
                            <Chip label={t('inactive')} color="error" />
                          </Box>
                        )}
                        <Box flexGrow={1} />
                        {game.gameType === 'interactive' && (
                          <Button
                            href={`${vars.extenalURL.gameInteractive}?gameId=${game._id}`}
                            className="play_game_btn"
                            target="_blank"
                            size="small"
                            onClick={(e) => e.stopPropagation()}
                          >
                            {t('play_game')}
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Card>
                </NavLink>
              </Grid>
            );
          })
        ) : (
          <NoRecordItem grid />
        )}
      </Grid>
      <EditDialog
        title={t('add_game')}
        handleSave={formik.handleSubmit}
        open={dialogMode === 'add'}
        handleClose={() => setDialogMode(null)}
        sx={{ overflowY: 'hidden' }}
      >
        <TextField
          fullWidth
          label={t('name')}
          {...formikProps(formik, 'name')}
          sx={{ mb: 2 }}
        />
        <SubjectSelector
          fullWidth
          sx={{ mb: 2 }}
          multiple
          selected={_.get(formik.values, 'subjects')}
          setSelected={(v) => formik.setFieldValue('subjects', v)}
        />

        <ClassSelector
          fullWidth
          multiple
          size="medium"
          // {...formikProps(formik, 'filters')}
          selected={_.get(formik.values, 'filters')}
          setSelected={(v) => formik.setFieldValue('filters', v)}
          sx={{ mb: 2 }}
        />
        <TextField
          select
          {...formikProps(formik, 'gameType')}
          label={t('type')}
          sx={{ mb: 2, width: { xs: '100%', sm: '50%' }, pr: { xs: 0, sm: 2 } }}
        >
          <MenuItem value={'mc'}>
            <span>{t('game_mc')}</span>
            <span style={{ color: 'gray', paddingLeft: '8px' }}>
              {`( ${t('single_player_mode')} )`}
            </span>
          </MenuItem>
          <MenuItem value={'interactive'}>
            <span>{t('game_interactive')}</span>
            <span style={{ color: 'gray', paddingLeft: '8px' }}>
              {`( ${t('multi_player_mode')} )`}
            </span>
          </MenuItem>
          {/* <MenuItem value={'interactive'}> {t('game_interactive') + ' ' + t('multi_player_mode')} </MenuItem> */}
        </TextField>
        {hasPointConfig && (
          <>
            <TextField
              label={t('point_correct')}
              {...formikProps(formik, 'pointCorrect')}
              sx={{ mb: 2, width: { xs: '100%', sm: '50%' } }}
            />
            <TextField
              label={t('point_incorrect')}
              {...formikProps(formik, 'pointIncorrect')}
              sx={{
                mb: 2,
                width: { xs: '100%', sm: '50%' },
                pr: { xs: 0, sm: 2 }
              }}
            />
            <TextField
              label={t('point_exchange_rate')}
              {...formikProps(formik, 'pointExchangeRate')}
              sx={{ mb: 2, width: { xs: '100%', sm: '50%' } }}
            />
          </>
        )}
      </EditDialog>
    </LayoutContainer>
  );
}
