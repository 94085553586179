

const json2csv = require('json2csv').parse
const _ = require('lodash')
const csv = require('csvtojson')
const moment = require('moment-timezone')
export default {
    readFile: function (callBack, accept = 'image/*') {
        var input = document.createElement('input')
        input.type = 'file'
        input.accept = accept
        input.onchange = function (e) {
            if (e.target.files && e.target.files[0]) {
                var file = e.target.files[0]
                callBack(file)
                e.target.value = null
            }
        }
        input.click()
    },
    readFileAsText: async function (file) {
        return new Promise((resolve, reject) => {
            try {
                const fileReader = new FileReader()
                fileReader.onload = (e) => {
                    resolve(e.target.result)
                }
                fileReader.readAsText(file, 'utf-8')
            } catch (error) {
                reject(error)
            }

        })
    },
    csv2JsonArray: async function (string, fields) {
        const jsonArray = await csv().fromString(string)
        if (fields) {
            const maped = _.map(jsonArray, item => {
                var temp = {}
                _.keys(item).forEach(f => {
                    var fname = _.get(fields, f, f)
                    _.set(temp, fname, item[f])
                })

                return temp
            })
            return maped
        }
        return jsonArray
    },
    exportCsv: function (array, fields, filename) {
        const csv = json2csv(array, { fields: fields })
        var hiddenElement = document.createElement('a')
        hiddenElement.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csv)
        hiddenElement.target = '_blank'
        hiddenElement.download = `${filename}_${moment().format('YYYYMMDD-HHmmss')}.csv`
        hiddenElement.click()
    },
    searchFilter: (rows, search, searchFields) => {
        var fieldMatched = (row, str, field) => {
            var text = ''
            if (_.isString(field)) {
                text = _.get(row, field)
            }
            else if (_.isFunction(field)) {
                text = field(row)
            }
            return new RegExp(_.toString(str).replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1"), 'i').test(_.toString(text))
        }

        return _.filter(rows, row => {
            if (_.isArray(search)) {
                return _.every(searchFields, (searchField, index) => {
                    var arr = _.split(_.get(search, index, ''), ' ')
                    if (arr.length == 0 || search == '') return true
                    return _.every(arr, s => {
                        return _.some(searchField, f => fieldMatched(row, s, f))
                    })
                })
            }
            else if (_.isString(search)) {
                var arr = _.split(search, ' ')
                if (arr.length == 0 || search == '') return true
                return _.every(arr, s => {
                    return _.some(searchFields, f => fieldMatched(row, s, f))
                })
            }
            else return true
        })
    },
    /**
     * 
     * @param {*} from DD-MM-YYYY
     * @param {*} to DD-MM-YYYY
     * @param {*} interval number
     * @returns 
     */
    datesArray: (from, to, interval) => {
        let ret = [];
        const fromDate = moment(from, 'DD-MM-YYYY');
        const toDate = moment(to, 'DD-MM-YYYY');
        let date = fromDate.add(interval, 'days');
        while (toDate >= date) {
            ret.push(date.format('DD-MM-YYYY'));
            date = moment(date).add(interval, 'days');
        }
        return ret;
    },
    /**
     * 
     * @param {*} from DD-MM-YYYY
     * @param {*} to DD-MM-YYYY
     * @param {*} interval number
     * @returns 
     */
    monthsArray: (from, to, interval) => {
        let ret = [];
        const fromDate = moment(from, 'MM-YYYY');
        const toDate = moment(to, 'MM-YYYY');
        let date = fromDate.add(interval, 'months');
        while (toDate >= date) {
            ret.push(date.format('MM-YYYY'));
            date = moment(date).add(interval, 'months');
        }
        return ret;
    }
}