import { Helmet } from 'react-helmet';
import {
    Box,
    Card,
    Container,
    Typography
} from '@material-ui/core';
import LayoutContainer from 'src/layout/LayoutContainer';
import { NavLink } from 'react-router-dom';
import { useLocale } from 'src/provider/LocaleProvider';
import useApi from 'src/api/api';
import { useEffect } from 'react';
import { useAuthData } from 'src/provider/AuthDataProvider';
import _ from 'lodash';
import Icons from 'src/icons';
import NoRecordItem from 'src/components/NoRecordItem';
export default () => {
    const { t, translate } = useLocale();
    const { organization } = useAuthData();
    const links = _.get(organization, 'external_links');
    return (
        <LayoutContainer title={t('external_links')}>
            {_.size(links) > 0 ? _.map(links, link =>
                <a href={link.url} target='_blank'>
                    <Card sx={{ p: 2, mb: 2 }}>
                        <Typography variant='h4' sx={{ mb: 1, display: 'inline-flex', verticalAlign: 'middle' }}>
                            {translate(link.name)}
                            <Icons.LaunchIcon sx={{ ml: 1 }} />
                        </Typography>
                        <Typography variant='h5' color='textSecondary'>
                            {translate(link.description)}
                        </Typography>
                    </Card>
                </a>
            ) : <NoRecordItem />}
        </LayoutContainer>
    );
};