import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';
import useApi from 'src/api/api';
import DataTableNoLimit from 'src/components/DataTable/DataTableNoLimit';
import LoadingView from 'src/components/LoadingView';
import Icons from 'src/icons';
import LayoutContainer from 'src/layout/LayoutContainer';
import { useAuthData } from 'src/provider/AuthDataProvider';
import { useBackDrop } from 'src/provider/BackdropProvider';
import { useLocale } from 'src/provider/LocaleProvider';
const moment = require('moment-timezone');
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF80FF'];
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  row: {
    // height: '10px',
    // paddingBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(1)
    // marginTop: theme.spacing(1)
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },

  cardTitle: {
    fontWeight: 700
  },
  tableCell: {
    '& .MuiTableCell-root': {
      textAlign: 'center',
      borderLeft: 'none !important'
    },
    '& .MuiTableRow-root': {
      height: '60px'
    }
  }
}));

const BirthdayBanner = memo(({ date_of_birth, organization }) => {
  const { t, translate, replaceParams } = useLocale();

  if (!date_of_birth) {
    return null;
  }

  const thisYearBirthDay = moment(date_of_birth).set(
    'year',
    new Date().getFullYear()
  );
  const dayDiff = moment(thisYearBirthDay).diff(
    moment().startOf('day'),
    'days'
  );

  const day_show_before = !_.isNil(organization.day_before_birthday)
    ? organization.day_before_birthday
    : 7;
  const day_show_after = organization.day_after_birthday || 0;

  if (dayDiff < 0 && dayDiff > -day_show_after)
    return (
      <Alert
        variant="outlined"
        icon={<Icons.CakeIcon />}
        sx={{ mb: 2, fontSize: 24 }}
      >
        <Typography variant="h4">
          {organization.birthday_message && organization.birthday_message != ''
            ? replaceParams(translate(organization.birthday_message), {
                day: -dayDiff
              })
            : t('congrat_birthday')}
        </Typography>
      </Alert>
    );

  if (dayDiff === 0) {
    return (
      <Alert
        variant="outlined"
        icon={<Icons.CakeIcon />}
        sx={{ mb: 2, fontSize: 24 }}
      >
        <Typography variant="h4">
          {organization.birthday_message && organization.birthday_message != ''
            ? replaceParams(translate(organization.birthday_message), {
                day: -dayDiff
              })
            : t('congrat_birthday')}
        </Typography>
      </Alert>
    );
  }
  if (dayDiff > 0 && dayDiff <= day_show_before) {
    return (
      <Alert variant="outlined" icon={<Icons.CakeIcon />} sx={{ mb: 2 }}>
        <Typography variant="h4">
          {organization.near_birthday_message &&
          organization.near_birthday_message != ''
            ? replaceParams(translate(organization.near_birthday_message), {
                day: dayDiff
              })
            : t('congrat_near_birthday', { day: dayDiff })}
        </Typography>
      </Alert>
    );
  }

  return null;
});

const Overview = () => {
  const classes = useStyles();

  const api = useApi();
  const nav = useNavigate();
  const { t, getLanguage } = useLocale();
  const { userInfo, selectedPeriod, organization } = useAuthData();

  const [pointHistory, setPointHistory] = useState([]);
  const [pointRecord, setPointRecord] = useState({ current: 0, total: 0 });
  const [attendanceHistory, setAttendanceHistory] = useState([]);
  const [currentPeriodInfo, setCurrentPeriodInfo] = useState({
    name: '',
    start: '',
    end: ''
  });
  const [submittedHomeworkRecord, setSubmittedHomeworkRecord] = useState([]);
  const [unFinishedHomeworkRecord, setUnfinishedHomeworkRecord] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(async () => {
    setIsLoading(true);
    if (selectedPeriod) {
      api
        .getUserProfile()
        .then(({ data }) => {
          let point = _.find(data, (k) => selectedPeriod === k.period_id);
          setPointRecord({
            ...point,
            current: point.point,
            total: point.cumulative_point
          });
        })
        .catch((e) => console.log(e));
      api
        .getUserPointHistories(selectedPeriod, userInfo._id)
        .then(({ data }) => {
          setPointHistory(_.orderBy(data, ['created_at'], ['desc']));
        })
        .catch((e) => console.log(e));
      api
        .getUserAttendanceList(selectedPeriod, userInfo._id)
        .then(({ data }) => {
          setAttendanceHistory(_.orderBy(data, ['created_at'], ['desc']));
        })
        .catch((e) => console.log(e));
      api.getCurrentPeriod(selectedPeriod).then((periodRes) => {
        if (periodRes)
          setCurrentPeriodInfo({
            ...currentPeriodInfo,
            name: periodRes.name,
            start: periodRes.start,
            end: periodRes.end
          });
      });
      api
        .getStudentSubmittedHomeworkRecord(userInfo._id)
        .then((res) => {
          setSubmittedHomeworkRecord(res.data);
        })

        .catch((e) => console.log(e));

      api
        .getStudentUnfinishedHomeworkRecord(userInfo._id)
        .then((res) => {
          setUnfinishedHomeworkRecord(res.data);
          setIsLoading(false);
        })
        .catch((e) => console.log(e));

      //     .catch((e) => console.log(e));
    }
  }, [selectedPeriod]);

  if (isLoading) {
    return <LoadingView></LoadingView>;
  }
  return (
    <LayoutContainer title={t('overview_title')}>
      <BirthdayBanner
        date_of_birth={userInfo.date_of_birth}
        organization={organization}
      />
      <div className={classes.row}>
        {currentPeriodInfo.start && currentPeriodInfo.end && (
          <Typography>{`${currentPeriodInfo.start} ${t('_to_')} ${
            currentPeriodInfo.end
          }`}</Typography>
        )}
      </div>
      <Grid container spacing={4}>
        <>
          {organization?.banner && (
            <Grid item xs={12}>
              <img
                src={organization?.banner}
                className="MuiPaper-elevation1"
                style={{
                  width: '100%',
                  aspectRatio: '6',
                  margin: '24px 0',
                  backgroundColor: 'white',
                  objectFit: 'fill'
                }}
              />
            </Grid>
          )}
        </>
        <Grid item sm={6} xs={12}>
          <Card className={'flex jcc ac h100'}>
            <CardContent>
              <br />
              <Typography
                className={classes.cardTitle}
                color="textSecondary"
                gutterBottom
                variant="h3"
              >
                {t('point')}
              </Typography>
              <br />
              <Typography variant="h3" align="center">
                {pointRecord.current}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Card className={'flex jcc ac h100'}>
            <CardContent>
              <br />
              <Typography
                className={classes.cardTitle}
                color="textSecondary"
                gutterBottom
                variant="h3"
              >
                {t('cumulative_point')}
              </Typography>
              <br />
              <Typography variant="h3" align="center">
                {pointRecord.total}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ mb: 2 }}>
            <Box p={2} mb={2} textAlign="center">
              <Typography variant="h3">{t('student_point_ratio')}</Typography>
            </Box>
            <Box p={2} pt={0} sx={{ height: '500px', overflow: 'auto' }}>
              <ResponsiveContainer width="100%" height="100%" minWidth="800px">
                <PieChart>
                  <Legend />
                  <Pie
                    data={_.entries(pointRecord?.points)}
                    outerRadius="70%"
                    dataKey="1"
                    nameKey={(obj) => t(obj[0] + '_point')}
                    cx="50%"
                    cy="50%"
                    fill="#8884d8"
                    isAnimationActive={false}
                    labelLine={false}
                    local
                    label={(e) => {
                      if (e.percent > 0) {
                        return `${e.name}: ${e.value} (${_.round(
                          e.percent * 100,
                          1
                        )}%)`;
                      }
                      return ' ';
                    }}
                  >
                    {_.map(_.entries(pointRecord?.points), (entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card className={'mb8'}>
            <CardHeader
              className={classes.actions}
              title={t('student_information')}
            />
            <CardContent>
              <DataTableNoLimit
                className={classes.tableCell}
                rows={[{ ...userInfo }]}
                heads={[
                  { id: 'name.zh', label: t('name_zh'), value: 'name.zh' },
                  { id: 'name.en', label: t('name_en'), value: 'name.en' },
                  { id: 'username', label: t('u_id'), value: 'u_id' }
                ]}
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card className="mb8">
            <CardHeader
              className={classes.actions}
              action={
                <Button
                  color="primary"
                  size="medium"
                  variant="text"
                  onClick={() => nav('/user/overview/point_record')}
                >
                  {t('see_all')} <Icons.ArrowRightIcon />
                </Button>
              }
              title={t('point_history')}
            />
            <Divider />
            <CardContent>
              <DataTableNoLimit
                className={classes.tableCell}
                rows={_.slice(pointHistory, 0, 3)}
                heads={[
                  {
                    label: t('date'),
                    value: 'created_at',
                    content: (row) =>
                      moment(row.created_at).format('YYYY-MM-DD HH:MM'),
                    width: '33.3%'
                  },
                  { label: t('pointrule_name'), value: 'pointRule.name' },
                  { label: t('point'), value: 'point' }
                ]}
              />
            </CardContent>
          </Card>
          <div align="right" className={'font12'}>
            Last Updated at {moment().format('YYYY-MM-DD HH:mm:ss')}
          </div>
        </Grid>

        <Grid item xs={12}>
          <Card className="mb8">
            <CardHeader
              className={classes.actions}
              action={
                <Button
                  color="primary"
                  size="medium"
                  variant="text"
                  onClick={() => nav('/user/overview/attendance_record')}
                >
                  {t('see_all')} <Icons.ArrowRightIcon />
                </Button>
              }
              title={t('attendance_history')}
            />
            <Divider />
            <CardContent>
              <DataTableNoLimit
                className={classes.tableCell}
                rows={_.slice(attendanceHistory, 0, 3)}
                heads={[
                  {
                    label: t('date'),
                    value: 'created_at',
                    content: (row) =>
                      moment(row.created_at).format('YYYY-MM-DD HH:MM'),
                    width: '33.3%'
                  },
                  {
                    label: t('attendancerule_name'),
                    value: 'attendanceRule.name'
                  },
                  { label: t('type'), value: 'type' }
                ]}
              />
            </CardContent>
          </Card>
          <div align="right" className={'font12'}>
            Last Updated at {moment().format('YYYY-MM-DD HH:mm:ss')}
          </div>
        </Grid>
        <Grid item xs={12}>
          <Card className="mb8">
            <CardHeader
              className={classes.actions}
              action={
                <Button
                  color="primary"
                  size="medium"
                  variant="text"
                  onClick={() =>
                    nav('/user/overview/submitted_homework_record')
                  }
                >
                  {t('see_all')} <Icons.ArrowRightIcon />
                </Button>
              }
              title={t('submitted_homework_record')}
            />
            <Divider />
            <CardContent>
              <DataTableNoLimit
                className={classes.tableCell}
                rows={_.slice(submittedHomeworkRecord?.records, 0, 3)}
                heads={[
                  {
                    label: t('date'),
                    value: 'date',
                    content: (row) => moment(row.date).format('YYYY-MM-DD'),
                    width: '33.3%'
                  },
                  {
                    label: t('status'),
                    value: 'status',
                    translate: 1,
                    width: '33.3%'
                  },
                  {
                    label: t('action_created_by'),
                    content: (row) =>
                      _.get(row, `created_by.name.${getLanguage()}`),
                    width: '33.3%'
                  }
                ]}
              />
            </CardContent>
          </Card>
          <div align="right" className={'font12'}>
            Last Updated at {moment().format('YYYY-MM-DD HH:mm:ss')}
          </div>
        </Grid>
        <Grid item xs={12}>
          <Card className="mb8">
            <CardHeader
              className={classes.actions}
              action={
                <Button
                  color="primary"
                  size="medium"
                  variant="text"
                  onClick={() =>
                    nav('/user/overview/unfinished_homework_record')
                  }
                >
                  {t('see_all')} <Icons.ArrowRightIcon />
                </Button>
              }
              title={t('unfinished_homework_record')}
            />
            <Divider />
            <CardContent>
              <DataTableNoLimit
                className={classes.tableCell}
                rows={_.slice(unFinishedHomeworkRecord?.records, 0, 3)}
                heads={[
                  {
                    label: t('date'),
                    content: (row) => moment(row.date).format('YYYY-MM-DD'),
                    width: '20%',
                    sort: true
                  },
                  {
                    label: t('subject'),
                    translate: 1,
                    content: (row) =>
                      _.get(row, `homework_type.subject.name.${getLanguage()}`),
                    width: '20%'
                  },
                  {
                    label: t('code'),
                    translate: 1,
                    content: (row) => _.get(row, `homework_type.name`),
                    width: '20%'
                  },
                  {
                    label: t('remark'),
                    translate: 1,
                    content: (row) => _.get(row, `remarks`),
                    width: '20%'
                  },
                  {
                    label: t('action_created_by'),
                    content: (row) =>
                      _.get(row, `created_by.name.${getLanguage()}`),
                    width: '20%'
                  }
                ]}
              />
            </CardContent>
          </Card>
          <div align="right" className={'font12'}>
            Last Updated at {moment().format('YYYY-MM-DD HH:mm:ss')}
          </div>
        </Grid>
      </Grid>
    </LayoutContainer>
  );
};
export default Overview;
