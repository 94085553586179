import { Box, Button, Container, IconButton, InputAdornment, MenuItem, Select, Switch, TextField, Tooltip } from '@material-ui/core';
import React, { Fragment, useMemo, useCallback, useEffect, useState, useRef, useLayoutEffect } from 'react'
import useApi from 'src/api/api';
import { Helmet } from 'react-helmet';
import DataTable from 'src/components/DataTable/DataTable';
import { useLocale } from 'src/provider/LocaleProvider';
import { useAuthData } from 'src/provider/AuthDataProvider';
import Icons from 'src/icons';
import EditDialog from 'src/components/EditDialog';
import { Formik, useFormik } from 'formik';
import formikProps from 'src/utils/formikProps';
import utils from 'src/utils/utils';
import SearchInput from 'src/components/SearchInput';
import _ from 'lodash';
import { UsbTwoTone } from '@material-ui/icons';
import { useSnack } from 'src/provider/SnackbarProvider';
import LayoutContainer from 'src/layout/LayoutContainer';
import LoadingView from 'src/components/LoadingView';
const moment = require('moment-timezone')

const yup = require('yup')

const AttendanceRules = () => {
    const api = useApi()
    const { t, getLanguage } = useLocale()

    const rowsRef = useRef([])
    const snack = useSnack()

    const [dialogMode, setDialogMode] = useState(null);
    const [selectedRow, setSelectedRow] = useState();

    const [search, setSearch] = useState('');
    const [rules, setRules] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getRules()
    }, []);

    
    function getRules() {
        api.getAttendanceRules().then(res => setRules(res.data))
        .then(() => setIsLoading(false))
       
    }

    const schema = yup.object().shape({
        name: yup.string().required(),
        description: yup.string(),
        isActive: yup.bool()
    })

    const formik = useFormik({
        initialValues: selectedRow || {},
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: (values, helper) => {
            if (values._id) {
                api.updateAttendanceRules(values._id, values).then(res => {
                    setDialogMode(null)
                    getRules()
                    snack.open()
                }).catch(e => {
                    snack.error()
                }).finally(() => {
                    helper.setSubmitting(false)
                })
            }
            else {
                api.addAttendanceRules(values).then(res => {
                    setDialogMode(null)
                    getRules()
                    snack.open()
                }).catch(e => {
                    snack.error()
                }).finally(() => {
                    helper.setSubmitting(false)
                })
            }
        }
    })

    useEffect(() => {
        if (dialogMode) formik.resetForm()
    }, [dialogMode]);

    const handleCreate = () => {
        setSelectedRow(null)
        setDialogMode('add')
    }

    const handleEdit = (row) => {
        setSelectedRow(row)
        setDialogMode('edit')
    }


    const filtered = useCallback(utils.searchFilter(rules, search, ['name', `ruleCategory.name.${getLanguage()}`]), [rules, search])

    if (isLoading) {
        return <LoadingView></LoadingView>;
    }
    return (
        <LayoutContainer title={t('attendanceRules_title')}>
            <Box className='flex ac' mb={2}>
                <SearchInput setSearch={setSearch} />
                <div className="spacer" />
                <Button sx={{ ml: 1 }} onClick={handleCreate}>
                    {t('add_new_attendanceRules_title')}
                </Button>
            </Box>
            <DataTable rowsRef={rowsRef} rows={filtered} heads={[
                { id: 'name', label: t('pointrule_name'), value: 'name', sort: true },
                { id: 'description', label: t('description'), value: 'description', sort: true },
                { id: 'status', label: t('status'), value: `isActive`, content: r => r.isActive ? t('active') : t('inactive'), className: (row) => row.isActive ? 'text-green' : 'text-red', sort: true },
                {
                    id: 'action', label: t('actions'), content: (row) => {
                        return <Box sx={{ mx: -1 }}>
                            <Tooltip title={t('edit')}>
                                <IconButton onClick={() => handleEdit(row)} sx={{ p: .5 }}> <Icons.EditIcon /> </IconButton>
                            </Tooltip>
                        </Box>
                    }
                },
            ]} _orderBy='point' _order='asc'
            />
            {/* Dialogs */}
            <EditDialog title={dialogMode == 'edit' ? t('edit_attendanceRules_title') : t('add_new_attendanceRules_title')} handleSave={formik.handleSubmit} open={dialogMode == 'add' || dialogMode == 'edit'} handleClose={() => setDialogMode(null)}>
                <TextField
                    fullWidth
                    label={t('name')}
                    {...formikProps(formik, 'name')}
                    sx={{ mb: 2 }}
                />
                <TextField
                    fullWidth
                    label={t('description')}
                    {...formikProps(formik, 'description')}
                    sx={{ mb: 2 }}
                />
                <TextField
                    select
                    fullWidth
                    label={t('status')}
                    {...formikProps(formik, 'isActive')}
                >
                    <MenuItem key={'active'} value={true}> {t('active')} </MenuItem>
                    <MenuItem key={'inactive'} value={false}> {t('inactive')} </MenuItem>
                </TextField>
            </EditDialog>
        </LayoutContainer>
    );
}

export default AttendanceRules;