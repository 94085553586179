import { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
    Avatar,
    Box,
    Card,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useLocale } from 'src/provider/LocaleProvider'
import classNames from 'classnames'
const _ = require('lodash')

const useStyles = makeStyles({
    table: {
        "& .MuiTableRow-root": {
            height: '50px'
        },
        "& .MuiTableCell-root": {
            whiteSpace: 'nowrap',
            textAlign: 'center'
            // borderLeft: "1px solid rgba(224, 224, 224, 1)",
        },
        "& .MuiTableSortLabel-icon": {
            opacity: 0.5,
        },
        "& .Mui-active .MuiTableSortLabel-icon": {
            opacity: 1,
            color: 'limegreen',
        },
        "& .MuiCheckbox-root": {
            padding: '4px'
        }
    },
})



const DataTable = ({
    rows = [], row_id = '_id', heads = [], _orderBy = 'created_at', _order = 'desc', placeHolder = '-', className = '', loading = false,
    rowClassName = new Function(),
    rowsPerPage = 10,
    rowsPerPageOptions = [5, 10, 25],
    rowsRef,
    rowOnClick = new Function(),
    checked = [],
    setChecked = new Function(),
    ...rest
}) => {
    const classes = useStyles()

    const [page, setPage] = useState(0)

    const [order, setOrder] = useState(_order)
    const [orderBy, setOrderBy] = useState(_orderBy)
    const [limit, setLimit] = useState(rowsPerPage)

    const sorted = useMemo(() => _.orderBy(rows, [orderBy], [order]), [rows, orderBy, order])
    const rowLength = _.size(sorted)
    const chunks = _.chunk(sorted, limit)
    const emptyRows = limit - Math.min(limit, rowLength - page * limit)
    const headCells = heads
    const bodyCells = _.size(chunks) > 0 && chunks[page] ? chunks[page] : []
    // const [checked, setChecked] = useState([])

    const handleLimitChange = (event) => {
        setLimit(event.target.value)
    }

    useEffect(() => {
        if (rows && page * limit > rows.length) {
            setPage(0)
        }
    }, [rows?.length, limit]);

    const handlePageChange = (event, newPage) => {
        setPage(newPage)
    }

    const handleSort = (cell) => {
        if (orderBy == cell.value) {
            setOrder(order === 'asc' ? 'desc' : 'asc')
        }
        else {
            setOrderBy(cell.value)
        }
    }

    useEffect(() => {
        if (rowsRef && rowsRef.current) {
            rowsRef.current = sorted
        }
    }, [sorted]);



    /* --------------checkbox------------------------ */
    const handleCheckAll = () => {
        var newChecked = _.toArray(checked)
        if (_.size(checked) <= 0) {
            newChecked = bodyCells
        }
        else if (_.size(checked) >= _.size(bodyCells)) {
            newChecked = []
        }
        else {
            newChecked = []
        }
        setChecked(newChecked)
    }

    const handleCheck = (row) => {
        var newChecked = _.toArray(checked)
        if (isChecked(row._id)) {
            _.pull(newChecked, row)
        }
        else {
            newChecked.push(row)
        }

        setChecked(newChecked)
    }

    const isChecked = (id) => _.findIndex(checked, { _id: id }) >= 0
    /* --------------------------------------------- */


    const { t } = useLocale()

    return (

        <Card {...rest}>
            <PerfectScrollbar>
                <Box >
                    {/* {'https://discord.gg/SCGddHEM'} */}
                    <Table size='small' className={classNames(classes.table, className)}>
                        <TableHead>
                            <TableRow >
                                {
                                    _.map(headCells, (cell, index) => {
                                        if (cell.type === 'checkbox')
                                            return (
                                                <TableCell key={cell.label + index} sx={{ ...cell.sx, width: '30px', }}>
                                                    <Checkbox
                                                        disabled={_.size(bodyCells) === 0}
                                                        indeterminate={_.size(checked) > 0 && _.size(checked) < _.size(bodyCells) && _.size(bodyCells) > 0}
                                                        checked={_.size(checked) == _.size(bodyCells) && _.size(bodyCells) > 0}
                                                        onClick={handleCheckAll}
                                                    />
                                                </TableCell>
                                            )
                                        return (
                                            <TableCell key={cell.label + index} sx={cell.sx}>
                                                {cell.sort === true
                                                    ? <TableSortLabel
                                                        active={orderBy === cell.value}
                                                        direction={orderBy === cell.value ? order : 'asc'}
                                                        onClick={(event) => handleSort(cell)}
                                                        hideSortIcon={false}
                                                    >
                                                        {cell.label}
                                                    </TableSortLabel>
                                                    : _.get(cell, 'label')
                                                }
                                            </TableCell>)
                                    })
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_.map(bodyCells, (bodyCell, index) => (
                                <TableRow
                                    hover
                                    key={index}
                                    className={rowClassName(bodyCell, page * limit + index)}
                                    onClick={() => {
                                        rowOnClick(bodyCell)
                                    }}
                                >
                                    {_.map(headCells, cell => {
                                        var render = ''
                                        if (cell.content) {
                                            render = cell.content(bodyCell, page * limit + index)
                                        }
                                        else {
                                            render = _.get(bodyCell, cell.value)
                                        }
                                        if (cell.translate) {
                                            render = t(_.toString(render))
                                        }
                                        if (typeof render != 'number' && (_.isNil(render) || render == '')) {
                                            render = cell.placeHolder || placeHolder
                                        }
                                        if (cell.type === 'checkbox')
                                            render =
                                                <Checkbox
                                                    checked={isChecked(bodyCell._id)}
                                                    onClick={(e) => {
                                                        if (!e) var e = window.event;
                                                        e.cancelBubble = true;
                                                        if (e.stopPropagation) e.stopPropagation();
                                                        handleCheck(bodyCell)
                                                    }}
                                                />
                                        return (
                                            <TableCell className={cell.className && cell.className(bodyCell, page * limit + index)} key={cell.label + index} width={cell.width} sx={cell.sx}>
                                                {render}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: emptyRows * 50 }}>
                                    <TableCell colSpan={99}>
                                        {rowLength === 0 && !loading && <Typography variant='h5'>{t('no_records')}</Typography>}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Box>
            </PerfectScrollbar>
            <TablePagination
                component="div"
                count={rowLength}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={rowsPerPageOptions}
                labelRowsPerPage={t('rows_per_page')}
            // labelDisplayedRows={({ from, to, count }) => t('label_of', { a: from, b: to, c: count })}
            />
        </Card >
    )
}


export default DataTable
