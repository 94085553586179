import { StyledEngineProvider, ThemeProvider } from '@material-ui/core';
import { useEffect } from 'react';
import ReactGA from 'react-ga';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useLocation, useRoutes } from 'react-router-dom';
import 'src/theme/index.css';
import './assets/css/utils.css';
import BackdropProvider from './provider/BackdropProvider';
import LocaleProvider from './provider/LocaleProvider';
import SnackbarProvider from './provider/SnackbarProvider';
import routes from './routes';
import theme from './theme';
import GlobalStyles from './theme/GlobalStyles';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false // default: true
    }
  }
});

const App = () => {
  const content = useRoutes(routes);
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize('UA-207649151-1');
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    }
  }, [location.pathname]);

  return (
    <QueryClientProvider client={queryClient}>
      <LocaleProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <GlobalStyles />
            <BackdropProvider>
              <SnackbarProvider>{content}</SnackbarProvider>
            </BackdropProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </LocaleProvider>
    </QueryClientProvider>
  );
};

export default App;
