import { Box, CircularProgress } from '@material-ui/core';

const LoadingView = () => {
  return (
    
    <Box display="flex" height="100%">
      <Box m="auto"><CircularProgress></CircularProgress></Box>
    </Box>
    
  );
};

export default LoadingView;
