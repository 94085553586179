import { Box, Button, Container, IconButton, InputAdornment, MenuItem, Select, Switch, TextField, Tooltip } from '@material-ui/core';
import React, { Fragment, useMemo, useCallback, useEffect, useState, useRef, useLayoutEffect } from 'react'
import useApi from 'src/api/api';
import { Helmet } from 'react-helmet';
import DataTable from 'src/components/DataTable/DataTable';
import { useLocale } from 'src/provider/LocaleProvider';
import { useAuthData } from 'src/provider/AuthDataProvider';
import Icons from 'src/icons';
import EditDialog from 'src/components/EditDialog';
import { Formik, useFormik } from 'formik';
import formikProps from 'src/utils/formikProps';
import utils from 'src/utils/utils';
import SearchInput from 'src/components/SearchInput';
import _ from 'lodash';
import { UsbTwoTone } from '@material-ui/icons';
import { useSnack } from 'src/provider/SnackbarProvider';
import LayoutContainer from 'src/layout/LayoutContainer';
import ImageUploader from 'src/components/Formik/ImageUploader';
import LoadingView from 'src/components/LoadingView';
const moment = require('moment-timezone')

const yup = require('yup')

const BadgeRule = () => {
    const api = useApi()
    const { t, getLanguage, translate } = useLocale()

    const rowsRef = useRef([])
    const snack = useSnack()

    const [dialogMode, setDialogMode] = useState(null);
    const [selectedRow, setSelectedRow] = useState();

    const [search, setSearch] = useState('');
    const [rules, setRules] = useState([]);
    const [pointRules, setPointRules] = useState([]);
    const [ruleCategories, setRuleCategories] = useState([]);
    const [attendanceRules, setattendanceRules] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        getRules()
        api.getPointRules({ isReward: true }).then(res => setPointRules(res.data))
        api.getPointRuleCategories().then(res => setRuleCategories(res.data))
        api.getAttendanceRules().then(res => setattendanceRules(res.data))
        .then(() => setIsLoading(false))
    }, []);

    function getRules() {
        api.getBadgeRuleList().then(res => setRules(res.data))
    }

    const schema = yup.object().shape({
        name: yup.string().required(),
        reward_by: yup.string().required().oneOf(['pointRule', 'ruleCategory', 'attendanceRule', 'all_reward']),
        description: yup.string(),
        attendanceRule: yup.string().nullable().when('reward_by', {
            is: v => v == 'attendanceRule',
            then: yup.string().required()
        }),
        pointRule: yup.string().nullable().when('reward_by', {
            is: v => v == 'pointRule',
            then: yup.string().required()
        }),
        ruleCategory: yup.string().nullable().when('reward_by', {
            is: v => v == 'ruleCategory',
            then: yup.string().required()
        }),
        threshold_by: yup.string().required().oneOf(['count', 'point']),
        threshold: yup.number().min(1).required(),
        cutoff_period: yup.string().required().oneOf(['no_limit', 'weekly', 'monthly', 'periodly']),
        cutoff_limit: yup.number().min(1).nullable().when('cutoff_period', {
            is: v => v !== 'no_limit',
            then: yup.number().required()
        }),
        image_url: yup.mixed().required()
    })

    const formik = useFormik({
        initialValues: {
            name: '',
            description: '',
            reward_by: 'all_reward',
            attendanceRule: undefined,
            pointRule: undefined,
            ruleCategory: undefined,
            threshold_by: 'point',
            threshold: undefined,
            cutoff_period: 'no_limit',
            cutoff_limit: undefined,
            isActive: true,
            ...selectedRow
        },
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: (values, helper) => {
            if (values._id) {
                api.updateBadgeRule(values._id, values).then(res => {
                    setDialogMode(null)
                    getRules()
                    snack.open()
                }).catch(e => {
                    snack.error()
                }).finally(() => {
                    helper.setSubmitting(false)
                })
            }
            else {
                api.addBadgeRule(values).then(res => {
                    setDialogMode(null)
                    getRules()
                    snack.open()
                }).catch(e => {
                    snack.error()
                }).finally(() => {
                    helper.setSubmitting(false)
                })
            }
        }
    })

    useEffect(() => {
        if (dialogMode) formik.resetForm()
    }, [dialogMode]);

    const handleCreate = () => {
        setSelectedRow(null)
        setDialogMode('add')
    }

    const handleEdit = (row) => {
        setSelectedRow({ ...row, pointRule: row.pointRule?._id, ruleCategory: row.ruleCategory?._id })
        setDialogMode('edit')
    }

    const handleDelete = (row) => {
        setSelectedRow({ ...row, pointRule: row.pointRule?._id, ruleCategory: row.ruleCategory?._id })
        setDialogMode('delete')
    }

    const handleConfirmDelete = () => {
        api.deleteBadgeRule(selectedRow._id).then(res => {
            api.getBadgeRuleList().then(res => {
                setRules(res.data)
                setDialogMode(null)
            })
        }).catch(e => {
            console.log('api.deleteBadgeRule => e', e)

        })
    }

    const filtered = useCallback(utils.searchFilter(rules, search, ['name']), [rules, search])

    if (isLoading) {
        return <LoadingView></LoadingView>;
    }
    return (
        <LayoutContainer title={t('badge_rule')}>
            <Box className='flex ac' mb={2}>
                <SearchInput setSearch={setSearch} />
                <div className="spacer" />
                <Button sx={{ ml: 1 }} onClick={handleCreate}>
                    {t('add_badge_rule')}
                </Button>
            </Box>
            <DataTable rowsRef={rowsRef} rows={filtered} heads={[
                {
                    label: t('badge_image'), value: 'image_url', content: (row) => {
                        return <img src={row.image_url} style={{ height: '48px' }} />
                    }
                },
                { label: t('name'), value: 'name', sort: true },
                { label: t('badge_reward_by'), value: 'reward_by', translate: true, sort: true },
                { label: t('badge_threshold_by'), value: 'threshold_by', translate: true, sort: true },
                { label: t('badge_threshold'), value: 'threshold', sort: true },
                { label: t('badge_cutoff_period'), value: 'cutoff_period', translate: true, sort: true },
                { label: t('badge_cutoff_limit'), value: 'cutoff_limit', sort: true },
                { label: t('status'), content: (row) => row.isActive ? t('active') : t('inactive'), className: (row) => row.isActive ? 'text-green' : 'text-red' },
                {
                    label: t('actions'), content: (row) => {
                        return <Box sx={{ mx: -1 }}>
                            <Tooltip title={t('edit')}>
                                <IconButton onClick={() => handleEdit(row)} sx={{ p: .5 }}> <Icons.EditIcon /> </IconButton>
                            </Tooltip>
                        </Box>
                    }
                },
            ]} _orderBy='point' _order='asc'
            />
            {/* Dialogs */}
            <EditDialog
                title={dialogMode == 'edit' ? t('edit_badge_rule') : t('add_badge_rule')}
                handleSave={formik.handleSubmit}
                open={dialogMode == 'add' || dialogMode == 'edit'}
                handleClose={() => setDialogMode(null)}
                TitleComponent={() => (
                    <>
                        {selectedRow && <Tooltip title={t('delete')}>
                            <IconButton onClick={() => setDialogMode('delete')}  >
                                <Icons.DeleteForeverIcon />
                            </IconButton>
                        </Tooltip>}
                    </>
                )}
            >
                <ImageUploader
                    formik={formik}
                    label={t('badge_image')}
                    name={`image_url`}
                    helperText={`< 1MB | PNG ${t('or')} JPEG`}
                    sx={{ mb: 2 }}
                />
                <TextField
                    fullWidth
                    label={t('name')}
                    {...formikProps(formik, 'name')}
                    sx={{ mb: 2 }}
                />
                <TextField
                    fullWidth
                    label={t('description')}
                    {...formikProps(formik, 'description')}
                    sx={{ mb: 2 }}
                />
                <TextField
                    select
                    disabled={dialogMode !== 'add'}
                    fullWidth
                    label={t('badge_reward_by')}
                    {...formikProps(formik, 'reward_by')}
                    sx={{ mb: 2 }}
                >
                    <MenuItem key={'pointRule'} value={'pointRule'}> {t('pointRule')} </MenuItem>
                    <MenuItem key={'ruleCategory'} value={'ruleCategory'}> {t('ruleCategory')} </MenuItem>
                    <MenuItem key={'attendanceRule'} value={'attendanceRule'}> {t('attendanceRule')} </MenuItem>
                    <MenuItem key={'all_reward'} value={'all_reward'}> {t('all_reward')} </MenuItem>
                </TextField>
                {formik.values.reward_by == 'pointRule' &&
                    <TextField
                        disabled={dialogMode !== 'add'}
                        select
                        fullWidth
                        label={t('pointRule')}
                        {...formikProps(formik, 'pointRule')}
                        sx={{
                            mb: 2, '& .MuiSelect-select': {
                                display: 'flex !important'
                            }
                        }}
                    >
                        {_.map(pointRules, (r, index) => <MenuItem key={index} value={r._id} sx={{ display: 'flex' }}>
                            <span style={{ flexGrow: 1 }}>{r.name}</span>
                            <span>{t(r.type)}</span>
                        </MenuItem>)}
                    </TextField>
                }
                {formik.values.reward_by == 'attendanceRule' &&
                    <TextField
                        disabled={dialogMode !== 'add'}
                        select
                        fullWidth
                        label={t('attendanceRule')}
                        {...formikProps(formik, 'attendanceRule')}
                        sx={{
                            mb: 2, '& .MuiSelect-select': {
                                display: 'flex !important'
                            }
                        }}
                    >
                        {_.map(attendanceRules, (r, index) => <MenuItem key={index} value={r._id} sx={{ display: 'flex' }}>
                            <span style={{ flexGrow: 1 }}>{r.name}</span>
                            {/* <span>{t(r.type)}</span> */}
                        </MenuItem>)}
                    </TextField>
                }
                {formik.values.reward_by == 'ruleCategory' &&
                    <TextField
                        select
                        disabled={dialogMode !== 'add'}
                        fullWidth
                        label={t('rule_category')}
                        {...formikProps(formik, 'rule_category ')}
                        sx={{
                            mb: 2, '& .MuiSelect-select': {
                                display: 'flex !important'
                            }
                        }}
                    >
                        {_.map(ruleCategories, (r, index) => <MenuItem key={index} value={r._id} sx={{ display: 'flex' }}>
                            <span style={{ flexGrow: 1 }}>{translate(r.name)}</span>
                            <span>{t(r.type)}</span>
                        </MenuItem>)}
                    </TextField>
                }
                <TextField
                    select
                    fullWidth
                    disabled={dialogMode !== 'add'}
                    label={t('badge_threshold_by')}
                    {...formikProps(formik, 'threshold_by')}
                    sx={{ mb: 2 }}
                >
                    <MenuItem key={'count'} value={'count'}> {t('count')} </MenuItem>
                    <MenuItem key={'point'} value={'point'}> {t('point')} </MenuItem>
                </TextField>
                <TextField
                    fullWidth
                    type='number'
                    disabled={dialogMode !== 'add'}
                    label={t('badge_threshold')}
                    {...formikProps(formik, 'threshold')}
                    sx={{ mb: 2 }}
                />
                <TextField
                    select
                    disabled={dialogMode !== 'add'}
                    fullWidth
                    label={t('badge_cutoff_period')}
                    {...formikProps(formik, 'cutoff_period')}
                    sx={{ mb: 2 }}
                >
                    <MenuItem key={'no_limit'} value={'no_limit'}> {t('no_limit')} </MenuItem>
                    <MenuItem key={'weekly'} value={'weekly'}> {t('weekly')} </MenuItem>
                    <MenuItem key={'monthly'} value={'monthly'}> {t('monthly')} </MenuItem>
                    <MenuItem key={'periodly'} value={'periodly'}> {t('periodly')} </MenuItem>
                </TextField>
                {formik.values.cutoff_period !== 'no_limit' &&
                    <TextField
                        fullWidth
                        disabled={dialogMode !== 'add'}
                        type='number'
                        label={t('badge_cutoff_limit')}
                        sx={{ mb: 2 }}
                        {...formikProps(formik, 'cutoff_limit')}
                    />
                }
                <TextField
                    select
                    fullWidth
                    label={t('status')}
                    {...formikProps(formik, 'isActive')}
                >
                    <MenuItem key={'active'} value={true}> {t('active')} </MenuItem>
                    <MenuItem key={'inactive'} value={false}> {t('inactive')} </MenuItem>
                </TextField>
            </EditDialog>
            <EditDialog
                title={t('delete')}
                handleSave={handleConfirmDelete}
                open={dialogMode == 'delete'}
                handleClose={() => setDialogMode(null)}
                confirmText={t('confirm')}
            >
                <p>
                    {t('delete_confirm_msg', { a: `${selectedRow?.name}` })}
                </p>
            </EditDialog>
        </LayoutContainer>
    );
}

export default BadgeRule;