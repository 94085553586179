
import { Box, Button, TextField, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Tooltip, Typography, MenuItem, Grid, Card, CardContent, Avatar, Paper, List, ListItem, Hidden } from '@material-ui/core';
import { useFormik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import useApi from 'src/api/api';
import DataTable from 'src/components/DataTable/DataTable';
import EditDialog from 'src/components/EditDialog';
import Icons from 'src/icons';
import LayoutContainer from 'src/layout/LayoutContainer';
import { useAuthData } from 'src/provider/AuthDataProvider';
import { useLocale } from 'src/provider/LocaleProvider';
import formikProps from 'src/utils/formikProps';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined'
import { NavLink } from 'react-router-dom';
import ClassSelector from 'src/components/ClassSelector';
import AssignmentIcon from '@material-ui/icons/Assignment'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined'
import ImageUploader from 'src/components/Formik/ImageUploader';
import PerfectScrollbar from 'react-perfect-scrollbar'
const prettyBytes = require('pretty-bytes');
const yup = require('yup')

const QuestionCard = ({ question = {}, handleEdit, index, handleDelete, OptionComponent, hasImage = true }) => {
    const { t, translate } = useLocale()
    return (
        <Card sx={{ mb: 2, display: 'flex', '& img': { height: '100%', width: '100%' } }}>
            <List sx={{
                p: 0,
                width: '100%',
                '& .MuiIconButton-root': {
                    // p: 0.5
                },
                '& .MuiListItem-root': {
                    height: '42px',
                    '&::-webkit-scrollbar': {
                        display: 'none'
                    }
                },
            }}>
                <Hidden smUp>
                    <ListItem divider sx={{ overflowX: 'auto' }} >
                        <Typography sx={{ whiteSpace: 'nowrap' }}>   {t('question')} {index + 1} : {question.text} </Typography>
                    </ListItem>
                </Hidden>
                <Grid container className='w100'>
                    {hasImage &&
                        <Grid item xs={12} sm={2} sx={{
                            borderRight: { xs: null, sm: '1.5px solid black' },
                            borderBottom: { sm: null, xs: '1.5px solid black' },
                            borderColor: { xs: 'grey.main', sm: 'grey.main' },
                            '& .image': { height: { xs: '240px', sm: '100%' } },
                        }}>
                            <Box className='image' >
                                {question.image && <img src={question.image} />}
                            </Box>
                        </Grid>
                    }
                    <Grid item xs >
                        {(handleEdit || handleDelete) &&
                            <ListItem divider>
                                <Box flexGrow={1} />
                                <Tooltip title={t('edit_question')}>
                                    <IconButton color='primary' onClick={() => handleEdit(question)}>
                                        <Icons.EditIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={t('delete_question')}>
                                    <IconButton color='primary' onClick={() => handleDelete(question)}  >
                                        <Icons.DeleteForeverIcon />
                                    </IconButton>
                                </Tooltip>
                            </ListItem>
                        }
                        <Hidden smDown>
                            <ListItem divider sx={{ overflowX: 'auto' }} >
                                <Typography sx={{ whiteSpace: 'nowrap' }}>   {t('question')} {index + 1} : {question.text} </Typography>
                            </ListItem>
                        </Hidden>
                        {_.map(question?.options, (option, index) => {
                            if (OptionComponent) return <OptionComponent option={option} index={index} />
                            return <ListItem key={index} divider style={{ height: '40px' }}>
                                <Typography nowrap sx={{ color: option.isAns ? 'limegreen' : 'red' }}> {t('option')} {index + 1}:</Typography>
                                <Box mr='20px' />
                                <Typography noWrap > {option.text || "Null"}</Typography>
                            </ListItem>
                        })}
                    </Grid>

                </Grid>
            </List>
        </Card >
    );
}

export default QuestionCard;