import { Outlet, useNavigate } from 'react-router';
import { Navigate } from 'react-router-dom';
import DashboardLayout from './layout/Dashboard/DashboardLayout';
import MainLayout from './layout/Main/MainLayout';
// import Account from './pages/Account';
// import CustomerList from './pages/CustomerList';
import Dashboard from './pages/Dashboard/Dashboard';
// import ProductList from './pages/ProductList';
// import Register from './pages/Register';
// import Settings from './pages/Settings';
// import SignIn from './pages/SignIn/SignIn';
import Login from 'src/pages/Login/Login';
import NotFound from 'src/pages/NotFound';
import Settings from 'src/pages/Settings';
import AuthDataProvider, { useAuthData } from './provider/AuthDataProvider';
import OrganizationInfo from './pages/Organization/OrganizationInfo';
import OrganizationPeriodList from './pages/Organization/OrganizationPeriodList';
import OrganizationFilterList from './pages/Organization/OrganizationFilterList';
import StudentList from './pages/Account/StudentList';
import StudentUpload from './pages/Account/StudentUpload';
import AdminList from './pages/Account/AdminList';
import AdminUpload from './pages/Account/AdminUpload';
import ClassRanking from './pages/Dashboard/ClassRanking';
import UserRanking from './pages/Dashboard/UserRanking';
import PointRuleCategories from './pages/Point/PointRuleCategories';
import PointRules from './pages/Point/PointRules';
import { Box, Container, IconButton, Typography } from '@material-ui/core';
import Icons from './icons';
import { Helmet } from 'react-helmet';
import { useLocale } from './provider/LocaleProvider';
import { makeStyles } from '@material-ui/styles';
import { useState } from 'react';
import PeriodSelector from './components/PeriodSelector';
import AttendanceRules from './pages/Attendance/AttendanceRules';
import AttendanceHistory from './pages/Attendance/AttendanceHistory';
import DeviceList from './pages/DeviceList';
import Notice from './pages/Notice/Notice';
import PointRedeem from './pages/Point/PointRedeem';
import PointReward from './pages/Point/PointReward';
import PointHistory from './pages/Point/PointHistory';
import { useSnack } from './provider/SnackbarProvider';
import Overview from './pages/User/Overview';
import Profile from './pages/User/Profile';
import { CheckIn, CheckOut } from './pages/Attendance/TakeAttendance';
import { useBackDrop } from './provider/BackdropProvider';
import NoticeEdit from './pages/Notice/NoticeEdit';
import SetupPassoword from './pages/Login/SetupPassoword';
import NoticeDetail from './pages/User/NoticeDetail';
import Memo from './pages/Memo/Memo';
import MemoPublish from './pages/Memo/MemoPublish';
import MemoDetail from './pages/User/MemoDetail';
// import GameList from './pages/Game/GameList';
// import GameDetail from './pages/Game/GameDetail';
import GameList from './pages/Game/GameList';
import GameDetail from './pages/Game/GameDetail';
import GameEdit from './pages/Game/GameEdit';
import UserGameList from './pages/User/UserGameList';
import GameRecord from './pages/Game/GameRecord';
import StudentRecord from './pages/Account/StudentRecord';
import UAttendanceRecord from './pages/User/AttendanceRecord';
import UPointRecord from './pages/User/PointRecord';
import USubmittedHomeworkRecord from './pages/User/SubmittedHomeworkRecord';
import UUnfinishedHomeworkRecord from './pages/User/UnfinishedHomeworkRecord';
import UNotice from './pages/User/Notice';
import UMemo from './pages/User/Memo';

import StudentAttendanceRecord from './pages/Account/StudentAttendanceRecord';
import StudentPointRecord from './pages/Account/StudentPointRecord';
import ParentPointReward from './pages/Parent/ParentPointReward';
import PointRedeemRules from './pages/Point/PointRedeemRules ';
import BadgeRule from './pages/Badge/BadgeRule';
import BadgeList from './pages/Badge/BadgeList';
import Badge from './pages/User/Badge';
import QrCodeView from './pages/QrCodeView';
import ExternalSite from './pages/ExternalSite';

import BirthdaySetting from './pages/Birthday/BirthdaySetting';

import SubmittedHomework from './pages/Homework/SubmittedHomework';
import UnfinishedHomework from './pages/Homework/UnfinishedHomework';
import SubmittedHomeworkRecord from './pages/Homework/HomeworkRecord/SubmittedHomeworkRecord';
import UnfinishedHomeworkRecord from './pages/Homework/HomeworkRecord/UnfinishedHomeworkRecord';
import HomeworkHistory from './pages/Homework/HomeworkHistory/HomeworkHistory';
import HomeworkType from './pages/Homework/HomeworkType/HomeworkType';
import HomeworkStatistics from './pages/Homework/Statistics/HomeworkStatistics';
import StudentHomeworkStatistics from './pages/Homework/Statistics/StudentHomeworkStatistics';
import HomeworkSetting from './pages/Homework/HomeworkSetting';

import { SubjectSetting } from './pages/Subject/SubjectSetting';
import { LearningResource } from './pages/LearningResource/LearningResource';

// import BoardGameDashboard from './pages/BoardGame/BoardGameDashboard';
// import BoardGameDetail from './pages/BoardGame/BoardGameDetail';
// import BoardGameSessionDetail from './pages/BoardGame/BoardGameSessionDetail';
// import BoardGameProfileDetail from './pages/BoardGame/BoardGameProfileDetail';
// import GameEdit from './pages/Game/GameEdit/components/GameEdit';
const _ = require('lodash');

const RoleChecker = ({ Component = () => null, roles = '', module }) => {
  const auth = useAuthData();
  const snack = useSnack();
  const backDrop = useBackDrop();
  const local = useLocale();
  const nav = useNavigate();
  var arr = roles.split(' ');
  if (
    module &&
    auth?.organization?.modules &&
    auth?.organization.modules[module] == false
  ) {
    return <Navigate to="/not-found" />;
  }
  if (_.includes(arr, 'parent') && !auth?.userInfo?.isParent) {
    return <Navigate to="/not-found" />;
  } else if (_.includes(arr, 'student') && auth?.userInfo?.isParent) {
    return <Navigate to="/not-found" />;
  }
  if (!_.includes(arr, auth?.userInfo?.role)) {
    return <Navigate to="/not-found" />;
  } else
    return (
      <Component
        snack={snack}
        auth={auth}
        local={local}
        nav={nav}
        backDrop={backDrop}
        t={local.t}
      />
    );
};

const routes = [
  {
    path: '/admin',
    element: <DashboardLayout role="admin" />,
    children: [
      //Dashboard
      {
        path: '/dashboard',
        element: (
          <RoleChecker roles="admin organization_admin" Component={Dashboard} />
        )
      },
      {
        path: '/dashboard/user_ranking',
        element: (
          <RoleChecker
            roles="admin organization_admin"
            Component={UserRanking}
          />
        )
      },
      {
        path: '/dashboard/class_ranking',
        element: (
          <RoleChecker
            roles="admin organization_admin"
            Component={ClassRanking}
          />
        )
      },
      //Orgnaization
      {
        path: '/organizationinfo',
        element: (
          <RoleChecker
            roles="organization_admin"
            Component={OrganizationInfo}
          />
        )
      },
      {
        path: '/organizationfilter',
        element: (
          <RoleChecker
            roles="organization_admin"
            Component={OrganizationFilterList}
          />
        )
      },
      {
        path: '/period',
        element: (
          <RoleChecker
            roles="organization_admin"
            Component={OrganizationPeriodList}
          />
        )
      },
      //Accounts
      {
        path: '/student_list',
        element: (
          <RoleChecker
            roles="admin organization_admin"
            Component={StudentList}
          />
        )
      },
      {
        path: '/student_list/:userId',
        element: (
          <RoleChecker
            roles="admin organization_admin"
            Component={StudentRecord}
          />
        )
      },
      {
        path: '/student_list/:userId/point_record',
        element: (
          <RoleChecker
            roles="admin organization_admin"
            Component={StudentPointRecord}
          />
        )
      },
      {
        path: '/student_list/:userId/attendance_record',
        element: (
          <RoleChecker
            roles="admin organization_admin"
            Component={StudentAttendanceRecord}
          />
        )
      },
      {
        path: '/student_list/:userId/unfinished_homework_record',
        element: (
          <RoleChecker
            roles="admin organization_admin"
            Component={UnfinishedHomeworkRecord}
          />
        )
      },
      {
        path: '/student_list/:userId/submitted_homework_record',
        element: (
          <RoleChecker
            roles="admin organization_admin"
            Component={SubmittedHomeworkRecord}
          />
        )
      },
      {
        path: '/student_list/upload',
        element: (
          <RoleChecker
            roles="admin organization_admin"
            Component={StudentUpload}
          />
        )
      },
      {
        path: '/admin_list',
        element: (
          <RoleChecker roles="organization_admin" Component={AdminList} />
        )
      },
      {
        path: '/admin_list/upload',
        element: (
          <RoleChecker roles="organization_admin" Component={AdminUpload} />
        )
      },
      //Points
      {
        path: '/pointrules',
        element: (
          <RoleChecker
            roles="admin organization_admin"
            Component={PointRules}
          />
        )
      },
      {
        path: '/pointrule_categories',
        element: (
          <RoleChecker
            roles="admin organization_admin"
            Component={PointRuleCategories}
          />
        )
      },
      {
        path: '/reward',
        element: (
          <RoleChecker
            roles="admin organization_admin"
            Component={PointReward}
          />
        )
      },
      {
        path: '/redeem',
        element: (
          <RoleChecker
            roles="admin organization_admin"
            Component={PointRedeem}
          />
        )
      },
      {
        path: '/redeem_pointrules',
        element: (
          <RoleChecker
            roles="admin organization_admin"
            Component={PointRedeemRules}
          />
        )
      },
      {
        path: '/history',
        element: (
          <RoleChecker
            roles="admin organization_admin"
            Component={PointHistory}
          />
        )
      },
      //Attendance
      {
        path: '/attendancerules',
        element: (
          <RoleChecker
            roles="admin organization_admin"
            Component={AttendanceRules}
          />
        )
      },
      {
        path: '/attendance_history',
        element: (
          <RoleChecker
            roles="admin organization_admin"
            Component={AttendanceHistory}
          />
        )
      },
      {
        path: '/checkin',
        element: (
          <RoleChecker roles="admin organization_admin" Component={CheckIn} />
        )
      },
      {
        path: '/checkout',
        element: (
          <RoleChecker roles="admin organization_admin" Component={CheckOut} />
        )
      },
      {
        path: '/studentoverview',
        element: (
          <RoleChecker roles="admin organization_admin" Component={Dashboard} />
        )
      },
      //Game
      {
        path: '/games',
        element: (
          <RoleChecker
            module="game"
            roles="admin organization_admin"
            Component={GameList}
          />
        )
      },
      {
        path: '/games/:gameId',
        element: (
          <RoleChecker
            module="game"
            roles="admin organization_admin"
            Component={GameDetail}
          />
        )
      },
      {
        path: '/games/edit/:gameId',
        element: (
          <RoleChecker
            module="game"
            roles="admin organization_admin"
            Component={GameEdit}
          />
        )
      },
      {
        path: '/games/record/:gameId',
        element: (
          <RoleChecker
            module="game"
            roles="admin organization_admin"
            Component={GameRecord}
          />
        )
      },
      //Notice
      {
        path: '/notice',
        element: (
          <RoleChecker
            module="notice"
            roles="admin organization_admin"
            Component={Notice}
          />
        )
      },
      {
        path: '/notice/publish',
        element: (
          <RoleChecker
            module="notice"
            roles="admin organization_admin"
            Component={() => <NoticeEdit isEdit={false} />}
          />
        )
      },
      {
        path: '/notice/edit/:notice_id',
        element: (
          <RoleChecker
            module="notice"
            roles="admin organization_admin"
            Component={NoticeEdit}
          />
        )
      },
      //Birthday
      {
        path: '/birthdaysetting',
        element: (
          <RoleChecker
            module="birthday"
            roles="admin organization_admin"
            Component={BirthdaySetting}
          />
        )
      },
      //Learning Resource
      {
        path: '/learningresource',
        element: (
          <RoleChecker
            module="learningResource"
            roles="admin organization_admin"
            Component={LearningResource}
          />
        )
      },
      //Homework
      {
        path: '/homeworksubmitrecord',
        element: (
          <RoleChecker
            module="homework"
            roles="admin organization_admin"
            Component={SubmittedHomework}
          />
        )
      },
      {
        path: '/unfinishedhomeworkrecord',
        element: (
          <RoleChecker
            module="homework"
            roles="admin organization_admin"
            Component={UnfinishedHomework}
          />
        )
      },
      {
        path: '/homeworkHistory',
        element: (
          <RoleChecker
            module="homework"
            roles="admin organization_admin"
            Component={HomeworkHistory}
          />
        )
      },
      {
        path: '/homeworktype',
        element: (
          <RoleChecker
            module="homework"
            roles="organization_admin"
            Component={HomeworkType}
          />
        )
      },
      {
        path: '/homeworkstatistics',
        element: (
          <RoleChecker
            module="homework"
            roles="admin organization_admin"
            Component={HomeworkStatistics}
          />
        )
      },
      {
        path: '/homeworksetting',
        element: (
          <RoleChecker
            module="homework"
            roles="organization_admin"
            Component={HomeworkSetting}
          />
        )
      },
      //Subject
      {
        path: '/subjectsetting',
        element: (
          <RoleChecker
            module="subject"
            roles="organization_admin"
            Component={SubjectSetting}
          />
        )
      },
      //Memo
      {
        path: '/memo',
        element: (
          <RoleChecker
            module="memo"
            roles="admin organization_admin"
            Component={Memo}
          />
        )
      },
      {
        path: '/memo/publish',
        element: (
          <RoleChecker
            module="memo"
            roles="admin organization_admin"
            Component={MemoPublish}
          />
        )
      },

      //Device
      {
        path: '/device',
        element: (
          <RoleChecker roles="organization_admin" Component={DeviceList} />
        )
      },
      //Setting
      {
        path: '/settings',
        element: (
          <RoleChecker roles="admin organization_admin" Component={Settings} />
        )
      },
      //Badges
      {
        path: '/badge_rule',
        element: (
          <RoleChecker
            module="badge"
            roles="admin organization_admin"
            Component={BadgeRule}
          />
        )
      },
      {
        path: '/badge_list',
        element: (
          <RoleChecker
            module="badge"
            roles="admin organization_admin"
            Component={BadgeList}
          />
        )
      },
      //BoardGame
      // { path: '/boardgame/dashboard', element: <RoleChecker roles='admin organization_admin' Component={BoardGameDashboard} /> },
      // { path: '/boardgame/game/:gameId', element: <RoleChecker roles='admin organization_admin' Component={BoardGameDetail} /> },
      // { path: '/boardgame/session/:sessionId', element: <RoleChecker roles='admin organization_admin' Component={BoardGameSessionDetail} /> },
      // { path: '/boardgame/profile/:profileId', element: <RoleChecker roles='admin organization_admin' Component={BoardGameProfileDetail} /> },
      //External website
      {
        path: '/external_links',
        element: (
          <RoleChecker
            roles="admin organization_admin"
            Component={ExternalSite}
          />
        )
      },
      //Redirect
      { path: '/', element: <Navigate to="/admin/dashboard" /> },
      { path: '*', element: <Navigate to="/not-found" /> }
    ]
  },
  {
    path: '/user',
    element: <DashboardLayout role="user" />,
    children: [
      { path: '/overview', element: <Overview /> },
      { path: '/overview/point_record', element: <UPointRecord /> },
      { path: '/overview/attendance_record', element: <UAttendanceRecord /> },
      {
        path: '/overview/unfinished_homework_record',
        element: <UUnfinishedHomeworkRecord />
      },
      {
        path: '/overview/submitted_homework_record',
        element: <USubmittedHomeworkRecord />
      },
      { path: '/profile', element: <Profile /> },
      { path: '/badge', element: <Badge /> },
      {
        path: '/reward',
        element: (
          <RoleChecker roles="user parent" Component={ParentPointReward} />
        )
      },
      {
        path: '/notice',
        element: <RoleChecker roles="user" Component={UNotice} />
      },
      {
        path: '/notice/:id',
        element: <RoleChecker roles="user" Component={NoticeDetail} />
      },
      {
        path: '/memo',
        element: <RoleChecker roles="user " Component={UMemo} />
      },
      {
        path: '/memo/:id',
        element: <RoleChecker roles="user " Component={MemoDetail} />
      },
      {
        path: '/studentHomeworkStatistics',
        element: (
          <RoleChecker
            module="homework"
            roles="user"
            Component={StudentHomeworkStatistics}
          />
        )
      },
      { path: '/notice/publish', element: <NoticeEdit /> },
      { path: '/settings', element: <Settings /> },
      { path: '/games', element: <UserGameList /> },
      { path: '/', element: <Navigate to="/user/overview" /> },
      { path: '*', element: <Navigate to="/not-found" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: '/not-found', element: <NotFound /> },
      { path: '/', element: <Navigate to="/sign-in" /> },
      { path: '*', element: <Navigate to="/sign-in" /> }
    ]
  },
  {
    path: '/',
    element: <Outlet />,
    children: [
      { path: '/reset_password', element: <SetupPassoword /> },
      { path: '/sign-in', element: <Login /> },
      { path: '/qr-code', element: <QrCodeView /> },
      { path: '/', element: <Navigate to="/sign-in" /> },
      { path: '*', element: <Navigate to="/sign-in" /> }
    ]
  }
  // { path: '/', element: <Navigate to="/sign-in" /> }
];

export default routes;
