import { Box, Button, Card, Container, Divider, IconButton, Paper, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import useApi from 'src/api/api';
import DataTable from 'src/components/DataTable/DataTable';
import Icons from 'src/icons';
import LayoutContainer from 'src/layout/LayoutContainer';
import { useAuthData } from 'src/provider/AuthDataProvider';
import { useLocale } from 'src/provider/LocaleProvider';
import classNames from 'classnames';
const prettyBytes = require('pretty-bytes');

const MemoDetail = () => {
    const { t } = useLocale()
    const api = useApi()
    const [memo, setMemo] = useState();
    const nav = useNavigate()
    const { id } = useParams()
    // const snack = useSnack()



    useEffect(() => {
        if (id) {
            api.getMemoById(id)
                .then(res => {

                    setMemo(res.data)
                    if (!res.data.user_info?.is_read) {
                        api.updateMemoInfo(id, { is_read: true })
                            .catch(e => {

                            })
                    }
                }).catch(e => console.log(e))
        }
    }, []);
    if (!memo) return null

    return (

        <LayoutContainer title={t('memo')} backSrc={'/user/memo'}>
            <Card style={{ padding: '16px' }}>

                <div className='flex-sb ac w100 mb8'>
                    <Typography variant='h2' >
                        {memo.title}
                    </Typography>

                    <IconButton sx={{ color: memo.user_info?.is_favourite && '#FFB000' }} onClick={(e) => {

                        api.updateMemoInfo(memo._id, { is_favourite: !memo.user_info?.is_favourite })
                            .catch(e => {

                            })
                            .finally(() => {
                                let _memo = { ...memo }
                                _.set(_memo, `user_info.is_favourite`, !memo.user_info?.is_favourite)
                                setMemo(_memo)

                            })

                    }}>
                        <Icons.FavouriteIcon />
                    </IconButton>
                </div>
                <Divider sx={{ mb: 1 }} />
                <Typography style={{ marginBottom: '24px', whiteSpace: 'pre-wrap' }}>
                    {memo.message}
                </Typography>

            </Card>

        </LayoutContainer>

    );
}

export default MemoDetail;