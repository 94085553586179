import { Helmet } from 'react-helmet';
import { Backdrop, Box, Container, Tab, Grid, Card, CardContent, Typography, CardHeader, Button, Divider, IconButton, TextField, InputAdornment, List, Tooltip, Tabs, Avatar, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useEffect } from 'react';
import { useBackDrop } from 'src/provider/BackdropProvider';
import { useState } from 'react';
import useApi from 'src/api/api';
import { ArrowBack } from '@material-ui/icons';
import Icons from 'src/icons';
import DataTable from 'src/components/DataTable/DataTable';
import DataTableNoLimit from 'src/components/DataTable/DataTableNoLimit';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router';
import { useLocale } from 'src/provider/LocaleProvider';
import utils from 'src/utils/utils';
import SearchInput from 'src/components/SearchInput';
import LayoutContainer from 'src/layout/LayoutContainer';


import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined'
import AssignmentIcon from '@material-ui/icons/Assignment'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined'
import ImageUploader from 'src/components/Formik/ImageUploader';
import QuestionCard from 'src/components/Game/QuestionCard';

const moment = require('moment-timezone')

const useStyles = makeStyles(theme => ({

    row: {
        // height: '10px',
        // paddingBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2)
    },
    spacer: {
        flex: 1
    },

    tableCell: {
        "& .MuiTableCell-root": {
            textAlign: 'center',
            borderLeft: "none !important",
        },
        "& .MuiTableRow-root": {
            height: '60px'
        },

    },
    caption: {
        fontSize: '12px'
    }

}))

const DataBox = ({ title, value, Icon, className, ...rest }) => {
    return (
        <Box
            sx={{
                '& .avatar': {
                    backgroundColor: 'transparent',
                    color: 'black',
                    // border: '1px solid black',
                    height: 56,
                    width: 56
                }
            }}
            {...rest}
        >
            <CardContent sx={{ p: 2 }}>
                <Grid
                    container
                    justify="space-between"
                    spacing={0}
                >
                    <Grid item xs>
                        <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h5"
                        >
                            {title}
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="h3"
                        >
                            {value}
                        </Typography>
                    </Grid>
                    <Grid item style={{ alignSelf: 'center' }}>
                        <Avatar className={'avatar'}>
                            {Icon}
                        </Avatar>
                    </Grid>

                </Grid>
            </CardContent>
        </Box>
    )
}


const SessionRecord = ({ gameData }) => {
    const classes = useStyles()
    const { t, getLanguage, translate } = useLocale()

    var sessions = _.map(_.orderBy(gameData, 'created_at', 'desc'), session => {
        var records = _.map(session.records, r => {
            return ({ ...r, isCorrect: _.every(r.answers, 'isAns') })
        })
        return ({
            ...session,
            records: records,
            correct: _.sumBy(records, r => r.isCorrect ? 1 : 0),
            incorrect: _.sumBy(records, r => r.isCorrect ? 0 : 1),
            accuracy: _.sumBy(records, r => r.isCorrect ? 1 : 0) / _.size(records),
            class: _.get(session, 'profile.class'),
            class_no: _.get(session, 'profile.class_no'),
            name: translate(_.get(session, 'user.name'))
        })
    })


    return sessions.map(s => {
        return <Card className='flex ac' sx={{
            mb: 2, p: 2,
        }}>
            <Grid container spacing={2} sx={{
                '& .MuiGrid-item': {
                    display: 'flex',
                    alignItems: 'center'
                }
            }}>
                <Grid item xs={12} sm={6} md={2} >
                    <span className='bold textoverflow-1' >{moment(s.created_at).format("YYYY-MM-DD HH:MM")}</span>
                </Grid>
                <Grid item xs={12} sm={6} md={2} >
                    <Box className='textoverflow-1'>
                        <span> {s.class} </span>
                        <span> {_.padStart(s.class_no, 2, '0')} </span>
                        <span> {s.name} </span>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} sx={{ minWidth: '120px' }}>
                    <Box display='flex' sx={{ overflow: 'hidden', flexGrow: 1, borderRadius: '4px', height: '24px' }}>
                        <Box flexGrow={s.correct} className='flex ac' sx={{ backgroundColor: 'limegreen', color: "white" }}>
                            {_.toNumber(s.correct) > 0
                                ? <>
                                    <Icons.CheckIcon sx={{ p: 0, height: '16px', display: '' }} />
                                    <span style={{ paddingRight: '8px' }}> {s.correct} </span>
                                </>
                                : null
                            }
                        </Box>
                        <Box flexGrow={s.incorrect} className='flex ac' sx={{ backgroundColor: 'red', color: "white" }}>
                            {_.toNumber(s.incorrect) > 0
                                ? <>
                                    <Icons.CloseIcon sx={{ p: 0, height: '16px' }} />
                                    <span style={{ paddingRight: '8px' }}> {s.incorrect} </span>
                                </>
                                : null
                            }
                        </Box>
                    </Box>
                    <Box ml={1} minWidth='40px'>
                        {_.round(s.accuracy, 3) * 100 + '%'}
                    </Box>
                </Grid>
                <Grid className='textoverflow-1' item xs sm md={3} sx={{ minWidth: '120px' }}>
                    <Box minWidth='60px' className='tc'>
                        <p>{s.point}</p>
                        <p>{'point'}</p>
                    </Box>
                    <Box ml={1} className='tc' minWidth='60px'>
                        <p>{s.pointExchanged}</p>
                        <p>{'pointExchanged'}</p>
                    </Box>
                </Grid>
            </Grid>
        </Card>

    })
}

const QuestionRecord = ({ gameData, questions }) => {
    const { t, getLanguage, translate } = useLocale()
    return _.map(questions, (question, index) => {
        console.log('return_.map => question', question)
        var a = _.flattenDeep(_.map(question.records, r => r.answers))
        var count = _.countBy(a, '_id')
        var sum = _.size(a)

        return <QuestionCard question={question} index={index} OptionComponent={({ option, index }) => {
            var c = _.get(count, option._id, 0)
            return <ListItem key={index} divider sx={{ flexWrap: { xs: 'wrap', sm: 'nowrap' } }} style={{ height: 'auto' }}>
                <Grid container spacing={1} >
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography noWrap variant='h5' >
                            <span style={{ color: option.isAns ? 'limegreen' : 'red', marginRight: "16px" }}>{t('option')} {index + 1}:</span>
                            <span>{option.text}</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2} sx={{ textAlign: { sm: 'left', md: 'center' } }} >
                        {`${c} ans`}
                    </Grid>
                    <Grid item xs={12} sm={8} md={4} sx={{
                        display: 'flex', width: {
                            xs: '100%', sm: 'auto'
                        }
                    }}>
                        <Box sx={{ backgroundColor: 'grey.main', borderRadius: '4px', mr: 2, height: '24px', flexGrow: 1 }}>
                            <Box sx={{ backgroundColor: option.isAns ? 'limegreen' : 'red', width: _.round(c / sum * 100, 1) + '%', borderRadius: '4px', height: '100%' }} />
                        </Box>
                        <Box width='40px'>
                            {_.round(c / sum * 100, 1) + '%'}
                        </Box>
                    </Grid>
                </Grid>
            </ListItem>
        }} />
    })
}


const TabPanel = ({ value, tab, children }) => {
    if (value == tab) {
        return children
    }
    return null
}

const GameRecord = () => {
    const classes = useStyles()
    const loadingView = useBackDrop();
    const api = useApi()
    const nav = useNavigate()
    const { t, translate } = useLocale()
    const { gameId } = useParams()

    const [game, setGame] = useState({});
    const [gameData, setGameData] = useState()

    const [tab, setTab] = useState('session');
    const [totalSession, setTotalSession] = useState(0)
    const [totalUser, setTotalUser] = useState(0)

    useEffect(() => {
        setTotalSession(_.size(gameData))
        var users = _.map(gameData, item => item.user._id)
        var unique = _.union(users)
        setTotalUser(_.size(unique))
    }, [gameData])

    const handleExport = () => {
        utils.exportCsv(gameData, [
            // { label: t('rank'), value: (row) => { return (_.findIndex(classRanking, (k) => k.class === row.class) + 1) } },
            { label: t('date'), value: (row) => moment(row.created_at).format("YYYY-MM-DD HH:MM") },
            { label: t('class'), value: row => row['class'], },
            { label: t('name_zh'), value: row => row.name?.zh },
            { label: t('name_en'), value: row => row.name?.en, },
            { label: t('point'), value: (row) => row['point'] }
        ], 'class_ranking')
    }

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        api.getGameData(gameId, { records: true }).then(res => {

            setGame(res.data)
        })
        return api.getGameRecordDetails(gameId).then(res => {
            setGameData(res.data)
        }).catch(e => console.log(e))
    }

    return (
        <LayoutContainer title={t('game_record')} backSrc={`/admin/games/${gameId}`}>
            <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={8} sx={{
                    '& img': {
                        height: '100%',
                        width: '100%'
                    },
                    '& .MuiTypography-root': {
                        overflow: 'hidden', lineHeight: '32px', maxHeight: '64px'
                    },
                }} >
                    <Card sx={{ height: '100%' }}>
                        <Grid container>
                            <Grid item sx={{ p: 2, height: '120px', width: '120px' }}>
                                <img src={game.logo || '/images/game.png'} />
                            </Grid>
                            <Grid item xs lg sx={{ p: 2, height: '120px' }}>
                                <Typography variant="h3" >
                                    {game.name}
                                </Typography>
                                <Typography color="textSecondary" variant="h4">
                                    {game.description}
                                </Typography>
                            </Grid>
                            <Divider flexItem sx={{ width: '100%' }} />
                            <Grid item xs={12} sx={{ p: 2 }}>
                                <Box display='flex'>
                                    <DescriptionOutlinedIcon />
                                    <Box pr={1} />
                                    <Typography noWrap color="textSecondary" variant="h4" >
                                        {t('gametype') + ' : ' + t(`game_${game.gameType}`)}
                                    </Typography>
                                </Box>
                                <Box display='flex'>
                                    <PeopleAltOutlinedIcon />
                                    <Box pr={1} />
                                    <Typography color="textSecondary" variant="h4" >
                                        {t('grade_or_class') + ' : ' + (_.size(game.filters) > 0 ? _.map(game.filters, f => translate(f.name)).sort().join(', ') : t('all_grade_and_class'))}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={4}  >
                    <Card sx={{ height: '100%' }}>
                        <DataBox title={t('game.total_session')} value={totalSession} Icon={<AssignmentIcon />} />
                        <Divider style={{ margin: '6px 0' }} />
                        <DataBox title={t('game.total_user')} value={totalUser} Icon={<PeopleAltIcon />} />
                    </Card>
                </Grid>
            </Grid>
            <Card sx={{ mb: 2, borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tab} onChange={(e, v) => setTab(v)} >
                    <Tab label={t('play_record')} value={'session'} />
                    <Tab label={t('question_report')} value={'question'} />
                    {/* <Tab label="Play Record" value={'record'} /> */}
                </Tabs>
            </Card>
            {/* <TabPanel value={'record'} tab={tab}>
                <Grid container alignItems="center" >
                    <Grid item xs={12} className={'tr'} mb={2}>
                        <Button onClick={handleExport} >
                            {t('export')}
                        </Button>
                    </Grid>
                </Grid>
                <DataTable
                    sx={{ mb: 1 }}
                    className={classes.tableCell}
                    rows={(gameData)}
                    heads={[
                        {
                            label: t('date'), value: 'created_at', content: (row) => moment(row.created_at).format("YYYY-MM-DD HH:MM")
                            , sort: true,
                            width: '33.3%'
                        },
                        // { label: t('rank'), value: 'rank', content: (row, index) => { return (_.findIndex(classRanking, (k) => k.class === row.class) + 1) } },
                        { label: t('class'), value: 'profile.class', },
                        { label: t('name_zh'), value: 'user.name.zh' },
                        { label: t('name_en'), value: 'user.name.en', },
                        { label: t('point'), value: 'point' }
                    ]}

                />
            </TabPanel> */}
            <TabPanel value={'question'} tab={tab}>
                <QuestionRecord gameData={gameData} questions={game.questions} />
            </TabPanel>
            <TabPanel value={'session'} tab={tab}>
                <SessionRecord gameData={gameData} />
            </TabPanel>

            {/* <div align='right' className={'font12'}>Last Updated at {moment().format("YYYY-MM-DD HH:mm:ss")}</div> */}

        </LayoutContainer>
    )
}
export default GameRecord;
