

const _ = require('lodash')

export default function (formik, name, { helperText } = {}) {
    const value = _.get(formik.values, name, '')
    const error = _.get(formik.errors, name)
    const touched = _.get(formik.touched, name)
    const isError = Boolean(touched && error)
    return ({
        name: name,
        value: value,
        error: isError,
        helperText: isError && error || helperText,
        onBlur: formik.handleBlur,
        onChange: formik.handleChange
    })
}