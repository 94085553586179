import DashboardIcon from '@material-ui/icons/Dashboard';

import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import GavelIcon from '@material-ui/icons/Gavel';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import CakeIcon from '@material-ui/icons/Cake';
import AssignmentIcon from '@material-ui/icons/Assignment';
import FolderIcon from '@material-ui/icons/Folder';

import Icons from 'src/icons';
const _ = require('lodash');

export default (t, modules = {}) => [
  {
    name: t('dashboard_title'),
    href: '/admin/dashboard',
    icon: () => <DashboardIcon />
  },
  {
    name: t('accounts'),
    icon: () => <PeopleIcon style={{ width: '24px', height: '24px' }} />,
    pages: [
      {
        href: '/admin/student_list',
        title: t('users_title')
      }
    ]
  },
  {
    name: t('points'),
    icon: () => <GavelIcon />,
    pages: [
      {
        href: '/admin/pointrule_categories',
        title: t('rule_category'),
        exact: true
      },
      {
        href: '/admin/pointrules',
        title: t('pointRules_title'),
        exact: true
      },
      {
        href: '/admin/redeem_pointrules',
        title: t('prize_rules'),
        exact: true
      },
      {
        href: '/admin/reward',
        title: t('add_point'),
        exact: true
      },
      {
        href: '/admin/redeem',
        title: t('redeem_prizes'),
        exact: true
      },
      {
        href: '/admin/history',
        title: t('history_title'),
        exact: true
      }
    ]
  },
  {
    name: t('attendances'),
    icon: () => <InsertDriveFileIcon />,
    pages: [
      {
        href: '/admin/attendancerules',
        title: t('attendanceRules_title'),
        exact: true
      },
      {
        href: '/admin/checkin',
        title: t('checkin'),
        exact: true
      },
      {
        href: '/admin/checkout',
        title: t('checkout'),
        exact: true
      },
      {
        href: '/admin/attendance_history',
        title: t('attendance_title'),
        exact: true
      }
    ]
  },
  {
    name: t('badges'),
    show: modules.badge,
    icon: () => <Icons.FaAward style={{ width: '24px', height: '22px' }} />,
    pages: [
      {
        href: '/admin/badge_rule',
        title: t('badge_rule')
      },
      {
        href: '/admin/badge_list',
        title: t('badges')
      }
    ]
  },
  {
    name: t('birthday'),
    show: modules.birthday,
    icon: () => <CakeIcon style={{ width: '24px', height: '24px' }} />,
    href: '/admin/birthdaysetting'
  },
  {
    name: t('learning_resource'),
    show: modules.learningResource,
    icon: () => <FolderIcon style={{ width: '24px', height: '24px' }} />,
    href: '/admin/learningresource'
  },
  {
    name: t('homework'),
    show: modules.homework,
    icon: () => <AssignmentIcon style={{ width: '24px', height: '24px' }} />,
    pages: [
      {
        href: '/admin/homeworksubmitrecord',
        title: t('submitted_homework')
      },
      {
        href: '/admin/unfinishedhomeworkrecord',
        title: t('unfinished_homework')
      },
      {
        href: '/admin/homeworkhistory',
        title: t('homework_record_history')
      },
      {
        href: '/admin/homeworkstatistics',
        title: t('homework_statistics')
      }
    ]
  },
  {
    name: t('notice'),
    show: modules.notice,
    icon: () => <Icons.NoticeIcon />,
    href: '/admin/notice'
  },
  {
    name: t('memo'),
    show: modules.memo,
    icon: () => <Icons.EmailIcon />,
    href: '/admin/memo'
  },
  {
    name: t('games'),
    show: modules.game,
    icon: () => <Icons.SportsEsportsIcon />,
    href: '/admin/games'
  },
  {
    name: t('settings_title'),
    icon: () => <SettingsIcon />,
    href: '/admin/settings'
  },
  {
    name: t('external_links'),
    icon: () => <Icons.LaunchIcon />,
    href: '/admin/external_links'
  }
];
