import { useNavigate } from 'react-router'
import { Box, Typography, IconButton } from '@material-ui/core';
import Icons from 'src/icons';

const LayoutTitle = ({ title = '', goBack, backSrc, children }) => {
    const nav = useNavigate()
    const handleBack = () => {
        if (goBack) {
            nav(-1)
        }
        else if (backSrc) {
            nav(backSrc)
        }
    }

    return (
        <Box mb={2} className='flex'>
            <Box className='flex ac'>
                {backSrc && <IconButton sx={{ p: 0.5, mr: 1 }} onClick={handleBack}>
                    <Icons.ArrowBackIcon />
                </IconButton>}
                <Typography color='primary' className='bold' variant={'h2'}>{title}</Typography>
            </Box>
            <Box flexGrow={1} />
            {children}
        </Box>
    )
}

export default LayoutTitle