import React, { Fragment, useMemo, useCallback, useEffect, useState, useRef, useLayoutEffect } from 'react'
import { Box, Button, Card, CardActions, CardContent, CardHeader, Container, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, TextField } from '@material-ui/core'
import { useLocale } from 'src/provider/LocaleProvider'
import useApi from 'src/api/api';
import { Helmet } from 'react-helmet';
import { useFormik } from 'formik';
import formikProps from 'src/utils/formikProps';
import { useAuthData } from 'src/provider/AuthDataProvider';
import { makeStyles } from '@material-ui/styles';
import utils from 'src/utils/utils';
import LayoutContainer from 'src/layout/LayoutContainer';
import Icons from 'src/icons';
import LoadingView from 'src/components/LoadingView';
const yup = require('yup')
const _ = require('lodash')
const useStyles = makeStyles((theme) => ({
    imageuploader: {
        position: 'relative',
        '& img': {
            objectFit: 'contain'
        },
        '& .removeIcon': {
            position: 'absolute', right: 0, top: 0, zIndex: 999
        },
        '& .uploadIcon': {
            // backgroundColor: 'rgba(255,255,255,0.5)',
            // borderRadius: '4px',
            position: 'absolute', right: 10, bottom: 10, zIndex: 999
        },
        '& .MuiFormControl-root': {
            height: '300px',
            border: '1px solid',
            borderRadius: '4px',
            borderColor: 'rgba(0, 0, 0, 0.23)',
            padding: '8px',
            '&:hover': {
                borderColor: 'black',
            },
            '& .MuiInputLabel-root': {
                padding: '0 4px',
                backgroundColor: 'white'
            },

        },
        '& .error': {
            borderColor: 'red !important',
            '&:hover': {
                borderColor: 'red !important',
            },
        }
    }

}))
const MB = 1048576

const OrganizationInfo = ({ snack }) => {
    const classes = useStyles()
    const api = useApi()
    const { t } = useLocale()
    const [info, setinfo] = useState({})
    const auth = useAuthData()
    const [rules, setRules] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        api.getOrganizationInfo(setinfo)
        api.getPointRules({ type: 'reward', isActive: true }).then(res => {
            setRules(res.data)
            setIsLoading(false);
        })
    }, [])


    const formik = useFormik({
        initialValues: info || {
            logo: null,
            banner: null,
        },
        enableReinitialize: true,
        validationSchema: yup.object().shape({
            name: yup.object().shape({
                en: yup.string().required(),
                zh: yup.string().required()
            }),
            logo: yup.mixed().maxFilesize(1024),
            banner: yup.mixed().maxFilesize(1024),

        }),
        onSubmit: async (values, helper) => {
            try {
                await api.batchUploadBlobs(values, ['logo', 'banner'])
                await api.updateOrganizationInfo(values)
                auth.refresh()
                snack.open()
                formik.setSubmitting(false)
            }
            catch (e) {
                snack.error()
                formik.setSubmitting(false)
            }
        }
    })
    const renderImg = (size, src, old_src) => {
        if (size) {
            return <img className='h100 w100' src={URL.createObjectURL(src)} />
        }
        else {
            if (src)
                return <img className='h100 w100' src={src} />
        }
    }
    const { values, errors } = formik

    if (isLoading) {
        return <LoadingView></LoadingView>;
      }

    return (
        <>
            <LayoutContainer title={t('organization')}>
                <Card>
                    <form onSubmit={formik.handleSubmit}>
                        <CardContent>
                            <TextField
                                disabled
                                label={t('prefix')}
                                fullWidth value={info.prefix || ''}
                                InputProps={{
                                    readOnly: true
                                }}
                                sx={{ mb: 2 }}
                            />
                            <TextField label={t('name_zh')} fullWidth {...formikProps(formik, 'name.zh')} sx={{ mb: 2 }} />
                            <TextField label={t('name_en')} fullWidth {...formikProps(formik, 'name.en')} sx={{ mb: 2 }} />
                            <Box className={classes.imageuploader} sx={{ mb: 2 }}>
                                <FormControl variant="outlined" fullWidth error={formik.errors.logo && true} className={formik.errors.logo && 'error'} >
                                    <InputLabel shrink >{t('logo')}</InputLabel>
                                    {renderImg(values.logo?.size, values.logo, info.logo)}
                                    <div className='removeIcon'>
                                        {values.logo &&
                                            <IconButton onClick={() => { formik.setFieldValue('logo', null) }}>
                                                <Icons.DeleteIcon />
                                            </IconButton>
                                        }
                                    </div>
                                    <div className='uploadIcon'>
                                        <Button variant='text' onClick={() => utils.readFile(f => formik.setFieldValue('logo', f))}>
                                            <Icons.PublishIcon />
                                            <span>  {t('choose_file')}</span>
                                        </Button>
                                    </div>
                                </FormControl >
                                <FormHelperText component='div' error={formik.errors.logo && true}>
                                    {formik.errors.logo}
                                    <p>{`< 1MB | PNG ${t('or')} JPEG | 1:1 ${t('aspect_ratio')}`}</p>
                                </FormHelperText>
                            </Box>
                            <Box className={classes.imageuploader} sx={{ mb: 2 }}>
                                <FormControl variant="outlined" fullWidth error={formik.errors.banner && true} className={formik.errors.banner && 'error'} >
                                    <InputLabel shrink >{t('banner')}</InputLabel>
                                    {renderImg(values.banner?.size, values.banner, info.banner)}
                                    <div className='removeIcon'>
                                        {values.banner &&
                                            <IconButton onClick={() => { formik.setFieldValue('banner', null) }}>
                                                <Icons.DeleteIcon />
                                            </IconButton>
                                        }
                                    </div>
                                    <div className='uploadIcon'>
                                        <Button variant='text' onClick={() => utils.readFile(f => formik.setFieldValue('banner', f))}>
                                            <Icons.PublishIcon />
                                            <span>  {t('choose_file')}</span>
                                        </Button>
                                    </div>
                                </FormControl >
                                <FormHelperText component='div' error={formik.errors.banner && true}>
                                    {formik.errors.banner}
                                    <p>{`< 1MB | PNG ${t('or')} JPEG | 6:1 ${t('aspect_ratio')}`}</p>
                                </FormHelperText>
                            </Box>
                            {/* <TextField
                                select
                                fullWidth
                                {...formikProps(formik, 'birthday_point_rule')}
                                label={t('birthday_point_rule')}
                                sx={{ mb: 2 }}
                            >
                                <MenuItem value={null}> {'-------'} </MenuItem>
                                {_.map(rules, c => {
                                    return <MenuItem key={c._id} value={c._id}> {c?.name} </MenuItem>
                                })}
                            </TextField>
                            <TextField label={t('birthday_message_en')} fullWidth {...formikProps(formik, 'birthday_message.en')} sx={{ mb: 2 }} />
                            <TextField label={t('birthday_message_zh')} fullWidth {...formikProps(formik, 'birthday_message.zh')} sx={{ mb: 2 }} />
                            <TextField label={t('near_birthday_message_en')}
                                {...formikProps(formik, 'near_birthday_message.en', { helperText: t('near_birthday_message_helper', null, 'en') })}
                                fullWidth
                                sx={{ mb: 2 }} />
                            <TextField label={t('near_birthday_message_zh')}
                                {...formikProps(formik, 'near_birthday_message.zh', { helperText: t('near_birthday_message_helper', null, 'zh') })}
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            <TextField label={t('day_before_birthday')} type='number' fullWidth {...formikProps(formik, 'day_before_birthday')} sx={{ mb: 2 }} /> */}
                            {/* <FormTextField disabled name={t('prefix')} value={info.prefix} readOnly />
                        <FormTextField name={t('name_en')} value={info.name && info.name.en} setValue={handleSetNameValue('en')} />
                        <FormTextField name={t('name_zh')} value={info.name && info.name.zh} setValue={handleSetNameValue('zh')} />
                        <UploadImage name={t('logo')} file={info.logo} setFile={handleSetValue('logo')} handleDelete={handleDeleteValue('logo')} max={1000000} />
                        <Typography variant='body2' color='textSecondary' style={{ marginBottom: '12px' }}>
                            PNG {t('or')} JPEG <br />
                1:1 {t('aspect_ratio')}<br />
                        </Typography>
                        <UploadImage name={t('banner')} file={info.banner} setFile={handleSetValue('banner')} handleDelete={handleDeleteValue('banner')} aspectRatio={6} max={1000000} />
                        <Typography variant='body2' color='textSecondary' style={{ marginBottom: '12px' }}>
                            PNG {t('or')} JPEG <br />
                6:1 {t('aspect_ratio')}<br />
                        </Typography> */}
                            {/* 
                            <FormControl variant="outlined" fullWidth className={classes.outlined} sx={{ mb: 2 }} onClick={() => utils.readFile(f => formik.setFieldValue('logo', f))}>
                                <InputLabel shrink >{t('logo')}</InputLabel>
                                <img className='h100 w100' src={values.logo?.size ? URL.createObjectURL(values.logo) : values.logo} style={{ objectFit: 'contain' }} />
                            </FormControl >
                            <FormControl variant="outlined" fullWidth className={classes.outlined} sx={{ mb: 2 }} onClick={() => utils.readFile(f => formik.setFieldValue('banner', f))}>
                                <InputLabel shrink >{t('logo')}</InputLabel>
                                <img className='h100 w100' src={values.banner?.size ? URL.createObjectURL(values.banner) : values.banner} style={{ objectFit: 'contain' }} />
                            </FormControl > */}
                        </CardContent>
                        <CardActions>
                            <Button type='reset' onClick={() => {
                                formik.resetForm()
                            }} variant='outlined' > {t('reset')}</Button>
                            <Button type='submit' > {t('save')}</Button>
                        </CardActions>
                    </form>
                </Card>
            </LayoutContainer>
        </>
    )
}

export default OrganizationInfo