import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import PropTypes, { object } from 'prop-types'

// Translation Higher Order Component
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage,
    translate,
    getLanguage,
    getLanguages,

    getDefaultLanguage,
} from 'react-switch-lang'
import axios from 'axios'
import { useLocation, useParams } from 'react-router'
import en from 'src/lang/en/translation.json'
import zh from 'src/lang/zh/translation.json'
// Do this two lines only when setting up the application

const _ = require('lodash')
const LocaleContext = React.createContext()
const yup = require('yup')

export function useLocale() {
    return useContext(LocaleContext) || {
        translate: new Function(),
        getLanguage: new Function(),
        getLanguages,
        replaceParams: new Function(),
        t: new Function(),
        setLanguage: new Function(),
        getLanguageLabel: new Function()
    }
}
const moment = require('moment-timezone')

const LocaleProvider = (props) => {


    // const location = useLocation()
    // const params = useParams()
    // const [path, setPath] = useState();
    // useEffect(() => {
    //     var p = location.pathname
    //     for (const key of _.keys(params)) {
    //         p = _.replace(p, params[key], 'id')
    //     }
    //     setPath(p)
    // }, [location.pathname]);

    const [available, setAvailable] = useState(false);
    const { children, t } = props

    useEffect(() => {
        getJSON()
    }, [])
    const config = {
        headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
        }
    }

    const getJSON = async () => {
        // var en = await axios.get('/lang/en.json', config).then(res => res.data)
        // var zh = await axios.get('/lang/zh.json', config).then(res => res.data)
        setTranslations({ en, zh })
        setDefaultLanguage('zh')
        setLanguageCookie()
        var lang = localStorage.getItem('language')
        switchLanguage(lang)
        setAvailable(true)
    }

    const switchLanguage = (key) => {
        if (getLanguages().includes(key)) {
            setLanguage(key)
            localStorage.setItem('language', key)
        }
        else {
            setLanguage(getDefaultLanguage())
            localStorage.setItem('language', getDefaultLanguage())
        }
    }


    const translate = (obj) => {
        if (obj) {
            var value = obj[getLanguage()] || obj[getDefaultLanguage()]
            if (value) return value
            for (var lang of getLanguages()) {
                if (obj[lang]) return obj[lang]
            }
            for (var key of Object.keys(obj)) {
                if (obj[key]) return obj[key]
            }
            return null
        }
        return null
    }

    const getLanguageLabel = (lang) => {
        switch (lang) {
            case 'en': return 'English'
            case 'zh': return '中文'
            default: return 'unknown'
        }
    }



    /**
     * Replace params in string
     * @param {*} str 
     * @param {*} params 
     */
    const replaceParams = (str, params) => {
        var result = str
        console.log("file: LocaleProvider.js ~ line 121 ~ replaceParams ~ result", result)
        for (const key in params) {
            result = _.replace(result, `{${key}}`, params[key])
        }
        return result
    }



    yup.setLocale({
        // use constant translation keys for messages without values
        mixed: {
            required: ({ label, value, path }) => t('yup_required'),
            notType: () => t('yup_wrong_type'),

        },
        string: {
            min: ({ min }) => t('yup_string_min', { min: min }),
            max: ({ max }) => t('yup_string_max', { max: max }),
            length: ({ length }) => `exact ${length} characters`,
            matches: () => t('yup_wrong_type')
        },
        // array: {
        //     min: ({ min }) => t('min_array', { min: min }),
        //     max: ({ max }) => t('max_array', { max: max })
        // },
        number: {
            min: ({ min }) => t('yup_number_min', { min: min }),
            max: ({ max }) => t('yup_number_max', { max: max }),
        }
    })

    yup.addMethod(yup.string, 'isValidTime', function (message) {
        return this.test('foramt', t('yup_wrong_type'), v => !v || moment(v, 'HH:mm', true).isValid())
    })

    yup.addMethod(yup.string, 'isValidDate', function (message) {
        return this.test('foramt', t('yup_wrong_type') + ' (YYYY-MM-DD)', v => !v || moment(v, 'YYYY-MM-DD', true).isValid())
    })

    yup.addMethod(yup.mixed, 'maxFilesize', function (size) {
        return this.test('fileSize', t('file_too_large'), value => {
            if (_.isObject(value)) return !value || value.size <= size * 1000
            return true
        })
    })





    const state = {
        t: (str, ...rest) => t(_.toString(str), ...rest),
        translate,
        replaceParams,
        setLanguage: switchLanguage,
        getLanguage,
        getLanguages,
        getLanguageLabel
    }
    if (!t || !available) return null
    return (
        <LocaleContext.Provider value={state} >
            {children}
        </LocaleContext.Provider>
    )
}

LocaleProvider.propTypes = {
    t: PropTypes.func.isRequired,
}

export default translate(LocaleProvider)