import { Button, Grid, TextField, Alert, Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import useApi from 'src/api/api';
import PagingTable, { usePagination } from 'src/components/DataTable/PagingTable';
import CustomDatePicker from 'src/components/DatePicker';
import LoadingView from 'src/components/LoadingView';
import LayoutContainer from 'src/layout/LayoutContainer';
import { useAuthData } from 'src/provider/AuthDataProvider';
import { useLocale } from 'src/provider/LocaleProvider';
import utils from 'src/utils/utils';
import { useMutation } from 'react-query';

const AttendanceHistory = () => {
    const api = useApi()
    const { t, getLanguage } = useLocale()

    const { selectedPeriod } = useAuthData()
    const pagination = usePagination({ _limitOptions: [10, 25, 50], _limit: 10 });
    const {
        page,
        setPage,
        limit,
        order,
        orderBy,
    } = pagination;

    const handleSearch = (path) => (e) => {
        setSearch((s) => ({ ...s, [path]: e.target.value }));
    };
    const [dateRange, setDateRange] = useState(moment().startOf('month'));
    const [search, setSearch] = useState({});

    const [attendanceList, setAttendanceList] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const [lastUpdate, setLastUpdate] = useState();
    const timer = useRef();

    async function getRows() {
        try {
            const res = await api
                .getPointHistoriesV2({
                    dateRange: dateRange.toISOString(),
                    search,
                    order_by: orderBy,
                    order,
                    limit,
                    page,
                    type: "",
                    attendanceRule: true,
                })
            if (res.data.last_updated_at !== lastUpdate) {
                setAttendanceList(res.data.pages);
            } else {
                setAttendanceList((h) => {
                    return { ...h, ...res.data.pages };
                });
            }
            setTotal(res.data.total);
            setLastUpdate(res.data.last_updated_at);
            setIsLoading(false);
        } catch (err) {
            console.log(err)
        }

    }

    useEffect(() => {
        let pre = Math.max(0, page - 1);
        let current = page;
        let next = Math.min(total, page + 1);
        if (!(attendanceList[pre] && attendanceList[current] && attendanceList[next])) {
            getRows();
        }
    }, [page]);

    useEffect(() => {
        setAttendanceList({});
        getRows();
    }, [orderBy, order, dateRange, selectedPeriod]);

    useEffect(() => {
        setAttendanceList({});
        if (page === 0) {
            getRows();
        } else {
            setPage(0);
        }
    }, [limit]);

    useEffect(() => {
        if (attendanceList) {
            setPage(0);
            setAttendanceList({});
            clearTimeout(timer.current);
            timer.current = setTimeout(getRows, 500);
        }
    }, [search]);


    const getCSVDate = async () => {
        const dateForExport = await api.getAllPointHistoriesStream({
            dateRange: dateRange,
            search,
            order_by: orderBy,
            order,
            limit,
            page,
            type: "",
            attendanceRule: true,
        })
        if (!dateForExport) {
            return null
        } else {
            return _.flatten(dateForExport)
        }
    }

    const doExport = async () => {
        var data = await getCSVDate();
        utils.exportCsv(data, [
            { label: t('created_at'), value: row => new Date(row.created_at).toLocaleString() },
            { label: t('attendancerule_name'), value: row => row.attendanceRule?.name },
            { label: t('class'), value: row => row.profile?.class },
            { label: t('class_no'), value: row => row.profile?.class_no },
            { label: t('name_zh'), value: row => row.user?.name?.zh },
            { label: t('name_en'), value: row => row.user?.name?.en },
            { label: t('state'), value: row => t(row.type) },
            {
                label: t('action_created_by'),
                value: row => row.created_by?.name?.[getLanguage()]
            },
        ], `attendance_history`)
    }
    const { mutate: handleExport, ...handleExportMutation } =
        useMutation(doExport);

    if (isLoading) {
        return <LoadingView></LoadingView>;
    }
    return (
        <LayoutContainer title={t('attendance_title')}>
            {(handleExportMutation.isLoading) && (
                <>
                    <Alert variant="standard" severity="info" sx={{ mb: 2 }}>
                        {t('exporting')}
                    </Alert>
                </>
            )}
            <Grid
                container
                spacing={1}
                sx={{
                    mb: 2,
                    '& .MuiGrid-item': {
                        pt: 1.5
                    }
                }}
            >
                <Grid item>
                    <CustomDatePicker selectedDate={dateRange} setSelectedDate={setDateRange} readOnly
                        views={['month', 'year']} inputFormat='yyyy-MM' sx={{ width: '200px' }} />
                </Grid>
                <Grid item>
                    <TextField
                        label={t('name')}
                        size="small"
                        onChange={handleSearch('name')}
                        sx={{ width: '200px' }}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        label={t('class')}
                        size="small"
                        onChange={handleSearch('class')}
                        sx={{ width: '200px' }}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        label={t('class_no')}
                        size="small"
                        onChange={handleSearch('class_no')}
                        sx={{ width: '200px' }}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        label={t('action_created_by')}
                        size="small"
                        onChange={handleSearch('created_by')}
                        sx={{ width: '200px' }}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        label={t('attendancerule_name')}
                        size="small"
                        onChange={handleSearch('attendance_rule_name')}
                        sx={{ width: '200px' }}
                    />
                </Grid>
                <Grid item>
                    <FormControl size="small"
                        sx={{ width: '200px' }}>
                        <InputLabel >{t('state')}</InputLabel>
                        <Select

                            value={search.type}
                            label={t('state')}
                            onChange={handleSearch('type')}
                        >
                            <MenuItem value={""}>
                                {t('see_all')}
                            </MenuItem>
                            <MenuItem value={"checkin"}>{t('checkin')}</MenuItem>
                            <MenuItem value={"checkout"}>{t('checkout')}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs className="flex ac" justifyContent="flex-end">
                    <Button
                        sx={{ ml: 1 }}
                        onClick={handleExport}
                        disabled={handleExportMutation.isLoading}
                    >
                        {t('export')}
                    </Button>
                </Grid>
            </Grid>

            <PagingTable rowClassName={row => classNames({ 'text-green': row.type === 'checkin' })}
                total={total}
                rows={attendanceList[page]}
                // rowsRef={rowsRef} rows={utils.searchFilter(attendanceList, search, [
                //     'profile.class',
                //     'profile.class_no',
                //     'user.name.en',
                //     'user.name.zh',
                //     'attendanceRule.name',
                //     (row) => t(row.type || ''),
                //     // (row) => new Date(row.created_at).toLocaleString()
                // ])}
                heads={[
                    // { id: 'u_id', label: t('u_id'), value: 'profile.u_id', sort: true },
                    { id: 'created_at', label: t('created_at'), value: 'created_at', content: (row) => new Date(row.created_at).toLocaleString(), sort: true },
                    { id: 'attendancerule_name', label: t('attendancerule_name'), value: 'attendanceRule.name', sort: true },
                    { id: 'class', label: t('class'), value: 'profile.class', sort: true },
                    { id: 'class_no', label: t('class_no'), value: 'profile.class_no', sort: true },
                    { id: 'name_zh', label: t('name_zh'), value: 'user.name.zh', sort: true },
                    { id: 'name_en', label: t('name_en'), value: 'user.name.en', sort: true },
                    // { id: 'deviceName', label: t('deviceName'), value: 'device.name', sort: true },
                    { id: 'state', label: t('state'), value: 'type', translate: 1, sort: true },
                    {
                        id: 'created_by',
                        label: t('action_created_by'),
                        value: `created_by.name.${getLanguage()}`
                    },
                ]}
                {...pagination}
            />
        </LayoutContainer >
    );
}

export default AttendanceHistory;