const devUrl = 'http://localhost:5001';
// const devUrl = 'https://pointant-class.azurewebsites.net';
// const devUrl = 'https://pointant-class-dev-hfehaaekeyf3h9bt.eastasia-01.azurewebsites.net';
module.exports = {
  env: process.env.NODE_ENV,
  extenalURL: {
    gameMC: 'https://pointantclassgame.blob.core.windows.net/mc1/index.html#',
    gameInteractive:
      'https://interactivequiz.z7.web.core.windows.net/admin/pointant_class'
  },
  server: {
    url:
      process.env.NODE_ENV === 'development'
        ? devUrl
        : 'https://pointant-class.azurewebsites.net'
  }
};
