import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, IconButton, MenuItem, Paper, TextField, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import useApi from 'src/api/api';
import DataTable from 'src/components/DataTable/DataTable';
import Icons from 'src/icons';
import LayoutContainer from 'src/layout/LayoutContainer';
import { useAuthData } from 'src/provider/AuthDataProvider';
import { useLocale } from 'src/provider/LocaleProvider';
import classNames from 'classnames';
import EditDialog from 'src/components/EditDialog';
import { useFormik } from 'formik';
import formikProps from 'src/utils/formikProps';
import ClassSelector from 'src/components/ClassSelector';
import TransferTable from 'src/components/TransferTable';
import utils from 'src/utils/utils';
import { useSnack } from 'src/provider/SnackbarProvider';
import SearchInput from 'src/components/SearchInput';
const prettyBytes = require('pretty-bytes');
const moment = require('moment-timezone')
const yup = require('yup')

const MemoPublish = () => {

    const { selectedPeriod, currentPeriod } = useAuthData()
    const { t, translate, getLanguage } = useLocale()
    const [studentList, setStudentList] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState([]);
    const api = useApi()
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState();
    const [memo, setMemo] = useState()
    const snack = useSnack()


    const arr = [
        { id: 'class', label: t('class'), value: 'class' },
        { id: 'class_no', label: t('class_no'), value: 'class_no' },
        { id: 'name', label: t('name'), value: `name.${getLanguage()}` },
    ]



    const nav = useNavigate()

    useEffect(() => {
        api.getStudentList().then(res => {
            
            setSelectedStudent([])
            setStudentList(res.data)
        })
    }, [selectedPeriod]);


    const formik = useFormik({
        initialValues: {
            memo: {
                ...memo,
                target: 'student'
            },
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            memo: yup.object({
                title: yup.string().required().nullable(),
                message: yup.string().required().nullable(),
            })
        }),
        onSubmit: (value, helper) => {
            api.addMemo({ ...value.memo, period: currentPeriod, to_student: _.includes(value.memo.target, 'student'), to_parent: _.includes(value.memo.target, 'parent') }).then(res => {

                snack.open('created')
                nav("/admin/memo")
            }).catch(e => {
                snack.error('failed')
                helper.setSubmitting(false)
            })

        }
    })

    useEffect(() => {
        formik.setFieldValue('memo.profile_ids', _.map(selectedStudent, s => s.profile))
    }, [selectedStudent]);

    // const handleCheckBoxChange = (e, value) => {
    //     formik.setFieldValue(`target.${e.target.name}`, value)
    // }

    const classFitered = useCallback(utils.searchFilter(studentList, [search, filter?.value], [['class', 'class_no', 'name'], ['class']]), [studentList, filter, search])

    return (
        <LayoutContainer title={t('edit_memo')} backSrc={'/admin/memo'}>
            <form onSubmit={formik.handleSubmit}>
                <TextField label={t('title')} name='title' {...formikProps(formik, 'memo.title')} fullWidth style={{ marginBottom: '16px' }} />
                <TextField label={t('message')} name='message' multiline {...formikProps(formik, 'memo.message')} fullWidth style={{ marginBottom: '16px' }} inputProps={{
                    style: { minHeight: '72px' }
                }} />
                {/* <OrganizationFilterSelector value={_.get(formik.values, 'memo.filters')} setValue={(v) => formik.setFieldValue('memo.filters', v)} style={{ marginBottom: '16px' }} /> */}
                <TextField
                    select
                    fullWidth
                    label={t('target')}
                    {...formikProps(formik, 'memo.target')}
                    sx={{ mb: 2 }}
                >
                    <MenuItem value={'student'}> {t('student')} </MenuItem>
                    <MenuItem value={'parent'}> {t('admin')} </MenuItem>
                    <MenuItem value={'student_and_parent'}> {t('student_and_parent')} </MenuItem>
                </TextField>

                <Box>
                    <Typography mb={2} variant='h4'>{t('choose_student')}</Typography>
                    <Paper style={{ padding: '24px' }}>

                        <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item>
                                <ClassSelector selected={filter} setSelected={setFilter} />
                            </Grid>
                            <Grid item>
                                <SearchInput setSearch={setSearch} />
                            </Grid>

                        </Grid>

                        <TransferTable list={classFitered} selected={selectedStudent} setSelected={setSelectedStudent}
                            leftHeads={useCallback(arr, [t, getLanguage])}
                            rightHeads={useCallback(arr, [t, getLanguage])}
                        />

                    </Paper>
                </Box>
                <Box display='flex' mb={2} mt={2}>
                    <Box flexGrow={1} />
                    <Button sx={{ mr: 1 }} color='primary' variant='contained' onClick={() => {
                        nav('/admin/memo')
                    }}>
                        {t('back')}
                    </Button>
                    <Button color='primary' disabled={formik.isSubmitting} variant='contained' onClick={formik.handleSubmit}>
                        {t('publish')}
                    </Button>
                </Box>
            </form>
        </LayoutContainer >

    );
}

export default MemoPublish;