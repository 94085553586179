import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import useApi from 'src/api/api';
import ClassSelector from 'src/components/ClassSelector';
import LoadingView from 'src/components/LoadingView';
import SearchInput from 'src/components/SearchInput';
import TransferTable from 'src/components/TransferTable';
import Icons from 'src/icons';
import LayoutContainer from 'src/layout/LayoutContainer';
import { useAuthData } from 'src/provider/AuthDataProvider';
import { useLocale } from 'src/provider/LocaleProvider';
import { useSnack } from 'src/provider/SnackbarProvider';
import utils from 'src/utils/utils';
const _ = require('lodash');
const yup = require('yup');
const useStyles = makeStyles({
  table: {
    '& .MuiTableRow-root': {
      height: '50px'
    },
    '& .MuiTableCell-root': {
      whiteSpace: 'nowrap',
      textAlign: 'center'
    },
    '& .MuiTableBody-root .pointer': {
      cursor: 'pointer'
    },
    '& .MuiTableSortLabel-icon': {
      opacity: 0.5
    },
    '& .Mui-active .MuiTableSortLabel-icon': {
      opacity: 1,
      color: 'limegreen'
    },
    '& .MuiCheckbox-root': {
      padding: 0
    },
    '& .MuiTableHead-root': {
      outline: '1px rgba(200, 200, 200, 1) solid',
      position: 'sticky',
      top: 0,
      backgroundColor: 'white',
      zIndex: 10
    }
  },
  checked: {
    backgroundColor: 'rgba(50, 255, 50,0.3)',
    '&:hover': {
      backgroundColor: 'rgba(50, 255, 50,0.4) !important'
    }
  }
});

const RedeemTransferTable = ({
  list,
  selectedStudent,
  selected,
  setSelected = new Function()
}) => {
  const classes = useStyles();
  const snack = useSnack();
  const { t, translate, getLanguage } = useLocale();

  console.log('hello');

  const student_point = _.get(selectedStudent, 'point', 0);
  const totalRedeemPoints = _.toArray(selected).reduce(
    (a, b) => a + b.point * b.count,
    0
  );

  const handleMoveRight = (row) => {
    if (totalRedeemPoints + row.point > student_point) {
      snack.error(t('lack_point'));
      return;
    }
    var temp = _.toArray(selected);
    var index = _.findIndex(temp, { _id: row._id });
    if (index < 0) {
      temp.push({ ...row, count: 1 });
    } else {
      _.set(temp, `${index}.count`, _.get(temp, `${index}.count`, 0) + 1);
    }

    setSelected(temp);
  };

  const handleMoveLeft = (row) => {
    var temp = _.toArray(selected);
    var index = _.findIndex(temp, { _id: row._id });
    var count = _.get(temp, `${index}.count`, 1);
    if (count > 1) {
      _.set(temp, `${index}.count`, count - 1);
    } else {
      temp.splice(index, 1);
    }
    setSelected(temp);
  };

  const handleClearRow = (row) => {
    var temp = _.toArray(selected);
    var index = _.findIndex(temp, { _id: row._id });
    temp.splice(index, 1);
    setSelected(temp);
  };

  const handleClearAll = (row) => {
    setSelected([]);
  };

  if (!selectedStudent) {
    return (
      <Card
        className={'flex jcc ac'}
        sx={{ height: '500px', width: '100%', fontSize: '60px' }}
      >
        {t('no_selected_student')}
      </Card>
    );
  }

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} lg={6}>
        <Card>
          <Box className="flex ac" p={1}>
            <Typography varient="h5" color="textSecondary" sx={{ p: 1 }}>
              {`${_.size(list)} ${t('choices')}`}
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ height: '500px', overflow: 'auto', width: '100%' }}>
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow sx={{ height: '30px' }}>
                  <TableCell> {t('rule_name')} </TableCell>
                  <TableCell> {t('need_point')} </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {_.map(_.orderBy(list, 'name', 'asc'), (row, index) => (
                  <TableRow
                    hover
                    key={index}
                    className="pointer"
                    onClick={() => handleMoveRight(row)}
                  >
                    <TableCell> {_.get(row, 'name', '-')} </TableCell>
                    <TableCell> {_.get(row, 'point', '0')} </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Card>
          <Box className="flex ac" p={1}>
            <Typography varient="h5" color="textSecondary" sx={{ p: 1 }}>
              {`${selectedStudent.class} (${
                selectedStudent.class_no
              }) ${translate(selectedStudent.name)} ${t('point_used', {
                a: totalRedeemPoints,
                b: student_point - totalRedeemPoints
              })}`}
            </Typography>
            <Box flexGrow={1} />
            <Button
              variant="outlined"
              size="big"
              disabled={_.size(selected) <= 0}
              onClick={handleClearAll}
            >
              {t('clear')}
            </Button>
          </Box>
          <Divider />
          <Box sx={{ height: '500px', overflow: 'auto', width: '100%' }}>
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow sx={{ height: '30px' }}>
                  <TableCell> {t('name')} </TableCell>
                  <TableCell> {t('need_point')} </TableCell>
                  <TableCell> {t('count')} </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {_.map(_.orderBy(selected, 'name', 'asc'), (row, index) => (
                  <TableRow hover key={index}>
                    <TableCell> {_.get(row, 'name', '-')} </TableCell>
                    <TableCell> {_.get(row, 'point', '0')} </TableCell>
                    <TableCell> {_.get(row, 'count', '0')} </TableCell>
                    <TableCell width="100px">
                      <IconButton sx={{ p: 0.5 }}>
                        <Icons.RemoveIcon onClick={() => handleMoveLeft(row)} />
                      </IconButton>
                      <IconButton sx={{ p: 0.5 }}>
                        <Icons.DeleteIcon onClick={() => handleClearRow(row)} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

const ByRules = ({ studentList, setStudentList, selectedPeriod, rules }) => {
  const snack = useSnack();
  const classes = useStyles();
  const api = useApi();
  const { t, translate, getLanguage } = useLocale();
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState();

  const formik = useFormik({
    initialValues: {
      profile: '',
      pointRules: [],
      selectedStudent: null,
      selectedRules: []
    },
    validateOnMount: true,
    validationSchema: yup.object().shape({
      profile: yup.string().required(),
      pointRules: yup
        .array()
        .min(1)
        .of(
          yup.object({
            rule: yup.string().required(),
            count: yup.number().min(1)
          })
        )
    }),
    onSubmit: (values, helper) => {
      api
        .studentRedeem(values)
        .then((res) => {
          snack.open(t('success_redeem'));
          console.log('api.studentRedeem => helper', helper);
          getStudentData(helper);
        })
        .catch((e) => {
          snack.error(t('fail_redeem'));
        })
        .finally(() => {
          helper.setSubmitting(false);
        });
    }
  });

  const { values, errors, setFieldValue } = formik;

  function getStudentData(helper) {
    if (!selectedPeriod) return;
    api.getStudentList().then((res) => {
      setStudentList(res.data);
      if (helper) {
        helper.resetForm();
        helper.validateForm();
      } else {
        formik.resetForm();
        formik.validateForm();
      }
    });
  }

  useEffect(() => {
    setFieldValue('profile', values.selectedStudent?.profile);
  }, [values.selectedStudent]);

  useEffect(() => {
    setFieldValue(
      'pointRules',
      _.map(values.selectedRules, (r) => ({ rule: r._id, count: r.count }))
    );
  }, [values.selectedRules]);

  // var classFiltered = utils.searchFilter(studentList, filter?.value, ['class'])

  var studentFiltered = useMemo(
    () =>
      utils.searchFilter(
        studentList,
        [filter?.value, search],
        [['class'], ['name.en', 'name.zh', 'class', 'class_no']]
      ),
    [studentList, search, filter]
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant="h3" paragraph>
        {t('choose_student')}
      </Typography>
      <Grid container spacing={1} sx={{ mb: 2 }}>
        <Grid item>
          <ClassSelector selected={filter} setSelected={setFilter} />
        </Grid>
        <Grid item>
          <SearchInput setSearch={setSearch} />
        </Grid>
      </Grid>
      <Card elevation={2} sx={{ mb: 3, maxWidth: '800px' }}>
        <List component="div" sx={{ pr: 2 }}>
          <ListItem style={{ height: '40px' }}>
            <ListItemText primary={t('class')} style={{ flexBasis: '20%' }} />
            <ListItemText
              primary={t('class_no')}
              style={{ flexBasis: '20%' }}
            />
            <ListItemText primary={t('name')} style={{ flexBasis: '40%' }} />
            <ListItemText
              primary={t('point_balance')}
              style={{ textAlign: 'right', flexBasis: '20%' }}
            />
          </ListItem>
        </List>
        <Divider />
        <List component="div" sx={{ overflowY: 'scroll', maxHeight: '240px' }}>
          {_.map(studentFiltered, (student, index) => {
            var className = classNames({
              [classes.checked]: student.profile == formik.values.profile
            });
            return (
              <ListItem
                className={className}
                key={student._id}
                button
                onClick={() => setFieldValue('selectedStudent', student)}
              >
                <ListItemText
                  primary={student.class || '-'}
                  style={{ flexBasis: '20%' }}
                />
                <ListItemText
                  primary={student.class_no || '-'}
                  style={{ flexBasis: '20%' }}
                />
                <ListItemText
                  primary={translate(student.name || '-')}
                  style={{ flexBasis: '40%' }}
                />
                <ListItemText
                  primary={student.point || '0'}
                  style={{ textAlign: 'right', flexBasis: '20%' }}
                />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Card>
      {values.selectedStudent && (
        <>
          <Typography variant="h3" paragraph>
            {t('choose_rule')}
          </Typography>
          <Grid container spacing={1} sx={{ mb: 2 }}>
            <Grid item>
              <SearchInput setSearch={setSearch} />
            </Grid>
          </Grid>
          <RedeemTransferTable
            selectedStudent={values.selectedStudent}
            list={utils.searchFilter(rules, search, ['name'])}
            selected={values.selectedRules}
            setSelected={(v) => setFieldValue('selectedRules', v)}
          />
        </>
      )}
      <Box className="flex ac" justifyContent="flex-end" mt={2}>
        <Button
          type="submit"
          disabled={
            _.size(formik.errors) > 0 ||
            !values.selectedStudent ||
            formik.isSubmitting
          }
        >
          {t('confirm')}
        </Button>
      </Box>
    </form>
  );
};

const ByProfiles = ({ studentList, setStudentList, selectedPeriod, rules }) => {
  const snack = useSnack();
  const classes = useStyles();
  const api = useApi();
  const { t, translate, getLanguage } = useLocale();
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState();
  const [selectedRule, setSelectedRule] = useState();
  const [selectedStudent, setSelectedStudent] = useState([]);

  const formik = useFormik({
    initialValues: {
      selectedCategory: '',
      pointRule_id: '',
      counts: 1,
      profiles: []
    },
    validationSchema: yup.object().shape({
      pointRule_id: yup.string().required(),
      profiles: yup.array().min(1).of(yup.string().required()).required(),
      counts: yup.number().min(1)
    }),
    onSubmit: (values, helper) => {
      api
        .studentRedeemByProfile(values)
        .then((res) => {
          snack.open(t('success_redeem'));
          api.getStudentList().then((res) => {
            setStudentList(res.data);
            setSelectedRule(null);
            setSelectedStudent([]);
            helper.resetForm();
          });
        })
        .catch((e) => {
          snack.error(t('fail_redeem'));
        })
        .finally(() => {
          helper.setSubmitting(false);
        });
    }
  });

  useEffect(() => {
    api.getStudentList().then((res) => {
      setStudentList(res.data);
      formik.resetForm();
      setSelectedRule(null);
      setSelectedStudent([]);
    });
  }, [selectedPeriod]);

  useEffect(() => {
    formik.setFieldValue(
      'profiles',
      _.map(selectedStudent, (s) => s.profile)
    );
  }, [selectedStudent]);

  useEffect(() => {
    setSelectedStudent([]);
    if (selectedRule) formik.setFieldValue('pointRule_id', selectedRule?._id);
  }, [selectedRule]);

  var classFiltered = useMemo(
    () => utils.searchFilter(studentList, filter?.value, ['class']),
    [studentList, filter?.value]
  );
  var ruleFiltered = utils.searchFilter(
    rules,
    [formik.values.selectedCategory, search],
    [['ruleCategory._id'], ['name']]
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant="h3" paragraph>
        {t('choose_rule')}
      </Typography>
      <Grid container spacing={1} sx={{ mb: 2 }}>
        <Grid item sx={{ flex: 'auto' }}>
          <SearchInput setSearch={setSearch} />
        </Grid>
      </Grid>
      <Card elevation={2} sx={{ mb: 3, maxWidth: '800px' }}>
        <List
          className={classes.list}
          component="div"
          role="list"
          sx={{ pr: 2 }}
        >
          <ListItem style={{ height: '40px' }}>
            <ListItemText
              primary={t('rule_name')}
              style={{ flexBasis: '70%' }}
            />
            <ListItemText
              primary={t('point')}
              style={{ textAlign: 'right', flexBasis: '30%' }}
            />
          </ListItem>
        </List>
        <Divider />
        <List
          className={classes.list}
          component="div"
          role="list"
          sx={{ overflowY: 'scroll', maxHeight: '240px' }}
        >
          {_.map(ruleFiltered, (rule, index) => {
            var className = classNames({
              [classes.checked]: rule._id == formik.values.pointRule_id
            });
            return (
              <ListItem
                className={className}
                key={rule._id}
                button
                onClick={() => setSelectedRule(rule)}
              >
                <ListItemText
                  primary={rule.name}
                  style={{ flexBasis: '70%' }}
                />
                <ListItemText
                  primary={rule.point}
                  style={{ textAlign: 'right', flexBasis: '30%' }}
                />
              </ListItem>
            );
          })}
          {_.size(ruleFiltered) == 0 && (
            <ListItem>
              <ListItemText
                primary={t('no_record')}
                style={{ textAlign: 'center', flexBasis: '30%' }}
              />
            </ListItem>
          )}
          <ListItem />
        </List>
      </Card>
      {selectedRule?.name && (
        <>
          <Typography variant="h3" paragraph>
            {t('choose_student')}
          </Typography>
          <Grid container spacing={1} sx={{ mb: 2 }}>
            <Grid item>
              <ClassSelector selected={filter} setSelected={setFilter} all />
            </Grid>
            <Grid item sx={{ flex: 'auto' }}>
              <SearchInput setSearch={setSearch} />
            </Grid>
          </Grid>
          <TransferTable
            list={utils.searchFilter(classFiltered, search, [
              'class',
              'class_no',
              'name'
            ])}
            selected={selectedStudent}
            setSelected={setSelectedStudent}
            leftHeads={[
              { id: 'class', label: t('class'), value: 'class' },
              { id: 'class_no', label: t('class_no'), value: 'class_no' },
              { id: 'name', label: t('name'), value: `name.${getLanguage()}` },
              {
                id: 'point',
                label: t('point'),
                value: `point`,
                placeHolder: '0'
              }
            ]}
            rightHeads={[
              { id: 'class', label: t('class'), value: 'class' },
              { id: 'class_no', label: t('class_no'), value: 'class_no' },
              { id: 'name', label: t('name'), value: `name.${getLanguage()}` },
              {
                id: 'point',
                label: t('point'),
                value: `point`,
                placeHolder: '0'
              }
            ]}
            isValid={(row) => {
              return row?.point >= selectedRule.point;
            }}
            onRowInvalid={(row) => {
              snack.error(t('lack_point_msg', { count: _.size(row) }));
            }}
          />
        </>
      )}
      <Box className="flex ac" justifyContent="flex-end" mt={2}>
        {/* {selectedRule?.name &&
                    <>
                        <Typography variant='h5' sx={{ mr: 1 }} >
                            {t('reward_confirm_message', { a: _.size(selectedStudent), b: selectedRule?.name, c: formik.values.counts })}
                        </Typography>
                        <TextField
                            select
                            size='small'
                            label={t('count')}
                            {...formikProps(formik, 'counts')}
                            sx={{ width: '100px', mr: 1 }}
                        >
                            {_.times(5, n => <MenuItem key={n} value={n + 1}> {n + 1} </MenuItem>)}
                        </TextField>
                    </>
                } */}
        <Button
          type="submit"
          disabled={
            _.size(formik.errors) > 0 || !selectedRule || formik.isSubmitting
          }
        >
          {t('confirm')}
        </Button>
      </Box>
    </form>
  );
};

const PointRedeem = () => {
  const classes = useStyles();
  const api = useApi();
  const { t, translate, getLanguage } = useLocale();
  const [rules, setRules] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const { selectedPeriod } = useAuthData();
  const [tab, setTab] = useState('rules');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    api.getPointRules({ isActive: true }).then((res) => {
      setRules(
        _.filter(
          res.data,
          (r) => r.type == 'redeem' || r.type == 'virtual_redeem'
        )
      );
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    api.getStudentList().then((res) => {
      setStudentList(res.data);
    });
  }, [selectedPeriod]);
  if (isLoading) {
    return <LoadingView></LoadingView>;
  }

  return (
    <LayoutContainer title={t('redeem_prizes')}>
      <Card sx={{ mb: 2, borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={(e, v) => setTab(v)}>
          <Tab label={t('redeem_prize_by_rules')} value={'rules'} />
          <Tab label={t('redeem_prize_by_profiles')} value={'profiles'} />
        </Tabs>
      </Card>
      <div style={{ display: tab == 'rules' ? 'block' : 'none' }}>
        <ByRules
          studentList={studentList}
          setStudentList={setStudentList}
          rules={rules}
          selectedPeriod={selectedPeriod}
        />
      </div>
      <div style={{ display: tab == 'profiles' ? 'block' : 'none' }}>
        <ByProfiles
          studentList={studentList}
          setStudentList={setStudentList}
          rules={rules}
          selectedPeriod={selectedPeriod}
        />
      </div>
    </LayoutContainer>
  );
};

export default PointRedeem;
