import { useState } from 'react'
import { Link as RouterLink, NavLink, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Tooltip,
  Typography
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined'
import InputIcon from '@material-ui/icons/Input'
import Logo from '../Logo'
import { useAuthData } from 'src/provider/AuthDataProvider'
import { useLocale } from 'src/provider/LocaleProvider'
import PeriodSelector from 'src/components/PeriodSelector'
import Icons from 'src/icons'

const DashboardNavbar = ({ onMobileNavOpen, ...rest }) => {
  const navigate = useNavigate()
  const { t } = useLocale()
  const { translate } = useLocale()

  const handleLogout = () => {
    localStorage.removeItem('token')
    navigate('/sign-in', { replace: true })
  }

  const { organization, userInfo } = useAuthData()
  const checkRole = (userInfo) => {
    if (!userInfo)
      return ""
    if (userInfo.role === 'user' && userInfo.isParent) 
      return (t('login_label_parent'))
    if (userInfo.role === 'admin' || userInfo.role ==='organization_admin')
      return (t('login_label_admin'))

    return (t('login_label_user'))
  }

  return (
    <AppBar
      elevation={0}
      // style={{ zIndex: 1300 }}
      {...rest}
    >
      <Toolbar style={{ pl: 0 }}>
        <Hidden mdUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
            sx={{ ml: -2, mr: 0.5 }}
          >
            <MenuIcon sx={{ height: 30, width: 30 }} />
          </IconButton>
        </Hidden>
        <NavLink to={userInfo?.role == 'user' ? '/user' : '/admin'}>
          <Logo />
        </NavLink>
        <Typography>{checkRole(userInfo)}</Typography>
        <Box sx={{ flexGrow: 1 }} />
        {organization.logo && <Avatar alt='logo' src={organization.logo} sx={{ height: '42px', width: '42px', mr: 1, border: '1px solid #E4E6EB' }} />}
        <Box pr={1} sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Typography
            variant="h4"
            color="inherit"
          >
            {translate(organization.name)}
          </Typography>
        </Box>
        <Tooltip title={t('help_button')}>
          <IconButton target='_blank' href='https://docs.animaetech.com/zh-tw/pointant-class' color="inherit" sx={{ backgroundColor: '#E4E6EB', height: '32px', width: '32px', ml: 1 }}>
            <Icons.IoHelpSharp style={{ color: 'black' }} />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  )
}

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func
}

export default DashboardNavbar
