

import React, { Fragment, useMemo, useCallback, useEffect, useState, useRef, useLayoutEffect } from 'react'
import useApi from 'src/api/api';
import LayoutContainer from 'src/layout/LayoutContainer';
import { useLocale } from 'src/provider/LocaleProvider';
import { Backdrop, Box, Container, Grid, Card, CardContent, Typography, CardHeader, Button, Divider, IconButton, Table, TableHead, TableCell, TableBody, TableRow, CheckBox, Checkbox } from '@material-ui/core';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';


const useStyles = makeStyles({
    table: {
        "& .MuiTableRow-root": {
            height: '30px'
        },
        "& .MuiTableCell-root": {
            whiteSpace: 'nowrap',
            textAlign: 'center'
        },
        "& .MuiTableSortLabel-icon": {
            opacity: 0.5,
        },
        "& .Mui-active .MuiTableSortLabel-icon": {
            opacity: 1,
            color: 'limegreen',
        },
        "& .MuiCheckbox-root": {
            padding: 0
        },
        "& .MuiTableHead-root": {
            outline: '1px rgba(200, 200, 200, 1) solid',
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            zIndex: 10,
        },
    },
    checked: {
        backgroundColor: 'rgba(50, 255, 50,0.3)',
        '&:hover': {
            backgroundColor: 'rgba(50, 255, 50,0.4) !important'
        }
    }
})



const LeftTable = ({ heads, rows, selected, setSelected, placeHolder = '-', isValid, onRowInvalid }) => {
    const classes = useStyles()
    const { t } = useLocale()
    const [checked, setChecked] = useState([]);
    const isChecked = (id) => _.findIndex(checked, { _id: id }) >= 0


    useEffect(() => {
        setChecked(_.filter(checked, c => _.findIndex(rows, { _id: c.id }) >= 0))
    }, [rows]);

    const handleCheck = (row) => {
        var newChecked = _.toArray(checked)
        if (isChecked(row._id)) {
            _.pull(newChecked, row)
        }
        else {
            newChecked.push(row)
        }
        setChecked(newChecked)
    }

    const handleCheckAll = (row) => {
        var newChecked = _.toArray(checked)
        if (_.size(checked) <= 0) {
            newChecked = rows
        }
        else if (_.size(checked) >= _.size(rows)) {
            newChecked = []
        }
        else {
            newChecked = []
        }
        setChecked(newChecked)
    }


    const handleMoveRight = () => {
        var input = _.groupBy(checked, c => isValid(c))
        setSelected(_.concat(selected, _.intersection(input['true'], rows)))
        if (input['false'] && input['false'].length > 0) {
            onRowInvalid(input['false'])
        }
        setChecked([])
    }

    return (
        <Card>
            <Box className='flex ac' p={1} >
                <Typography varient='h5' color='textSecondary' sx={{ p: 1 }} >
                    {`${_.size(checked)}/${_.size(rows)} ${t('Reward.selected')}`}
                </Typography>
                <Box flexGrow={1} />
                <Button variant="outlined" size="big" disabled={_.size(checked) <= 0} onClick={handleMoveRight} >
                    {'+'}
                </Button>
            </Box>
            <Divider />
            <Box sx={{ height: '500px', overflow: 'auto', width: '100%' }}>
                <Table size='small' className={classes.table}>
                    <TableHead>
                        <TableRow sx={{ height: '30px' }}  >
                            {
                                _.map(heads, cell => (
                                    <TableCell key={cell.id} sx={cell.sx}>
                                        {_.get(cell, 'label')}
                                    </TableCell>
                                ))
                            }
                            <TableCell>
                                <Checkbox
                                    disabled={_.size(rows) === 0}
                                    indeterminate={_.size(checked) > 0 && _.size(checked) < _.size(rows) && _.size(rows) > 0}
                                    checked={_.size(checked) == _.size(rows) && _.size(rows) > 0}
                                    onClick={handleCheckAll}
                                />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {_.map(rows, (row, index) => (
                            <TableRow
                                hover
                                key={index}
                                onClick={() => handleCheck(row)}
                                className={classNames({ [classes.checked]: isChecked(row._id) })}

                            >
                                {_.map(heads, cell => {
                                    var render = cell.content ? cell.content(row, index) : _.get(row, cell.value)
                                    return (
                                        <TableCell key={cell.id + index} width={cell.width} sx={cell.sx}>
                                            {!render || render == '' ? cell.placeHolder || placeHolder : render}
                                        </TableCell>
                                    )
                                })}
                                <TableCell>
                                    <Checkbox
                                        checked={isChecked(row._id)}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        </Card >
    )
}

const Rightable = ({ heads, rows, placeHolder = '-', selected, setSelected }) => {
    const classes = useStyles()
    const { t } = useLocale()

    const [checked, setChecked] = useState([]);

    const isChecked = (id) => _.findIndex(checked, { _id: id }) >= 0

    const handleCheck = (row) => {
        var newChecked = _.toArray(checked)
        if (isChecked(row._id)) {
            _.pull(newChecked, row)
        }
        else {
            newChecked.push(row)
        }

        setChecked(newChecked)
    }

    const handleCheckAll = (row) => {
        var newChecked = _.toArray(checked)
        if (_.size(checked) <= 0) {
            newChecked = rows
        }
        else if (_.size(checked) >= _.size(rows)) {
            newChecked = []
        }
        else {
            newChecked = []
        }
        setChecked(newChecked)
    }

    const handleMoveLeft = () => {
        setSelected(selected => {
            return _.differenceBy(selected, checked, '_id')
        })
        setChecked([])
    }

    return (
        <Card>
            <Box className='flex ac' p={1} >
                <Typography varient='h5' color='textSecondary' sx={{ p: 1 }}>
                    {`${_.size(checked)}/${_.size(rows)} ${t('Reward.selected')}`}
                </Typography>
                <Box flexGrow={1} />
                <Button variant="outlined" size="big" disabled={_.size(checked) <= 0} onClick={handleMoveLeft} >
                    {'-'}
                </Button>
            </Box>
            <Divider />
            <Box sx={{ height: '500px', overflow: 'auto', width: '100%' }}>
                <Table size='small' className={classes.table}>
                    <TableHead>
                        <TableRow sx={{ height: '30px' }}  >
                            {
                                _.map(heads, cell => (
                                    <TableCell key={cell.id} sx={cell.sx}>
                                        {_.get(cell, 'label')}
                                    </TableCell>
                                ))
                            }
                            <TableCell>
                                <Checkbox
                                    disabled={_.size(rows) === 0}
                                    indeterminate={_.size(checked) > 0 && _.size(checked) < _.size(rows) && _.size(rows) > 0}
                                    checked={_.size(checked) == _.size(rows) && _.size(rows) > 0}
                                    onClick={handleCheckAll}
                                />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {_.map(rows, (row, index) => (
                            <TableRow
                                hover
                                key={index}
                                onClick={() => handleCheck(row)}
                                className={classNames({ [classes.checked]: isChecked(row._id) })}
                            >
                                {_.map(heads, cell => {
                                    var render = cell.content ? cell.content(row, index) : _.get(row, cell.value)
                                    return (
                                        <TableCell key={cell.id + index} width={cell.width} sx={cell.sx}>
                                            {!render || render == '' ? cell.placeHolder || placeHolder : render}
                                        </TableCell>
                                    )
                                })}
                                <TableCell>
                                    <Checkbox

                                        checked={isChecked(row._id)}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        </Card>
    )
}


const TransferTable = ({ list, selected, setSelected = new Function(), leftHeads = [], rightHeads, isValid = () => true, onRowInvalid = new Function() }) => {
    const left = useMemo(() => _.orderBy(_.differenceBy(list, selected, '_id'), 'class', 'asc'), [list, selected])
    const right = useMemo(() => _.orderBy(selected, 'class', 'asc'), [selected])

    return useMemo(() => (
        <Grid container spacing={2} alignItems="center" >
            <Grid item xs={12} lg={6}>
                <LeftTable rows={left} heads={leftHeads} selected={selected} setSelected={setSelected} isValid={isValid} onRowInvalid={onRowInvalid} />
            </Grid>
            <Grid item xs={12} lg={6}>
                <Rightable rows={right} heads={rightHeads || leftHeads} selected={selected} setSelected={setSelected} />
            </Grid>
        </Grid>
    ), [list, selected, setSelected]);
}

export default TransferTable;