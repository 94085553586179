import {
  Box,
  Button,
  Collapse,
  Divider,
  Drawer,
  Hidden,
  List,
  ListItem
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import NavItem from '../NavItem';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useLocale } from 'src/provider/LocaleProvider';

import { makeStyles } from '@material-ui/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import useApi from 'src/api/api';
import Icons from 'src/icons';
import { useAuthData } from 'src/provider/AuthDataProvider';
import NavCategory from '../NavCategory';
import adminCategory from './SidebarCategory/adminCategory';
import organizationAdminCategory from './SidebarCategory/organizationAdminCategory';
import parentCategory from './SidebarCategory/parentCategory';
import studentCategory from './SidebarCategory/studentCategory';

const useStyles = makeStyles({
  item: {
    fontWeight: 'medium',
    // fontSize: '1rem',
    justifyContent: 'flex-start',
    letterSpacing: 0,
    textTransform: 'none',
    width: '100%'
  }
});

const DashboardSidebar = ({ onMobileClose: onClose, openMobile: isOpen }) => {
  const location = useLocation();
  const api = useApi();
  const { t } = useLocale();
  const classes = useStyles();

  const [openState, setOpenState] = useState({});
  const { userInfo, organization } = useAuthData();

  const handleToggleExpand = (index) => {
    setOpenState((s) => ({ ...s, [index]: s[index] ? false : true }));
  };

  const handleExpand = (index, flag = true) => {
    setOpenState((s) => ({ ...s, [index]: flag }));
  };

  const categories = useMemo(() => {
    if (userInfo?.role === 'organization_admin') {
      return organizationAdminCategory(t, organization.modules);
    } else if (userInfo?.role === 'admin') {
      return adminCategory(t, organization.modules);
    } else if (userInfo.isParent) {
      return parentCategory(t, organization.modules);
    } else {
      return studentCategory(t, organization.modules);
    }
  }, [organization.modules, t, userInfo]);

  useEffect(() => {
    if (categories && categories.length > 0) {
      for (
        let category_index = 0;
        category_index < categories.length;
        category_index++
      ) {
        const category = categories[category_index];
        if (category.pages && category.pages.length > 0) {
          for (
            let category_page_index = 0;
            category_page_index < category.pages.length;
            category_page_index++
          ) {
            const page = category.pages[category_page_index];
            if (
              page.href &&
              matchPath(
                {
                  path: page.href,
                  end: true
                },
                location.pathname
              )
            ) {
              handleExpand(category_index);
              return;
            }
          }
        }
      }
    }
  }, [categories, location.pathname]);

  const content = (
    <PerfectScrollbar>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Divider />
        <Box sx={{ px: 2, py: 1 }}>
          <List>
            {categories?.map((category, index) => {
              if (category.show === false) return null;
              if (!category.href && !category.pages) return null;
              if (category.href) {
                return (
                  <Box key={category.name}>
                    <NavCategory
                      href={category.href}
                      title={category.name}
                      icon={category.icon}
                    />
                    {/* <Divider /> */}
                  </Box>
                );
              }
              return (
                <Box key={category.name}>
                  <ListItem
                    disableGutters
                    sx={{
                      display: 'flex',
                      py: 0
                      // border: '1px solid black'
                    }}
                  >
                    <Button
                      variant="text"
                      onClick={() => handleToggleExpand(index)}
                      className={classes.item}
                      sx={{
                        color: 'text.primary',
                        py: 1.25,
                        '& svg': {
                          mr: 1
                        }
                      }}
                    >
                      {category.icon && <category.icon size="20" />}
                      <span>{category.name}</span>
                      <Box sx={{ flexGrow: 1 }} />
                      {openState[index] ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </Button>
                  </ListItem>
                  {category.pages.map((item, page_index) => {
                    if (item.show === false) return null;
                    if (!item.href) return null;
                    return (
                      <Collapse
                        key={page_index}
                        in={openState[index]}
                        timeout="auto"
                        unmountOnExit
                      >
                        <NavItem
                          href={item.href}
                          key={item.title}
                          title={item.title}
                          icon={item.icon}
                        />
                      </Collapse>
                    );
                  })}
                  {/* <Divider /> */}
                </Box>
              );
            })}
            <ListItem
              disableGutters
              sx={{
                display: 'flex',
                py: 0
                // border: '1px solid black'
              }}
            >
              <Button
                variant="text"
                onClick={() => api.logout()}
                className={classes.item}
                sx={{
                  color: 'text.primary',
                  py: 1.25,
                  '& svg': {
                    mr: 1
                  }
                }}
              >
                <Icons.InputIcon />
                <span>{t('sign_out')}</span>
              </Button>
            </ListItem>
            <Box sx={{ flexGrow: 1 }} />
          </List>
          <Box sx={{ flexGrow: '1' }}></Box>
        </Box>
      </Box>
    </PerfectScrollbar>
  );

  return (
    <>
      <Hidden mdUp>
        <Drawer
          anchor="left"
          onClose={onClose}
          open={isOpen}
          variant="temporary"
          // style={{ zIndex: 1100 }}
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)'
              // height: '100%'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default DashboardSidebar;
