import { Box, Container, IconButton, Switch, TextField, Tooltip, Button, MenuItem, Typography, Card, CardHeader, CardContent, CardActions } from '@material-ui/core';
import React, { Fragment, useMemo, useCallback, useEffect, useState, useRef, useLayoutEffect } from 'react'
import useApi from 'src/api/api';
import { Helmet } from 'react-helmet';
import DataTable from 'src/components/DataTable/DataTable';
import { useLocale } from 'src/provider/LocaleProvider';
import { useAuthData } from 'src/provider/AuthDataProvider';
import Icons from 'src/icons';
import EditDialog from 'src/components/EditDialog';
import { useFormik } from 'formik';
import { useSnack } from 'src/provider/SnackbarProvider';
import formikProps from 'src/utils/formikProps';
import SearchInput from 'src/components/SearchInput';
import LayoutContainer from 'src/layout/LayoutContainer';
import LoadingView from 'src/components/LoadingView';
const yup = require('yup')
const _ = require('lodash')
const moment = require('moment-timezone')

const OrganizationPeriodList = () => {
    const api = useApi()
    const { t } = useLocale()
    const [periodList, setPeriodList] = useState([]);

    const [dialogMode, setDialogMode] = useState(null);
    const [selectedRow, setSelectedRow] = useState();



    const auth = useAuthData()
    const snack = useSnack()


    const [activated, setActivated] = useState('');
    const [isLoading, setIsLoading] = useState(true);


    const getData = async () => {
        await api.getPeriodList(setPeriodList)
        setIsLoading(false)
    }

    useEffect(() => {
        setIsLoading(true)
        getData()
    
    }, []);


    useEffect(() => {
        setActivated(auth.currentPeriod)
    }, [auth.currentPeriod]);

    const schema = yup.object().shape({
        name: yup.string().required().default(''),
        isActive: yup.bool().default(false),
        start_at: yup.date().required().default(new Date()),
        end_at: yup.date().required().default(new Date())
    })

    const formik = useFormik({
        initialValues: selectedRow,
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: (values, helper) => {
            if (values._id) {
                api.updatePeriod(values._id, values).then(res => {
                    setDialogMode(null)
                    getData()
                    snack.open()
                }).catch(e => {
                    snack.error()
                }).finally(() => {
                    helper.setSubmitting(false)
                })
            }
            else {
                api.addPeriod(values).then(res => {
                    setDialogMode(null)
                    getData()
                    snack.open()
                }).catch(e => {

                    snack.error()
                }).finally(() => {
                    helper.setSubmitting(false)
                })
            }
        }
    })

    useEffect(() => {
        if (dialogMode) formik.resetForm()
    }, [dialogMode]);

    const handleSwitch = async () => {
        var temp = auth.currentPeriod
        if (!selectedRow._id) return
        if (auth.currentPeriod == selectedRow._id) return
        auth.setCurrentPeriod(selectedRow._id)
        api.activatePeriod(selectedRow._id).then(res => {
            auth.setSelectedPeriod(selectedRow._id)
            auth.refresh()
            setDialogMode(null)
        }).catch(e => {
            auth.setCurrentPeriod(temp)
            snack.error()
        })
    }

    // const handleSwitchId = async (row) => {
    //     if (auth.currentPeriod == activated) return
    //     api.activatePeriod(row?._id).then(res => {
    //         auth.setCurrentPeriod(row._id)
    //         auth.setSelectedPeriod(row._id)
    //         auth.refresh()
    //     }).catch(e => {
    //         snack.error()
    //     })
    // }

    const handleAdd = async (row) => {
        setSelectedRow(schema.cast({}))
        setDialogMode('add')
    }

    const handleEdit = async (row) => {
        setSelectedRow(schema.cast(row || {}))
        setDialogMode('edit')
    }

    const handleDelete = async (row) => {
        setSelectedRow(schema.cast(row || {}))
        setDialogMode('delete')
    }

    if (isLoading) {
        return <LoadingView></LoadingView>;
   }

    return (
        <LayoutContainer title={t('period_title')}>
            <Box display='flex' justifyContent='flex-end' mb={2}>
                {/* <SearchInput setSearch={setSearch} /> */}
                <Box flexGrow={1} />
                <Button onClick={handleAdd}>
                    {t('add_period')}
                </Button>
            </Box>
            <DataTable rows={periodList} heads={[
                { id: 'name', label: (t('name')), value: 'name', sort: true },
                { id: 'start_at', label: (t('start_at')), value: 'start_at', content: (r, i, v) => moment(r.start_at).format('YYYY/MM/DD'), sort: true, },
                { id: 'end_at', label: (t('end_at')), value: 'end_at', content: (r, i, v) => moment(r.end_at).format('YYYY/MM/DD'), sort: true, },
                {
                    id: 'status', label: t('status'), sx: { textAlign: 'center' }, content: (row) => {
                        return (
                            auth.currentPeriod == row._id ? t('active') : t('inactive')
                        )
                    },
                    className: row => auth.currentPeriod == row._id ? 'text-green' : 'text-red'
                },
                {
                    id: 'action', label: t('actions'), content: (row) => {
                        return <Box sx={{ mx: -1 }}>
                            <Tooltip title={t('edit')}>
                                <IconButton onClick={() => handleEdit(row)} sx={{ p: .5 }}> <Icons.EditIcon /> </IconButton>
                            </Tooltip>
                            {/* <IconButton onClick={() => handleDelete(row)} sx={{ px: 0.5, py: 0 }}> <Icons.DeleteForeverIcon /> </IconButton> */}
                        </Box>
                    }
                },
            ]} _orderBy='end_at' _order='desc' />
            <EditDialog
                maxWidth='xs'
                title={t('activate_this_period')}
                open={dialogMode == 'activate'}
                handleClose={() => setDialogMode(null)}
                confirmText={t("confirm")}
                handleSave={handleSwitch}
            >
                {/* <span >{t('confirm_resetpw')}</span> */}
                <p>{t('change_period_to_a', { a: selectedRow?.name })}</p>
                {/* <p>{t('activate_period_msg')}</p> */}
                {/* <span className='bold' style={{ marginLeft: '4px' }}>{`${selectedRow?.class} ${selectedStudent?.class_no}  ${_.get(selectedStudent, `name.${getLanguage()}`)} `}</span> */}
            </EditDialog>
            <EditDialog
                title={dialogMode == 'add' ? t('add_period') : t('edit_period_title')}
                open={dialogMode == 'add' || dialogMode == 'edit'}
                handleSave={formik.handleSubmit}
                handleClose={() => setDialogMode(null)}
                TitleComponent={() => selectedRow && (
                    <Button
                        onClick={() => setDialogMode('activate')}
                        disabled={selectedRow._id == auth.currentPeriod}
                        sx={{
                            backgroundColor: 'limegreen',
                            '&:hover': {
                                backgroundColor: '#2db82d'
                            }
                        }}
                    >
                        {t('activate_this_period')}
                    </Button>
                )}
            >
                <TextField
                    fullWidth
                    label={t('name')}
                    {...formikProps(formik, 'name')}
                    sx={{ mb: 2 }}
                />
                <TextField
                    type="date"
                    label={t('start_at')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    {...formikProps(formik, 'start_at')}
                    value={moment(formik.values?.start_at).format('YYYY-MM-DD')}
                    onChange={e => {
                        formik.setFieldValue('start_at', moment(e.target.value).toDate())
                    }}
                    sx={{ width: { xs: '100%', sm: '50%' }, pr: { xs: 0, sm: 1 }, mb: 2 }}
                />
                <TextField
                    type="date"
                    label={t('end_at')}
                    defaultValue={new Date()}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    {...formikProps(formik, 'end_at')}
                    value={moment(formik.values?.end_at).format('YYYY-MM-DD')}
                    onChange={e => {
                        formik.setFieldValue('end_at', moment(e.target.value).toDate())
                    }}
                    sx={{ width: { xs: '100%', sm: '50%' }, pr: { xs: 0, sm: 1 }, mb: 2 }}
                />
            </EditDialog>
        </LayoutContainer >
    );
}

export default OrganizationPeriodList;