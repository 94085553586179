import { Tabs, Tab,Card, Box, Button, Checkbox, Container, FormControlLabel, IconButton, MenuItem, Switch, TextField, Tooltip } from '@material-ui/core';
import React, { Fragment, useMemo, useCallback, useEffect, useState, useRef, useLayoutEffect } from 'react'
import useApi from 'src/api/api';
import { Helmet } from 'react-helmet';
import DataTable from 'src/components/DataTable/DataTable';
import { useLocale } from 'src/provider/LocaleProvider';
import Icons from 'src/icons';
import EditDialog from 'src/components/EditDialog';
import { Formik, useFormik } from 'formik';
import formikProps from 'src/utils/formikProps';
import utils from 'src/utils/utils';
import LayoutContainer from 'src/layout/LayoutContainer';
import SearchInput from 'src/components/SearchInput';
import { useAuthData } from 'src/provider/AuthDataProvider';
import { NavLink } from 'react-router-dom';
import { status } from 'nprogress';
import LoadingView from 'src/components/LoadingView';
const moment = require('moment-timezone')
const _ = require('lodash')
const yup = require('yup')
const AdminList = ({ snack }) => {
    const api = useApi()
    const { t, getLanguage } = useLocale()
    const rowsRef = useRef([])

    const { userInfo } = useAuthData()

    const [dialogMode, setDialogMode] = useState(null);
    const [selectedStudent, setSelectedStudent] = useState();
    const [tab, setTab] = useState('active');
    const [categories, setCategories] = useState();
    const [activeCategories, setActiveCategories] = useState();

    const [adminList, setAdminList] = useState([]);
    const [search, setSearch] = useState('');
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        setIsLoading(true);
        api.getAdminList().then(res => {
            setCategories(_.filter(res.data, ['isActive', false]))
            setActiveCategories(_.filter(res.data, ['isActive', true]))
            setIsLoading(false);
            //setAdminList(res.data)
            // console.log(categories)
            // console.log(activeCategories)
            // console.log(adminList)
        })
    }, []);



    const schema = yup.object().shape({
        name: yup.object({
            en: yup.string(),
            zh: yup.string(),
        }),
        email: yup.string().email().required(),
        role: yup.string()
    })


    const formik = useFormik({
        initialValues: selectedStudent || {
            name: {
                en: '',
                zh: ''
            },
            email: '',
            role: 'admin',
            isActive: true,
            privilege: { all_action: false }

        },
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: (values, helper) => {
            if (values._id) {
                api.updateUser(values._id, values).then(res => {
                    snack.open(t('saved'))
                    setDialogMode(null)
                    api.getAdminList().then(res => {
                        setCategories(_.filter(res.data, ['isActive', false]))
                        setActiveCategories(_.filter(res.data, ['isActive', true]))
                        //setAdminList(res.data)
                    })
                }).catch(e => {
                    snack.error(t('failed'))
                }).finally(() => {
                    helper.setSubmitting(false)
                })
            }
            else {
                api.addAdmin(values).then(res => {
                    snack.open(t('saved'))
                    setDialogMode(null)
                    api.getAdminList().then(res => {
                        setCategories(_.filter(res.data, ['isActive', false]))
                        setActiveCategories(_.filter(res.data, ['isActive', true]))
                        //setAdminList(res.data)
                    })
                }).catch(e => {
                    snack.error(t('failed'))
                }).finally(() => {
                    helper.setSubmitting(false)
                })
            }
        }
    })

    useEffect(() => {
        if (dialogMode) formik.resetForm()
    }, [dialogMode]);

    const handleExport = () => {
        utils.exportCsv(rowsRef.current, [
            { label: t('email'), value: row => row.email },
            { label: t('name_zh'), value: row => row.name?.zh },
            { label: t('name_en'), value: row => row.name?.en },
            { label: t('role'), value: row => t(row.role) }
        ], 'admin_list')
    }

    const handleCreate = () => {
        setSelectedStudent(null)
        setDialogMode('add')
    }

    const handleEdit = (row) => {
        setSelectedStudent(row)
        setDialogMode('edit')
    }

    const handleDelete = (row) => {
        setSelectedStudent(row)
        setDialogMode('delete')
    }


    const filteredList = useCallback(utils.searchFilter(adminList, search, ['name.en', 'name.zh', 'role', 'email']), [adminList, search])

    const handleConfirmReset = () => {
        api.resetUserPassword(selectedStudent?._id).then(res => {
            api.getAdminList().then(res => {
                snack.open()
            //    setAdminList(res.data)
                setCategories(_.filter(res.data, ['isActive', false]))
                setActiveCategories(_.filter(res.data, ['isActive', true]))
                setDialogMode(null)
            })
        }).catch(e => {
            snack.error()
        })
    }
/////////////////////////////////////////////////////////////////////////////////////////
    const headCells = [
        { label: t('name_zh'), value: 'name.zh', sort: true },
        { label: t('name_en'), value: 'name.en', sort: true },
        { label: t('role'), value: 'role', content: (row) => t(row.role), sort: true },
        { label: t('email'), value: 'email', sort: true },
        { label: t('setup_password'), value: 'setup_password' },
        { label: t('status'), sx: { textAlign: 'center' }, content: (row) => row.isActive ? t('active') : t('inactive'), className: (row) => row.isActive ? 'text-green' : 'text-red' },
        {
            label: t('actions'), content: (row) => {
                return <Box sx={{ mx: -1 }}>
                    <Tooltip title={t('edit')}>
                        <IconButton onClick={() => handleEdit(row)} sx={{ p: .5 }}> <Icons.EditIcon /> </IconButton>
                    </Tooltip>
                </Box>
            }
        },
    ]

    const rows = useMemo(() => {
        if (!categories) return categories
        return categories.filter(rowfilter)
    }, [search, categories])

    function rowfilter(row) {
        if (!search || search === '') return true
        if (row.name) {
            for (var key in row.name) {
                var value = row.name[key]
                if (value.includes(search.trim())) {
                    return true
                }
            }
        }
        return false
    }

    var filtered = useMemo(() => utils.searchFilter(tab == 'active' ? activeCategories : categories, search, ['name.en', `name.zh`, 'role', 'email']), [categories,activeCategories, search, tab])

    if (isLoading) {
        return <LoadingView></LoadingView>;
    }
    return (
        <LayoutContainer title={t('admin_title')}>
            <Card sx={{ mb: 2, borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tab} onChange={(e, v) => setTab(v)} >
                    <Tab label={t('active')} value={'active'} />
                    <Tab label={t('inactive')} value={'inactive'} />
                </Tabs>
            </Card>
            <Box display='flex' justifyContent='flex-end' alignItems='center' mb={2}>
                <SearchInput setSearch={setSearch} />
                <div className="spacer" />
                <Button onClick={handleExport}>
                    {t('export')}
                </Button>
                <NavLink to='/admin/admin_list/upload'>
                    <Button sx={{ ml: 1 }}>
                        {t('import')}
                    </Button>
                </NavLink>
                <Button sx={{ ml: 1 }} onClick={handleCreate}>
                    {t('add_admin')}
                </Button>
            </Box>
            
            {tab == 'active' &&
                <DataTable row_id='_id' rows={filtered} heads={headCells} _rowsPerPage={10} _orderBy='email' _order='asc' />
            }
            {tab == 'inactive' &&
                <DataTable row_id='_id' rows={filtered} heads={headCells} _rowsPerPage={10} _orderBy='email' _order='asc' />
            }
            {/* <DataTable rowsRef={rowsRef}
                rows={filteredList}
                search={search}
                searchFields={['name.en', 'name.zh', 'role', 'email']}
                heads={[
                    { label: t('name_zh'), value: 'name.zh', sort: true },
                    { label: t('name_en'), value: 'name.en', sort: true },
                    { label: t('role'), value: 'role', content: (row) => t(row.role), sort: true },
                    { label: t('email'), value: 'email', sort: true },
                    { label: t('setup_password'), value: 'setup_password' },
                    { label: t('status'), sx: { textAlign: 'center' }, content: (row) => row.isActive ? t('active') : t('inactive'), className: (row) => row.isActive ? 'text-green' : 'text-red' },
                    {
                        label: t('actions'), content: (row) => {
                            return <Box sx={{ mx: -1 }}>
                                <Tooltip title={t('edit')}>
                                    <IconButton onClick={() => handleEdit(row)} sx={{ p: .5 }}> <Icons.EditIcon /> </IconButton>
                                </Tooltip>
                            </Box>
                        }
                    },
                ]} _orderBy='email' _order='asc' /> */}
            <EditDialog
                maxWidth='xs'
                title={t('reset_password_title')}
                open={dialogMode == 'resetpw'}
                handleClose={() => setDialogMode(null)}
                confirmText={t("confirm")}
                handleSave={handleConfirmReset}
            >
                <span >{t('confirm_resetpw')}</span>
                <span className='bold' style={{ marginLeft: '4px' }}>{`${_.get(selectedStudent, `name.${getLanguage()}`)} `}</span>
            </EditDialog>
            <EditDialog
                TitleComponent={() => (
                    selectedStudent && <Tooltip title={t('reset_password_title')}>
                        <IconButton onClick={() => setDialogMode('resetpw')} disabled={selectedStudent._id == userInfo?._id}  >
                            <Icons.VpnKeyIcon />
                        </IconButton>
                    </Tooltip>
                )}
                title={dialogMode == 'edit' ? t('edit_admin_title') : t('add_admin_title')}
                handleSave={formik.handleSubmit} open={dialogMode == 'add' || dialogMode == 'edit'}
                handleClose={() => setDialogMode(null)}
            >
                <TextField
                    disabled={dialogMode == 'edit'}
                    fullWidth
                    label={t('email')}
                    {...formikProps(formik, 'email')}
                    sx={{ mb: 2 }}
                />
                <TextField
                    select
                    fullWidth
                    disabled={dialogMode == 'edit' && formik.values._id === userInfo?._id}
                    label={t('role')}
                    {...formikProps(formik, 'role')}
                    sx={{ mb: 2 }}
                >
                    <MenuItem value={'organization_admin'}> {t('organization_admin')} </MenuItem>
                    <MenuItem value={'admin'}> {t('admin')} </MenuItem>
                </TextField>
                <TextField
                    fullWidth
                    label={t('name_zh')}
                    {...formikProps(formik, 'name.zh')}
                    sx={{ mb: 2 }}
                />
                <TextField
                    fullWidth
                    label={t('name_en')}
                    {...formikProps(formik, 'name.en')}
                    sx={{ mb: 2 }}
                />
                <TextField
                    select
                    disabled={dialogMode == 'edit' && formik.values._id === userInfo?._id}
                    fullWidth
                    label={t('status')}
                    {...formikProps(formik, 'isActive')}
                    sx={{ mb: 2 }}
                >
                    <MenuItem key={'active'} value={true}> {t('active')} </MenuItem>
                    <MenuItem key={'inactive'} value={false}> {t('inactive')} </MenuItem>
                </TextField>
                {formik.values.role == 'admin' && <>
                    <FormControlLabel control={<Checkbox checked={formik.values.privilege?.all_action}  {...formikProps(formik, 'privilege.all_action')} />} label={t('can_see_all_action')} />
                </>
                }
            </EditDialog>
            <EditDialog
                title={t('delete_student')}
                handleSave={formik.handleSubmit}
                open={dialogMode == 'delete'}
                handleClose={() => setDialogMode(null)}
            >
                <p>
                    {'Are you sure to delete'}
                </p>
                <p>{selectedStudent?.username}</p>
                <p>{selectedStudent?.class}</p>
                <p>{selectedStudent?.class_no}</p>
                <p>{selectedStudent?.name?.en}</p>
            </EditDialog>


        </LayoutContainer>

    );
}

export default AdminList;