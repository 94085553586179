import { Box, Container, IconButton, Switch, TextField, Tooltip, Button, MenuItem, Typography, Card, CardHeader, CardContent, CardActions } from '@material-ui/core';
import React, { Fragment, useMemo, useCallback, useEffect, useState, useRef, useLayoutEffect } from 'react'
import useApi from 'src/api/api';
import { Helmet } from 'react-helmet';
import DataTable from 'src/components/DataTable/DataTable';
import { useLocale } from 'src/provider/LocaleProvider';
import { useAuthData } from 'src/provider/AuthDataProvider';
import Icons from 'src/icons';
import EditDialog from 'src/components/EditDialog';
import { useFormik } from 'formik';
import { useSnack } from 'src/provider/SnackbarProvider';
import formikProps from 'src/utils/formikProps';
import SearchInput from 'src/components/SearchInput';
import LayoutContainer from 'src/layout/LayoutContainer';
import utils from 'src/utils/utils';
import LoadingView from 'src/components/LoadingView';
const yup = require('yup')
const _ = require('lodash')
const moment = require('moment-timezone')

const OrganizationFilterList = () => {
    const api = useApi()
    const { t } = useLocale()
    const [filterList, setFilterList] = useState([]);

    const [dialogMode, setDialogMode] = useState(null);
    const [selectedRow, setSelectedRow] = useState();

    const auth = useAuthData()
    const snack = useSnack()

    const [search, setSearch] = useState('');
    const [isLoading, setIsLoading] = useState(true)

    const getData = async () => {
        await api.getOrganizationFilters(setFilterList)
        setIsLoading(false)
    }

    useEffect(() => {
        setIsLoading(true)
        getData()

    }, []);

    const schema = yup.object().shape({
        name: yup.object().shape({
            en: yup.string().required(),
            zh: yup.string().required()
        }),
        attribute: yup.mixed().oneOf(['grade', 'class']).required(),
        value: yup.string().required(),
    })

    const formik = useFormik({
        initialValues: selectedRow || {
            name: {
                en: '',
                zh: ''
            },
            attribute: '',
            value: ''
        },
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: (values, helper) => {
            if (values._id) {
                api.updateOrganizationFilters(values._id, values).then(res => {
                    setDialogMode(null)
                    getData()
                    snack.open()
                }).catch(e => {
                    snack.error()
                }).finally(() => {
                    helper.setSubmitting(false)
                })
            }
            else {
                api.addOrganizationFilters(values).then(res => {
                    setDialogMode(null)
                    getData()
                    snack.open()
                }).catch(e => {

                    snack.error()
                }).finally(() => {
                    helper.setSubmitting(false)
                })
            }
        }
    })



    useEffect(() => {
        if (dialogMode) formik.resetForm()
    }, [dialogMode]);

    const handleAdd = async (row) => {
        setSelectedRow(null)
        setDialogMode('add')
    }

    const handleEdit = async (row) => {
        setSelectedRow(schema.cast(row || {}))
        setDialogMode('edit')
    }

    const handleDelete = async (row) => {
        setSelectedRow(schema.cast(row || {}))
        setDialogMode('delete')
    }

    const filtered = useCallback(utils.searchFilter(filterList, search, ['name.en', 'name.zh', (row) => t(row.attribute), 'value']), [filterList, search])

    if (isLoading) {
        return <LoadingView></LoadingView>;
      }


    return (
        <LayoutContainer title={t('organizationFilter_title')}>
            <Box display='flex' justifyContent='flex-end' mb={2} alignItems='center'>
                <SearchInput setSearch={setSearch} />
                <Box flexGrow={1} />
                <Button onClick={handleAdd}>
                    {t('add_new_organizationFilter_title')}
                </Button>
            </Box>
            <DataTable rows={filtered} heads={[
                { id: 'name_zh', label: (t('name_zh')), value: 'name.zh', sort: true },
                { id: 'name_en', label: (t('name_en')), value: 'name.en', sort: true },
                { id: 'attribute', label: (t('attribute')), value: 'attribute', content: (row) => t(row.attribute) },
                { id: 'value', label: (t('value')), value: 'value', sort: true },
                {
                    id: 'action', label: t('actions'), content: (row) => {
                        return <Box sx={{ mx: -1 }}>
                            <IconButton onClick={() => handleEdit(row)} sx={{ px: 0.5, py: 0 }}> <Icons.EditIcon /> </IconButton>
                        </Box>
                    }
                },
            ]} _orderBy='name.en' _order='asc' />
            <EditDialog
                title={dialogMode == 'edit' ? t('edit_organizationFilter_title') : t('add_new_organizationFilter_title')}
                open={dialogMode == 'add' || dialogMode == 'edit'}
                handleSave={formik.handleSubmit}
                handleClose={() => setDialogMode(null)}
            >
                <TextField
                    fullWidth
                    label={t('name_zh')}
                    {...formikProps(formik, 'name.zh')}
                    sx={{ mb: 2 }}
                />
                <TextField
                    fullWidth
                    label={t('name_en')}
                    {...formikProps(formik, 'name.en')}
                    sx={{ mb: 2 }}
                />

                <TextField
                    select
                    fullWidth
                    label={t('attribute')}
                    {...formikProps(formik, 'attribute')}
                    sx={{ mb: 2 }}
                >
                    <MenuItem key={'grade'} value={'grade'}> {t('grade')} </MenuItem>
                    <MenuItem key={'class'} value={'class'}> {t('class')} </MenuItem>
                </TextField>
                <TextField
                    fullWidth
                    label={t('value')}
                    {...formikProps(formik, 'value')}
                />
            </EditDialog>
        </LayoutContainer>
    );
}

export default OrganizationFilterList;