import { Button, Card, CardContent, CardHeader, Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import useApi from 'src/api/api';
import DataTableNoLimit from 'src/components/DataTable/DataTableNoLimit';
import LoadingView from 'src/components/LoadingView';
import Icons from 'src/icons';
import LayoutContainer from 'src/layout/LayoutContainer';
import { useAuthData } from 'src/provider/AuthDataProvider';
import { useBackDrop } from 'src/provider/BackdropProvider';
import { useLocale } from 'src/provider/LocaleProvider';
const moment = require('moment-timezone')

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  row: {
    // height: '10px',
    // paddingBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(1)
    // marginTop: theme.spacing(1)
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },

  cardTitle: {
    fontWeight: 700
  },


  tableCell: {
    "& .MuiTableCell-root": {
      textAlign: 'center',
      borderLeft: "none !important",
    },
    "& .MuiTableRow-root": {
      height: '60px'
    },
  }

}))

const Dashboard = () => {
  const classes = useStyles()
  const loadingView = useBackDrop();
  const api = useApi()
  const nav = useNavigate()
  const { t } = useLocale()
  const { selectedPeriod, organization } = useAuthData()
  const [totalReward, setReward] = useState([])
  const [totalRedeem, setRedeem] = useState([])
  const [userRanking, setUserRanking] = useState([])
  const [classRanking, setClassRanking] = useState([])
  const [currentPeriodInfo, setCurrentPeriodInfo] = useState({
    name: '',
    start: '',
    end: ''
  })
  const [isLoading, setIsLoading] = useState(true);

  useEffect(async () => {
    // loadingView.open()
    setIsLoading(true);
    api.getTotalReward(selectedPeriod).then(({ data }) => { setReward(data) }).catch(e => { })
    api.getTotalRedeem(selectedPeriod).then(({ data }) => { setRedeem(data) }).catch(e => { })
    api.getUserRanking(selectedPeriod).then(({ data }) => { setUserRanking(data) }).catch(e => { })
    api.getClassRanking(selectedPeriod).then(({ data }) => { setClassRanking(data) }).catch(e => { })
    api.getCurrentPeriod(selectedPeriod).then(periodRes => {
      if (periodRes) setCurrentPeriodInfo({
        ...currentPeriodInfo,
        name: periodRes.name,
        start: periodRes.start,
        end: periodRes.end
      })
      setIsLoading(false);
    })


    // loadingView.close()

  }, [selectedPeriod])

  if (isLoading) return <LoadingView></LoadingView>

  return (
    <LayoutContainer title={t('dashboard_title')}>
      <div className={classes.row}>
        {(currentPeriodInfo.start && currentPeriodInfo.end) && <Typography>{`${currentPeriodInfo.start} ${t('_to_')} ${currentPeriodInfo.end}`}</Typography>}
      </div>
      <Grid
        container
        spacing={4}
      >
        <>
          {organization?.banner &&
            <Grid item xs={12}>
              <img src={organization?.banner} className='MuiPaper-elevation' style={{ width: '100%', aspectRatio: '6', objectFit: 'contain' }} />
            </Grid>
          }
        </>
        <Grid item sm={6} xs={12} >
          <Card className={'flex jcc ac h100'}>
            <CardContent>
              <br />
              <Typography
                className={classes.cardTitle}
                color="textSecondary"
                gutterBottom
                variant="h3"
              >
                {t('total_reward')}
              </Typography>
              <br />
              <Typography variant="h3" align='center'>{_.get(totalReward, '0.count', "0")}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={6} xs={12} >
          <Card className={'flex jcc ac h100'}>
            <CardContent>
              <br />
              <Typography
                className={classes.cardTitle}
                color="textSecondary"
                gutterBottom
                variant="h3"
              >
                {t('total_redeem')}
              </Typography>
              <br />
              <Typography variant="h3" align='center'>{_.get(totalRedeem, '0.count', "0")}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} >
          <Card className='mb8'>
            <CardHeader
              className={classes.actions}
              action={
                <Button
                  color="primary"
                  size="medium"
                  variant="text"
                  onClick={() => nav('/admin/dashboard/user_ranking')}
                >
                  {t('see_all')} <Icons.ArrowRightIcon />
                </Button>
              }
              title={t('studentRankTable_title')}
            />
            <Divider />
            <CardContent>
              <DataTableNoLimit
                className={classes.tableCell}
                rows={_.slice(userRanking, 0, 3)}
                heads={[
                  { id: 'rank', label: t('rank'), value: 'rank', content: (row, index) => index + 1 },
                  { id: 'class', label: t('class'), value: 'class', },
                  { id: 'name.zh', label: t('name_zh'), value: 'name.zh' },
                  { id: 'name.en', label: t('name_en'), value: 'name.en', },
                  { id: 'totalpoints', label: t('total_point'), value: 'totalpoints' }
                ]}
              />
            </CardContent>
          </Card>
          <div align='right' className={'font12'}>Last Updated at {moment().format("YYYY-MM-DD HH:mm:ss")}</div>

        </Grid>

        <Grid item xs={12} >
          <Card className='mb8'>
            <CardHeader
              className={classes.actions}
              action={
                <Button
                  color="primary"
                  size="medium"
                  variant="text"
                  onClick={() => nav('/admin/dashboard/class_ranking')}
                >
                  {t('see_all')} <Icons.ArrowRightIcon />
                </Button>
              }
              title={t('classRankTable_title')}
            />
            <Divider />
            <CardContent>
              <DataTableNoLimit
                className={classes.tableCell}
                rows={_.slice(classRanking, 0, 3)}

                heads={[
                  { id: 'rank', label: t('rank'), value: 'rank', content: (row, index) => index + 1 },
                  { id: 'class', label: t('class'), value: 'class', },
                  { id: 'totalpoints', label: t('total_point'), value: 'totalpoints' }
                ]}

              />
            </CardContent>
          </Card>
          <div align='right' className={'font12'}>Last Updated at {moment().format("YYYY-MM-DD HH:mm:ss")}</div>

        </Grid>
      </Grid>
    </LayoutContainer>)
}
export default Dashboard;
