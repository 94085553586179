import AssignmentIcon from '@material-ui/icons/Assignment';
import DashboardIcon from '@material-ui/icons/Dashboard';
import GavelIcon from '@material-ui/icons/Gavel';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';

import Icons from 'src/icons';

export default (t, modules = {}) => [
  {
    name: t('overview_title'),
    icon: () => <DashboardIcon />,
    href: '/user/overview'
  },
  {
    name: t('profile_title'),
    icon: () => <PeopleIcon />,
    href: '/user/profile'
  },
  {
    name: t('add_point'),
    icon: () => <GavelIcon />,
    href: '/user/reward'
  },
  {
    name: t('homework_statistics'),
    icon: () => <AssignmentIcon />,
    href: '/user/studentHomeworkStatistics'
  },
  {
    name: t('settings_title'),
    icon: () => <SettingsIcon />,
    href: '/user/settings'
  },
  {
    name: t('notice'),
    show: modules.notice,
    icon: () => <Icons.NoticeIcon />,
    href: '/user/notice'
  },

  {
    name: t('memo'),
    show: modules.memo,
    icon: () => <Icons.EmailIcon />,
    href: '/user/memo'
  }
];
