import { Box, Button, Card, Container, Divider, IconButton, Paper, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import useApi from 'src/api/api';
import DataTable from 'src/components/DataTable/DataTable';
import Icons from 'src/icons';
import LayoutContainer from 'src/layout/LayoutContainer';
import { useAuthData } from 'src/provider/AuthDataProvider';
import { useLocale } from 'src/provider/LocaleProvider';
import classNames from 'classnames';
const prettyBytes = require('pretty-bytes');

const NoticeDetail = () => {
    const { t } = useLocale()
    const api = useApi()
    const [notice, setNotice] = useState();
    const nav = useNavigate()
    const { id } = useParams()
    // const snack = useSnack()



    useEffect(() => {
        if (id) {
            api.getNoticeById(id)
                .then(res => {

                    setNotice(res.data)
                    if (!res.data.user_info?.is_read) {
                        api.updateNoticeInfo(id, { is_read: true })
                            .catch(e => {

                            })
                    }


                }).catch(e => console.log(e))
        }
    }, []);
    if (!notice) return null

    return (

        <LayoutContainer title={t('notice')} backSrc={'/user/notice'}>
            <Card style={{ padding: '16px' }}>


                <div className='flex-sb ac w100 mb8'>
                    <Typography variant='h2' >
                        {notice.title}
                    </Typography>

                    <IconButton sx={{ color: notice.user_info?.is_favourite && '#FFB000' }} onClick={(e) => {

                        api.updateNoticeInfo(notice._id, { is_favourite: !notice.user_info?.is_favourite })
                            .catch(e => {
                            })
                            .finally(() => {
                                let _notice = { ...notice }
                                _.set(_notice, `user_info.is_favourite`, !notice.user_info?.is_favourite)
                                setNotice(_notice)

                            })

                    }}>
                        <Icons.FavouriteIcon />
                    </IconButton>
                </div>
                <Divider sx={{ mb: 1 }} />
                <Typography style={{ marginBottom: '24px', whiteSpace: 'pre-wrap' }}>
                    {notice.message}
                </Typography>
                {
                    _.map(notice.attachments, (a, index) => {
                        return <Box display='flex' alignItems='center'
                            mb={1} p={1} style={{
                                //  backgroundColor: 'rgba(0,0,0,0.1)' 
                                border: '1px solid black',
                                borderRadius: '8px'

                            }}
                        >
                            <Icons.AttachFileIcon style={{ marginRight: '8px' }} />
                            <a href={a.url} target='_blank' style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                marginRight: '8px'
                            }}>
                                {a && a.name}
                            </a>
                            <div style={{
                                whiteSpace: 'nowrap',
                            }}>
                                {a.size && `(${prettyBytes(a.size)})`}
                            </div>
                            <div style={{ flexGrow: 1 }} />
                            {/* <div style={{
                                    whiteSpace: 'nowrap',
                                }}>
                                    {a.size && prettyBytes(a.size)}
                                </div>
                                {a.url && <IconButton href={a.url} target='_blank' download={a.name} style={{ padding: 0, marginLeft: '8px' }} >
                                    <Icons.GetAppIcon />
                                </IconButton>
                                } */}
                        </Box>
                    })
                }
            </Card>

        </LayoutContainer>

    );
}

export default NoticeDetail;