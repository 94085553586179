import { Helmet } from 'react-helmet';
import { Backdrop, Box, Container, Grid, Card, CardContent, Typography, CardHeader, Button, Divider, IconButton, TextField, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useEffect } from 'react';
import { useBackDrop } from 'src/provider/BackdropProvider';
import { useState } from 'react';
import useApi from 'src/api/api';
import { ArrowBack } from '@material-ui/icons';
import Icons from 'src/icons';
import DataTable from 'src/components/DataTable/DataTable';
import DataTableNoLimit from 'src/components/DataTable/DataTableNoLimit';
import _ from 'lodash';
import { useNavigate } from 'react-router';
import { useLocale } from 'src/provider/LocaleProvider';
import utils from 'src/utils/utils';
import SearchInput from 'src/components/SearchInput';
import LayoutContainer from 'src/layout/LayoutContainer';
import { useAuthData } from 'src/provider/AuthDataProvider';
const moment = require('moment-timezone')

const useStyles = makeStyles(theme => ({

    row: {
        // height: '10px',
        // paddingBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2)
    },
    spacer: {
        flex: 1
    },

    tableCell: {
        "& .MuiTableCell-root": {
            textAlign: 'center',
            borderLeft: "none !important",
        },
        "& .MuiTableRow-root": {
            height: '60px'
        },

    },
    caption: {
        fontSize: '12px'
    }

}))




const UAttendanceRecord = () => {
    const classes = useStyles()
    const loadingView = useBackDrop();
    const api = useApi()
    const nav = useNavigate()
    const { t } = useLocale()
    const { userInfo, selectedPeriod, currentPeriod } = useAuthData()


    const [search, setSearch] = useState(null)
    const [attendanceHistory, setAttendanceHistory] = useState([])

    const handleExport = () => {
        utils.exportCsv(attendanceHistory, [
            { label: t('date'), value: (row) => row['date'] },
            { label: t('attendancerule_name'), value: (row) => row.attendanceRule.name },
            { label: t('type'), value: (row) => row['type'] }
        ], 'attendance_record')
    }

    useEffect(() => {
        // loadingView.open()
  

        api.getUserAttendanceList(selectedPeriod, userInfo._id).then(({ data }) => setAttendanceHistory(data)).catch(e => console.log(e))

        // loadingView.close()

    }, [])
    return (
        <LayoutContainer title={t('attendance_history')} backSrc='/user/overview'>
            <Grid container alignItems="center" >

                <Grid item xs={12} className={'tr'} mb={2}>
                    <Button onClick={handleExport} >
                        {t('export')}
                    </Button>
                </Grid>
            </Grid>
            <DataTable
                sx={{ mb: 1 }}
                className={classes.tableCell}
                rows={(attendanceHistory)}
                heads={[
                    {
                        label: t('date'), value: 'created_at', content: (row) => moment(row.created_at).format("YYYY-MM-DD HH:MM"),
                        width: '33.3%',
                        // sort: true
                    },
                    { label: t('attendancerule_name'), value: 'attendanceRule.name', width: '33.3%', },
                    { label: t('type'), value: 'type', width: '33.3%', }
                ]}

            />
            <div align='right' className={'font12'}>Last Updated at {moment().format("YYYY-MM-DD HH:mm:ss")}</div>

        </LayoutContainer>
    )
}
export default UAttendanceRecord;
