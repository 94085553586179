import { Box, Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useEffect, useRef, useState } from 'react';
import {
  Bar,
  BarChart,
  Brush,
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import useApi from 'src/api/api';
import CustomDatePicker from 'src/components/DatePicker';
import { useAuthData } from 'src/provider/AuthDataProvider';
import { useLocale } from 'src/provider/LocaleProvider';
const moment = require('moment-timezone');
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF80FF'];

export default function UnfinishedHomeworkChart() {
  const { t } = useLocale();
  const { selectedPeriod } = useAuthData();
  const api = useApi();
  const [rechartData, setRechartData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(moment().toDate());
  const timer = useRef();

  useEffect(() => {
    if (selectedPeriod) {
      api.getUnfinishedHomeworkStat(startDate, endDate).then((res) => {
        const { data } = res;
        const { dateRange } = data;
        setRechartData(data);
        setStartDate(dateRange.fromDate);
        setEndDate(dateRange.toDate);
      });
    }
  }, [selectedPeriod]);

  const getData = async () => {
    return api.getUnfinishedHomeworkStat(startDate, endDate).then((res) => {
      setRechartData(res.data);
    });
  };

  // console.log(_.isDate(toDate.toDate()));

  useEffect(() => {
    if (rechartData) {
      setRechartData({});
      clearTimeout(timer.current);
      timer.current = setTimeout(getData, 500);
    }
  }, [startDate, endDate]);
  return (
    <>
      <Box mb={2} sx={{ display: 'flex', justifyContent: 'space-around' }}>
        <CustomDatePicker
          label={t('from')}
          selectedDate={startDate}
          setSelectedDate={setStartDate}
        />
        <CustomDatePicker
          label={t('to')}
          selectedDate={endDate}
          setSelectedDate={setEndDate}
        />
      </Box>
      <Card sx={{ p: 2, mb: 2 }}>
        <Box mb={2} textAlign="center">
          <Typography variant="h3">
            {t('total_uncompleted_homework_by_grade')}
          </Typography>
        </Box>

        <Box sx={{ height: '300px' }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={rechartData.gradeData}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar
                dataKey="total"
                stackId="a"
                name={t('total_students')}
                fill={COLORS[0]}
              />
              <ReferenceLine
                y={rechartData?.total / rechartData?.gradeData?.length}
                stroke={COLORS[1]}
                label={t('average')}
                strokeWidth="2"
              />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Card>
      <Card sx={{ p: 2, mb: 2 }}>
        <Box mb={2} textAlign="center">
          <Typography variant="h3">
            {t('total_uncompleted_homework_by_class')}
          </Typography>
        </Box>

        <Box sx={{ height: '300px' }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={rechartData.classData}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar
                dataKey="total"
                stackId="a"
                name={t('total_students')}
                fill={COLORS[2]}
              />
              <ReferenceLine
                y={rechartData.total / rechartData.classData?.length}
                stroke={COLORS[3]}
                label={t('average')}
                strokeWidth="2"
              />
              <Brush />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Card>
    </>
  );
}
