import { Helmet } from 'react-helmet';
import { Backdrop, Box, Container, Grid, Card, CardContent, Typography, CardHeader, Button, Divider, IconButton, TextField, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useEffect } from 'react';
import { useBackDrop } from 'src/provider/BackdropProvider';
import { useState } from 'react';
import useApi from 'src/api/api';
import { ArrowBack } from '@material-ui/icons';
import Icons from 'src/icons';
import DataTable from 'src/components/DataTable/DataTable';
import DataTableNoLimit from 'src/components/DataTable/DataTableNoLimit';
import _ from 'lodash';
import { useNavigate } from 'react-router';
import { useLocale } from 'src/provider/LocaleProvider';
import utils from 'src/utils/utils';
import SearchInput from 'src/components/SearchInput';
import LayoutContainer from 'src/layout/LayoutContainer';
import { useAuthData } from 'src/provider/AuthDataProvider';
const moment = require('moment-timezone')

const useStyles = makeStyles(theme => ({

    row: {
        // height: '10px',
        // paddingBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2)
    },
    spacer: {
        flex: 1
    },

    tableCell: {
        "& .MuiTableCell-root": {
            textAlign: 'center',
            borderLeft: "none !important",
        },
        "& .MuiTableRow-root": {
            height: '60px'
        },

    },
    caption: {
        fontSize: '12px'
    }

}))

const Profile = () => {
    const classes = useStyles()
    const loadingView = useBackDrop();
    const api = useApi()
    const nav = useNavigate()
    const { t } = useLocale()
    const authData = useAuthData()


    const [search, setSearch] = useState(null)
    const [profileRecord, setProfileRecord] = useState([])

    const handleExport = () => {
        utils.exportCsv(profileRecord, [
            { label: t('period'), value: (row) => row['period'] },
            { label: t('class'), value: (row) => row['class'], },
            { label: t('class_no'), value: (row) => row['class_no'] },
            { label: t('cumulative_point'), value: (row) => row['cumulative_point'] },
            { label: t('point_balance'), value: (row) => row['point'] },
        ], 'student_profile')
    }

    useEffect(() => {
        // loadingView.open()
        // loadingView.close()
        api.getUserProfile().then((({ data }) => setProfileRecord(data))).catch(e => console.log(e))

    }, [])
    return (
        <LayoutContainer title={t('profile_title')} >
            <Grid container alignItems="center" >
                <Grid item xs={12} className={'tr'} mb={2}>
                    <Button onClick={handleExport} >
                        {t('export')}
                    </Button>
                </Grid>
            </Grid>
            <DataTable
                className={classes.tableCell}
                rows={(profileRecord)}
                heads={[
                    { id: 'period', label: t('period'), value: 'period', },
                    { id: 'class', label: t('class'), value: 'class', sort: true },
                    { id: 'class_no', label: t('class_no'), value: 'class_no', sort: true },
                    { id: 'cumulative_point', label: t('cumulative_point'), value: 'cumulative_point', sort: true },
                    { id: 'point', label: t('point'), value: 'point', sort: true },
                ]}
            />
            {/* <div align='right' className={'font12'}>Last Updated at {moment().format("YYYY-MM-DD HH:mm:ss")}</div> */}
        </LayoutContainer>
    )
}
export default Profile;
