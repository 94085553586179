import { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
    Avatar,
    Box,
    Card,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useLocation } from 'react-router'
import { useLocale } from 'src/provider/LocaleProvider'
import utils from 'src/utils/utils'
var classNames = require('classnames');

const _ = require('lodash')

const useStyles = makeStyles({
    table: {
        // border: '2px solid black',
        "& .MuiTableRow-root": {
            height: '40px'
        },
        "& .MuiTableCell-root": {
            whiteSpace: 'nowrap',
            // borderLeft: "1px solid rgba(224, 224, 224, 1)"
        },
        "& .MuiTableSortLabel": {
            "&-icon": {
                opacity: 0.5
            },
            "&-active": {
                opacity: 1,
                "& .MuiTableSortLabel-icon": {
                    color: 'limegreen !important'
                },
                // "& .MuiTableSortLabel-iconDirectionDesc": {
                //     color: 'red !important'
                // }
            }

        }
    }
})

const DataTableNoLimit = ({ rows = [], row_id = '_id', rowsRef, heads = [], placeHolder = '-', _orderBy = 'created_at', _order = 'desc', loading = true, className = '', ...rest }) => {

    const classes = useStyles()

    const [order, setOrder] = useState(_order)
    const [orderBy, setOrderBy] = useState(_orderBy)
    const sorted = useMemo(() => _.orderBy(rows, [orderBy], [order]), [rows, orderBy, order])
    const rowLength = _.size(sorted)
    const { t } = useLocale()
    const headCells = heads
    const bodyCells = sorted

    useEffect(() => {
        if (rowsRef && rowsRef.current) {
            rowsRef.current = sorted
        }
    }, [sorted]);

    const handleSort = (cell) => {
        if (orderBy == cell.value) {
            setOrder(order === 'asc' ? 'desc' : 'asc')
        }
        else {
            setOrderBy(cell.value)
        }
    }

    return (
        <Card {...rest}>
            <PerfectScrollbar>
                <Box >
                    <Table size='small' className={classNames(classes.table, className)}>
                        <TableHead>
                            <TableRow  >
                                {
                                    _.map(headCells, (cell, i) => (
                                        <TableCell key={cell + i}>
                                            {cell.sort === true
                                                ? <TableSortLabel
                                                    active={orderBy === cell.value}
                                                    direction={orderBy === cell.value ? order : 'asc'}
                                                    onClick={(event) => handleSort(cell)}
                                                    hideSortIcon={false}
                                                >
                                                    {cell.label}
                                                </TableSortLabel>
                                                : _.get(cell, 'label')
                                            }
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_.map(bodyCells, (bodyCell, index) => (
                                <TableRow
                                    hover
                                    key={bodyCell + index}
                                    sx={{ height: '40px' }}
                                >
                                    {_.map(headCells, cell => {
                                        var render = ''
                                        if (cell.content) {
                                            render = cell.content(bodyCell, index)
                                        }
                                        else {
                                            render = _.get(bodyCell, cell.value)
                                        }
                                        if (cell.translate) {
                                            render = t(_.toString(render))
                                        }
                                        if (!render || render == '') {
                                            render = cell.placeHolder || placeHolder
                                        }
                                        return (
                                            <TableCell key={Math.random()} width={cell.width} sx={cell.sx}>
                                                {render}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            ))}
                            {_.size(bodyCells) == 0 &&
                                <TableRow style={{ height: 50 }}>
                                    <TableCell colSpan={99}>
                                        <Typography variant='h5'>{t('no_records')}</Typography>
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </Box>
            </PerfectScrollbar>
        </Card >
    )
}


export default DataTableNoLimit
