import { Box } from '@material-ui/core';
import QRCode from 'qrcode.react';
import { useLocation } from 'react-router';

const queryString = require('query-string');
export default () => {
  const location = useLocation();
  const { uid } = queryString.parse(location.search);

  return (
    <Box
      className="flex jcc ac h100"
      style={{ flexDirection: 'column' }}
      sx={{
        '& *': {
          userSelect: 'none'
        }
      }}
    >
      <Box flexGrow={1} />

      <Box mb={2} fontWeight="bold">
        {uid}
      </Box>
      <QRCode
        className="qr-code"
        value={uid}
        level="H"
        style={{
          padding: '0 64px',
          width: '100%',
          height: 'fit-content',
          maxWidth: '300px',
          maxHeight: '300px',
          objectFit: 'contain'
        }}
      />
      <Box flexGrow={1} />
      <Box textAlign="center" p={1} fontSize="60%">
        Copyright © 2023 Animae Technologies Limited. All Rights Reserved.
      </Box>
    </Box>
  );
};
