import { Box, Button, Card, Container, IconButton, InputAdornment, MenuItem, Select, Switch, TextField, Tooltip, Tabs, Tab } from '@material-ui/core';
import React, { Fragment, useMemo, useCallback, useEffect, useState, useRef, useLayoutEffect } from 'react';
import useApi from 'src/api/api';
import { Helmet } from 'react-helmet';
import DataTable from 'src/components/DataTable/DataTable';
import { useLocale } from 'src/provider/LocaleProvider';
import { useAuthData } from 'src/provider/AuthDataProvider';
import Icons from 'src/icons';
import EditDialog from 'src/components/EditDialog';
import { Formik, useFormik } from 'formik';
import formikProps from 'src/utils/formikProps';
import utils from 'src/utils/utils';
import SearchInput from 'src/components/SearchInput';
import _ from 'lodash';
import { SignalCellularNullTwoTone, UsbTwoTone } from '@material-ui/icons';
import { useSnack } from 'src/provider/SnackbarProvider';
import LayoutContainer from 'src/layout/LayoutContainer';
import LoadingView from 'src/components/LoadingView';



const moment = require('moment-timezone');
const yup = require('yup');
const DeviceList = () => {
    const api = useApi();
    const { t, getLanguage } = useLocale();

    const rowsRef = useRef([]);
    const snack = useSnack();

    const [dialogMode, setDialogMode] = useState(null);
    const [selectedRow, setSelectedRow] = useState();
    const [search, setSearch] = useState('');
    const [devices, setDevices] = useState([]);
    const [activeDevices, setActiveDevices] = useState([]);
    const [attendanceRules, setAttendanceRules] = useState([]);
    const [redeemRules, setRedeemRules] = useState();
    const [tab, setTab] = useState('active');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true)
        api.getAttendanceRules().then(res => {
            setAttendanceRules(res.data);
        });
        api.getPointRules({ isReward: false }).then(res => {
            setRedeemRules(res.data);
            //console.log(res.data)
        });
        api.getDevices().then(res => {
            //setDevices(res.data);
            setDevices(_.filter(res.data, ['isEnableDeviceFunction', false]))
            setActiveDevices(_.filter(res.data, ['isEnableDeviceFunction', true]))
            setIsLoading(false)
            //console.log(res.data)
        });
    }, []);


    function getRules() {
        api.getDevices().then(res => {
            setDevices(_.filter(res.data, ['isEnableDeviceFunction', false]))
            setActiveDevices(_.filter(res.data, ['isEnableDeviceFunction', true]))
            //setDevices(res.data);
        });
    }

    const schema = yup.object().shape({
        name: yup.string().required(),
        description: yup.string(),
        isActive: yup.bool(),
        pointRule: yup.string().when('state', {
            is: 'redeem',
            then: yup.string().required()
        }),
        attendanceRule: yup.string().when('state', {
            is: (v) => v == 'checkin' || v == 'checkout',
            then: yup.string().required()
        }),
        isEnableDeviceFunction: yup.bool(),
    });

    const formik = useFormik({
        initialValues: selectedRow || {
            name: '',
            description: '',
            isActive: true
        },
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: (values, helper) => {
            if (values._id) {
                api.updateDevice(values._id, values).then(res => {
                    setDialogMode(null);
                    getRules();
                    snack.open();
                }).catch(e => {
                    snack.error();
                }).finally(() => {
                    helper.setSubmitting(false);
                });
            }
            else {
                snack.error('missing device id');
                helper.setSubmitting(false);
                // api.addDevice(values).then(res => {
                //     setDialogMode(null)
                //     getRules()
                //     snack.open()
                // }).catch(e => {
                //     snack.error()
                // }).finally(() => {
                //     helper.setSubmitting(false)
                // })
            }
        }
    });
    const { values, errors } = formik;
    useEffect(() => {
        if (dialogMode) formik.resetForm();
    }, [dialogMode]);

    const handleCreate = () => {
        setSelectedRow(null);
        setDialogMode('add');
    };

    const handleEdit = (row) => {
        setSelectedRow({ ...row, attendanceRule: row?.attendanceRule?._id, pointRule: row?.pointRule?._id });
        setDialogMode('edit');
    };

    // useEffect(() => {
    //     if ((values.state !== 'checkin' && values.state !== 'checkout')) {
    //         formik.setFieldValue('attendanceRule', null)
    //     }
    // }, [values.state]);


    const headCells = [
        { id: 'name', label: t('name'), value: 'name', sort: true },
        { id: 'state', label: t('state'), value: 'state', translate: 1, sort: true },
        { id: 'attendancerule_name', label: t('attendancerule_name'), value: 'attendanceRule.name', sort: true },
        { id: 'pointRule', label: t('pointRule'), value: 'pointRule.name', sort: true },
        { id: 'description', label: t('description'), value: 'description', sort: true },
        { id: 'status', label: t('status'), value: `isActive`, content: r => r.isEnableDeviceFunction ? t('active') : t('inactive'), sort: true ,className: (row) => row.isEnableDeviceFunction ? 'text-green' : 'text-red'},
        {
            id: 'action', label: t('actions'), content: (row) => {
                return <Box sx={{ mx: -1 }}>
                    <Tooltip title={t('edit')}>
                        <IconButton onClick={() => handleEdit(row)} sx={{ p: .5 }}> <Icons.EditIcon /> </IconButton>
                    </Tooltip>
                </Box>;
            }
        },
    ]

    const rows = useMemo(() => {
        if (!devices) return devices
        return devices.filter(rowfilter)
    }, [search, devices])

    function rowfilter(row) {
        if (!search || search === '') return true
        if (row.name) {
            for (var key in row.name) {
                var value = row.name[key]
                if (value.includes(search.trim())) {
                    return true
                }
            }
        }
        return false
    }

    var filtered = useMemo(() => utils.searchFilter(tab == 'active' ? activeDevices : devices, search, ['name', 'state', 'attendancerule_name',
        'pointRule', 'description', 'status']), [devices, activeDevices, search, tab])

        if (isLoading) {
            return <LoadingView></LoadingView>;
        }
    return (
        <LayoutContainer title={t('devices_title')}>
            <Card sx={{ mb: 2, borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tab} onChange={(e, v) => setTab(v)} >
                    <Tab label={t('active')} value={'active'} />
                    <Tab label={t('inactive')} value={'inactive'} />
                </Tabs>
            </Card>
            <Box className='flex ac' mb={2}>
                <SearchInput setSearch={setSearch} />
                <div className="spacer" />
                {/* <Button sx={{ ml: 1 }} onClick={handleCreate}>
                    {t('add')}
                </Button> */}
            </Box>
            {/* <DataTable rowsRef={rowsRef} rows={filtered}
                heads={[
                    { id: 'name', label: t('name'), value: 'name', sort: true },
                    { id: 'state', label: t('state'), value: 'state', translate: 1, sort: true },
                    { id: 'attendancerule_name', label: t('attendancerule_name'), value: 'attendanceRule.name', sort: true },
                    { id: 'pointRule', label: t('pointRule'), value: 'pointRule.name', sort: true },
                    { id: 'description', label: t('description'), value: 'description', sort: true },
                    { id: 'status', label: t('status'), value: `isActive`, content: r => r.isEnableDeviceFunction ? t('active') : t('inactive'), sort: true },
                    {
                        id: 'action', label: t('actions'), content: (row) => {
                            return <Box sx={{ mx: -1 }}>
                                <Tooltip title={t('edit')}>
                                    <IconButton onClick={() => handleEdit(row)} sx={{ p: .5 }}> <Icons.EditIcon /> </IconButton>
                                </Tooltip>
                            </Box>;
                        }
                    },
                ]} _orderBy='name' _order='asc'
            /> */}
            {tab == 'active' &&
                <DataTable rowsRef={rowsRef} rows={filtered} heads={headCells} _rowsPerPage={10} _orderBy='name' _order='asc' />
            }
            {tab == 'inactive' &&
                <DataTable rowsRef={rowsRef} rows={filtered} heads={headCells} _rowsPerPage={10} _orderBy='name' _order='asc' />
            }
            {/* Dialogs */}
            <EditDialog title={dialogMode == 'edit' ? t('edit_device') : t('edit_device')} handleSave={formik.handleSubmit} open={dialogMode == 'add' || dialogMode == 'edit'} handleClose={() => setDialogMode(null)}>
                <TextField
                    fullWidth
                    label={t('name')}
                    {...formikProps(formik, 'name')}
                    sx={{ mb: 2 }}
                />
                <TextField
                    fullWidth
                    label={t('description')}
                    {...formikProps(formik, 'description')}
                    sx={{ mb: 2 }}
                />
                <TextField
                    select
                    fullWidth
                    label={t('state')}
                    {...formikProps(formik, 'state')}
                    sx={{ mb: 2 }}
                >
                    <MenuItem key={'checkin'} value={'checkin'}> {t('checkin')} </MenuItem>
                    <MenuItem key={'checkout'} value={'checkout'}> {t('checkout')} </MenuItem>
                    <MenuItem key={'enquiry'} value={'enquiry'}> {t('enquiry')} </MenuItem>
                    <MenuItem key={'game'} value={'game'}> {t('games')} </MenuItem>
                    <MenuItem key={'redeem'} value={'redeem'}> {t('redeem')} </MenuItem>
                </TextField>
                {!(values.state !== 'checkin' && values.state !== 'checkout') &&
                    <TextField
                        select
                        disabled={(values.state !== 'checkin' && values.state !== 'checkout')}
                        fullWidth
                        label={t('attendanceRule')}
                        {...formikProps(formik, 'attendanceRule')}
                        sx={{ mb: 2 }}
                    >
                        {_.map(attendanceRules, r => <MenuItem key={r._id} value={r._id}> {r.name} </MenuItem>)}
                    </TextField>
               }
               {values.state == 'redeem' &&
                    <TextField
                        select
                        fullWidth
                        label={t('pointRule')}
                        {...formikProps(formik, 'pointRule')}
                        sx={{ mb: 2 }}
                    >
                        {_.map(redeemRules, r => <MenuItem key={r._id} value={r._id}> {r.name} </MenuItem>)}
                    </TextField>
                }
                <TextField
                    select
                    fullWidth
                    label={t('status')}
                    {...formikProps(formik, 'isEnableDeviceFunction')}
                    sx={{ mb: 2 }}
                >
                    <MenuItem key="true" value={true}> {t("active")} </MenuItem>
                    <MenuItem key="false" value={false}> {t("inactive")} </MenuItem>
                </TextField>
            </EditDialog>
        </LayoutContainer>
    );
};

export default DeviceList;