import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Paper, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import useApi from 'src/api/api';
import DataTable from 'src/components/DataTable/DataTable';
import Icons from 'src/icons';
import LayoutContainer from 'src/layout/LayoutContainer';
import { useAuthData } from 'src/provider/AuthDataProvider';
import { useLocale } from 'src/provider/LocaleProvider';
import classNames from 'classnames';
import EditDialog from 'src/components/EditDialog';
import LoadingView from 'src/components/LoadingView';
const moment = require('moment-timezone')


const Memo = () => {
    const { t } = useLocale()
    const api = useApi()
    const [memo, setMemo] = useState([]);

    const nav = useNavigate()
    const [selectedRow, setSelectedRow] = useState();
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [selected, setSelected] = useState([])
    const [dialogMode, setDialogMode] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        api.getMemo().then(res => {
            console.log('res: ', res);
            setMemo(res.data)
            setIsLoading(false);
        }).catch(e => console.log(e));
    }, []);

    const handleDeleteNotice = () => {
        api.batchDeleteMemo(_.map(selected, k => k._id)).then(() =>
            api.getMemo().then(res => {
                setMemo(res.data)
                setSelected([])
                setDialogMode(null)
            })).catch(e => console.log(e))
    }
    if (isLoading) {
        return <LoadingView></LoadingView>;
    }
    return (

        <LayoutContainer title={t('memo')}>
            <Box display='flex' mb={2}>
                <Box flexGrow={1} />
                <Button disabled={_.size(selected) <= 0} onClick={() => setDialogMode('delete')} sx={{ mr: 1 }}>
                    {t('delete')}
                </Button>
                <Button color='primary' variant='contained' onClick={() => nav('/admin/memo/publish')}>
                    {t('add_memo')}
                </Button>
            </Box>
            <Dialog
                // maxWidth={maxWidth}
                open={isDialogOpen}
            >
                <DialogTitle sx={{ pt: 3, fontSize: '24px' }} color='textPrimary' className='bold' >
                    {selectedRow?.title}
                </DialogTitle>
                <Divider />
                <DialogContent>
                    {/* <Typography paragraph>
                            {_.map(selectedRow?.filters, f => t(f.name)).sort().join(', ')}
                        </Typography> */}
                    <Typography paragraph style={{ whiteSpace: 'pre-wrap' }}>
                        {selectedRow?.message}
                    </Typography>
                </DialogContent>
                <Divider />

                <DialogActions>

                    <Button onClick={() => setIsDialogOpen(false)}>
                        {t('close')}
                    </Button>

                </DialogActions>
            </Dialog>
            <DataTable
                rowOnClick={(row) => {
                    setSelectedRow(row)
                    setIsDialogOpen(true)
                }}
                sx={{
                    "& .MuiTableBody-root .MuiTableRow-hover": {
                        cursor: 'pointer'
                    },
                }}
                rows={memo}
                loading={false}
                heads={[
                    {
                        type: 'checkbox',

                    },

                    { label: t('title'), value: 'title',  },

                    // {
                    //     id: 'message', label: t('message'), sx: { maxWidth: '20%', }, content: (row) =>

                    //         <div className='w100 textoverflow-1'>{row.message}</div>

                    // },
                    {
                        label: t('created_at'),
                        value: 'created_at',

                        content: (row) => moment(row.created_at).format('YYYY/MM/DD'), sort: true
                    },
                ]}
                checked={selected}
                setChecked={setSelected}
            />
            <EditDialog
                title={t('delete_memo')}
                handleSave={handleDeleteNotice}
                open={dialogMode == 'delete'}
                handleClose={() => setDialogMode(null)}
                confirmText={t('confirm')}
            >
                <p>
                    {t('delete_memo_confirm_msg')}
                </p>
            </EditDialog>
        </LayoutContainer >

    );
}

export default Memo;