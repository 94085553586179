import { Helmet } from 'react-helmet';
import LayoutTitle from './LayoutTitle';
import {
    Container
} from '@material-ui/core';
import PeriodSelectorV2 from './PeriodSelectorV2';
import { Stack } from '@mui/material';


const LayoutContainer = ({ helmetTitle = '', title, backSrc, goBack, children, Component = () => null, ActionComponent = () => null, }) => {
    return (
        <Container>
            <Helmet>
                <title>{title || helmetTitle} - Pointant Class</title>
            </Helmet>
            <Stack direction="row" sx={{ justifyContent: "space-between", alignItems: "flex-start" }}>
                {title && <LayoutTitle title={title} backSrc={backSrc} goBack={goBack}><ActionComponent /></LayoutTitle>}
                <PeriodSelectorV2 />
            </Stack>
            {children || <Component />}
        </Container>
    )
}
export default LayoutContainer
