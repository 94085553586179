import { Helmet } from 'react-helmet';
import {
  Backdrop,
  Box,
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  CardHeader,
  Button,
  Divider,
  IconButton,
  TextField,
  InputAdornment
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useCallback, useEffect } from 'react';
import { useBackDrop } from 'src/provider/BackdropProvider';
import { useState } from 'react';
import useApi from 'src/api/api';
import { ArrowBack } from '@material-ui/icons';
import Icons from 'src/icons';
import DataTable from 'src/components/DataTable/DataTable';
import DataTableNoLimit from 'src/components/DataTable/DataTableNoLimit';
import _ from 'lodash';
import { useNavigate } from 'react-router';
import { useLocale } from 'src/provider/LocaleProvider';
import utils from 'src/utils/utils';
import SearchInput from 'src/components/SearchInput';
import LayoutContainer from 'src/layout/LayoutContainer';
import { useAuthData } from 'src/provider/AuthDataProvider';
import RuleSelector from 'src/components/RuleSelector';
const moment = require('moment-timezone');

const useStyles = makeStyles((theme) => ({
  row: {
    // height: '10px',
    // paddingBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2)
  },
  spacer: {
    flex: 1
  },

  tableCell: {
    '& .MuiTableCell-root': {
      textAlign: 'center',
      borderLeft: 'none !important'
    },
    '& .MuiTableRow-root': {
      height: '60px'
    }
    //
  },
  caption: {
    fontSize: '12px'
  }
}));

const USubmittedHomeworkRecord = () => {
  const classes = useStyles();
  const loadingView = useBackDrop();
  const api = useApi();
  const nav = useNavigate();
  const { t, getLanguage } = useLocale();
  const { userInfo, selectedPeriod, currentPeriod } = useAuthData();

  const [filter, setFilter] = useState(null);
  const [record, setRecord] = useState([]);

  const handleExport = () => {
    utils.exportCsv(
      record,
      [
        {
          label: t('date'),
          value: (row) => moment(row.date).format('YYYY-MM-DD')
        },
        { label: t('status'), value: (row) => row.pointRule.name },
        {
          label: t('action_created_by'),
          value: (row) => _.get(row, `created_by.name.${getLanguage()}`)
        }
      ],
      'point_record'
    );
  };

  useEffect(() => {
    // loadingView.open()

    api
      .getStudentSubmittedHomeworkRecord(userInfo._id)
      .then(({ data }) => {
        console.log('data: ', data);
        setRecord(data.records);
      })
      .catch((e) => console.log(e));
    // loadingView.close()
  }, []);

  const filteredList = useCallback(
    utils.searchFilter(record, filter?._id, ['pointRule.ruleCategory._id']),
    [record, filter]
  );

  return (
    <LayoutContainer
      title={t('submitted_homework_record')}
      backSrc="/user/overview"
    >
      <Grid container spacing={1} sx={{ mb: 2 }}>
        {/* <Grid item xs={12} sm={6}>
          <RuleSelector selected={filter} setSelected={setFilter} />
        </Grid> */}
        <div></div>
        <Grid item xs={12} sm={12} className="flex ac">
          <div className="spacer"></div>
          <Button onClick={handleExport}>{t('export')}</Button>
        </Grid>
      </Grid>
      <DataTable
        sx={{ mb: 1 }}
        className={classes.tableCell}
        rows={filteredList}
        heads={[
          {
            label: t('date'),
            value: 'date',
            content: (row) => moment(row.date).format('YYYY-MM-DD'),
            width: '33.3%'
          },
          {
            label: t('status'),
            value: 'status',
            translate: 1,
            width: '33.3%'
          },
          {
            label: t('action_created_by'),
            content: (row) => _.get(row, `created_by.name.${getLanguage()}`),
            width: '33.3%'
          }
        ]}
      />
      <div align="right" className={'font12'}>
        Last Updated at {moment().format('YYYY-MM-DD HH:mm:ss')}
      </div>
    </LayoutContainer>
  );
};
export default USubmittedHomeworkRecord;
