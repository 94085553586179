import {
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { useEffect, useMemo, useRef, useState } from 'react';
import useApi from 'src/api/api';
import ClassSelector from 'src/components/ClassSelector';
import CustomDatePicker from 'src/components/DatePicker';
import LoadingView from 'src/components/LoadingView';
import { PeriodWarning } from 'src/components/PeriodWarning';
import SearchInput from 'src/components/SearchInput';
import TransferTable from 'src/components/TransferTable';
import LayoutContainer from 'src/layout/LayoutContainer';
import { useAuthData } from 'src/provider/AuthDataProvider';
import { useLocale } from 'src/provider/LocaleProvider';
import { useSnack } from 'src/provider/SnackbarProvider';
import utils from 'src/utils/utils';

const moment = require('moment-timezone');
const _ = require('lodash');
const yup = require('yup');
const useStyles = makeStyles({
  checked: {
    backgroundColor: 'rgba(50, 255, 50,0.3)',
    '&:hover': {
      backgroundColor: 'rgba(50, 255, 50,0.4) !important'
    }
  }
});

const UnfinishedHomework = () => {
  const classes = useStyles();
  const api = useApi();
  const timer = useRef();
  const snack = useSnack();
  const { t, getLanguage } = useLocale();

  const [studentList, setStudentList] = useState([]);
  const [allSubject, setAllSubject] = useState([]);
  const { selectedPeriod, organization } = useAuthData();

  const [search, setSearch] = useState('');
  const [searchStudent, setSearchStudent] = useState('');
  const [filter, setFilter] = useState();
  const [homeworkCategory, setHomeworkCategory] = useState('');

  const [selectedStudent, setSelectedStudent] = useState([]);
  const [homeworkType, setHomeworkType] = useState([]);

  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const [dateRange, setDateRange] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isOutdated, setIsOutdated] = useState(false);

  const formik = useFormik({
    initialValues: {
      homeworkType_id: '',
      profiles: [],
      remarkMap: {},
      date: selectedDate
    },
    validationSchema: yup.object().shape({
      homeworkType_id: yup.string().required(),

      profiles: yup
        .array()
        .min(1)
        .of(
          yup.object().shape({
            profile: yup.string().required(),
            remark: yup.string()
          })
        ),
      date: yup.date().required()
    }),
    onSubmit: async (values, helper) => {
      helper.setSubmitting(true);

      api
        .putUnfinishedHomeworkRecord(values)
        .then((_res) => {
          snack.open(t('upload_success'));
          api.getStudentList().then((res) => {
            setStudentList(res.data);
            setSelectedStudent([]);
          });
        })
        .catch((_e) => {
          snack.error(t('upload_fail'));
        })
        .finally(() => {
          helper.setSubmitting(false);
          helper.resetForm();
        });
    }
  });
  useEffect(() => {
    setDateRange(organization?.homework_date_range?.unfinishHomeworkRange);
    api
      .getHomeworkType()
      .then((res) => {
        setAllSubject(res.data?.allSubject);
        setHomeworkType(res.data?.homeworkType);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    api.getStudentList().then((res) => {
      setStudentList(res.data);
      formik.resetForm();
      setSelectedStudent([]);
    });
  }, [selectedPeriod]);

  //* setFormik State
  useEffect(() => {
    formik.setFieldValue(
      'profiles',
      _.map(selectedStudent, (s) => ({
        profile: s.profile,
        remarks: s?.remark ?? ''
      }))
    );
  }, [selectedStudent]);

  useEffect(() => {
    formik.setFieldValue('date', selectedDate);
  }, [selectedDate]);
  //* setFormik State

  var classFiltered = useMemo(
    () => utils.searchFilter(studentList, filter?.value, ['class']),
    [studentList, filter?.value]
  );

  var homeworkTypeFiltered = _.flow(
    () => {
      if (!homeworkCategory) return homeworkType;
      return _.filter(
        homeworkType,
        (type) => type?.subject?._id === homeworkCategory
      );
    },
    (result) => {
      return utils.searchFilter(result, [search], [['name']]);
    }
  );

  var handleRemarkChange = (profile, remarkValue) => {
    const newSelectedStudent = _.map(selectedStudent, (student) => {
      if (profile === student.profile)
        return { ...student, remark: remarkValue };
      else return student;
    });

    setSelectedStudent(newSelectedStudent);
  };
  const debounce = (profile, remarkValue) => {
    clearTimeout(timer.current);
    timer.current = setTimeout(handleRemarkChange(profile, remarkValue), 200);
  };

  useEffect(() => {
    api.getPeriodList((result) => {
      const selectedPeriodObject = _.filter(
        result,
        (period) => period?._id === selectedPeriod
      );

      if (moment().isAfter(selectedPeriodObject[0].end_at)) setIsOutdated(true);
      else setIsOutdated(false);
    });
  }, [selectedPeriod]);

  if (isLoading) {
    return <LoadingView></LoadingView>;
  }
  return (
    <LayoutContainer title={t('unfinished_homework')}>
      {!!isOutdated && <PeriodWarning />}

      <form onSubmit={formik.handleSubmit}>
        <Typography variant="h3" paragraph>
          {t('homework_chosen')}
        </Typography>
        <Grid container spacing={1} sx={{ mb: 2 }}>
          <Grid item>
            <FormControl>
              <InputLabel shrink>{t('subject')}</InputLabel>
              <Select
                className="mySelect"
                variant="outlined"
                size="small"
                displayEmpty
                label={t('subject')}
                onChange={(event) => setHomeworkCategory(event.target.value)}
                value={homeworkCategory}
                defaultValue={''}
                sx={{ minWidth: '200px', width: 'min(200px, 100%)', mr: 1 }}
              >
                <MenuItem value=""> {t('see_all')} </MenuItem>
                {_.map(allSubject, (n) => (
                  <MenuItem key={n._id} value={n._id}>
                    {' '}
                    {`${_.get(n, `name.${getLanguage()}`)}`}{' '}
                    {/* {_.get(n, `name.${getLanguage()}`, '-')}{' '} */}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sx={{ flex: 'auto' }}>
            <SearchInput setSearch={setSearch} />
          </Grid>

          <Grid item>
            <CustomDatePicker
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              minDate={moment().subtract(dateRange, 'days').toDate()}
              maxDate={moment().toDate()}
            />
          </Grid>
        </Grid>

        <Card elevation={2} sx={{ mb: 3, maxWidth: '800px' }}>
          <List
            className={classes.list}
            component="div"
            role="list"
            sx={{ pr: 2 }}
          >
            <ListItem style={{ height: '40px' }} key={'code'}>
              <ListItemText primary={t('code')} style={{ flexBasis: '70%' }} />
            </ListItem>
          </List>
          <Divider />
          <List
            className={classes.list}
            component="div"
            role="list"
            sx={{ overflowY: 'scroll', maxHeight: '400px' }}
          >
            {_.map(homeworkTypeFiltered(), (type, _index) => {
              var className = classNames({
                [classes.checked]: type._id === formik.values.homeworkType_id
              });
              if (type.isActive) {
                return (
                  <ListItemButton
                    className={className}
                    key={type._id}
                    onClick={() =>
                      formik.setFieldValue('homeworkType_id', type?._id)
                    }
                  >
                    <ListItemText
                      primary={type.name}
                      style={{ flexBasis: '70%' }}
                    />
                  </ListItemButton>
                );
              } else return null;
            })}
            {_.size(homeworkTypeFiltered()) === 0 && (
              <ListItemButton key={'no_record'}>
                <ListItemText
                  primary={t('no_record')}
                  style={{ textAlign: 'center', flexBasis: '30%' }}
                />
              </ListItemButton>
            )}
            <ListItemButton />
          </List>
        </Card>

        {!!formik.values?.homeworkType_id && !!selectedDate && (
          <>
            <Typography variant="h3" paragraph>
              {t('choose_student')}
            </Typography>
            <Grid container spacing={1} sx={{ mb: 2 }}>
              <Grid item>
                <ClassSelector selected={filter} setSelected={setFilter} all />
              </Grid>
              <Grid item sx={{ flex: 'auto' }}>
                <SearchInput setSearch={setSearchStudent} />
              </Grid>
            </Grid>
            <TransferTable
              list={utils.searchFilter(classFiltered, searchStudent, [
                'class',
                'class_no',
                'name.en',
                'name.zh'
              ])}
              selected={selectedStudent}
              setSelected={setSelectedStudent}
              leftHeads={[
                { id: 'class', label: t('class'), value: 'class' },
                { id: 'class_no', label: t('class_no'), value: 'class_no' },
                {
                  id: 'name',
                  label: t('name'),
                  value: `name.${getLanguage()}`
                }
              ]}
              rightHeads={[
                { id: 'class', label: t('class'), value: 'class' },
                { id: 'class_no', label: t('class_no'), value: 'class_no' },
                {
                  id: 'name',
                  label: t('name'),
                  value: `name.${getLanguage()}`
                },
                {
                  id: 'remark',
                  label: t('remark'),
                  content: (row) => {
                    return (
                      <TextField
                        label={t('remark')}
                        index={row.index}
                        onChange={(e) => {
                          debounce(row.profile, e.target.value);
                        }}
                      />
                    );
                  }
                }
              ]}
            />
          </>
        )}

        <Box className="flex ac" justifyContent="flex-end" mt={2}>
          <Button
            type="submit"
            disabled={
              _.size(formik.errors) > 0 ||
              !formik.values.homeworkType_id ||
              formik.isSubmitting
            }
          >
            {t('confirm')}
          </Button>
        </Box>
      </form>
    </LayoutContainer>
  );
};

export default UnfinishedHomework;
