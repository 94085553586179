import {
	Box,
	Button,
	Card,
	Chip,
	IconButton,
	Tooltip,
} from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import { useMemo, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import useApi from "src/api/api";
import ClassSelector from "src/components/ClassSelector";
import SearchInput from "src/components/SearchInput";
import { SubjectSelector } from "src/components/SubjectSelector";
import Icons from "src/icons";
import LayoutContainer from "src/layout/LayoutContainer";
import { useLocale } from "src/provider/LocaleProvider";
import { PreviewElement } from "./PreviewElement";
import { ResourceCreateDialog } from "./ResourceActionDialog";

const _ = require("lodash");
const moment = require("moment-timezone");
const prettyBytes = require('pretty-bytes');
export const LearningResource = () => {
	const { t, getLanguage } = useLocale();
	const api = useApi();
	const [paginationModel, setPaginationModel] = useState({
		pageSize: 5,
		page: 0,
	});
	const [dialogMode, setDialogMode] = useState("");
	const [filter, setFilter] = useState();
	const [subject, setSubject] = useState("ALL");
	const [name, setName] = useState("");
	const [selectedRow, setSelectedRow] = useState({});
	const isOpen = useMemo(
		() => dialogMode === "CREATE" || dialogMode === "EDIT",
		[dialogMode],
	);

	const { data, isLoading } = useQuery(
		[
			"learning-resource",
			paginationModel.page + 1,
			paginationModel.pageSize,
			filter,
			subject,
			name,
		],
		async () => {
			const { data } = await api.getResources({
				page: paginationModel.page + 1,
				limit: paginationModel.pageSize,
				filters: filter?._id ? [filter] : [],
				subjects: subject === "ALL" ? [] : [subject],
				name,
			});
			return data;
		},
	);
	const storagePecentage = (data?.storage?.usedStorage / data?.storage?.totalStorage) * 100;
	const totalStorage = prettyBytes(data?.storage?.totalStorage ?? 0);
	const usedStorage = prettyBytes(data?.storage?.usedStorage ?? 0);
	const chipColor = storagePecentage > 80 ? "error" : storagePecentage > 60 ? "warning" : "success";

	const rows = useMemo(() => {
		if (!data?.resources) return [];
		return _.map(data.resources, (row, index) => ({
			id: index,
			...row,
		}));
	}, [data]);

	const columns = [
		{
			field: "entry_point",
			headerName: "",
			flex: 1,
			sortable: false,
			renderCell: (params) => {
				const ext = params?.row?.file_extension;
				return <PreviewElement params={params} ext={ext} />;
			},
		},
		{
			field: "name",
			headerName: t("name"),
			flex: 1,
			valueGetter: (params) => params.row?.name[getLanguage()],
		},
		{
			field: "created_by",
			headerName: t("action_created_by"),
			flex: 1,
			valueGetter: (params) => params.row?.created_by?.name[getLanguage()],
		},
		{
			field: "created_at",
			headerName: t("created_at"),
			flex: 1,
			valueGetter: (params) =>
				moment(params.value).format("YYYY-MM-DD HH:mm:ss"),
		},
		{
			field: "updated_at",
			headerName: t("Last_Updated_at"),
			flex: 1,
			valueGetter: (params) =>
				moment(params.value).format("YYYY-MM-DD HH:mm:ss"),
		},
		{
			field: "",
			headerName: t("actions"),
			flex: 1,
			renderCell: (params) => {
				return (
					<Box sx={{ mx: -1 }}>
						<Tooltip title={t("edit")}>
							<IconButton onClick={() => handleEdit(params)} sx={{ p: 0.5 }}>
								<Icons.EditIcon />
							</IconButton>
						</Tooltip>
					</Box>
				);
			},
		},
	];

	const handleEdit = (params) => {
		const { row } = params;
		setSelectedRow({
			...row,
		});

		setDialogMode("EDIT");
	};

	const handleCreate = () => {
		setSelectedRow();
		setDialogMode("CREATE");
	};

	const deboundedSearch = _.debounce((value) => {
		setName(value);
	}, 500);

	return (
		<LayoutContainer title={t("learning_resource")}>
			<Box mb={3} sx={{ display: "flex", justifyContent: "space-between" }}>
				<Box display={"flex"}>
					<SearchInput setSearch={deboundedSearch} />
					<ClassSelector
						selected={filter}
						setSelected={setFilter}
						sx={{ mx: 1 }}
					/>
					<SubjectSelector selected={subject} setSelected={setSubject} />
				</Box>
				<Box display={"flex"} sx={{ gap: 1 }}>
					<Tooltip title={`${t("learning_resource_storage")}: ${usedStorage}/${totalStorage}`}>
						<Chip
							icon={<Icons.SdStorageIcon />}
							color={chipColor}
							label={`${storagePecentage}%`}
						/>
					</Tooltip>
					<Button onClick={handleCreate}>{t("add_learning_resource")}</Button>
				</Box>
			</Box>
			<Card sx={{ minHeight: 220, minWidth: 300 }}>
				<DataGrid
					rowHeight={220}
					rows={rows}
					columns={columns}
					loading={isLoading}
					pageSizeOptions={[5, 10, 15]}
					paginationModel={paginationModel}
					onPaginationModelChange={setPaginationModel}
					paginationMode="server"
					rowCount={data?.totalPages * paginationModel?.pageSize || 0}
				/>
			</Card>

			{isOpen && (
				<ResourceCreateDialog
					dialogMode={dialogMode}
					setDialogMode={setDialogMode}
					isEdit={dialogMode === "EDIT"}
					isOpen={isOpen}
					selectedRow={selectedRow}
				/>
			)}
		</LayoutContainer>
	);
};
