import React, { Fragment, useMemo, useCallback, useEffect, useState, useRef, useLayoutEffect } from 'react'
import useApi from 'src/api/api';
import { Helmet } from 'react-helmet';
import DataTable from 'src/components/DataTable/DataTable';
import { useLocale } from 'src/provider/LocaleProvider';
import { useAuthData } from 'src/provider/AuthDataProvider';
import Icons from 'src/icons';
import { Formik, useFormik } from 'formik';
import formikProps from 'src/utils/formikProps';
import utils from 'src/utils/utils';
import LayoutContainer from 'src/layout/LayoutContainer';
import { useSnack } from 'src/provider/SnackbarProvider';
import { useNavigate } from 'react-router';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { BsFillTriangleFill } from 'react-icons/bs';
import {
    Avatar,
    Grid,
    Box,
    Button,
    Card,
    Checkbox,
    Radio,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import classNames from 'classnames'
import EditDialog from 'src/components/EditDialog';
const moment = require('moment-timezone')
const _ = require('lodash')
const yup = require('yup')


const useStyles = makeStyles({
    table: {
        "& .MuiTableRow-root": {
            height: '50px'
        },
        "& .MuiTableCell-root": {
            whiteSpace: 'nowrap',
            textAlign: 'center',

        },
        '& .borderLeft': {
            borderLeft: "1px solid rgba(224, 224, 224, 0.8)",
        },
        "& .MuiTableSortLabel-icon": {
            opacity: 0.5,
        },
        "& .Mui-active .MuiTableSortLabel-icon": {
            opacity: 1,
            color: 'limegreen',
        },
        '& .row-new': {
            backgroundColor: 'rgba(50, 255, 50,0.2)',
            '&:hover': {
                backgroundColor: 'rgba(50, 255, 50,0.3)'
            }
        },
        '& .row-update': {
            backgroundColor: 'rgba(255, 255, 50,0.2)',
            '&:hover': {
                backgroundColor: 'rgba(255, 255, 50,0.3)'
            }
        },
        '& .cell-update': {
            // color: 'brown',
            fontWeight: 'bold'
        },
        '& .error': {
            backgroundColor: 'rgba(255, 50, 50,0.2) !important',
            '&:hover': {
                backgroundColor: 'rgba(255, 50, 50,0.3) !important'
            }
        }
    },
    toolbar: {
        display: 'flex',
        '& .left': {
            width: '100%',
            display: 'flex',
            margin: '-8px',
            width: 'calc(100% - 16px)',
            flexWrap: 'wrap',
            '& .item': {
                display: 'flex',
                alignItems: 'center',
                padding: '8px',
                whiteSpace: 'nowrap'
            }
        },
        '& .right': {
            width: '100%',
            display: 'flex',
            margin: '-8px',
            width: 'calc(100% - 16px)',
            flexWrap: 'wrap',
            '& .item': {
                display: 'flex',
                alignItems: 'center',
                padding: '8px',
                whiteSpace: 'nowrap'
            }
        }
    }
})


const StudentUploadTable = ({
    adminList = [],
    rows = [],
    loading = false,
    _orderBy = 'index',
    _order = 'asc',
    rowsPerPage = 10,
    rowsPerPageOptions = [5, 10, 25],
    rowsRef,
    formik,
    ...rest
}) => {
    const { t } = useLocale()
    const classes = useStyles()


    const { organization } = useAuthData()

    const [order, setOrder] = useState(_order)
    const [orderBy, setOrderBy] = useState(_orderBy)

    const sorted = _.orderBy(_.map(rows, (r, index) => ({ ...r, index: index + 1, class_no: r.class_no && _.toString(r.class_no).padStart(2, '0') })), [orderBy], [order])

    const handleSort = (path) => {
        if (orderBy == path) {
            setOrder(order === 'asc' ? 'desc' : 'asc')
        }
        else {
            setOrderBy(path)
            setOrder('asc')
        }
    }

    const headCell = (label, path, sort = true) => {
        if (sort) {
            return (
                <TableSortLabel active={orderBy === path} direction={orderBy === path ? order : 'asc'} onClick={(e) => handleSort(path)} hideSortIcon={false} >
                    {label}
                </TableSortLabel>
            )
        }
        else return label
    }

    useEffect(() => {
        formik.validateForm()
    }, [order, orderBy]);

    return (
        <Card {...rest}>
            <Box >
                <Table size='small' className={classNames(classes.table)}>
                    <TableHead>
                        <TableRow sx={{ height: '30px' }}  >
                            <TableCell width='20px'>{headCell(t('index'), 'index')}</TableCell>
                            <TableCell className='borderLeft'>{headCell(t('email'), 'email')}</TableCell>
                            {/* <TableCell>{headCell(t('email'), 'email', false)}</TableCell> */}
                            <TableCell>{headCell(t('name_zh'), 'name.zh')}</TableCell>
                            <TableCell>{headCell(t('name_en'), 'name.en')}</TableCell>
                            <TableCell>{headCell(t('password'), 'username', false)}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {_.map(sorted, (row, index) => {
                            var errors = _.get(formik.errors, row.index - 1)
                            var isError = _.size(errors) > 0
                            var oldStudent = _.find(adminList, { email: row.email })
                            return <>
                                <TableRow style={{ height: 50 }} className={classNames({ 'row-new': !oldStudent, 'row-update': oldStudent, 'error': isError })}>
                                    <TableCell >
                                        {row.index}
                                    </TableCell>
                                    {_.map(['email', 'name.zh', 'name.en', 'password'], (path, index) => {
                                        var newValue = _.get(row, path)
                                        var oldValue = _.get(oldStudent, path)
                                        var isChanged = oldStudent && newValue != oldValue
                                        return <TableCell className={classNames({ 'cell-update': !isError && isChanged, 'borderLeft': index == 0 })}>
                                            {newValue || '-'}
                                        </TableCell>
                                    })}
                                </TableRow>
                                {_.size(errors) > 0 && <TableRow>
                                    <TableCell colSpan={99} sx={{ px: 1 }} >
                                        <Box display='flex' flexDirection='row'>
                                            <BsFillTriangleFill style={{ height: '16px', width: '16px', color: 'red' }} />
                                            <Box sx={{ color: 'red', ml: 1, textAlign: 'left' }}>
                                                {_.keys(errors).map(e => {
                                                    return <p>{`${t(e)} : ${errors[e]}`}</p>
                                                })}
                                            </Box>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                                }
                            </>
                        })}
                        {_.size(rows) == 0 &&
                            <TableRow style={{ height: 50 }}>
                                <TableCell colSpan={99}>
                                    <Typography variant='h5'>{t('no_records')}</Typography>
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </Box>
        </Card >
    )
}


const StudentUpload = () => {
    const api = useApi()
    const nav = useNavigate()

    const { t } = useLocale()
    const snack = useSnack()
    const classes = useStyles()
    const [adminList, setAdminList] = useState([]);
    const [newAdminList, setNewAdminList] = useState([]);
    const [open, setOpen] = useState();


    useEffect(() => {
        api.getAdminList().then(res => {
            setAdminList(res.data)
        })
    }, []);

    const schema = yup.array().of(
        yup.object().shape({
            name: yup.object({
                en: yup.string(),
                zh: yup.string(),
            }),
            email: yup.string().required(),
            password: yup.string().min(6).nullable()
        })
    )
    const formik = useFormik({
        initialValues: newAdminList || [],
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: (values, helper) => {
            api.batchCreateAdmin(values).then(res => {
                nav('/admin/admin_list')
                snack.open(t('upload_success'))
            }).catch(e => {
                snack.error(t('upload_fail'))
            }).finally(() => {
                helper.setSubmitting(false)
            })
        }
    })

    const handleExport = () => {
        utils.exportCsv([], [
            { label: 'Email', value: row => row.email },
            { label: 'Chinese_Name', value: row => row.name?.zh },
            { label: 'English_Name', value: row => row.name?.en },
            { label: 'Password', value: row => row.password },
        ], 'admin_template')
    }

    const handleCsv = async (f) => {
        var csv = await utils.readFileAsText(f)
        var arr = await utils.csv2JsonArray(csv, {
            'Email': 'email',
            'Chinese_Name': 'name.zh',
            'English_Name': 'name.en',
            'Password': 'password',
        })

        setNewAdminList(_.filter(arr, item => item.email))
    }

    useEffect(() => {
        formik.validateForm()
    }, [formik.initialValues]);


    const handleConfirm = async () => {
        var errors = await formik.validateForm()
        if (_.size(errors) <= 0) {
            setOpen(true)
        }
    }

    var count = useMemo(() => _.countBy(newAdminList, s => {
        return _.findIndex(adminList, { email: s.email }) >= 0 ? 'update' : 'new'
    }), [open])

    return (
        <LayoutContainer title={t('upload_admin')} backSrc="/admin/admin_list">
            <Grid container spacing={1} mb={2}>
                <Grid item xs className='flex ac' sx={{ minWidth: 'min(100%,300px)', width: '200px' }}>
                    <Grid container spacing={1} >
                        <Grid item className='flex ac'>
                            <Box height='20px' width='20px' sx={{ backgroundColor: 'rgba(50, 255, 50,0.2) !important', mr: 1, border: '2px black solid' }} />
                            <span > {t('add_admin')} </span>
                        </Grid>
                        <Grid item className='flex ac'>
                            <Box height='20px' width='20px' sx={{ backgroundColor: 'rgba(255, 255, 50,0.2)!important', mr: 1, border: '2px black solid' }} />
                            <span > {t('update_admin')} </span>
                        </Grid>
                        <Grid item className='flex ac'>
                            <Box height='20px' width='20px' sx={{ backgroundColor: 'rgba(255, 50, 50,0.2) !important', mr: 1, border: '2px black solid' }} />
                            <span > {t('invalid_data')} </span>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item >
                    <Grid container spacing={1} >
                        <Grid item className='flex ac' >
                            <Button onClick={handleExport} variant='outlined'>
                                {t('download_template')}
                            </Button>
                        </Grid>
                        <Grid item className='flex ac' >
                            <Button onClick={() => utils.readFile(handleCsv, '.csv')}>
                                {t('choose_file')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <StudentUploadTable formik={formik} rows={newAdminList} adminList={adminList} _orderBy='class' _order='asc' />
            <Box display='flex' justifyContent='flex-end' mt={2}>
                <Button onClick={handleConfirm} disabled={_.size(formik.errors)}>
                    {t('save')}
                </Button>
            </Box>
            <EditDialog title={t('confirm_upload_user')} maxWidth='xs' handleSave={formik.handleSubmit} open={open} handleClose={() => setOpen(false)} confirmText={t('confirm')}>
                {count?.new && <p>{t('add_a_admins', { a: count?.new })}</p>}
                {count?.update && <p>{t('update_a_admins', { a: count?.update })}</p>}
            </EditDialog>
        </LayoutContainer>
    );
}

export default StudentUpload;