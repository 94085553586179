import {
  Box,
  Button,
  Grid,
  IconButton,
  Tab,
  Tabs,
  TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import useApi from 'src/api/api';
import FacebookIcon from 'src/icons/Facebook';
import { useLocale } from 'src/provider/LocaleProvider';
import { useSnack } from 'src/provider/SnackbarProvider';
import * as Yup from 'yup';

const _ = require('lodash');

const useStyles = makeStyles((theme) => ({
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.png)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  langIcon: {
    fontSize: '16px',
    lineHeight: '32px',
    width: '40px',
    height: '40px'
  },
  form: {},
  a: {
    '&:visited': {
      color: 'inherit'
    },
    local: {
      '&:hover': {
        cursor: 'pointer'
      }
    }
  }
}));

const Login = () => {
  const navigate = useNavigate();
  const { t, setLanguage } = useLocale();
  const api = useApi();
  const token = localStorage.getItem('token');

  const snack = useSnack();
  const classes = useStyles();

  const [currentTab, setCurrentTab] = useState('user');

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touchedText: false,
    touchedPassword: false,
    errors: {},
    type: 'username',
    textFieldName: '用戶名稱'
  });

  useEffect(() => {
    if (token) {
      api.getUserInfo().then((res) => {
        if (res.data.setup_password) {
          return navigate('/reset_password', { replace: true });
        }
        if (res.data.role == 'user') {
          navigate('/user', { replace: true });
        } else {
          navigate('/admin', { replace: true });
        }
      });
    }
  }, []);

  const login = (values, actions) => {
    var data;
    if (currentTab == 'user' || currentTab == 'parent') {
      data = {
        username: values.username,
        password: values.password,
        type: currentTab
      };
    } else {
      data = {
        email: values.email,
        password: values.password,
        type: currentTab
      };
    }
    api
      .login(data)
      .then((res) => {
        if (res.data.setup_password) {
          navigate('/reset_password', { replace: true });
        } else {
          if (data.type == 'admin') {
            navigate('/admin', { replace: true });
          } else {
            navigate('/user', { replace: true });
          }
        }
      })
      .catch((e) => {
        if (e == 'timeout') {
          snack.error(t('server_connection_error'));
        } else if (e == 'invalid_user') {
          snack.error(t('invalid_user'));
        } else if (e == 'invalid_password') {
          snack.error(t('invalid_password'));
        } else {
          snack.error(t('login_fail_message'));
        }
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      username: '',
      email: '',
      password: ''
    },
    validationSchema: Yup.object().shape({
      username: Yup.string()
        .max(255)
        .when('tab', {
          is: (v) => currentTab == 'user' || currentTab == 'parent',
          then: Yup.string().required(t('yup_required_username'))
        }),
      email: Yup.string()
        .email()
        .max(255)
        .when('tab', {
          is: (v) => currentTab == 'admin',
          then: Yup.string().required(t('yup_required_email'))
        }),
      password: Yup.string().max(255).required()
    }),
    onSubmit: login
  });

  const {
    errors,
    setValues,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    touched,
    values
  } = formik;

  useEffect(() => {
    formik.resetForm();
  }, [currentTab]);

  if (token) return null;
  return (
    <>
      <Helmet>
        <title>{t('login')} - Pointant Class</title>
      </Helmet>
      <Grid container className="h100_w100">
        <Grid item xs={0} md={5}>
          <div className={classes.quote}></div>
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          className="flex jcc ac"
          sx={{ flexDirection: 'column', position: 'relative' }}
        >
          <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
            <IconButton
              onClick={() => setLanguage('en')}
              className={classes.langIcon}
              sx={{ ml: 1 }}
            >
              EN
            </IconButton>
            <IconButton
              onClick={() => setLanguage('zh')}
              className={classes.langIcon}
            >
              繁
            </IconButton>
          </Box>
          <Box flexGrow={1} />
          <Box sx={{ width: { xs: '100%', sm: '80%' } }} p={3}>
            <form onSubmit={handleSubmit} className="w80">
              <Box
                width={'150px'}
                height={'150px'}
                mb={2}
                mx={'auto'}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  window.location.href = 'https://class.pointant-app.com';
                }}
              >
                <img src="/images/logo.png" alt="" className={'h100_w100'} />
              </Box>
              <RolesTab currentTab={currentTab} setCurrentTab={setCurrentTab} />
              {currentTab !== 'admin' ? (
                <TextField
                  error={Boolean(touched.username && errors.username)}
                  fullWidth
                  helperText={touched.username && errors.username}
                  label={t('username')}
                  margin="normal"
                  name="username"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="username"
                  value={values.username}
                />
              ) : (
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label={t('email')}
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                />
              )}
              <TextField
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                label={t('password')}
                margin="normal"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
              />
              <Box sx={{ py: 2 }}>
                <Button
                  color="primary"
                  disabled={isSubmitting || _.size(errors) > 0}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {t('login')}
                </Button>
              </Box>
              <Box>
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => snack.info(t('forget_password_msg'))}
                  style={{ float: 'right' }}
                >
                  {t('forget_password')}
                </Button>
              </Box>
              <Box
                mt={1}
                className="w100 flex jcc"
                m={'-4px'}
                style={{
                  flexFlow: 'wrap',
                  visibility: currentTab === 'admin' ? 'visible' : 'hidden '
                }}
              >
                <a
                  href="https://apps.apple.com/us/app/id1528318712"
                  style={{ margin: '8px' }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/p5_appstore_V2.png`}
                    height="50"
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.animae.pointant_class_app_admin"
                  style={{ margin: '8px' }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/p5_googleplay_V2.png`}
                    height="50"
                  />
                </a>
              </Box>
            </form>
          </Box>
          <Box flexGrow={1} />
          <Box className="w100 tc">
            <span>
              Copyright © 2023 Animae Technologies Limited. All Rights Reserved.
            </span>
            <a
              className={classes.a}
              href="https://www.facebook.com/PointantClass"
              target="_blank"
              style={{ float: 'right' }}
            >
              <FacebookIcon sx={{ m: 0.5 }} />
            </a>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

const RolesTab = (props) => {
  const { t, setLanguage } = useLocale();
  return (
    <Tabs
      value={props.currentTab}
      onChange={(e, value) => {
        props.setCurrentTab(value);
      }}
      indicatorColor="primary"
      textColor="primary"
      centered
    >
      <Tab
        style={{ outline: 'none' }}
        label={t('login_label_user')}
        value={'user'}
      />
      <Tab
        style={{ outline: 'none' }}
        label={t('login_label_parent')}
        value={'parent'}
      />
      <Tab
        style={{ outline: 'none' }}
        label={t('login_label_admin')}
        value={'admin'}
      />
    </Tabs>
  );
};

export default Login;
