import { Helmet } from 'react-helmet';
import {
  Backdrop,
  Box,
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  CardHeader,
  Button,
  Divider,
  IconButton,
  TextField,
  InputAdornment
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useEffect, useMemo } from 'react';
import { useBackDrop } from 'src/provider/BackdropProvider';
import { useState } from 'react';
import useApi from 'src/api/api';
import { ArrowBack } from '@material-ui/icons';
import Icons from 'src/icons';
import DataTable from 'src/components/DataTable/DataTable';
import DataTableNoLimit from 'src/components/DataTable/DataTableNoLimit';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router';
import { useLocale } from 'src/provider/LocaleProvider';
import utils from 'src/utils/utils';
import SearchInput from 'src/components/SearchInput';
import LayoutContainer from 'src/layout/LayoutContainer';
import { useAuthData } from 'src/provider/AuthDataProvider';
const moment = require('moment-timezone');

const useStyles = makeStyles((theme) => ({
  row: {
    // height: '10px',
    // paddingBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2)
  },
  spacer: {
    flex: 1
  },

  tableCell: {
    '& .MuiTableCell-root': {
      textAlign: 'center',
      borderLeft: 'none !important'
    },
    '& .MuiTableRow-root': {
      height: '60px'
    }
  },
  caption: {
    fontSize: '12px'
  }
}));

const SubmittedHomeworkRecord = () => {
  const classes = useStyles();
  const loadingView = useBackDrop();
  const api = useApi();
  const nav = useNavigate();
  const [search, setSearch] = useState();
  const { t, translate, getLanguage } = useLocale();
  const { userInfo, selectedPeriod, currentPeriod } = useAuthData();
  const { userId } = useParams();

  const [record, setRecord] = useState([]);

  const handleExport = () => {
    utils.exportCsv(
      record,
      [
        {
          label: t('date'),
          value: (row) => moment(row['date']).format('YYYY-MM-DD')
        },
        {
          label: t('status'),
          value: (row) => t(row.status)
        },
        {
          label: t('action_created_by'),
          value: (row) => _.get(row, `created_by.name.${getLanguage()}`)
        }
      ],
      'submitted_homework_record'
    );
  };

  useEffect(() => {
    // loadingView.open()

    api
      .getStudentSubmittedHomeworkRecord(userId, selectedPeriod)
      .then((res) => {
        setRecord(res.data);
      })
      .catch((e) => console.log(e));

    // api.getAttendanceList({ period: selectedPeriod, user: userId }).then(({ data }) => { setAttendanceHistory(data) }).catch(e => console.log(e));

    // loadingView.close()
  }, [selectedPeriod]);

  var filtered = useMemo(
    () =>
      utils.searchFilter(record.records, search, [
        `created_by.name.en`,
        `created_by.name.zh`,
        'date',
        'status'
      ]),
    [record, search]
  );

  return (
    <LayoutContainer
      title={t('submitted_homework_record')}
      backSrc={`/admin/student_list/${userId}`}
    >
      <Grid container alignItems="center" spacing={1} sx={{ mb: 2 }}>
        <Grid item xs={12} sm={6}>
          <SearchInput setSearch={setSearch} />
        </Grid>
        <Grid item xs={12} sm={6} className={'tr'}>
          <Button onClick={handleExport}>{t('export')}</Button>
        </Grid>
      </Grid>
      <DataTable
        sx={{ mb: 1 }}
        className={classes.tableCell}
        rows={filtered}
        heads={[
          {
            label: t('date'),
            value: 'date',
            content: (row) => moment(row.date).format('YYYY-MM-DD'),
            width: '33.3%',
            sort: true
          },
          {
            label: t('status'),
            translate: 1,
            value: 'status',
            width: '33.3%'
          },
          {
            label: t('action_created_by'),
            content: (row) => _.get(row, `created_by.name.${getLanguage()}`),
            width: '33.3%'
          }
        ]}
      />
      <div align="right" className={'font12'}>
        Last Updated at {moment().format('YYYY-MM-DD HH:mm:ss')}
      </div>
    </LayoutContainer>
  );
};
export default SubmittedHomeworkRecord;
