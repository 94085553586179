import { Alert, Typography } from '@material-ui/core';
import { useLocale } from 'src/provider/LocaleProvider';

export function PeriodWarning() {
  const { t } = useLocale();
  return (
    <Alert severity="error" variant="outlined" sx={{ mb: 2, fontSize: 24 }}>
      <Typography variant="h4" color="red">
        {t('period_warning')}
      </Typography>
    </Alert>
  );
}
