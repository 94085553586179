import React, { Fragment, useMemo, useCallback, useEffect, useState, useRef, useLayoutEffect } from 'react'
import useApi from 'src/api/api';
import LayoutContainer from 'src/layout/LayoutContainer';
import { useLocale } from 'src/provider/LocaleProvider';
import { Backdrop, Box, Container, Grid, Card, CardContent, Typography, CardHeader, Button, Divider, IconButton, List, ListItem, ListItemText, TextField, MenuItem, Select, FormControl, InputLabel } from '@material-ui/core';
import TransferTable from 'src/components/TransferTable';
import SearchInput from 'src/components/SearchInput';
import utils from 'src/utils/utils';
import ClassSelector from 'src/components/ClassSelector';
import { makeStyles } from '@material-ui/styles';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { useSnack } from 'src/provider/SnackbarProvider';
import formikProps from 'src/utils/formikProps';
import { useAuthData } from 'src/provider/AuthDataProvider';
import { translate } from 'react-switch-lang';
const _ = require('lodash')
const yup = require('yup')
const useStyles = makeStyles({
    checked: {
        backgroundColor: 'rgba(50, 255, 50,0.3)',
        '&:hover': {
            backgroundColor: 'rgba(50, 255, 50,0.4) !important'
        }
    }
})

const ParentPointReward = () => {
    const classes = useStyles()
    const api = useApi()
    const { t, getLanguage, translate } = useLocale()

    const { selectedPeriod } = useAuthData()
    const [selectedRule, setSelectedRule] = useState();
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState();
    const [rules, setRules] = useState([]);
    const snack = useSnack()

    const [ruleCategories, setRuleCategories] = useState([]);

    useEffect(() => {
        api.getPointRuleCategories({ type: 'parent_reward' }).then(res => {
            setRuleCategories(res.data)
        })
        api.getPointRules({ type: 'parent_reward', isActive: true }).then(res => {
            setRules(res.data)
        })
    }, []);


    const formik = useFormik({
        initialValues: {
            selectedCategory: '',
            pointRule_id: '',
        },
        validationSchema: yup.object().shape({
            pointRule_id: yup.string().required(),
        }),
        onSubmit: (values, helper) => {
            api.parentReward({ pointRule_id: values.pointRule_id, period_id: selectedPeriod }).then(res => {
                snack.open(t('success_reward'))
                setSelectedRule(null)
                helper.resetForm()
            }).catch(e => {
                var result = e?.response?.data?.result
                if (result == 'quota_exceeded') {
                    snack.error(t('exceed_reward_quota_msg'))
                }
                else {
                    snack.error(t('fail_reward'))
                }
            }).finally(() => {
                helper.setSubmitting(false)
            })
        }
    })

    useEffect(() => {
        if (selectedRule) formik.setFieldValue('pointRule_id', selectedRule?._id)
    }, [selectedRule]);

    var ruleFiltered = utils.searchFilter(rules, [formik.values.selectedCategory, search], [['ruleCategory._id'], ['name']])
    return (
        <LayoutContainer title={t('reward')}>
            <form onSubmit={formik.handleSubmit}>
                <Typography variant='h3' paragraph >{t('choose_rule')}</Typography>
                <Grid container spacing={1} sx={{ mb: 2 }}>
                    <Grid item>
                        <FormControl>
                            <InputLabel shrink>{t('rule_category')}</InputLabel>
                            <Select
                                variant='outlined'
                                size='small'
                                displayEmpty
                                label={t('rule_category')}
                                {...formikProps(formik, 'selectedCategory')}
                                sx={{ minWidth: '200px', width: 'min(200px, 100%)', mr: 1 }}
                            >
                                {/* <option disabled selected value={""} > -- {t('select_a_period')} -- </option> */}
                                <MenuItem value=""> {t('all_rule_category')} </MenuItem>
                                {_.map(ruleCategories, n => <MenuItem key={n} value={n._id}> {translate(n.name)} </MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sx={{ flex: 'auto' }}>
                        <SearchInput setSearch={setSearch} />
                    </Grid>
                </Grid>
                <Card elevation={2} sx={{ mb: 3, maxWidth: '800px' }}>
                    <List className={classes.list} component="div" role="list" sx={{ pr: 2 }}>
                        <ListItem style={{ height: '40px' }}>
                            <ListItemText primary={t('rule_name')} style={{ flexBasis: '70%' }} />
                            <ListItemText primary={t('point')} style={{ textAlign: 'right', flexBasis: '30%' }} />
                        </ListItem>
                    </List>
                    <Divider />
                    <List className={classes.list} component="div" role="list" sx={{ overflowY: 'scroll', maxHeight: '400px' }}>
                        {_.map(ruleFiltered, (rule, index) => {
                            var className = classNames({ [classes.checked]: rule._id == formik.values.pointRule_id })
                            return (
                                <ListItem className={className} key={rule._id} button onClick={() => setSelectedRule(rule)} >
                                    <ListItemText primary={rule.name} style={{ flexBasis: '70%' }} />
                                    <ListItemText primary={rule.point} style={{ textAlign: 'right', flexBasis: '30%' }} />
                                </ListItem>
                            )
                        })}
                        {_.size(ruleFiltered) == 0 &&
                            <ListItem>
                                <ListItemText primary={t('no_record')} style={{ textAlign: 'center', flexBasis: '30%' }} />
                            </ListItem>
                        }
                        <ListItem />
                    </List>
                </Card>
                <Box className='flex ac' justifyContent='flex-end' mt={2}>
                    <Button type='submit' disabled={_.size(formik.errors) > 0 || !selectedRule || formik.isSubmitting}>{t('confirm')}</Button>
                </Box>
            </form >
        </LayoutContainer >
    );
}

export default ParentPointReward;