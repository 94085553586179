import { Box, Button, TextField, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Tooltip, Typography, MenuItem, Grid, Card, CardContent, Avatar, Paper, List, ListItem, ToggleButton, InputLabel } from '@material-ui/core';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import useApi from 'src/api/api';
import DataTable from 'src/components/DataTable/DataTable';
import EditDialog from 'src/components/EditDialog';
import Icons from 'src/icons';
import LayoutContainer from 'src/layout/LayoutContainer';
import { useAuthData } from 'src/provider/AuthDataProvider';
import { useLocale } from 'src/provider/LocaleProvider';
import formikProps from 'src/utils/formikProps';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined'
import { NavLink } from 'react-router-dom';
import ClassSelector from 'src/components/ClassSelector';
import AssignmentIcon from '@material-ui/icons/Assignment'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined'
import ImageUploader from 'src/components/Formik/ImageUploader';
import QuestionCard from 'src/components/Game/QuestionCard';
const prettyBytes = require('pretty-bytes');
const yup = require('yup')

const gameConfig = {
    interactive: {
        maxAns: 1
    }
}

export default function GameEdit({ snack }) {
    const nav = useNavigate()
    const api = useApi()
    const { t, translate } = useLocale()
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [dialogMode, setDialogMode] = useState();
    const { gameId } = useParams()
    const [game, setGame] = useState({});

    const [seleceted, setSeleceted] = useState();


    const maxAns = _.get(gameConfig, `${game.gameType}.maxAns`)

    const getData = () => {
        return api.getGameData(gameId).then(res => {
            setGame(res.data)
            return res
        })
    }

    useEffect(() => {
        getData()
    }, []);

    useEffect(() => {
        if (dialogMode) formik.resetForm()
    }, [dialogMode]);

    const formik = useFormik({
        initialValues: seleceted || {
            text: '',
            options: [
                { isAns: true, text: '' },
                { isAns: false, tesxt: '' }
            ],
            image: null,
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            text: yup.string().required().nullable(),
            image: yup.mixed().maxFilesize(1024),
            options: yup.array().of(
                yup.object({
                    text: yup.string().required().nullable(),
                    isAns: yup.bool().required().nullable()
                })
            ).test('max_correct_ans', t('max_correct_ans_msg', { a: maxAns }), value => {
                return !maxAns || maxAns >= _.filter(value, v => v.isAns).length

            }),
        }),
        onSubmit: async (values, helper) => {
            try {
                await api.batchUploadBlobs(values, ['image'])
                if (values._id) {
                    await api.updateQuestion(gameId, values)
                }
                else {
                    await api.addQuestion(gameId, values)
                }
                getData().then(res => {
                    setDialogMode(null)
                    helper.setSubmitting(false)
                })
            } catch (error) {


                snack.error()
                helper.setSubmitting(false)
            }
        },
    })

    const handleCreate = () => {
        setSeleceted(null)
        setDialogMode('add')
    }

    const handleEdit = (row) => {
        setSeleceted(row)
        setDialogMode('edit')
    }

    const handleDelete = (row) => {
        setSeleceted(row)
        setDialogMode('delete')
    }

    const handleConfirmDelete = () => {

        api.updateQuestion(gameId, { _id: formik.values._id, isActive: false }).then(res => {
            getData().then(res => {
                setDialogMode(null)
                snack.open(t('delete_success'))
            })
        })
    }

    const hasImage = game.gameType == 'mc' || game.gameType == 'interactive'
    console.log("file: GameEdit.js ~ line 217 ~ GameEdit ~ formik.errors.options ", formik.errors.options)

    return (
        <LayoutContainer title={t('edit_question')} backSrc={`/admin/games/${gameId}`}>
            <Box display='flex' width='100%' textAlign='left' mb={2} >
                <Typography variant='h3'>   {_.size(game.questions)}  {t('questions')} </Typography>
                <Box flexGrow={1} />
                <NavLink to={`/admin/games/edit/${gameId}`}>
                    <Button
                        variant="outlined"
                        onClick={handleCreate}
                        // startIcon={<BorderColorOutlinedIcon />}
                        style={{ marginLeft: '20px' }}
                    >
                        {t('add_question')}
                    </Button>
                </NavLink>
            </Box>
            {_.map(game?.questions, (question, index) => {
                return <QuestionCard question={question} index={index} handleEdit={handleEdit} handleDelete={handleDelete} hasImage={hasImage} />
            })}
            <FormikProvider value={formik}>
                <FieldArray name='options' render={helper =>
                    <EditDialog title={dialogMode == 'add' ? t('add_question') : t('edit_question')} handleSave={formik.handleSubmit} open={dialogMode == 'edit' || dialogMode == 'add'} handleClose={() => setDialogMode(null)} >
                        {hasImage &&
                            <ImageUploader
                                formik={formik}
                                label={t('question_image')}
                                name={`image`}
                                helperText={`< 1MB | PNG ${t('or')} JPEG`}
                                sx={{ mb: 2 }}
                            />
                        }
                        <TextField
                            fullWidth
                            label={t('question')}
                            {...formikProps(formik, 'text')}
                            sx={{ mb: 2 }}
                        />
                        {_.map(formik.values.options, (option, index) => {
                            return <Box display='flex' width='100%'
                                sx={{
                                    mb: 2,
                                    '& .MuiToggleButton-root': {
                                        color: 'rgba(128,0,0,1)',
                                        backgroundColor: 'rgba(255,0,0,0.3)',
                                        '&:hover': { backgroundColor: 'rgba(255,0,0,0.2)' },

                                    },
                                    '& .Mui-selected': {
                                        color: 'rgba(0,128,0,1) !important',
                                        backgroundColor: 'rgba(0,255,0,0.3) !important',
                                        '&:hover': { backgroundColor: 'rgba(0,255,0,0.2) !important' },
                                    },
                                }}
                            >
                                <ToggleButton
                                    selected={_.get(option, `isAns`, false)}
                                    // {...formikProps(formik, `options.${index}.isAns`)}
                                    onChange={() => formik.setFieldValue(`options.${index}.isAns`, !_.get(option, `isAns`, false))}
                                    sx={{ width: '56px', height: '56px', mr: 1 }}
                                >
                                    {option?.isAns ? <Icons.CheckIcon /> : <Icons.CloseIcon />}
                                </ToggleButton>
                                <TextField
                                    fullWidth
                                    label={t('option') + (index + 1)}
                                    {...formikProps(formik, `options.${index}.text`)}
                                // placeholder={t('option') + (index + 1)}
                                />
                                <Box pr='10px' />
                                <Button variant='text' disabled={index < 2} onClick={() => helper.remove(index)} style={{ width: '56px', height: '56px', backgroundColor: 'white', color: 'red' }}>
                                    {index < 2 ? '' : t('button.delete')}
                                </Button>
                            </Box>
                        })}
                        {_.size(formik.values.options) < 4 &&
                            <Box display='flex' justifyContent='center'
                                onClick={() => helper.push({ isAns: true, text: '' })}
                                sx={{ placeItems: 'center', height: '56px', backgroundColor: '#EEEEEE' }}
                            >
                                <Icons.AddIcon /> {t('add_question_option')}
                            </Box>
                        }
                        {!_.isArray(formik.errors.options) && formik.errors.options !== null &&
                            <InputLabel error>{formik.errors.options}</InputLabel>
                        }
                    </EditDialog>

                } />
                <EditDialog
                    title={t('delete_question')}
                    handleSave={handleConfirmDelete}
                    open={dialogMode == 'delete'}
                    handleClose={() => setDialogMode(null)}
                    confirmText={t('confirm')}
                >
                    <p>
                        {t('delete_confirm_msg', { a: `${t('question')}${_.findIndex(game?.questions, { _id: formik.values._id }) + 1}` })}
                    </p>
                </EditDialog>
            </FormikProvider>
        </LayoutContainer >)
}