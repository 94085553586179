import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import useApi from 'src/api/api';
import EditDialog from 'src/components/EditDialog';
import { useLocale } from 'src/provider/LocaleProvider';
import { useSnack } from 'src/provider/SnackbarProvider';
const _ = require('lodash');

const EditHomeworkTypeDialog = ({
  category,
  open,
  setOpen,
  allSubject,
  refresh = () => {}
}) => {
  const { t, getLanguage } = useLocale();
  const api = useApi();
  const [Name, setName] = useState('');
  const [Subject, setSubject] = useState('');
  const [dialogMode, setDialogMode] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const snack = useSnack();
  useEffect(() => {
    setName(category?.name);
    setSubject(category?.subject._id);
    setIsActive(category?.isActive);
  }, [open]);

  const checkString = (s) => _.isString(s) && s;
  var isComplete = checkString(Name) && checkString(Subject);

  function handleSave() {
    if (isComplete) {
      setSubmitting(true);
      api
        .putHomeworkType({
          name: Name,
          subject: Subject,
          homeworkType_id: category._id,
          isActive: isActive
        })
        .then(() => {
          snack.open(t('upload_success'));
          setOpen(false);
        })
        .catch((e) => {
          snack.error('upload_fail');
        })
        .finally(() => {
          setSubmitting(false);
          refresh();
        });
    }
  }
  const handleDelete = async () => {
    setSubmitting(true);
    await api
      .deleteHomeworkTypeByID(category._id)
      .then(() => {
        setDialogMode('');
        setOpen(false);
      })
      .catch((e) => {
        snack.error();
      })
      .finally((e) => {
        setSubmitting(false);
        refresh();
      });
  };

  return (
    <>
      <EditDialog
        open={open}
        handleClose={() => setOpen(false)}
        title={t('homework_edit')}
        handleSave={handleSave}
        confirmDisabled={!isComplete || submitting}
      >
        <FormControl fullWidth>
          <InputLabel>{t('subject')}</InputLabel>
          <Select
            className="mySelect"
            label={t('subject')}
            variant="outlined"
            size="small"
            displayEmpty
            onChange={(event) => setSubject(event.target.value)}
            value={Subject}
            defaultValue={''}
            sx={{ height: '50px', minWidth: '200px', width: '100%', mb: 2 }}
            fullWidth={true}
          >
            {_.map(allSubject, (n) => (
              <MenuItem key={n?._id} value={n?._id}>
                {' '}
                {`${_.get(n, `name.${getLanguage()}`)}`}{' '}
              </MenuItem>
            ))}
          </Select>
          <TextField
            fullWidth
            label={t('code')}
            value={Name}
            onChange={(e) => setName(e.target.value)}
            sx={{ mb: 2 }}
          />
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>{t('status')}</InputLabel>
          <Select
            className="mySelect"
            id="status"
            label={t('status')}
            variant="outlined"
            size="small"
            displayEmpty
            onChange={(event) => setIsActive(event.target.value)}
            value={isActive}
            sx={{ height: '50px', minWidth: '200px', width: '100%', mb: 2 }}
            fullWidth={true}
          >
            <MenuItem key={'active'} value={true}>
              {t('active')}
            </MenuItem>
            <MenuItem key={'inactive'} value={false}>
              {t('inactive')}
            </MenuItem>
          </Select>
        </FormControl>
      </EditDialog>
      <EditDialog
        title={t('delete')}
        handleSave={() => handleDelete()}
        open={dialogMode === 'delete'}
        handleClose={() => setDialogMode(null)}
        confirmText={t('confirm')}
        confirmDisabled={submitting}
      >
        <p>{t('delete_confirm_msg', { a: `${category?.name}` })}</p>
      </EditDialog>
    </>
  );
};

export default EditHomeworkTypeDialog;
