import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import useApi from 'src/api/api';
import LayoutContainer from 'src/layout/LayoutContainer';
import { useAuthData } from 'src/provider/AuthDataProvider';
import { useLocale } from 'src/provider/LocaleProvider';
import {
  Backdrop,
  Box,
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  CardHeader,
  Button,
  Divider,
  IconButton,
  TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import DataTableNoLimit from 'src/components/DataTable/DataTableNoLimit';
import Icons from 'src/icons';
import ClassSelector from 'src/components/ClassSelector';
import {
  PieChart,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
  ReferenceLine,
  Pie,
  Cell,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  AreaChart,
  Brush
} from 'recharts';
import LoadingView from 'src/components/LoadingView';
const moment = require('moment-timezone');
const _ = require('lodash');
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF80FF'];

const useStyles = makeStyles((theme) => ({
  row: {
    // height: '10px',
    // paddingBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(1)
    // marginTop: theme.spacing(1)
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },

  cardTitle: {
    fontWeight: 700
  },
  tableCell: {
    '& .MuiTableCell-root': {
      textAlign: 'center',
      borderLeft: 'none !important'
    },
    '& .MuiTableRow-root': {
      height: '60px'
    }
  }
}));
export default function StudentHomeworkStatistics() {
  const { userInfo, selectedPeriod, currentPeriod, organization } =
    useAuthData();
  const { t, getLanguage } = useLocale();
  const classes = useStyles();
  const nav = useNavigate();
  const api = useApi();
  const [submittedHomeworkRecord, setSubmittedHomeworkRecord] = useState([]);
  const [unFinishedHomeworkRecord, setUnFinishedHomeworkRecord] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState();
  const timer = useRef();
  function toPecentage(molecular, total) {
    if (!molecular) return null;
    return `${_.round((molecular / total) * 100, 1)}\%`;
  }

  useEffect(() => {
    if (selectedPeriod) {
      Promise.all([
        api
          .getStudentSubmittedHomeworkRecord(userInfo._id)
          .then((res) => {
            setSubmittedHomeworkRecord(res.data);
          })
          .catch((e) => console.log(e)),

        api
          .getStudentUnfinishedHomeworkRecord(userInfo._id)
          .then((res) => {
            setUnFinishedHomeworkRecord(res.data);
          })
          .catch((e) => console.log(e))
      ]).then(() => setIsLoading(false));
    }
  }, [selectedPeriod]);

  if (isLoading) return <LoadingView />;
  return (
    <LayoutContainer title={t('homework_statistics')}>
      {submittedHomeworkRecord.amount && (
        <Box sx={{ mb: 2 }}>
          <Card className={'mb8'}>
            {/* <CardHeader
              className={classes.actions}
              title={t('student_information')}
            /> */}
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    InputProps={{
                      readOnly: true
                    }}
                    fullWidth
                    label={t('total_days_uncompleted_homework')}
                    defaultValue={
                      submittedHomeworkRecord?.amount?.unsubmitted ||
                      t('no_record')
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    InputProps={{
                      readOnly: true
                    }}
                    fullWidth
                    label={t('total_uncompleted_homework')}
                    defaultValue={
                      unFinishedHomeworkRecord?.total || t('no_record')
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    InputProps={{
                      readOnly: true
                    }}
                    fullWidth
                    label={t('total_days_completed_homework')}
                    defaultValue={
                      submittedHomeworkRecord?.amount?.submitted ||
                      t('no_record')
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    InputProps={{
                      readOnly: true
                    }}
                    fullWidth
                    label={t('percentage_of_total_days_completed_homework')}
                    defaultValue={
                      toPecentage(
                        submittedHomeworkRecord?.amount?.submitted,
                        submittedHomeworkRecord?.total
                      ) || t('no_record')
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}

      {submittedHomeworkRecord.total ? (
        <Card sx={{ p: 2, mb: 2 }}>
          <Box mb={2} textAlign="center">
            <Typography variant="h3">
              {t('percentage_of_completion')}
            </Typography>
          </Box>
          <Box sx={{ height: '300px' }}>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Legend />
                <Pie
                  data={_.entries(submittedHomeworkRecord?.amount)}
                  outerRadius="70%"
                  dataKey="1"
                  nameKey={(obj) => t(obj[0])}
                  cx="50%"
                  cy="50%"
                  fill="#8884d8"
                  isAnimationActive={false}
                  labelLine={false}
                  local
                  label={(e) => {
                    if (e.percent > 0) {
                      return `${e.name}: ${e.value} (${_.round(
                        e.percent * 100,
                        1
                      )}%)`;
                    }
                    return ' ';
                  }}
                >
                  {_.map(
                    _.entries(submittedHomeworkRecord?.amount),
                    (entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    )
                  )}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </Box>
        </Card>
      ) : (
        <></>
      )}
      {unFinishedHomeworkRecord.total ? (
        <Card sx={{ p: 2, mb: 2 }}>
          <Box mb={2} textAlign="center">
            <Typography variant="h3">
              {t('monthly_number_of_uncompleted_homework')}
            </Typography>
          </Box>
          <Box sx={{ height: '300px' }}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={500}
                height={300}
                data={unFinishedHomeworkRecord?.dataInMonth}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar
                  dataKey="count"
                  stackId="a"
                  name={t('count')}
                  fill={COLORS[2]}
                />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Card>
      ) : (
        <></>
      )}
      {unFinishedHomeworkRecord.total ? (
        <Card sx={{ p: 2, mb: 2 }}>
          <Box mb={2} textAlign="center">
            <Typography variant="h3">
              {t('daily_number_of_uncompleted_homework')}
            </Typography>
          </Box>
          <Box sx={{ height: '300px' }}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={500}
                height={300}
                data={unFinishedHomeworkRecord?.dataInDay}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="day" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar
                  dataKey="count"
                  stackId="a"
                  name={t('count')}
                  fill={COLORS[2]}
                />
                <Brush />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Card>
      ) : (
        <></>
      )}
    </LayoutContainer>
  );
}
