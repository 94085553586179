import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from '@material-ui/core';
import { pink } from '@mui/material/colors';
import { useFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import useApi from 'src/api/api';
import ClassSelector from 'src/components/ClassSelector';
import DataTable from 'src/components/DataTable/DataTable';
import CustomDatePicker from 'src/components/DatePicker';
import LoadingView from 'src/components/LoadingView';
import { PeriodWarning } from 'src/components/PeriodWarning';
import SearchInput from 'src/components/SearchInput';
import LayoutContainer from 'src/layout/LayoutContainer';
import { useAuthData } from 'src/provider/AuthDataProvider';
import { useLocale } from 'src/provider/LocaleProvider';
import { useSnack } from 'src/provider/SnackbarProvider';
import utils from 'src/utils/utils';

const _ = require('lodash');
const moment = require('moment-timezone');
const yup = require('yup');

const SubmittedHomework = () => {
  const api = useApi();
  const { t, getLanguage } = useLocale();

  const [studentList, setStudentList] = useState([]);
  const { selectedPeriod, organization } = useAuthData();
  const [searchStudent, setSearchStudent] = useState('');
  const [filter, setFilter] = useState();
  const [dateRange, setDateRange] = useState(null);

  const snack = useSnack();
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const [isLoading, setIsLoading] = useState(true);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [isShowWarning, setIsShowWarning] = useState(false);

  const [profileMap, setProfileMap] = useState();

  const formik = useFormik({
    initialValues: {
      profiles: [],
      date: selectedDate
    },
    validationSchema: yup.object().shape({
      profiles: yup
        .array()
        .min(0)
        .of(
          yup.object().shape({
            profile: yup.string().required(),
            status: yup.string().required()
          })
        ),
      date: yup.string().required()
    }),
    onSubmit: (values, helper) => {
      // if (profileMap) {
      //   const profilesArr = Array.from(profileMap, ([key, value]) => {
      //     return { profile: key, status: value };
      //   });
      //   formik.setFieldValue('profiles', profilesArr);
      // }

      api
        .putHomeworkSubmitRecord(values)
        .then((_res) => {
          console.log(_res);
          snack.open(t('upload_success'));
          api.getStudentList().then((res) => {
            setStudentList(res.data);
          });
        })
        .catch(() => {
          snack.error(t('upload_fail'));
        })
        .finally(() => {
          helper.setSubmitting(false);
        });
    }
  });

  useEffect(() => {
    const getStudentList = async () => {
      setDateRange(organization?.homework_date_range?.submitHomeworkRange);
      api
        .getStudentList()
        .then((res) => {
          setStudentList(res.data);
        })
        .finally(() => setIsLoading(false));
    };
    getStudentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPeriod]);
  var classFiltered = useMemo(
    () => utils.searchFilter(studentList, filter?.value, ['class']),
    [studentList, filter?.value]
  );

  useEffect(() => {
    setProfileMap(new Map());
    const isInClassFiltered = (classFiltered) => (profileId) =>
      _.some(classFiltered, { profile: profileId });

    const setSubmitRecordToProfileMap = (submitRecords) => {
      _.map(submitRecords, (record) => {
        const { _id } = record.profile;

        if (isInClassFiltered(classFiltered)(record.profile?._id)) {
          setProfileMap((map) => new Map(map.set(_id, record?.status)));
        }
      });
    };

    const setAllStudentToSubmitted = (classFiltered) => {
      _.map(classFiltered, (student) => {
        const { profile } = student;
        if (profile)
          setProfileMap((map) => new Map(map.set(profile, 'submitted')));
      });
    };

    const getSubmittedHomeworkRecord = async () => {
      setIsTableLoading(true);
      await api
        .getSubmittedHomeworkRecord({
          search: { date: selectedDate, class: filter?.value },
          limit: 999
        })
        .then(({ data }) => _.values(data?.pages[0]))
        .then((submitRecords) => {
          if (!_.isEmpty(submitRecords)) {
            setSubmitRecordToProfileMap(submitRecords);
          } else {
            setAllStudentToSubmitted(classFiltered);
          }
        })
        .finally(() => {
          setIsTableLoading(false);
        });
    };

    if (filter?.value && selectedDate) {
      getSubmittedHomeworkRecord();
    }
  }, [classFiltered, selectedDate, filter?.value]);

  useEffect(() => {
    if (profileMap) {
      const profilesArr = Array.from(profileMap, ([key, value]) => {
        return { profile: key, status: value };
      });
      formik.setFieldValue('profiles', profilesArr);
    }
  }, [profileMap]);

  useEffect(() => {
    formik.setFieldValue('date', selectedDate);
  }, [selectedDate]);

  useEffect(() => {
    api.getPeriodList((result) => {
      const selectedPeriodObject = _.filter(
        result,
        (period) => period?._id === selectedPeriod
      );

      if (moment().isAfter(selectedPeriodObject[0].end_at))
        setIsShowWarning(true);
      else setIsShowWarning(false);
    });
  }, [selectedPeriod]);

  if (isLoading) {
    return <LoadingView></LoadingView>;
  }

  return (
    <LayoutContainer title={t('submitted_homework')}>
      {!!isShowWarning && <PeriodWarning />}
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1} sx={{ mb: 2 }}>
          <Grid item>
            <Typography variant="h3" paragraph>
              {t('choose_student')}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ mb: 2 }}>
          <Grid item>
            <ClassSelector
              selected={filter}
              setSelected={setFilter}
              all={false}
            />
          </Grid>
          <Grid item sx={{ flex: 'auto' }}>
            <SearchInput setSearch={setSearchStudent} />
          </Grid>
          <Grid item>
            <CustomDatePicker
              sx={{ size: 'small' }}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              minDate={moment().subtract(dateRange, 'days')}
              maxDate={moment()}
            />
          </Grid>
        </Grid>
        {!!filter && isTableLoading ? (
          <Box sx={{ height: '400px' }}>
            <LoadingView />
          </Box>
        ) : (
          <></>
        )}
        {!!filter && !isTableLoading && (
          <DataTable
            rows={utils.searchFilter(classFiltered, searchStudent, [
              'class',
              'class_no',
              'name.en',
              'name.zh'
            ])}
            heads={[
              {
                id: 'class',
                label: t('class'),
                value: 'class'
              },
              { id: 'class_no', label: t('class_no'), value: 'class_no' },
              {
                id: 'name',
                label: t('name'),
                value: `name.${getLanguage()}`
              },
              {
                id: 'remark',
                label: t('remark'),
                content: (row) => {
                  return (
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={profileMap.get(row.profile) ?? null}
                        onChange={(event) => {
                          setProfileMap(
                            new Map(
                              profileMap.set(row.profile, event.target.value)
                            )
                          );
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-evenly'
                          }}
                        >
                          <FormControlLabel
                            value="submitted"
                            control={<Radio />}
                            label={t('submitted')}
                            color="success"
                          />
                          <FormControlLabel
                            value="unsubmitted"
                            control={<Radio />}
                            label={t('unsubmitted')}
                            color="default"
                          />
                          <FormControlLabel
                            value="absent"
                            control={<Radio />}
                            label={t('absent')}
                            sx={{
                              color: pink[800],
                              '&.Mui-checked': {
                                color: pink[600]
                              }
                            }}
                          />
                        </Box>
                      </RadioGroup>
                    </FormControl>
                  );
                }
              }
            ]}
          ></DataTable>
        )}
        <Box className="flex ac" justifyContent="flex-end" mt={2}>
          <Button
            type="submit"
            disabled={
              !filter || _.size(formik.errors) > 0 || formik.isSubmitting
            }
          >
            {t('confirm')}
          </Button>
        </Box>
      </form>
    </LayoutContainer>
  );
};

export default SubmittedHomework;
