import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Tooltip
} from '@material-ui/core';
import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import useApi from 'src/api/api';
import PagingTable, {
  usePagination
} from 'src/components/DataTable/PagingTable';
import CustomDatePicker from 'src/components/DatePicker';
import EditDialog from 'src/components/EditDialog';
import LoadingView from 'src/components/LoadingView';
import Icons from 'src/icons';
import LayoutContainer from 'src/layout/LayoutContainer';
import { useAuthData } from 'src/provider/AuthDataProvider';
import { useLocale } from 'src/provider/LocaleProvider';
import { useSnack } from 'src/provider/SnackbarProvider';
import utils from 'src/utils/utils';

const PointHistory = () => {
  const api = useApi();
  const { t, getLanguage, translate } = useLocale();

  const pagination = usePagination({ _limitOptions: [10, 25, 50], _limit: 10 });
  const {
    page,
    setPage,
    limit,
    order,
    orderBy,
  } = pagination;

  const rowsRef = useRef([]);
  const snack = useSnack();
  const { selectedPeriod } = useAuthData();
  const [search, setSearch] = useState({});
  const [actions, setActions] = useState({});
  const [total, setTotal] = useState(0);
  const [lastUpdate, setLastUpdate] = useState();
  const [dateRange, setDateRange] = useState(moment().startOf('month'));

  const [dialogMode, setDialogMode] = useState('');
  const [selectedRow, setSelectedRow] = useState();
  const timer = useRef();
  const [rawData, setRawData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  async function getRows() {
    const res = await api
      .getPointHistoriesV2({
        dateRange: dateRange.toISOString(),
        search,
        order_by: orderBy,
        order,
        limit,
        page
      })
    if (res.data.last_updated_at !== lastUpdate) {
      setActions(res.data.pages);
    } else {
      setActions((h) => {
        return { ...h, ...res.data.pages };
      });
    }
    setTotal(res.data.total);
    setLastUpdate(res.data.last_updated_at);
    setIsLoading(false);
  }

  useEffect(() => {
    let pre = Math.max(0, page - 1);
    let current = page;
    let next = Math.min(total, page + 1);
    if (!(actions[pre] && actions[current] && actions[next])) {
      getRows();
    }
  }, [page]);

  useEffect(() => {
    setActions({});
    getRows();
  }, [orderBy, order, dateRange, selectedPeriod]);

  useEffect(() => {
    setActions({});
    if (page === 0) {
      getRows();
    } else {
      setPage(0);
    }
  }, [limit]);

  useEffect(() => {
    if (actions) {
      setPage(0);
      setActions({});
      clearTimeout(timer.current);
      timer.current = setTimeout(getRows, 500);
    }
  }, [search]);

  const handleDelete = (row) => {
    setDialogMode('delete');
    setSelectedRow(row);
  };

  const handleDeleteOne = async () => {
    await api.deletePointHistory(selectedRow?._id).then((res) => {
      setDialogMode(null);
      setSelectedRow(null);
      getRows();
    });
    await snack.open(t('delete_success'));
  };

  const getCSVDate = async () => {
    const dateForExport = await api.getAllPointHistoriesStream({
      dateRange: dateRange,
      search,
      order_by: orderBy,
      order,
      limit,
      page
    })
    if (!dateForExport) {
      return null
    } else {
      return _.flatten(dateForExport)
    }
  }

  const exportFiltered = async () => {
    var dateRangeISO = dateRange.toISOString();
    var data = rawData[dateRangeISO];
    if (!rawData[dateRange.toISOString()]) {
      data = await getCSVDate();
      setRawData((d) => ({ ...d, [dateRangeISO]: data }));
    }
    var filterd = _.filter(data, (row) => {
      var fm = (str, field) => {
        if (!str || str === '') return true;
        var text = _.get(row, field);
        return new RegExp(
          _.toString(str).replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1'),
          'i'
        ).test(_.toString(text));
      };
      return (
        fm(search.class, 'profile.class') &&
        fm(search.class_no, 'profile.class_no') &&
        fm(search.point_rule, 'pointRule.name') &&
        (fm(search.name, 'user.name.en') || fm(search.name, 'user.name.zh')) &&
        (fm(search.created_by, 'created_by.name.en') ||
          fm(search.created_by, 'created_by.name.zh'))
      );
    });
    utils.exportCsv(
      filterd,
      [
        {
          label: t('created_at'),
          value: (row) => new Date(row.created_at).toLocaleString()
        },
        { label: t('pointrule_name'), value: (row) => row.pointRule?.name },
        { label: t('point'), value: (row) => row.point, sort: true },
        { label: t('class'), value: (row) => row.profile?.class },
        { label: t('class_no'), value: (row) => row.profile?.class_no },
        { label: t('name_zh'), value: (row) => row.user?.name?.zh },
        { label: t('name_en'), value: (row) => row.user?.name?.en },
        { label: t('state'), value: (row) => t(row.type || '') },
        {
          label: t('action_created_by'),
          value: (row) => translate(row?.created_by?.name)
        }
      ],
      `point_history`
    );
    return null;
  };

  const handleSearch = (path) => (e) => {
    setSearch((s) => ({ ...s, [path]: e.target.value }));
  };

  const { mutate: handleExport, ...handleExportMutation } =
    useMutation(exportFiltered);

  if (isLoading) {
    return <LoadingView></LoadingView>;
  }
  return (
    <LayoutContainer title={t('history_title')}>

      {(handleExportMutation.isLoading) && (
        <>
          <Alert variant="standard" severity="info" sx={{ mb: 2 }}>
            {t('exporting')}
          </Alert>
        </>
      )}
      <Grid
        container
        spacing={1}
        sx={{
          mb: 2,
          '& .MuiGrid-item': {
            pt: 1.5
          }
        }}
      >
        <Grid item>
          <CustomDatePicker selectedDate={dateRange} setSelectedDate={setDateRange} readOnly
            views={['month', 'year']} inputFormat='yyyy-MM' sx={{ width: '200px' }} />
        </Grid>
        <Grid item>
          <TextField
            label={t('name')}
            size="small"
            onChange={handleSearch('name')}
            sx={{ width: '200px' }}
          />
        </Grid>
        <Grid item>
          <TextField
            label={t('class')}
            size="small"
            onChange={handleSearch('class')}
            sx={{ width: '200px' }}
          />
        </Grid>
        <Grid item>
          <TextField
            label={t('class_no')}
            size="small"
            onChange={handleSearch('class_no')}
            sx={{ width: '200px' }}
          />
        </Grid>
        <Grid item>
          <TextField
            label={t('action_created_by')}
            size="small"
            onChange={handleSearch('created_by')}
            sx={{ width: '200px' }}
          />
        </Grid>
        <Grid item>
          <TextField
            label={t('pointrule_name')}
            size="small"
            onChange={handleSearch('point_rule')}
            sx={{ width: '200px' }}
          />
        </Grid>

        <Grid item xs className="flex ac" justifyContent="flex-end">
          <Button
            sx={{ ml: 1 }}
            onClick={handleExport}
            disabled={handleExportMutation.isLoading}
          >
            {t('export')}
          </Button>
        </Grid>
      </Grid>

      <PagingTable
        rowClassName={(row) =>
          classNames({ 'text-green': row.type === 'redeem' })
        }
        total={total}
        rowsRef={rowsRef}
        rows={actions[page]}
        heads={[
          // { id: 'u_id', label: t('u_id'), value: 'profile.u_id', sort: true },
          {
            label: t('created_at'),
            value: 'created_at',
            content: (row) => new Date(row.created_at).toLocaleString(),
            sort: true
          },
          { label: t('class'), value: 'profile.class' },
          { label: t('class_no'), value: 'profile.class_no' },
          { label: t('name_zh'), value: 'user.name.zh' },
          { label: t('name_en'), value: 'user.name.en' },
          { label: t('pointrule_name'), value: 'pointRule.name' },
          { label: t('point'), value: 'point' },
          { label: t('state'), value: 'type', translate: 1 },
          {
            label: t('action_created_by'),
            value: `created_by.name.${getLanguage()}`
          },
          {
            label: t('actions'),
            value: '',
            content: (row) => {
              return (
                <Box sx={{ mx: -1 }}>
                  <Tooltip title={t('deleteActions_confirmMsg')}>
                    <IconButton
                      onClick={() => handleDelete(row)}
                      sx={{ p: 0.5 }}
                    >
                      {' '}
                      <Icons.DeleteForeverIcon />{' '}
                    </IconButton>
                  </Tooltip>
                </Box>
              );
            }
          }
        ]}
        {...pagination}
      />
      <EditDialog
        title={t('deleteActions_confirmMsg')}
        open={dialogMode === 'delete'}
        openTo="month"
        handleClose={() => setDialogMode(null)}
        handleSave={handleDeleteOne}
        confirmText={t('delete')}
      >
        {t('deleteActions_description')}
      </EditDialog>
    </LayoutContainer>
  );
};

export default PointHistory;
