import { Box, Button, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Paper, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import useApi from 'src/api/api';
import DataTable from 'src/components/DataTable/DataTable';
import Icons from 'src/icons';
import LayoutContainer from 'src/layout/LayoutContainer';
import { useAuthData } from 'src/provider/AuthDataProvider';
import { useLocale } from 'src/provider/LocaleProvider';
import classNames from 'classnames';

const prettyBytes = require('pretty-bytes');

const useStyles = makeStyles({
    list: {
        listStyle: 'none',
        minHeight: '500px'
    },
    listItem: {
        borderBottom: '1px solid rgba(0,58,137,.2)',
        padding: '16px',
    },
    listText: {
        marginBottom: 0,
        marginTop: '8px',
        '&:hover': {
            transition: 'opacity .2s',
            opacity: '.6'
        }
    },
    pager: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    isRead: {
        backgroundColor: 'rgba(0,0,0,0.1)'
    }


})

export default function UMemo() {
    const nav = useNavigate()
    const { t } = useLocale()
    const [memo, setMemo] = useState();
    const { selectedPeriod } = useAuthData()
    const api = useApi()
    const [pageInfo, setPageInfo] = useState({
        current: 0,
        total: 0,
        numberOfPage: 10
    })
    const [favouriteChecked, setFavouriteChecked] = useState(false);

    const classes = useStyles()
    useEffect(() => {
        api.getMemo(selectedPeriod).then(res => {
            setMemo(_.sortBy(res.data, 'created_at').reverse() || [])
            let _memo = favouriteChecked ? res.data.filter(k => k.user_info?.is_favourite) : res.data
            setPageInfo({ ...pageInfo, 'total': Math.ceil(_memo.length / pageInfo.numberOfPage) })
        }).catch(e => {

        })
    }, [selectedPeriod]);

    useEffect(() => {
        if (memo && memo.length > 0) {
            let _memo = favouriteChecked ? memo.filter(k => k.user_info?.is_favourite) : memo
            let totalLength = Math.ceil(_memo.length / pageInfo.numberOfPage)
            setPageInfo({ ...pageInfo, 'current': 0, 'total': totalLength })
        }

    }, [favouriteChecked])

    const filteredMemo = favouriteChecked ? memo?.filter(k => k.user_info?.is_favourite) : memo

    return (
        <LayoutContainer title={t('memo')}>
            <div className={'flex ac mb8'}>
                <Checkbox onChange={(e, checked) => setFavouriteChecked(checked)} size={'small'} style={{ padding: 5 }}></Checkbox>
                <Typography variant='h6' noWrap>已加星號</Typography>
            </div>
            <Paper >
                <ul className={classes.list}>

                    {(filteredMemo && filteredMemo.length > 0) ? _.slice(filteredMemo, pageInfo.current * pageInfo.numberOfPage, (pageInfo.current + 1) * pageInfo.numberOfPage).map((k, i) => {
                        return (
                            <li className={classNames(classes.listItem, { [classes.isRead]: k.user_info?.is_read })} key={k + i}>
                                <Link className={'txt-black'} to={`/user/memo/${k._id}`}>

                                    <IconButton sx={{ float: 'right', color: k.user_info?.is_favourite && '#FFB000' }} onClick={(e) => {
                                        e.preventDefault()

                                        api.updateMemoInfo(k._id, { is_favourite: !k.user_info?.is_favourite })

                                            .catch(e => {

                                            })
                                            .finally(() => {
                                                let _memo = [...memo]
                                                let id = _.findIndex(_memo, { _id: k._id })
                                                _.set(_memo, `${id}.user_info.is_favourite`, !k.user_info?.is_favourite)
                                                setMemo(_memo)
                                            })
                                    }}>
                                        <Icons.FavouriteIcon />
                                    </IconButton>
                                    <div className={'flex'}>
                                        <div>{moment(k.created_at).format('YYYY/MM/DD')}</div>

                                    </div>
                                    <Typography className={classes.listText} paragraph>{k.title}</Typography>

                                </Link>

                            </li>)
                    }) : <Typography className='flex ac jcc' sx={{ height: '500px' }} textAlign="center" variant={'h3'}>{t('no_record')}</Typography>}

                </ul>
                {pageInfo.total > 1 &&
                    <div className={classNames(classes.pager)}>
                        <IconButton disabled={pageInfo.current === 0} onClick={() => {
                            setPageInfo({ ...pageInfo, 'current': pageInfo.current - 1 })
                        }}>
                            <Icons.ArrowLeftIcon></Icons.ArrowLeftIcon>
                        </IconButton>
                        {Array(pageInfo.total).fill(null).map((k, i) =>
                            <Button key={k + i} disabled={pageInfo.current === i} variant={'text'} sx={{
                                m: 1, minWidth: '30px', width: '30px', height: '30px', borderRadius: '30px',
                                backgroundColor: (pageInfo.current === i) && 'rgba(63, 81, 181, 0.2)',
                                color: '#3f51b5!important'
                            }} onClick={() => {
                                // window.scrollTo(0, 0);
                                setPageInfo({ ...pageInfo, 'current': i })
                            }}>
                                {i + 1}
                            </Button>)}
                        <IconButton disabled={pageInfo.current === pageInfo.total - 1} onClick={() => {
                            setPageInfo({ ...pageInfo, 'current': pageInfo.current + 1 })
                        }}>
                            <Icons.ArrowRightIcon></Icons.ArrowRightIcon>
                        </IconButton>

                    </div>}
            </Paper>

        </LayoutContainer >)
}