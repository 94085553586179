import React, { useState, useEffect } from 'react';

import {
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box
} from '@material-ui/core';
import { useLocale } from 'src/provider/LocaleProvider';
import useApi from 'src/api/api';
import EditDialog from 'src/components/EditDialog';
import { TextField } from '@material-ui/core';
import { useAuthData } from 'src/provider/AuthDataProvider';
import { useSnack } from 'src/provider/SnackbarProvider';

const _ = require('lodash');

const AddHomeworkTypeDialog = ({
  open,
  setOpen,
  allSubject,
  refresh = new Function()
}) => {
  const { t, getLanguage } = useLocale();
  const snack = useSnack();
  const api = useApi();
  const [Name, setName] = useState('');
  const [Subject, setSubject] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const { selectedPeriod } = useAuthData();
  useEffect(() => {
    setName('');
    setSubject('');
  }, [open]);

  const checkString = (s) => _.isString(s) && s;

  var isComplete = checkString(Name) && checkString(Subject);

  function handleSave() {
    if (isComplete) {
      setSubmitting(true);
      api
        .putHomeworkType({
          name: Name,
          subject: Subject
        })
        .then(() => {
          snack.open(t('upload_success'));
          refresh();
          setOpen(false);
        })
        .catch((e) => {
          snack.error(t('upload_fail'));
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  }
  return (
    <EditDialog
      open={open}
      handleClose={() => setOpen(false)}
      title={t('homework_add')}
      handleSave={handleSave}
      confirmDisabled={!isComplete || submitting}
      //
    >
      <FormControl fullWidth>
        <InputLabel>{t('subject')}</InputLabel>
        <Select
          className="mySelect"
          label={t('subject')}
          variant="outlined"
          size="small"
          displayEmpty
          onChange={(event) => setSubject(event.target.value)}
          value={Subject?._id}
          defaultValue={''}
          sx={{ height: '50px', minWidth: '200px', width: '100%', mb: 2 }}
          fullWidth={true}
        >
          {_.map(allSubject, (n) => (
            <MenuItem key={n?.name.en} value={n?._id}>
              {' '}
              {`${_.get(n, `name.${getLanguage()}`)}`}
            </MenuItem>
          ))}
        </Select>
        <TextField
          fullWidth
          label={t('name')}
          value={Name}
          onChange={(e) => setName(e.target.value)}
          sx={{ mb: 2 }}
        />
      </FormControl>
    </EditDialog>
  );
};

export default AddHomeworkTypeDialog;
