import { Helmet } from 'react-helmet';
import { Backdrop, Box, Container, Grid, Card, CardContent, Typography, CardHeader, Button, Divider, IconButton, TextField, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useCallback, useEffect } from 'react';
import { useBackDrop } from 'src/provider/BackdropProvider';
import { useState } from 'react';
import useApi from 'src/api/api';
import { ArrowBack } from '@material-ui/icons';
import Icons from 'src/icons';
import DataTable from 'src/components/DataTable/DataTable';
import DataTableNoLimit from 'src/components/DataTable/DataTableNoLimit';
import _ from 'lodash';
import { useNavigate } from 'react-router';
import { useLocale } from 'src/provider/LocaleProvider';
import utils from 'src/utils/utils';
import SearchInput from 'src/components/SearchInput';
import LayoutContainer from 'src/layout/LayoutContainer';
import { useAuthData } from 'src/provider/AuthDataProvider';
import RuleSelector from 'src/components/RuleSelector';
const moment = require('moment-timezone')

const useStyles = makeStyles(theme => ({

    row: {
        // height: '10px',
        // paddingBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2)
    },
    spacer: {
        flex: 1
    },

    tableCell: {
        "& .MuiTableCell-root": {
            textAlign: 'center',
            borderLeft: "none !important",
        },
        "& .MuiTableRow-root": {
            height: '60px'
        },
        // 
    },
    caption: {
        fontSize: '12px'
    }

}))

const UPointRecord = () => {
    const classes = useStyles()
    const loadingView = useBackDrop();
    const api = useApi()
    const nav = useNavigate()
    const { t } = useLocale()
    const { userInfo, selectedPeriod, currentPeriod } = useAuthData()


    const [filter, setFilter] = useState(null)
    const [pointHistory, setPointHistory] = useState([])

    const handleExport = () => {
        utils.exportCsv(pointHistory, [
            { label: t('date'), value: (row) => row['date'] },
            { label: t('pointrule_name'), value: (row) => row.pointRule.name },
            { label: t('point'), value: (row) => row['point'] }
        ], 'point_record')
    }

    useEffect(() => {
        // loadingView.open()


        api.getUserPointHistories(selectedPeriod, userInfo._id).then((({ data }) => { console.log('data: ', data); setPointHistory(data) })).catch(e => console.log(e))
        // loadingView.close()

    }, [])

    const filteredList = useCallback(

        utils.searchFilter(pointHistory, filter?._id, ['pointRule.ruleCategory._id'])
        , [pointHistory, filter])
        

    return (
        <LayoutContainer title={t('point_history')} backSrc='/user/overview'>

            <Grid container spacing={1} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={6} >
                    <RuleSelector selected={filter} setSelected={setFilter} />
                </Grid>
                <Grid item xs={12} sm={6} className='flex ac'  >
                    <div className="spacer"></div>
                    <Button onClick={handleExport} >
                        {t('export')}
                    </Button>
                </Grid>
            </Grid>
            <DataTable
                sx={{ mb: 1 }}
                className={classes.tableCell}
                rows={(filteredList)}
                heads={[
                    {
                        label: t('date'), value: 'created_at', content: (row) => moment(row.created_at).format("YYYY-MM-DD HH:MM")
                        , sort: true,
                        width: '33.3%'
                    },
                    { id: 'pointrule_name', label: t('pointrule_name'), value: 'pointRule.name', width: '33.3%', },
                    { id: 'point', label: t('point'), value: 'point', width: '33.3%', }
                ]}

            />
            <div align='right' className={'font12'}>Last Updated at {moment().format("YYYY-MM-DD HH:mm:ss")}</div>

        </LayoutContainer>
    )
}
export default UPointRecord;
