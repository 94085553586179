/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import classNames from 'classnames';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import useApi from 'src/api/api';
import PagingTable, {
  usePagination
} from 'src/components/DataTable/PagingTable';
import TimePicker from 'src/components/DatePicker';
import EditDialog from 'src/components/EditDialog';
import LoadingView from 'src/components/LoadingView';
import Icons from 'src/icons';
import LayoutContainer from 'src/layout/LayoutContainer';
import { useAuthData } from 'src/provider/AuthDataProvider';
import { useLocale } from 'src/provider/LocaleProvider';
import { useSnack } from 'src/provider/SnackbarProvider';
import utils from 'src/utils/utils';
const moment = require('moment-timezone');

const SubmittedHomeworkHistory = () => {
  const api = useApi();
  const { t, getLanguage, translate } = useLocale();

  const pagination = usePagination();
  const { page, setPage, limit, order, orderBy } = pagination;

  const snack = useSnack();
  const { selectedPeriod } = useAuthData();
  const [records, setRecords] = useState({});
  const [total, setTotal] = useState(0);
  const [lastUpdate, setLastUpdate] = useState();
  const [date, setDate] = useState(moment().toDate());
  const [search, setSearch] = useState({ date });

  const [dialogMode, setDialogMode] = useState('');
  const [selectedRow, setSelectedRow] = useState();
  const timer = useRef();
  const [rawData, setRawData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setSearch((s) => ({ ...s, date: date ? date : null }));
  }, [date]);

  function getRows() {
    setRecords([]);
    api
      .getSubmittedHomeworkRecord({
        search,
        order_by: orderBy,
        order,
        limit,
        page,
        period_id: selectedPeriod
      })
      .then((res) => {
        if (res.data.last_updated_at !== lastUpdate) {
          setRecords(res.data.pages);
        } else {
          setRecords((h) => {
            return { ...h, ...res.data.pages };
          });
        }
        setTotal(res.data.total);
        setLastUpdate(res.data.last_updated_at);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    let pre = Math.max(0, page - 1);
    let current = page;
    let next = Math.min(total, page + 1);
    if (!(records[pre] && records[current] && records[next])) {
      getRows();
    }
  }, [page]);

  useEffect(() => {
    setRecords({});
    getRows();
  }, [orderBy, order, selectedPeriod]);

  useEffect(() => {
    setRecords({});
    if (page === 0) {
      getRows();
    } else {
      setPage(0);
    }
  }, [limit]);

  useEffect(() => {
    if (records) {
      setPage(0);
      setRecords({});
      clearTimeout(timer.current);
      timer.current = setTimeout(getRows, 500);
    }
  }, [search]);

  const handleDelete = (row) => {
    setDialogMode('delete');
    setSelectedRow(row);
  };

  const handleDeleteOne = async () => {
    setIsSubmitting(true);
    await api
      .deleteSubmittedHomeworkRecordByID(selectedRow?._id)
      .then((res) => {
        snack.open(t('delete_success'));
      })
      .catch((e) => {
        snack.error();
      })
      .finally(() => {
        setIsSubmitting(false);
        setDialogMode(null);
        setSelectedRow(null);
        getRows();
      });
  };

  const exportAll = async () => {
    var data = rawData['all'];
    if (!rawData['all']) {
      data = await api
        .getSubmittedHomeworkRecord({
          period_id: selectedPeriod,
          limit: 999999
        })
        .then((res) => {
          var result = _.get(res, 'data.pages.0', []);
          setRawData((d) => ({ ...d, all: result }));
          return result;
        });
    }
    utils.exportCsv(
      _.orderBy(data, 'date', 'desc'),
      [
        {
          label: t('date'),
          value: (row) => moment(row['date']).format('YYYY-MM-DD')
        },
        { label: t('class'), value: (row) => row.profile?.class },
        { label: t('class_no'), value: (row) => row.profile?.class_no },
        { label: t('name_zh'), value: (row) => row.user?.name?.zh },
        { label: t('name_en'), value: (row) => row.user?.name?.en },
        { label: t('status'), value: (row) => t(row?.status) },
        {
          label: t('action_created_by'),
          value: (row) => translate(row?.created_by?.name)
        }
      ],
      `Submitted Homework Record`
    );
    return null;
  };
  const exportFiltered = async () => {
    // var data = rawData[searchRange];
    // if (!rawData[searchRange]) {
    var data = await api
      .getSubmittedHomeworkRecord({
        period_id: selectedPeriod,
        limit: 999999,
        search
      })
      .then((res) => {
        var result = _.get(res, 'data.pages.0', []);
        // setRawData((d) => ({ ...d, [searchRange]: result }));

        return result;
      });
    // }
    var filterd = _.filter(data, (row) => {
      var fm = (str, field) => {
        if (!str || str === '') return true;
        var text = _.get(row, field);
        return new RegExp(
          _.toString(str).replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1'),
          'i'
        ).test(_.toString(text));
      };
      return (
        fm(search.class, 'profile.class') &&
        fm(search.class_no, 'profile.class_no') &&
        (fm(search.name, 'user.name.en') || fm(search.name, 'user.name.zh')) &&
        (fm(search.created_by, 'created_by.name.en') ||
          fm(search.created_by, 'created_by.name.zh'))
      );
    });

    utils.exportCsv(
      filterd,
      [
        {
          label: t('date'),
          value: (row) => moment(row['date']).format('YYYY-MM-DD')
        },
        { label: t('class'), value: (row) => row.profile?.class },
        { label: t('class_no'), value: (row) => row.profile?.class_no },
        { label: t('name_zh'), value: (row) => row.user?.name?.zh },
        { label: t('name_en'), value: (row) => row.user?.name?.en },
        { label: t('status'), value: (row) => t(row?.status) },
        {
          label: t('action_created_by'),
          value: (row) => _.get(row, `created_by.name.${getLanguage()}`)
        }
      ],
      `Submitted Homework Record`
    );
    return null;
  };

  const handleSearch = (path) => (e) => {
    setSearch((s) => ({ ...s, [path]: e.target.value }));
  };

  const { mutate: handleExportAll, ...handleExportAllMutation } =
    useMutation(exportAll);

  const { mutate: handleExport, ...handleExportMutation } =
    useMutation(exportFiltered);

  if (isLoading) {
    return (
      <LayoutContainer>
        <Box sx={{ height: '400px' }}>
          <LoadingView />
        </Box>
      </LayoutContainer>
    );
  }
  return (
    <Box m={2}>
      <Typography variant="h3" paragraph>
        {t('submitted_homework')}
      </Typography>
      {/* <Box className='flex ac' mb={2}> */}
      {(handleExportAllMutation.isLoading ||
        handleExportMutation.isLoading) && (
        <Alert variant="standard" severity="info" sx={{ mb: 2 }}>
          {t('exporting')}
        </Alert>
      )}
      <Grid
        container
        spacing={1}
        sx={{
          mb: 2,
          '& .MuiGrid-item': {
            pt: 1.5
          }
        }}
      >
        <Grid item>
          <TextField
            label={t('name')}
            size="small"
            onChange={handleSearch('name')}
            sx={{ width: '200px' }}
          />
        </Grid>
        <Grid item>
          <TextField
            label={t('class')}
            size="small"
            onChange={handleSearch('class')}
            sx={{ width: '200px' }}
          />
        </Grid>
        <Grid item>
          <TextField
            label={t('class_no')}
            size="small"
            onChange={handleSearch('class_no')}
            sx={{ width: '200px' }}
          />
        </Grid>
        <Grid item>
          <TextField
            label={t('action_created_by')}
            size="small"
            onChange={handleSearch('created_by')}
            sx={{ width: '200px' }}
          />
        </Grid>
        <Grid item>
          <TimePicker
            selectedDate={date}
            setSelectedDate={setDate}
          ></TimePicker>
        </Grid>

        <Grid item xs className="flex ac" justifyContent="flex-end">
          <Button
            onClick={handleExportAll}
            disabled={handleExportAllMutation.isLoading}
          >
            {t('export_all')}
          </Button>
          <Button
            sx={{ ml: 1 }}
            onClick={handleExport}
            disabled={handleExportMutation.isLoading}
          >
            {t('export')}
          </Button>
        </Grid>
      </Grid>

      <PagingTable
        rowClassName={(row) =>
          classNames({ 'text-green': row.type === 'redeem' })
        }
        total={total}
        // rowsRef={rowsRef}
        rows={records[page]}
        heads={[
          {
            label: t('date'),
            value: 'date',
            content: (row) => moment(row['date']).format('YYYY-MM-DD'),
            sort: true
          },
          { label: t('class'), value: 'profile.class' },
          { label: t('class_no'), value: 'profile.class_no' },
          { label: t('name_zh'), value: 'user.name.zh' },
          { label: t('name_en'), value: 'user.name.en' },
          { label: t('status'), content: (row) => t(row.status), translate: 1 },
          {
            label: t('action_created_by'),
            value: `created_by.name.${getLanguage()}`
          },
          {
            label: t('actions'),
            value: '',
            content: (row) => {
              return (
                <Box sx={{ mx: -1 }}>
                  <Tooltip title={t('deleteActions_confirmMsg')}>
                    <IconButton
                      onClick={() => handleDelete(row)}
                      sx={{ p: 0.5 }}
                    >
                      {' '}
                      <Icons.DeleteForeverIcon />{' '}
                    </IconButton>
                  </Tooltip>
                </Box>
              );
            }
          }
        ]}
        {...pagination}
      />
      <EditDialog
        title={t('deleteActions_confirmMsg')}
        open={dialogMode === 'delete'}
        handleClose={() => setDialogMode(null)}
        handleSave={handleDeleteOne}
        confirmText={t('delete')}
        confirmDisabled={isSubmitting}
      >
        {t('deleteActions_description')}
      </EditDialog>
    </Box>
  );
};

export default SubmittedHomeworkHistory;
