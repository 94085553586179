import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import useApi from 'src/api/api';
import Icons from 'src/icons';
import FacebookIcon from 'src/icons/Facebook';
import { useLocale } from 'src/provider/LocaleProvider';
import { useSnack } from 'src/provider/SnackbarProvider';
import formikProps from 'src/utils/formikProps';

const _ = require('lodash');

const useStyles = makeStyles((theme) => ({
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.png)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  form: {},
  a: {
    '&:visited': {
      color: 'inherit'
    },
    local: {
      '&:hover': {
        cursor: 'pointer'
      }
    }
  }
}));
const yup = require('yup');
const SetupPassoword = () => {
  const navigate = useNavigate();
  const { t, setLanguage } = useLocale();
  const api = useApi();
  const token = localStorage.getItem('token');
  const snack = useSnack();
  const classes = useStyles();

  const [userInfo, setUserInfo] = useState();

  useEffect(() => {
    if (token) {
      api.getUserInfo().then((res) => {
        setUserInfo(res.data);
      });
    } else {
      api.logout();
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      password: '',
      new_password: '',
      confirm_password: ''
    },
    validationSchema: yup.object().shape({
      password: yup.string().required().nullable(),
      new_password: yup
        .string()
        .min(6)
        .required()
        .nullable()
        .test('equal', t('yup_new_password'), function (value) {
          return this.parent.password !== value;
        })
        .label('new password'),
      confirm_password: yup
        .string()
        .required()
        .nullable()
        .test('equal', t('yup_confirm_password'), function (value) {
          return this.parent.new_password === value;
        })
        .label('confirm new password')
    }),
    onSubmit: (values, helper) => {
      api
        .setPassword({
          password: values.password,
          new_password: values.new_password
        })
        .then((res) => {
          if (userInfo.role == 'user') {
            navigate('/user', { replace: true });
          } else {
            navigate('/admin', { replace: true });
          }
        })
        .catch((e) => {
          helper.setFieldError('password', t('change_pw_fail'));
          snack.error(t('change_pw_fail'));
        })
        .finally(() => {
          helper.setSubmitting(false);
        });
    }
  });

  const {
    errors,
    setValues,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    touched,
    values
  } = formik;

  return (
    <>
      <Helmet>
        <title>Login - Pointant Class</title>
      </Helmet>
      <Grid container className="h100_w100">
        <Grid item xs={0} md={5}>
          <div className={classes.quote}></div>
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          className="flex jcc ac"
          sx={{ flexDirection: 'column', position: 'relative' }}
        >
          <Box sx={{ position: 'absolute', top: 0, left: 0 }}>
            <IconButton
              onClick={() => api.logout()}
              sx={{ width: '50px', height: '50px' }}
            >
              <Icons.ArrowBackIcon />
            </IconButton>
          </Box>
          <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
            <IconButton
              onClick={() => setLanguage('en')}
              className={classes.langIcon}
              sx={{ ml: 1 }}
            >
              EN
            </IconButton>
            <IconButton
              onClick={() => setLanguage('zh')}
              className={classes.langIcon}
            >
              繁
            </IconButton>
          </Box>
          <Box flexGrow={1} />
          <Box sx={{ width: { xs: '100%', sm: '80%' } }} p={3}>
            <Typography variant="h1" paragraph>
              {t('reset_password_title')}
            </Typography>
            <form onSubmit={handleSubmit} className="w80">
              <TextField
                fullWidth
                label={t('setup_password')}
                type="password"
                {...formikProps(formik, 'password')}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label={t('new_password')}
                type="password"
                {...formikProps(formik, 'new_password')}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label={t('confirm_password')}
                type="password"
                {...formikProps(formik, 'confirm_password')}
                sx={{ mb: 2 }}
              />
              <Box display="flex">
                <Box flexGrow={1} />
                <Button
                  color="primary"
                  disabled={isSubmitting || _.size(errors) > 0}
                  type="submit"
                  variant="contained"
                >
                  {'確認'}
                </Button>
              </Box>
            </form>
          </Box>
          <Box flexGrow={1} />
          <Box className="w100 tc">
            <span>
              Copyright © 2023 Animae Technologies Limited. All Rights Reserved.
            </span>
            <a
              className={classes.a}
              href="https://www.facebook.com/PointantClass"
              target="_blank"
              style={{ float: 'right' }}
            >
              <FacebookIcon sx={{ m: 0.5 }} />
            </a>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default SetupPassoword;
