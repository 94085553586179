import { Card } from '@material-ui/core';
import Icons from 'src/icons';

export const PreviewElement = ({ ext = '', params = {} }) => {
  const isImage =
    ext === 'jpg' || ext === 'jpeg' || ext === 'png' || ext === 'webm';
  const isPdf = ext === 'pdf';

  if (isImage) {
    return (
      <Card>
        <a href={params?.value} target="_blank" rel="noreferrer noopener">
          <img width={200} height={150} src={params?.value}></img>
        </a>
      </Card>
    );
  }
  if (isPdf) {
    return (
      <Card>
        <a href={params?.value} target="_blank" rel="noreferrer noopener">
          {/* <img width={300} height={200} src={params?.value}></img> */}
          <Icons.PictureAsPdfIcon sx={{ width: 200, height: 150 }} />
        </a>
      </Card>
    );
  }
  return (
    <a href={params?.value} target="_blank" rel="noreferrer noopener">
      <Icons.InsertDriveFileIcon sx={{ width: 200, height: 150 }} />
    </a>
  );
};
