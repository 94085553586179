import { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { styled } from '@material-ui/core/styles';
import DashboardNavbar from './DashboardNavbar'
import DashboardSidebar from './DashboardSidebar'

import { useLocale } from 'src/provider/LocaleProvider'
import AuthDataProvider, { useAuthData } from 'src/provider/AuthDataProvider'
import useApi from 'src/api/api';
import { Box } from '@material-ui/core';

const DashboardLayoutRoot = styled('div')(
  ({ theme }) => ({
    backgroundColor: '#f4f6f8',
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  })
)

const DashboardLayoutWrapper = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 256
    }
  })
)

const DashboardLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
})

const DashboardLayoutContent = styled('div')({
  display: 'flex',
  flexDirection: "column",
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto'
})

const Footer = styled('div')({
  textAlign: 'center',
  margin: '8px 0px',
  fontSize: '12px'
})



const DashboardLayout = ({ role = 'user' }) => {
  const [isNavigationDrawerOpen, setIsNavigationDrawerOpen] = useState(false)
  const location = useLocation()
  useEffect(() => {
    document.getElementById("body") && document.getElementById("body").scrollTo(0, 0)
  }, [location.pathname])
  useEffect(() => {
    setIsNavigationDrawerOpen(false)
  }, [location.pathname])

  return (
    <AuthDataProvider>
      <DashboardLayoutRoot>
        <DashboardNavbar
          onMobileNavOpen={() => setIsNavigationDrawerOpen(true)}
        />
        <DashboardSidebar
          role={role}
          onMobileClose={() => setIsNavigationDrawerOpen(false)}
          openMobile={isNavigationDrawerOpen}
        />
        <DashboardLayoutWrapper>
          <DashboardLayoutContainer>
            <DashboardLayoutContent id='body'>
              <Outlet />
              <Box flexGrow={1} />
              <Footer>
                Copyright &copy; {new Date().getFullYear()} Animae Technologies Limited. All Right Reserved
              </Footer>
            </DashboardLayoutContent>
          </DashboardLayoutContainer>
        </DashboardLayoutWrapper>
      </DashboardLayoutRoot>
    </AuthDataProvider>
  )
}

export default DashboardLayout
