import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core'
import React, { useState, useEffect, useContext, createContext } from 'react'


const BackdropContext = createContext()

export function useBackDrop() {
    const context = useContext(BackdropContext)
    return  {
        open: context.open,
        close: context.close
    }
}


export const DataBackdrop = ({ open }) => {
    return (
        <Backdrop open={open}>
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}


export default (props) => {
    const { children } = props
    const [open, setOpen] = useState(false)

    const state = {
        open: () => setOpen(true),
        close: () => setOpen(false)
    }

    return (
        <BackdropContext.Provider value={state}>
            {open && <Backdrop open={open} style={{ color: 'white', zIndex: 9999 }}>
                <CircularProgress style={{ color: 'white' }} />
            </Backdrop>}
            {children}
        </BackdropContext.Provider>
    )
}