const Logo = (props) => (
  <img
    alt="Logo"
    src="/images/logo.png"
    height='52'
    // width='52'
    {...props}
  />
)

export default Logo
