import { Helmet } from 'react-helmet';
import { Backdrop, Box, Container, Grid, Card, CardContent, Typography, CardHeader, Button, Divider, IconButton, TextField, InputAdornment, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useEffect } from 'react';
import { useBackDrop } from 'src/provider/BackdropProvider';
import { useState } from 'react';
import useApi from 'src/api/api';
import { ArrowBack } from '@material-ui/icons';
import Icons from 'src/icons';
import DataTable from 'src/components/DataTable/DataTable';
import DataTableNoLimit from 'src/components/DataTable/DataTableNoLimit';
import _ from 'lodash';
import { useNavigate } from 'react-router';
import { useLocale } from 'src/provider/LocaleProvider';
import utils from 'src/utils/utils';
import SearchInput from 'src/components/SearchInput';
import LayoutContainer from 'src/layout/LayoutContainer';
import { useAuthData } from 'src/provider/AuthDataProvider';
const moment = require('moment-timezone')

const useStyles = makeStyles(theme => ({

    row: {
        // height: '10px',
        // paddingBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2)
    },
    spacer: {
        flex: 1
    },

    tableCell: {
        "& .MuiTableCell-root": {
            textAlign: 'center',
            borderLeft: "none !important",
        },
        "& .MuiTableRow-root": {
            height: '60px'
        },

    },
    caption: {
        fontSize: '12px'
    }

}))

function sortRank(data, selectedCategory) {
    var temp
    if (selectedCategory && selectedCategory !== 'null') {
        temp = _.map(data, r => {
            var p = _.find(r?.pointsByRuleCategories, { ruleCategory_id: selectedCategory })
            if (p) {
                return ({ ...r, point: p.total || 0 })
            }
            return ({ ...r, point: 0 })
        })
    }
    else {
        temp = _.map(data, r => ({ ...r, point: r.totalpoints || 0 }))
    }
    temp = _.orderBy(temp, 'point', 'desc')
    console.log('sortRank => temp', temp)
    return temp
}



const ClassRanking = () => {
    const classes = useStyles()
    const loadingView = useBackDrop();
    const api = useApi()
    const nav = useNavigate()
    const { t, getLanguage } = useLocale()

    const { selectedPeriod, organization, } = useAuthData()
    const [classRanking, setClassRanking] = useState([])
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('null');

    const handleExport = () => {
        utils.exportCsv(classRanking, [
            { label: t('rank'), value: (row) => { return (_.findIndex(classRanking, (k) => k.class === row.class) + 1) } },
            { label: t('class'), value: (row) => row['class'], },
            { label: t('total_point'), value: (row) => row['totalpoints'] }
        ], 'class_ranking')
    }
    useEffect(() => {
        api.getPointRuleCategories({ setCategories })
    }, []);

    useEffect(() => {
        var temp = sortRank(classRanking, selectedCategory)
        setClassRanking(temp)
    }, [selectedCategory]);

    useEffect(() => {
        if (selectedPeriod) {
            api.getClassRanking(selectedPeriod).then(({ data }) => {
                var temp = sortRank(data, selectedCategory)
                setClassRanking(temp)
            })
        }
    }, [selectedPeriod])
    return (
        <LayoutContainer title={t('classRankTable_title')} backSrc='/admin/dashboard'>
            <Grid container spacing={1} alignItems="center" sx={{ mb: 2 }} >
                <Grid item xs>
                    <TextField
                        size='small'
                        select
                        fullWidth
                        value={selectedCategory}
                        label={t('rule_category')}
                        sx={{ maxWidth: '500px' }}
                        onChange={e => setSelectedCategory(e.target.value)}
                    >
                        <MenuItem value={'null'}> {t('all_rule_category')} </MenuItem>
                        {_.map(categories, c => {
                            return <MenuItem key={c._id} value={c._id}> {_.get(c, 'name.' + getLanguage())} </MenuItem>
                        })}
                    </TextField>
                </Grid>
                <Grid item >
                    <Button onClick={handleExport} >
                        {t('export')}
                    </Button>
                </Grid>
            </Grid>
            <DataTable
                sx={{ mb: 1 }}
                className={classes.tableCell}
                rows={(classRanking)}
                heads={[
                    { label: t('rank'), value: 'rank', content: (row, index) => { return (_.findIndex(classRanking, (k) => k.class === row.class) + 1) } },
                    { label: t('class'), value: 'class', },
                    { label: t('total_point'), value: 'point' }
                ]}

            />
            <div align='right' className={'font12'}>Last Updated at {moment().format("YYYY-MM-DD HH:mm:ss")}</div>

        </LayoutContainer>
    )
}
export default ClassRanking;
