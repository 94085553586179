import { Box, Button, Container, IconButton, InputAdornment, MenuItem, Select, Switch, TextField, Tooltip } from '@material-ui/core';
import React, { Fragment, useMemo, useCallback, useEffect, useState, useRef, useLayoutEffect } from 'react'
import useApi from 'src/api/api';
import { Helmet } from 'react-helmet';
import DataTable from 'src/components/DataTable/DataTable';
import { useLocale } from 'src/provider/LocaleProvider';
import { useAuthData } from 'src/provider/AuthDataProvider';
import Icons from 'src/icons';
import EditDialog from 'src/components/EditDialog';
import { Formik, useFormik } from 'formik';
import formikProps from 'src/utils/formikProps';
import utils from 'src/utils/utils';
import SearchInput from 'src/components/SearchInput';
import _ from 'lodash';
import { UsbTwoTone } from '@material-ui/icons';
import { useSnack } from 'src/provider/SnackbarProvider';
import LayoutContainer from 'src/layout/LayoutContainer';
import classNames from 'classnames';
import LoadingView from 'src/components/LoadingView';
const moment = require('moment-timezone')

const yup = require('yup')

const BadgeList = () => {
    const api = useApi()
    const { t, getLanguage } = useLocale()

    const rowsRef = useRef([])
    const snack = useSnack()
    const { selectedPeriod } = useAuthData()

    const [search, setSearch] = useState('');


    const [badgeList, setBadgeList] = useState();
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {setIsLoading(true)
        api.getBadge({ period: selectedPeriod }).then(res => setBadgeList(res.data))
        .then(() => setIsLoading(false))
    }, [selectedPeriod]);

    const handleExportAll = () => {
        utils.exportCsv(_.orderBy(badgeList, 'created_at', 'desc'), [
            { label: t('created_at'), value: row => new Date(row.created_at).toLocaleString() },
            { label: t('attendancerule_name'), value: row => row.attendanceRule?.name },
            { label: t('class'), value: row => row.profile?.class },
            { label: t('class_no'), value: row => row.profile?.class_no },
            { label: t('name_zh'), value: row => row.user?.name?.zh },
            { label: t('name_en'), value: row => row.user?.name?.en },
            { label: t('state'), value: row => t(row.type) },
        ], `attendance_history`)
    }

    const handleExport = () => {
        utils.exportCsv(rowsRef.current, [
            { label: t('created_at'), value: row => new Date(row.created_at).toLocaleString() },
            { label: t('attendancerule_name'), value: row => row.attendanceRule?.name },
            { label: t('class'), value: row => row.profile?.class },
            { label: t('class_no'), value: row => row.profile?.class_no },
            { label: t('name_zh'), value: row => row.user?.name?.zh },
            { label: t('name_en'), value: row => row.user?.name?.en },
            { label: t('state'), value: row => t(row.type) },
        ], `attendance_history`)
    }

    if (isLoading) {
        return <LoadingView></LoadingView>;
    }

    return (
        <LayoutContainer title={t('badges')}>
            <Box className='flex ac' mb={2}>
                <SearchInput setSearch={setSearch} />
                <div className="spacer" />
                {/* <Button sx={{ ml: 1 }} onClick={handleExportAll}>
                    {t('export_all')}
                </Button>
                <Button sx={{ ml: 1 }} onClick={handleExport}>
                    {t('export')}
                </Button> */}
            </Box>
            <DataTable rowClassName={row => classNames({ 'text-green': row.type == 'checkin' })}
                rowsRef={rowsRef} rows={utils.searchFilter(badgeList, search, [
                    'profile.class',
                    'profile.class_no',
                    'user.name.en',
                    'user.name.zh',
                    'badge_rule.name',
                    // (row) => t(row.type || ''),
                    // (row) => new Date(row.created_at).toLocaleString()
                ])} heads={[
                    // { id: 'u_id', label: t('u_id'), value: 'profile.u_id', sort: true },
                    { id: 'created_at', label: t('created_at'), value: 'created_at', content: (row) => new Date(row.created_at).toLocaleString(), sort: true },
                    { id: 'badge_rule_name', label: t('badge_rule_name'), value: 'badge_rule.name', sort: true },
                    { id: 'class', label: t('class'), value: 'profile.class', sort: true },
                    { id: 'class_no', label: t('class_no'), value: 'profile.class_no', sort: true },
                    { id: 'name_zh', label: t('name_zh'), value: 'user.name.zh', sort: true },
                    { id: 'name_en', label: t('name_en'), value: 'user.name.en', sort: true },
                    // { id: 'deviceName', label: t('deviceName'), value: 'device.name', sort: true },
                    // { id: 'state', label: t('state'), value: 'type', translate: 1, sort: true },
                ]}
            />
        </LayoutContainer>
    );
}

export default BadgeList;