import { Backdrop, Box, Button, Card, CardContent, CardMedia, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Paper, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import useApi from 'src/api/api';
import DataTable from 'src/components/DataTable/DataTable';
import Icons from 'src/icons';
import LayoutContainer from 'src/layout/LayoutContainer';
import { useAuthData } from 'src/provider/AuthDataProvider';
import { useLocale } from 'src/provider/LocaleProvider';
import classNames from 'classnames';
import EditDialog from 'src/components/EditDialog';

const prettyBytes = require('pretty-bytes');

const useStyles = makeStyles({
    list: {
        listStyle: 'none',
        minHeight: '500px',

    },
    listItem: {
        borderBottom: '1px solid rgba(0,58,137,.2)',
        // marginBottom: '16px',
        padding: '16px',
    },
    lastItem: {
        marginBottom: '0px'
    },

    listText: {
        marginBottom: 0,
        marginTop: '8px',
        '&:hover': {
            transition: 'opacity .2s',
            opacity: '.6'
        }
    },
    pager: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    isRead: {
        backgroundColor: 'rgba(0,0,0,0.1)'
    }

})

export default function Badge() {
    const nav = useNavigate()
    const { t } = useLocale()
    const { selectedPeriod, userInfo } = useAuthData()
    const api = useApi()
    const classes = useStyles()
    const [badges, setBadges] = useState([]);
    const [selectedBadge, setSelectedBadge] = useState();

    useEffect(() => {
        api.getBadgeGroupByRule({ user: userInfo?._id, getUngainedBadges: true }).then(res => {
            setBadges(res.data)
        })
    }, []);

    return (
        <LayoutContainer title={t('badges')}>
            <Grid container spacing={2} sx={{
                '.grayscale': {
                    filter: 'grayscale(100%)',
                    opacity: 0.5
                }
            }}>
                {_.map(badges, badge => {
                    var rule = badge.badge_rule
                    var image_url = rule?.image_url
                    if (!rule) return null
                    var styles = {}
                    if (badge.count < 1) {
                        styles = {
                            filter: 'grayscale(100%)',
                        }
                    }
                    // return (
                    //     <Box>
                    //         <Box className='h100 w100 tc' sx={{ position: "relative", overflow: 'visible' }} onClick={() => setSelectedBadge(badge)}>
                    //             <Box className='aspect-ratio-wrapper' pt='100%'>
                    //                 <img className={classNames('aspect-ratio-container', { 'grayscale': badge.count < 1 })} src={image_url} style={{ padding: '5%' }} />
                    //             </Box>
                    //             <Box p={1} tex>
                    //                 <Typography align='center'>
                    //                     {rule.name}
                    //                 </Typography>
                    //             </Box>
                    //             {badge.count > 0 &&
                    //                 <Box className='tc' sx={{ borderRadius: '12px', width: "fit-content", backgroundColor: 'rgba(200,200,200,0.5)', px: 1, py: 0.25, margin: 'auto' }}>
                    //                     {badge.count}
                    //                 </Box>
                    //             }
                    //         </Box>
                    //     </Box>
                    // )

                    return (
                        <Grid item sx={{ width: '160px' }}>
                            <Box className='h100 w100 tc' sx={{ position: "relative", overflow: 'visible' }} onClick={() => setSelectedBadge(badge)}>
                                <Box className='aspect-ratio-wrapper' pt='100%'>
                                    <img className={classNames('aspect-ratio-container', { 'grayscale': badge.count < 1 })} src={image_url} style={{ padding: '5%' }} />
                                </Box>
                                <Box p={1} tex>
                                    <Typography align='center'>
                                        {rule.name}
                                    </Typography>
                                </Box>
                                {badge.count > 0 &&
                                    <Box className='tc' sx={{ borderRadius: '12px', width: "fit-content", backgroundColor: 'rgba(200,200,200,0.5)', px: 1, py: 0.25, margin: 'auto' }}>
                                        {badge.count}
                                    </Box>
                                }
                            </Box>
                        </Grid>
                    )
                })}
            </Grid>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={selectedBadge}
                onClick={() => setSelectedBadge(null)}
            >
                {selectedBadge &&
                    <Card className='w100 h100' sx={{ padding: '10%', display: 'flex', flexDirection: 'column', alignItem: 'center', justifyContent: 'center' }} >
                        <Box flex={'1'} className='aspect-ratio-wrapper' mb={2} >
                            <img className='aspect-ratio-container' src={selectedBadge?.badge_rule?.image_url} />
                        </Box>
                        <IconButton sx={{ position: 'absolute', top: 0, right: 0 }}>
                            <Icons.CloseIcon sx={{ height: 48, width: 48 }} />
                        </IconButton>
                        <Box className='tc' sx={{
                            '& p': {
                                mt: 1
                            }
                        }}>
                            <p className='bold'>{`${selectedBadge.badge_rule?.name}`}</p>
                            {selectedBadge.badge_rule?.description && selectedBadge.badge_rule?.description != '' && <p>{`${selectedBadge.badge_rule?.description}`}</p>}
                            {selectedBadge.count > 0 ?
                                <>
                                    <p>{`${t('badge_get_a_times_msg', { a: selectedBadge.count })}`}</p>
                                    <p>{`${t('badge_latest_gain_at')}: ${moment(selectedBadge.last_created_at).format('YYYY/MM/DD')}`}</p>
                                </>
                                : <p>{t('badge_not_get_msg')}</p>
                            }
                        </Box>
                    </Card>
                }
            </Backdrop>
            {/* <EditDialog open={selectedBadge} handleClose={() => setSelectedBadge(null)}>
                <img src={selectedBadge?.badge_rule.image_url} className='w100 h100' />
            </EditDialog> */}
        </LayoutContainer >
    )
}