

import React, { Fragment, useMemo, useCallback, useEffect, useState, useRef, useLayoutEffect } from 'react'
import useApi from 'src/api/api';
import LayoutContainer from 'src/layout/LayoutContainer';
import { useLocale } from 'src/provider/LocaleProvider';
import { Backdrop, Box, Container, Grid, Card, CardContent, Typography, CardHeader, Button, Divider, IconButton, List, ListItem, ListItemText, TextField, MenuItem, Autocomplete } from '@material-ui/core';
import TransferTable from 'src/components/TransferTable';
import SearchInput from 'src/components/SearchInput';
import utils from 'src/utils/utils';
import ClassSelector from 'src/components/ClassSelector';
import { makeStyles } from '@material-ui/styles';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { useSnack } from 'src/provider/SnackbarProvider';
import formikProps from 'src/utils/formikProps';
import { Handyman } from '@material-ui/icons';
import { useAuthData } from 'src/provider/AuthDataProvider';
const _ = require('lodash')
const yup = require('yup')

export const CheckIn = props => {
    return <TakeAttendance checkin={true} {...props} />
}


export const CheckOut = props => {
    return <TakeAttendance checkin={false} {...props} />
}

const TakeAttendance = ({ checkin = true, snack }) => {

    const { selectedPeriod } = useAuthData()
    const { t, translate, getLanguage } = useLocale()
    const [rules, setRules] = useState([]);
    const [studentList, setStudentList] = useState([]);
    const [selectedRule, setSelectedRule] = useState();
    const [selectedStudent, setSelectedStudent] = useState([]);

    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState();


    const api = useApi()

    useEffect(() => {
        api.getAttendanceRules().then(res => {
            setRules(res.data)
        })
    }, [])

    useEffect(() => {
        api.getStudentList().then(res => {
            setSelectedStudent([])
            setStudentList(res.data)
        })
    }, [selectedPeriod]);

    const formik = useFormik({
        initialValues: {
            attendanceRule: '',
            profiles: []
        },
        validationSchema: yup.object().shape({
            attendanceRule: yup.string().required(),
            profiles: yup.array().min(1).of(yup.string().required()),
        }),
        onSubmit: async (values, helper) => {
            try {
                if (checkin) {
                    await api.studentCheckin(values)
                }
                else {
                    await api.studentCheckout(values)
                }
                snack.open(checkin ? t('checkin_success') : t('checkout_success'))
                // setSelectedRule({})
                setSelectedStudent([])
                helper.setSubmitting(false)
            } catch (error) {
                snack.error(checkin ? t('checkin_fail') : t('checkout_fail'))
                helper.setSubmitting(false)
            }

        }
    })

    const { values, errors } = formik

    var classFitered = utils.searchFilter(studentList, filter?.value, ['class'])

    useEffect(() => {
        formik.setFieldValue('profiles', _.map(selectedStudent, s => s.profile))
    }, [selectedStudent]);

    useEffect(() => {
        formik.setFieldValue('attendanceRule', selectedRule?._id)
    }, [selectedRule]);

    return (
        <LayoutContainer title={checkin ? t('checkin') : t('checkout')}>
            <form onSubmit={formik.handleSubmit}>
                <Typography variant='h3' paragraph>{t('choose_attendance_rule')}</Typography>
                <Autocomplete
                    options={rules}
                    value={selectedRule || {}}
                    getOptionLabel={op => op.name || ''}
                    renderInput={(params) => <TextField {...params} label={t('attendanceRule')} variant="outlined" />}
                    onChange={(e, v) => setSelectedRule(v)}
                    sx={{ mb: 3 }}
                />
                {values.attendanceRule &&
                    <Fragment>
                        <Typography variant='h3' paragraph>{t('choose_student')}</Typography>
                        <Grid container spacing={1} sx={{ mb: 2 }}>
                            <Grid item>
                                <ClassSelector selected={filter} setSelected={setFilter} all />
                            </Grid>
                            <Grid item>
                                <SearchInput setSearch={setSearch} />
                            </Grid>
                        </Grid>
                        <TransferTable list={utils.searchFilter(classFitered, search, ['class', 'class_no', 'name'])} selected={selectedStudent} setSelected={setSelectedStudent}
                            leftHeads={[
                                { id: 'class', label: t('class'), value: 'class' },
                                { id: 'class_no', label: t('class_no'), value: 'class_no' },
                                { id: 'name', label: t('name'), value: `name.${getLanguage()}` },
                            ]}
                            rightHeads={[
                                { id: 'class', label: t('class'), value: 'class' },
                                { id: 'class_no', label: t('class_no'), value: 'class_no' },
                                { id: 'name', label: t('name'), value: `name.${getLanguage()}` },
                            ]}
                        />
                    </Fragment>
                }
                <Box className='flex ac' justifyContent='flex-end' mt={2}>
                    <Button type='submit' disabled={_.size(formik.errors) > 0}>{t('confirm')}</Button>
                </Box>
            </form>
        </LayoutContainer >
    )
}

export default TakeAttendance