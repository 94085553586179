import React from 'react';
import useApi from 'src/api/api';
import LayoutContainer from 'src/layout/LayoutContainer';
import { useLocale } from 'src/provider/LocaleProvider';
import {
  Box,
  Card,
  Typography,
  Button,
  TextField,
  CardContent
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useSnack } from 'src/provider/SnackbarProvider';
import formikProps from 'src/utils/formikProps';
import { useAuthData } from 'src/provider/AuthDataProvider';

const _ = require('lodash');
const yup = require('yup');
// const auth = useAuthData();

const HomeworkSetting = () => {
  const api = useApi();
  const { t } = useLocale();

  const { organization } = useAuthData();

  const snack = useSnack();

  // const [isActive, setIsActive] = useState(true);

  // useEffect(() => {
  //   api.getPeriodList((result) => {
  //     const currentPeriodObject = _.filter(
  //       result,
  //       (period) => period?._id === currentPeriod
  //     );

  //   });
  // }, [currentPeriod]);
  const formik = useFormik({
    initialValues: {
      submitHomeworkRange:
        organization?.homework_date_range.submitHomeworkRange ?? -1,
      unfinishHomeworkRange:
        organization?.homework_date_range.unfinishHomeworkRange ?? -1,
      isActive: true
    },
    validationSchema: yup.object().shape({
      submitHomeworkRange: yup.number().integer().min(0).required(),
      unfinishHomeworkRange: yup.number().integer().min(0).required(),
      isActive: yup.boolean()
    }),
    onSubmit: (values, helper) => {
      console.log(values);
      helper.setSubmitting(true);
      api
        .putHomeworkDateRange(values)
        .then((res) => {
          return snack.open(t('upload_success'));
        })
        .catch((err) => {
          console.log(err);
          return snack.error(t('upload_fail'));
        })
        .finally(() => {
          helper.setSubmitting(false);
        });
    }
  });

  return (
    <LayoutContainer title={t('settings')}>
      <form onSubmit={formik.handleSubmit}>
        <Card>
          <CardContent>
            <Box sx={{ my: 2 }}>
              <Typography variant="h3" paragraph>
                {t('date_back_range_submitted_homework')}
              </Typography>
              <Box sx={{ display: 'flex', mx: 1, my: 2 }}>
                <TextField
                  label={t('date_back_range_label')}
                  fullWidth
                  {...formikProps(formik, 'submitHomeworkRange')}
                />
              </Box>

              <Typography variant="h3" paragraph>
                {t('date_back_range_uncompleted_homework')}
              </Typography>
              <Box sx={{ display: 'flex', mx: 1, my: 2 }}>
                <TextField
                  label={t('date_back_range_label')}
                  fullWidth
                  {...formikProps(formik, 'unfinishHomeworkRange')}
                />
              </Box>
              {/* <Box sx={{ my: 2 }}>
                <Typography variant="h3" paragraph>
                  {t('status')}
                </Typography>
                <Box sx={{ mx: 1 }}>
                  <FormControl fullWidth={true}>
                    <InputLabel shrink>{t('status')}</InputLabel>
                    <Select
                      className="mySelect"
                      id="status"
                      label={t('status')}
                      variant="outlined"
                      size="small"
                      displayEmpty
                      onChange={(event) => setIsActive(event.target.value)}
                      value={true}
                      sx={{
                        height: '50px',
                        minWidth: '200px',
                        width: '100%'
                      }}
                      fullWidth={true}
                      {...formikProps(formik, 'isActive')}
                    >
                      <MenuItem key={'active'} value={true}>
                        {t('active')}
                      </MenuItem>
                      <MenuItem key={'inactive'} value={false}>
                        {t('inactive')}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box> */}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  type="submit"
                  disabled={_.size(formik.errors) > 0 || formik.isSubmitting}
                >
                  {t('confirm')}
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </form>
    </LayoutContainer>
  );
};

export default HomeworkSetting;
