import AddIcon from '@material-ui/icons/Add';
import AirplayIcon from '@material-ui/icons/Airplay';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CakeIcon from '@material-ui/icons/Cake';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import EmailIcon from '@material-ui/icons/Email';
import GetAppIcon from '@material-ui/icons/GetApp';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import InputIcon from '@material-ui/icons/Input';
import LaunchIcon from '@material-ui/icons/Launch';
import NoticeIcon from '@material-ui/icons/Notifications';
import PublishIcon from '@material-ui/icons/Publish';
import RemoveIcon from '@material-ui/icons/Remove';
import SearchIcon from '@material-ui/icons/Search';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import FavouriteIcon from '@material-ui/icons/Star';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { FaAward } from 'react-icons/fa';
import { FiHelpCircle } from 'react-icons/fi';
import { IoIosHelpCircleOutline } from 'react-icons/io';
import { IoHelpCircleOutline, IoHelpSharp } from 'react-icons/io5';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import SdStorageIcon from '@material-ui/icons/SdStorage';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'

const Icons = {
  AddIcon,
  CakeIcon,
  SearchIcon,
  DeleteIcon,
  RemoveIcon,
  VisibilityIcon, //Eye Icon
  EditIcon, // Pen
  DeleteForeverIcon,
  AttachFileIcon,
  CheckIcon,
  CloseIcon,
  GetAppIcon,
  FavouriteIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowBackIcon,
  AirplayIcon,
  PublishIcon,
  NoticeIcon,
  InputIcon,
  VpnKeyIcon,
  EmailIcon,
  SportsEsportsIcon,
  HelpOutlineIcon,
  LaunchIcon,
  FiHelpCircle,
  IoIosHelpCircleOutline,
  IoHelpCircleOutline,
  IoHelpSharp,
  FaAward,
  PictureAsPdfIcon,
  InsertDriveFileIcon,
  SdStorageIcon
};

export default Icons;
