import {
  Box,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography
} from '@material-ui/core';
import { useState } from 'react';
import { useQuery } from 'react-query';
import useApi from 'src/api/api';
import CustomDatePicker from 'src/components/DatePicker';
import LoadingView from 'src/components/LoadingView';
import LayoutContainer from 'src/layout/LayoutContainer';
import { useAuthData } from 'src/provider/AuthDataProvider';

const moment = require('moment-timezone');
const _ = require('lodash');

const ClassSubmittedRecord = () => {
  const [date, setDate] = useState(moment().toDate());
  const { selectedPeriod } = useAuthData();
  const api = useApi();
  const { isLoading, error, data, isFetching } = useQuery(
    ['class-submit-homeword-records', date, selectedPeriod],
    () => api.getClassSubmittedRecord({ date: date }).then((res) => res.data),
    {
      refetchOnWindowFocus: false
    }
  );
  if (error) {
    return (
      <Box m={2}>
        <Typography>{error?.message}</Typography>
      </Box>
    );
  }
  if (isLoading || isFetching) {
    return (
      <LayoutContainer>
        <Box sx={{ height: '400px' }}>
          <LoadingView />
        </Box>
      </LayoutContainer>
    );
  }
  return (
    <Box m={2}>
      <CustomDatePicker
        key={'datePicker'}
        readOnly={true}
        selectedDate={date}
        setSelectedDate={setDate}
      ></CustomDatePicker>
      <FormGroup sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
        {_.map(data, (record) => {
          const [_class, isSubmited] = Object.entries(record)[0];

          return (
            <FormControlLabel
              key={_class}
              control={
                <Switch key={_class} checked={isSubmited} color="success" />
              }
              label={_class}
              labelPlacement="top"
            ></FormControlLabel>
          );
        })}
      </FormGroup>
    </Box>
  );
};

export default ClassSubmittedRecord;
